import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GetAppIcon from '@mui/icons-material/GetApp';
import SearchIcon from '@mui/icons-material/Search';
import { Button, IconButton, InputBase, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PredeterminedProjectsTable, SimpleTwoActionsDialogContent } from 'components';
import InputFile from 'components/InputFile/InputFile.component';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import theme from 'theme';

function PredeterminedProjectsLibrary(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const selectedProjects = location.state?.selectedProjects || [];

  const [deleteAllConfirmationDialogOpened, setDeleteAllConfirmationDialogOpened] = useState(false);

  const {
    deletePredeterminedProject,
    deleteAllPredeterminedProjects,
    getAllPredeterminedProjectList,
    getOperation,
    predeterminedProjectList,
    deleteAllPredeterminedProjectsState,
    predeterminedProjectsImport,
    resetPredeterminedProject,
    setPredeterminedProjectsSearch,
    setPredeterminedProjectsPage,
    uploadPredeterminedProjectsFile
  } = props;

  const openCreatePredeterminedProject = () => {
    resetPredeterminedProject();
    navigate({ pathname: '/predetermined-project' });
  };

  const navigateToLibrary = () => {
    navigate({ pathname: '/library' });
  };

  const openEditPredeterminedProject = (predeterminedProject) => {
    navigate({
      pathname: `/predetermined-project${predeterminedProject ? `/${predeterminedProject.id}` : ''}`
    });
  };

  const openDeletePredeterminedProject = (predeterminedProject) => {
    deletePredeterminedProject(predeterminedProject);
  };

  const mapPredeterminedProjectToDataTableRow = (predeterminedProject) => ({
    id: predeterminedProject.Id,
    Name: predeterminedProject.DtId,
    AssetClass: predeterminedProject.AssetClass,
    EquipmentGroup: predeterminedProject.OnEquipmentGroup,
    Operation: predeterminedProject.Operation,
    Date: predeterminedProject.PlannedDate
  });

  const mapPredeterminedProjectsListToDataTable = () =>
    // surcharge list from gantt if user is in gantt
    selectedProjects && selectedProjects.length > 0
      ? selectedProjects.map((predeterminedProject) => mapPredeterminedProjectToDataTableRow(predeterminedProject))
      : predeterminedProjectList.data.map((predeterminedProject) =>
          mapPredeterminedProjectToDataTableRow(predeterminedProject)
        );

  const onEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getAllPredeterminedProjectList();
    }
  };

  return (
    <>
      <div className={classes.topBar}>
        <div style={{ flex: 1 }}>
          <Button className='backBtn' color='primary' variant='contained' onClick={() => navigateToLibrary()}>
            <ArrowBackIcon /> {t('layouts.back')}
          </Button>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div className={classes.flexSearch}>
            <Paper className={classes.searchInput} component='form'>
              <InputBase
                className={classes.searchInputField}
                value={predeterminedProjectList.search}
                onChange={(event) => setPredeterminedProjectsSearch(event.target.value)}
                onKeyDown={onEnterPress}
              />
              <IconButton
                aria-label='search'
                className={classes.searchIcon}
                size='large'
                type='button'
                onClick={getAllPredeterminedProjectList}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>

          <div className={classes.buttonContainerRight}>
            <InputFile
              acceptFilesFormat='application/zip'
              error={predeterminedProjectsImport.errors}
              importFile={uploadPredeterminedProjectsFile}
              loading={predeterminedProjectsImport.uploading}
            >
              {t('commoncomponents.import.action')} <GetAppIcon />
            </InputFile>
            <Button color='primary' variant='contained' onClick={() => setDeleteAllConfirmationDialogOpened(true)}>
              {t('views.library.predeterminedproject.deleteAll')} <DeleteForeverIcon />
            </Button>
            <Button color='primary' variant='contained' onClick={() => openCreatePredeterminedProject()}>
              {t('views.library.predeterminedproject.newPredeterminedProject')} <ControlPointIcon />
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.tableContainer}>
        <Paper className={classes.tablePaper}>
          <PredeterminedProjectsTable
            currentPage={predeterminedProjectList.page}
            elementPerPage={predeterminedProjectList.elementPerPage}
            getOperation={getOperation}
            loading={predeterminedProjectList.loading || deleteAllPredeterminedProjectsState.loading}
            openDeletePredeterminedProject={openDeletePredeterminedProject}
            openEditPredeterminedProject={openEditPredeterminedProject}
            pageCount={predeterminedProjectList.pageCount}
            predeterminedProjectsList={mapPredeterminedProjectsListToDataTable()}
            refreshDataUsingPagination={setPredeterminedProjectsPage}
          />
        </Paper>
      </div>
      <SimpleTwoActionsDialogContent
        handleClickOnButton1={() => setDeleteAllConfirmationDialogOpened(false)}
        handleClickOnButton2={() => {
          deleteAllPredeterminedProjects();
          setDeleteAllConfirmationDialogOpened(false);
        }}
        id='delete-all-planned-projects-confirmation'
        labels={{
          title: t('genericcomponent.dialog.library.predeterminedproject_delete_all.title'),
          body: t('genericcomponent.dialog.library.predeterminedproject_delete_all.body'),
          button1: t('genericcomponent.dialog.library.predeterminedproject_delete_all.cancel'),
          button2: t('genericcomponent.dialog.library.predeterminedproject_delete_all.delete'),
          ariaLabelledby: 'confirm-exit-dialog',
          button2BGColor: '#062F4F'
        }}
        open={deleteAllConfirmationDialogOpened}
      />
    </>
  );
}

PredeterminedProjectsLibrary.propTypes = {
  deletePredeterminedProject: PropTypes.func.isRequired,
  deleteAllPredeterminedProjects: PropTypes.func.isRequired,
  getAllPredeterminedProjectList: PropTypes.func.isRequired,
  predeterminedProjectList: PropTypes.object.isRequired,
  deleteAllPredeterminedProjectsState: PropTypes.object.isRequired,
  predeterminedProjectsImport: PropTypes.object.isRequired,
  resetPredeterminedProject: PropTypes.func.isRequired,
  setPredeterminedProjectsSearch: PropTypes.func.isRequired,
  setPredeterminedProjectsPage: PropTypes.func.isRequired,
  uploadPredeterminedProjectsFile: PropTypes.func.isRequired,
  getOperation: PropTypes.func.isRequired
};

export default PredeterminedProjectsLibrary;

const useStyles = makeStyles(() => ({
  topBar: {
    display: 'flex',
    width: '90%',
    margin: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0 0'
  },
  flexSearch: {
    display: 'flex'
  },
  buttonContainerRight: {
    display: 'flex',
    flex: 2,
    alignSelf: 'center',
    gap: '1rem'
  },
  searchInput: {
    backgroundColor: theme.palette.nexans.white,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    marginRight: 24
  },
  searchInputField: {
    padding: 8,
    flex: 1,
    color: 'black'
  },
  searchIcon: {
    background: theme.palette.background.gradient,
    color: theme.palette.nexans.white,
    borderRadius: '0 10px 10px 0'
  },
  tableContainer: {
    width: '90%',
    margin: '20px auto 0'
  },
  tablePaper: {
    background: theme.palette.nexans.white
  }
}));
