import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ApiFinance } from '../../../../api/ApiFinance';
import { FINANCE_ACTIONS_KEY } from '../../../commons/FinanceConstants';
import { selectOrganizationId } from '../../sagaUtils';

function getSyncedList(networkLevelList, scenarioStart, scenarioEnd, data, currentScenarioId, simulationRunId) {
  const array = [];

  networkLevelList.forEach((nl) => {
    for (let iYear = scenarioStart - 1; iYear <= scenarioEnd; iYear += 1) {
      const found = data.find((el) => el.year === iYear && el.networkLevel === nl);
      const defaultFound = data.find((el) => el.year === iYear && el.networkLevel === 'Default');
      const closestDefaultValue = data
        .filter((el) => el.networkLevel === 'Default')
        .sort((a, b) => Math.abs(a.year - iYear) - Math.abs(b.year - iYear))[0];

      found
        ? array.push(found)
        : array.push({
            networkLevel: nl,
            year: iYear,
            suppliedEnergy: defaultFound?.suppliedEnergy || closestDefaultValue?.suppliedEnergy || 0,
            extraCharges: defaultFound?.extraCharges || closestDefaultValue?.extraCharges || 0,
            scenarioId: currentScenarioId,
            simulationRun: simulationRunId
          });
    }
  });
  return array;
}

export function* getAllOperatingForecast() {
  try {
    const currentScenarioId = yield select((state) => state.scenario.current.data.id);
    const simulationRunId = yield select((state) => state.scenario.current.data.lastRun?.csmSimulationRun);
    const networkLevelList = yield select((state) => state.finance.networkLevel.list);
    const scenarioStart = yield select(
      (state) =>
        state.scenario.current.data?.parametersValues?.find(
          (el) => el.parameterId === 'parameter_scenario_global_date_de_debut_scenario'
        )?.value
    );
    const scenarioEnd = yield select(
      (state) =>
        state.scenario.current.data?.parametersValues?.find(
          (el) => el.parameterId === 'parameter_scenario_global_date_de_fin_scenario'
        )?.value
    );

    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiFinance.fetchAllOperatingForecastFromCurrentScenarioId, organizationId, {
      currentScenarioId,
      simulationRunId
    });

    if (data?.length > 0) {
      yield put({
        type: FINANCE_ACTIONS_KEY.SET_ALL_OPERATING_FORECAST,
        operatingForecastList: data
      });
    }
    if (data?.length && networkLevelList?.length > 0) {
      yield put({
        type: FINANCE_ACTIONS_KEY.SET_CURRENT_SCENARIO_OPERATING_FORECAST,
        operatingForecastList: getSyncedList(
          networkLevelList,
          scenarioStart,
          scenarioEnd,
          data,
          currentScenarioId,
          simulationRunId
        )
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllOperatingForecastData() {
  yield takeEvery(FINANCE_ACTIONS_KEY.GET_ALL_OPERATING_FORECAST, getAllOperatingForecast);
}

export default findAllOperatingForecastData;
