import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getMontecarloUrl = (organizationId) => `${getBasePath(organizationId)}/simulation/montecarlo`;

const postSimulationIdByScenarioId = async (organizationId, simulationIdWithScenarioId) =>
  axios
    .post(getMontecarloUrl(organizationId), simulationIdWithScenarioId)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return [];
    });

export const ApiMontecarlo = {
  postSimulationIdByScenarioId
};
