import { ApiScenario } from 'api/ApiScenario';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { WORKSPACE_ID } from '../../../../config/AppInstance';
import { AppInsights } from '../../../../services/AppInsights';
import { Api } from '../../../../services/config/Api';
import { SCENARIO_ACTIONS_KEY } from '../../../commons/ScenarioConstants';
import { selectOrganizationId } from '../../sagaUtils';

const appInsights = AppInsights.getInstance();

// generators function
export function* stopScenario(action) {
  appInsights.trackScenarioStop();
  const scenarioId = action.scenarioId;
  const runStartTime = new Date().getTime();

  const { id } = yield select((state) => state.scenario.current.data);
  yield delay(1000);
  const organizationId = yield selectOrganizationId();
  const response = yield call(ApiScenario.findScenarioById, organizationId, WORKSPACE_ID, scenarioId);

  const scenarioRunId = response.data.lastRun?.scenarioRunId;
  try {
    // Launch scenario if parameters update succeeded
    if (scenarioRunId) yield call(Api.ScenarioRuns.stopScenarioRun, organizationId, scenarioRunId);

    // Start backend polling to update the scenario status
    yield put({
      type: SCENARIO_ACTIONS_KEY.STOP_SCENARIO_STATUS_POLLING,
      WORKSPACE_ID: WORKSPACE_ID,
      scenarioId: id,
      startTime: runStartTime
    });
  } catch (e) {
    console.error(e);
  }
}

// generators function
// Here is a watcher that takes EVERY action dispatched named CREATE_SCENARIO
// and binds createScenario saga to it
function* stopScenarioData() {
  yield takeEvery(SCENARIO_ACTIONS_KEY.STOP_SCENARIO, stopScenario);
}

export default stopScenarioData;
