/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScenarioUtils } from '@cosmotech/core';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Popper,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { SwitchButton } from 'components/SwitchButton';
import { IDataset, IRunTemplate, IScenario, IScenarioData, ISubset } from 'models';
import { useFetchSubsetList, useSubsetList } from 'state/hooks/Subset.hooks';
import { ICreateScenarioDialogProps } from './CreateScenarioDialog.model';

import { ENABLE_SUBSET_FEATURE } from 'config/AppInstance';
import theme from 'theme';
import { getCurrentScenarioRunTemplate, isDialogDataValid } from 'utils/helpers/ScenarioUtils';

const CreateScenarioDialog: React.FC<ICreateScenarioDialogProps> = ({
  open,
  closeDialog,
  scenarios,
  currentScenario,
  datasets,
  runTemplates,
  user,
  createScenario,
  workspaceId,
  solution,
  datasetsFilter,
  dialogLabels,
  errorLabels
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const filteredDatasets = datasetsFilter ? datasets.filter(datasetsFilter) : datasets;
  const defaultDataset = useMemo((): IDataset => {
    const twinCacheDatasetName = 'Asset Electrical Twin Cache';

    return filteredDatasets.find((d) => d.name === twinCacheDatasetName) ?? filteredDatasets[0];
  }, [filteredDatasets]);

  const defaultRunTemplate = runTemplates?.[0] || null;
  const currentScenarioSelected = currentScenario.data !== null;
  const subsetList = useSubsetList();
  const getSubsetList = useFetchSubsetList();

  const [scenarioName, setScenarioName] = useState('');
  const [scenarioNameFieldError, setScenarioNameFieldError] = useState<string | null>(null);
  const [isMaster, setIsMaster] = useState(true);
  const [datasetFieldValues, setDatasetFieldValues] = useState<IDataset>(defaultDataset);
  const [parentScenarioFieldValues, setParentScenarioFieldValues] = useState<IScenario | undefined>(undefined);
  const [parentSubsetFieldValues, setParentSubsetFieldValues] = useState<ISubset | null>(null);
  const [selectedRunTemplate, setSelectedRunTemplate] = useState<IRunTemplate | null>(defaultRunTemplate);
  const [selectedSubset, setSelectedSubset] = useState<ISubset | null>(null);

  const defaultSubset = subsetList.find((subset) => subset.id === currentScenario?.data?.subset?.id);

  useEffect(() => {
    getSubsetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!open) return; // Prevent changes if dialog is closed
    setScenarioName('');
    setDatasetFieldValues(defaultDataset);
    setScenarioNameFieldError(null);
    setSelectedRunTemplate(defaultRunTemplate);
    setIsMaster(true);
    setSelectedSubset(null);

    if (currentScenarioSelected) {
      setParentScenarioFieldValues(currentScenario.data);
      const currentRunTemplate = getCurrentScenarioRunTemplate(currentScenario.data, runTemplates);
      setSelectedRunTemplate(currentRunTemplate);
    } else {
      setParentScenarioFieldValues(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChangeScenarioName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newScenarioName = event.target.value;
    let errorLabel = null;
    const errorKey = ScenarioUtils.scenarioNameIsValid(newScenarioName, scenarios);
    if (errorKey) {
      errorLabel = errorLabels[errorKey as keyof typeof errorLabels];
      if (!errorLabel) {
        // eslint-disable-next-line no-console
        console.warn('Scenario error label key is broken!');
        errorLabel = 'Scenario name is invalid';
      }
    }
    setScenarioName(newScenarioName);
    setScenarioNameFieldError(errorLabel);
  };

  function createScenarioData() {
    const scenarioData: IScenarioData = {
      name: scenarioName,
      ownerId: user.userId.toString(),
      ownerName: user.userName,
      solutionId: solution.data.id,
      solutionName: solution.data.name,
      runTemplateId: selectedRunTemplate?.id,
      runTemplateName: selectedRunTemplate?.name,
      subsetId: selectedSubset?.id,
      datasetList: [datasetFieldValues.id]
    };

    if (!isMaster && parentScenarioFieldValues?.id) {
      scenarioData.parentId = parentScenarioFieldValues?.id;
      scenarioData.subsetParentId = parentSubsetFieldValues?.id;
    }
    return scenarioData;
  }

  const handleCreateScenario = () => {
    const scenarioData = createScenarioData();
    createScenario(workspaceId, scenarioData);
    closeDialog();
  };

  const createScenarioDisabled = !isDialogDataValid(
    scenarioName,
    scenarioNameFieldError,
    isMaster,
    selectedRunTemplate,
    parentScenarioFieldValues ?? null,
    datasetFieldValues
  );

  const onClose = (_: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      closeDialog();
    }
  };

  const scenarioParentAutocompleteInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      inputProps={{
        ...params.inputProps,
        'data-testid': 'scenario-parent-autocomplete-input'
      }}
      label={dialogLabels.scenarioParent}
      placeholder={dialogLabels.scenarioParentPlaceholder}
      variant='outlined'
    />
  );
  const subsetParentAutocompleteInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      inputProps={{
        ...params.inputProps,
        'data-testid': 'subset-parent-autocomplete-input'
      }}
      label={dialogLabels.subsetParent}
      placeholder={dialogLabels.subsetParentPlaceholder}
      variant='outlined'
    />
  );

  const subsetAutocompleteInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      inputProps={{
        ...params.inputProps,
        'data-testid': 'subset-autocomplete-input'
      }}
      label={t('layouts.tabs.assetregistry.subset.subsetSearch') as string}
    />
  );

  return (
    <Dialog
      aria-labelledby='form-dialog-title'
      data-cy='create-scenario-dialog'
      fullWidth={true}
      maxWidth='sm'
      open={open}
      onClose={onClose}
    >
      <DialogTitle id='form-dialog-title'>{dialogLabels.title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <TextField
              data-cy='create-scenario-dialog-name-textfield'
              defaultValue={subsetList.length > 0 ? defaultSubset?.name : ''}
              error={scenarioNameFieldError !== null}
              fullWidth={true}
              helperText={scenarioNameFieldError}
              id='scenarioName'
              label={dialogLabels.scenarioName}
              value={scenarioName}
              variant='standard'
              onBlur={handleChangeScenarioName}
              onChange={handleChangeScenarioName}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <SwitchButton
              backgroundColor={theme.palette.primary.main}
              initialButtonValue={isMaster ? 'Master' : 'Child'}
              primaryButton={{ value: 'Master', label: dialogLabels.scenarioMaster }}
              secondaryButton={{ value: 'Child', label: dialogLabels.scenarioChild }}
              onAlignmentChange={(alignment) => setIsMaster(alignment === 'Master')}
            />
          </Grid>
          {isMaster && ENABLE_SUBSET_FEATURE === 'true' && (
            <Grid item={true} xs={12}>
              <Autocomplete<ISubset>
                ListboxProps={{ style: { maxHeight: '32vh' } }}
                PopperComponent={({ style, ...props }) => <Popper {...props} style={{ ...style, height: 0 }} />}
                getOptionLabel={(option: ISubset) => option.name || ''}
                id='subset-search'
                options={subsetList}
                placeholder={dialogLabels.subsetPlaceholder}
                renderInput={subsetAutocompleteInput}
                sx={{ width: 300 }}
                value={selectedSubset}
                onChange={(_, newSubset) => setSelectedSubset(newSubset)}
              />
            </Grid>
          )}
          {!isMaster && (
            <>
              <Grid item={true} xs={12}>
                <Autocomplete
                  disableClearable={true}
                  getOptionLabel={(option) => option.name ?? ''}
                  id='parent-scenario'
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={scenarios}
                  renderInput={scenarioParentAutocompleteInput}
                  value={parentScenarioFieldValues === null ? undefined : parentScenarioFieldValues}
                  onChange={(_, newParentScenario) => setParentScenarioFieldValues(newParentScenario)}
                />
              </Grid>
              {ENABLE_SUBSET_FEATURE === 'true' && (
                <Grid item={true} xs={12}>
                  <Autocomplete
                    disableClearable={true}
                    disabled={true}
                    getOptionLabel={(option) => option.name ?? ''}
                    id='parent-subset'
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={subsetList}
                    renderInput={subsetParentAutocompleteInput}
                    value={parentSubsetFieldValues === null ? undefined : parentSubsetFieldValues}
                    onChange={(_, newSubsetParent) => setParentSubsetFieldValues(newSubsetParent)}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button color='primary' data-cy='create-scenario-dialog-cancel-button' id='cancel' onClick={closeDialog}>
          {dialogLabels.cancel}
        </Button>
        <Button
          color='primary'
          data-cy='create-scenario-dialog-submit-button'
          disabled={createScenarioDisabled}
          id='create'
          sx={{ opacity: createScenarioDisabled ? 0.5 : 1 }}
          variant='contained'
          onClick={handleCreateScenario}
        >
          {dialogLabels.create}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateScenarioDialog;

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  dialogContent: {
    marginTop: '16px'
  },
  dialogActions: {
    marginRight: '4px',
    marginBottom: '4px'
  }
}));
