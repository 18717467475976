import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiTechnicalPolicy } from '../../../../api/ApiTechnicalPolicy';
import { SCENARIO_TECHNICAL_POLICY_ACTIONS_KEY } from '../../../commons/ScenarioTechnicalPolicyConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllScenarioTechnicalPoliciesData() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiTechnicalPolicy.fetchAllScenarioTechnicalPolicies, organizationId);
    if (data.length > 0) {
      yield put({
        type: SCENARIO_TECHNICAL_POLICY_ACTIONS_KEY.SET_ALL_SCENARIO_TECHNICAL_POLICIES,
        scenarioTechnicalPolicies: data
      });
    } else {
      yield put({
        type: SCENARIO_TECHNICAL_POLICY_ACTIONS_KEY.SET_ALL_SCENARIO_TECHNICAL_POLICIES,
        scenarioTechnicalPolicies: []
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllScenarioTechnicalPoliciesData() {
  yield takeEvery(
    SCENARIO_TECHNICAL_POLICY_ACTIONS_KEY.GET_ALL_SCENARIO_TECHNICAL_POLICIES,
    getAllScenarioTechnicalPoliciesData
  );
}

export default findAllScenarioTechnicalPoliciesData;
