import { ISubset } from 'models';
import { useCallback } from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import {
  dispatchCreateSubset,
  dispatchDeleteSubset,
  dispatchEditSubset,
  dispatchFetchSubsetList
} from 'state/dispatchers/subset/subsetDispatcher';

interface SubsetRootState extends DefaultRootState {
  subset: {
    selected: ISubset;
    list: ISubset[];
  };
}

export const useCurrentSubset = (): ISubset => {
  return useSelector((state: SubsetRootState) => state.subset.selected);
};

export const useSubsetList = (): ISubset[] => {
  return useSelector((state: SubsetRootState) => state.subset.list);
};

export const useCreateSubset = () => {
  const dispatch = useDispatch();
  return useCallback((subset: ISubset | null) => dispatch(dispatchCreateSubset(subset)), [dispatch]);
};

export const useEditSubset = () => {
  const dispatch = useDispatch();
  return useCallback((subset: ISubset) => dispatch(dispatchEditSubset(subset)), [dispatch]);
};

export const useDeleteSubset = () => {
  const dispatch = useDispatch();
  return useCallback((idSubset: string) => dispatch(dispatchDeleteSubset(idSubset)), [dispatch]);
};

export const useFetchSubsetList = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchFetchSubsetList()), [dispatch]);
};
