import { call, put, takeEvery } from 'redux-saga/effects';
import { NumberUtils } from 'utils/helpers';
import { ApiIntegration } from '../../../../api/ApiIntegration';
import { DATAINTEGRATION_ACTIONS_KEY } from '../../../commons/DataIntegrationConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* fetchPipelineKpis() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiIntegration.fetchPipelineKpis, organizationId);

    yield put({
      type: DATAINTEGRATION_ACTIONS_KEY.SET_KPIS,
      subPipelinesKpis: NumberUtils.roundAndConvertToPositiveNestedNumbers(data)
    });
  } catch (e) {
    console.error(e);
  }
}

function* getPipelineKpisData() {
  yield takeEvery(DATAINTEGRATION_ACTIONS_KEY.GET_KPIS, fetchPipelineKpis);
}

export default getPipelineKpisData;
