import { put, select, takeLatest } from 'redux-saga/effects';
import { i18next } from 'services/config/i18next';
import { DATAINTEGRATION_ACTIONS_KEY } from 'state/commons/DataIntegrationConstants';
import { NOTIFICATION_ACTIONS_KEY } from 'state/commons/NotificationConstants';

export function* openNotificationPopupSaga(action) {
  const dataIntegrationError = yield select((state) => state.dataIntegration.error);

  try {
    if (dataIntegrationError) {
      yield put({
        type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
        message: `${dataIntegrationError} ❌`,
        isOpen: true
      });
    }
  } catch (error) {
    yield put({
      type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
      message: i18next.t('genericcomponent.modal.report.exportStatus.undefined'),
      isOpen: true
    });
  }
}

function* openNotificationPopupFromActions() {
  yield takeLatest(DATAINTEGRATION_ACTIONS_KEY.SET_ERROR, openNotificationPopupSaga);
}

export default openNotificationPopupFromActions;
