import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiIntegration } from '../../../../api/ApiIntegration';
import { DATAINTEGRATION_ACTIONS_KEY } from '../../../commons/DataIntegrationConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* fetchAgingModel() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiIntegration.fetchAgingModel, organizationId);
    yield put({
      type: DATAINTEGRATION_ACTIONS_KEY.SET_AGING_MODEL,
      agingModel: data
    });
  } catch (e) {
    console.error(e);
  }
}

function* getAgingModelData() {
  yield takeEvery(DATAINTEGRATION_ACTIONS_KEY.GET_AGING_MODEL, fetchAgingModel);
}

export default getAgingModelData;
