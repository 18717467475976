import { put, takeEvery } from 'redux-saga/effects';
import { BREADCRUMBS_ACTIONS_KEY } from '../../../commons/BreadCrumbsConstants';

// generators function
export function* addBreadCrumbsItems(action) {
  const items = action.items;

  yield put({
    type: BREADCRUMBS_ACTIONS_KEY.SET_BREADCRUMBS_ITEMS,
    items: items ? yield items : yield []
  });
}

function* addBreadCrumbsItemsData() {
  yield takeEvery(BREADCRUMBS_ACTIONS_KEY.ADD_BREADCRUMBS_ITEMS, addBreadCrumbsItems);
}

export default addBreadCrumbsItemsData;
