import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiIntegration } from '../../../../api/ApiIntegration';
import { DATAINTEGRATION_ACTIONS_KEY } from '../../../commons/DataIntegrationConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* launchMainPipeline() {
  try {
    yield put({ type: DATAINTEGRATION_ACTIONS_KEY.SET_LOADING, isLoading: true });
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiIntegration.runMainPipeline, organizationId);

    if (data.status === 400) {
      throw new Error(data.data.message);
    }
    yield put({
      type: DATAINTEGRATION_ACTIONS_KEY.GET_PIPELINE_LASTEST_RUN_DATA
    });

    yield put({ type: DATAINTEGRATION_ACTIONS_KEY.SET_LOADING, isLoading: false });
    return data;
  } catch (e) {
    yield put({ type: DATAINTEGRATION_ACTIONS_KEY.SET_ERROR, error: e.message });
    console.error(e);
    return [];
  }
}

function* runMainPipelineData() {
  yield takeEvery(DATAINTEGRATION_ACTIONS_KEY.RUN_MAIN_PIPELINE, launchMainPipeline);
}

export default runMainPipelineData;
