import { createAction, createReducer } from '@reduxjs/toolkit';
import { DIALOG_ACTIONS_KEY } from 'state/commons/DialogConstants';

interface IDialogState {
  isVisible: boolean;
  dialogType?: string;
  params?: unknown;
}

interface IOpenDialogAction {
  isVisible: boolean;
  dialogType?: string;
  params?: unknown;
}

export const dialogInitialState: IDialogState = {
  isVisible: false
};

const openDialog = createAction<IOpenDialogAction>(DIALOG_ACTIONS_KEY.OPEN_DIALOG);

export const dialogReducer = createReducer(dialogInitialState, (builder) => {
  builder.addCase(openDialog, (state, action) => {
    state.isVisible = action.payload.isVisible;
    state.dialogType = action.payload.dialogType;
    state.params = action.payload.params;
  });
});
