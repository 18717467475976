import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: '100%'
  },
  iconContainer: {
    textAlign: 'center'
  }
}));

const DashboardPlaceholder = (props) => {
  const classes = useStyles();
  const { title, label, icon, downloadLogsFile, downloadLabel } = props;

  return (
    <Grid
      container={true}
      spacing={4}
      direction='column'
      justifyContent='center'
      wrap='nowrap'
      alignItems='center'
      className={classes.gridContainer}
    >
      <Grid>
        <Typography variant='h3'>{title}</Typography>
      </Grid>
      <Grid item={true}>
        {icon !== undefined && <div className={classes.iconContainer}>{icon}</div>}
        <Typography data-cy='dashboard-placeholder' color='textSecondary' variant='body1'>
          {label}
        </Typography>
      </Grid>
      {downloadLogsFile && (
        <Grid item={true}>
          <Button variant='contained' color='primary' onClick={downloadLogsFile}>
            {downloadLabel}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

DashboardPlaceholder.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  icon: PropTypes.object,
  downloadLogsFile: PropTypes.func,
  downloadLabel: PropTypes.string
};

DashboardPlaceholder.defaultProps = {
  title: null
};

export default DashboardPlaceholder;
