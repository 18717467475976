import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getFinanceUrl = (organizationId) => `${getBasePath(organizationId)}/Financial`;

const fetchAllOperatingForecastFromCurrentScenarioId = (organizationId, { currentScenarioId, simulationRunId }) =>
  axios
    .get(
      simulationRunId
        ? `${getFinanceUrl(organizationId)}/operatingforecasts/${currentScenarioId}?simulationRun=${simulationRunId}`
        : `${getFinanceUrl(organizationId)}/operatingforecasts/${currentScenarioId}`
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const fetchAllWaccFromCurrentScenarioId = (organizationId, { currentScenarioId, simulationRunId }) =>
  axios
    .get(
      simulationRunId
        ? `${getFinanceUrl(organizationId)}/waccs/${currentScenarioId}?simulationRun=${simulationRunId}`
        : `${getFinanceUrl(organizationId)}/waccs/${currentScenarioId}`
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const updateOperatingForecasts = async (organizationId, newList) =>
  axios
    .put(`${getFinanceUrl(organizationId)}/upsertoperatingforecasts`, newList)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return [];
    });

const updateWaccs = async (organizationId, newList) =>
  axios
    .put(`${getFinanceUrl(organizationId)}/upsertwacc`, newList)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return [];
    });

export const ApiFinance = {
  fetchAllOperatingForecastFromCurrentScenarioId,
  fetchAllWaccFromCurrentScenarioId,
  updateOperatingForecasts,
  updateWaccs
};
