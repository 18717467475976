import { Dialog as DialogMui, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactNode } from 'react';
import { useDialogStatus, useHideDialog } from 'state/hooks/Dialog.hooks';

interface IDialogProps {
  children?: ReactNode;
  title?: string;
  height?: string | number;
  dialogType: string;
  actions?: ReactNode;
  onClose?: (event: object, reason: string) => void;
}

export const Dialog = (props: IDialogProps) => {
  const { children, title, height, dialogType, actions, onClose } = props;
  const isOpen = useDialogStatus(dialogType);
  const hideDialog = useHideDialog();

  return (
    <DialogMui
      PaperProps={{
        style: { height: height }
      }}
      data-testid='dialog'
      fullWidth
      maxWidth='md'
      open={isOpen}
      onClose={(event, reason) => (onClose ? onClose(event, reason) : hideDialog())}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {children && <DialogContent>{children}</DialogContent>}
      {actions && <DialogActions>{actions}</DialogActions>}
    </DialogMui>
  );
};
