// Available FINANCE actions
export const FINANCE_ACTIONS_KEY = {
  GET_ALL_NETWORK_LEVEL: 'GET_ALL_NETWORK_LEVEL',
  SET_ALL_NETWORK_LEVEL: 'SET_ALL_NETWORK_LEVEL',
  GET_ALL_OPERATING_FORECAST: 'GET_ALL_OPERATING_FORECAST',
  SET_ALL_OPERATING_FORECAST: 'SET_ALL_OPERATING_FORECAST',
  SET_CURRENT_SCENARIO_OPERATING_FORECAST: 'SET_CURRENT_SCENARIO_OPERATING_FORECAST',
  GET_ALL_WACC: 'GET_ALL_WACC',
  SET_ALL_WACC: 'SET_ALL_WACC',
  SET_CURRENT_SCENARIO_WACC: 'SET_CURRENT_SCENARIO_WACC',
  UPDATE_FINANCE_TAB: 'UPDATE_FINANCE_TAB'
};
