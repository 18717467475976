import { ApiMontecarlo } from 'api/ApiMontecarlo';
import { ApiScenario } from 'api/ApiScenario';
import { ApiScenarioRuns } from 'api/ApiScenarioRuns';
import { WORKSPACE_ID } from 'config/AppInstance';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { i18next } from 'services/config/i18next';
import { NOTIFICATION_ACTIONS_KEY } from 'state/commons/NotificationConstants';
import { SCENARIO_ACTIONS_KEY } from 'state/commons/ScenarioConstants';
import { selectOrganizationId } from '../../sagaUtils';

function* getSimulationStatus(organizationId, scenarioId) {
  const response = yield call(ApiScenario.findScenarioById, organizationId, WORKSPACE_ID, scenarioId);
  return {
    simulationRunId: response.data.lastRun?.csmSimulationRun,
    status: response.data.state
  };
}

function* scenarioLaunch(scenarioId, retries = 0) {
  try {
    const defaultDelay = 10000; // 10 seconds
    const organizationId = yield selectOrganizationId();

    const { simulationRunId, status } = yield call(getSimulationStatus, organizationId, scenarioId);

    if ((status === '' && simulationRunId === '') || status === 'Failed') {
      yield call(ApiScenarioRuns.runScenario, organizationId, WORKSPACE_ID, scenarioId);
      yield delay(defaultDelay);
      return yield call(scenarioLaunch, scenarioId, retries);
    }

    if (status === 'Running' || status === 'DataIngestionInProgress') {
      yield delay(defaultDelay);
      return yield call(scenarioLaunch, scenarioId, retries);
    }

    if (status === 'Successful' && retries < 100) {
      yield call(ApiMontecarlo.postSimulationIdByScenarioId, organizationId, [
        { simulationRunId, scenarioRunId: scenarioId }
      ]);
      yield call(ApiScenarioRuns.runScenario, organizationId, WORKSPACE_ID, scenarioId);
      yield delay(3000);
      yield call(scenarioLaunch, scenarioId, retries + 1);
      return;
    }

    yield put({
      type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
      message: i18next.t('scenarioRun:scenarioRunError')
    });
  } catch (error) {
    console.error('error', error);
  }
}

function* runSimulationLoopWithScenarioId() {
  const scenarioId = yield select((state) => state.scenario.current?.data.id);
  yield call(scenarioLaunch, scenarioId);
}

export function* runSimulationLoopWithScenarioIdData() {
  yield takeEvery(SCENARIO_ACTIONS_KEY.RUN_SIMULATION_LOOP, runSimulationLoopWithScenarioId);
}
