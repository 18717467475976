import { ApiIntegration } from 'api/ApiIntegration';
import { call, put, takeEvery } from 'redux-saga/effects';
import { DATAINTEGRATION_ACTIONS_KEY } from '../../../commons/DataIntegrationConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* removePipelineHistoryEntry(action) {
  const { entryId, pipelineType } = action;
  try {
    const organizationId = yield selectOrganizationId();
    yield call(ApiIntegration.removePipelineHistoryEntry, organizationId, entryId);

    yield put({
      type: DATAINTEGRATION_ACTIONS_KEY.GET_PIPELINE_HISTORY,
      pipelineType
    });
    yield put({
      type: DATAINTEGRATION_ACTIONS_KEY.GET_SUBPIPELINES_DATA,
      pipelineType
    });
  } catch (e) {
    console.error(e);
  }
}

function* removePipelineHistoryEntryData() {
  yield takeEvery(DATAINTEGRATION_ACTIONS_KEY.REMOVE_PIPELINE_HISTORY_ENTRY, removePipelineHistoryEntry);
}

export default removePipelineHistoryEntryData;
