import { call, put, takeEvery } from 'redux-saga/effects';
import { FAMILY_ACTIONS_KEY } from 'state/commons/FamilyConstants';
import { OPERATION_ACTIONS_KEY } from 'state/commons/OperationConstants';
import { PREDETERMINED_PROJECT_ACTIONS_KEY } from 'state/commons/PredeterminedProjectConstants';
import { TECHNICAL_POLICY_ACTIONS_KEY } from 'state/commons/TechnicalPolicyConstants';
import { ApiFamily } from '../../../../api/ApiFamily';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllFamiliesByAssetClassData(action) {
  const { operation, technicalPolicy, predeterminedProject } = action;
  const assetClass = operation?.AssetClass || technicalPolicy?.AssetClass || predeterminedProject?.AssetClass;

  try {
    if (assetClass) {
      const organizationId = yield selectOrganizationId();
      const data = yield call(ApiFamily.fetchAllFamiliesByAssetClass, organizationId, assetClass);
      if (data.length > 0) {
        yield put({
          type: FAMILY_ACTIONS_KEY.SET_ALL_FAMILIES_BY_ASSET_CLASS,
          familiesByAssetClass: data
        });
      } else {
        yield put({
          type: FAMILY_ACTIONS_KEY.SET_ALL_FAMILIES_BY_ASSET_CLASS,
          familiesByAssetClass: []
        });
      }
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllFamiliesByAssetClassData() {
  yield takeEvery(OPERATION_ACTIONS_KEY.SET_SELECTED_OPERATION, getAllFamiliesByAssetClassData);
  yield takeEvery(TECHNICAL_POLICY_ACTIONS_KEY.SET_SELECTED_TECHNICAL_POLICY, getAllFamiliesByAssetClassData);
  yield takeEvery(TECHNICAL_POLICY_ACTIONS_KEY.GET_ALL_FAMILIES_BY_ASSET_CLASS, getAllFamiliesByAssetClassData);
  yield takeEvery(PREDETERMINED_PROJECT_ACTIONS_KEY.SET_SELECTED_PREDETERMINED_PROJECT, getAllFamiliesByAssetClassData);
}

export default findAllFamiliesByAssetClassData;
