import { ApiIntegration } from 'api/ApiIntegration/ApiIntegration';
import { useOrganizationId } from 'state/hooks/Workspace.hooks';
import { getFilenameFromHeaders } from 'utils/helpers/ApiUtils';
import DownloadBlob from 'utils/hooks/DownloadBlob.hook';

export const useGetHistoryFileFromPipeline = () => {
  const organizationId = useOrganizationId();

  const getErrorLogInExcel = (entryId, filename) => {
    return ApiIntegration.downloadPipelineErrorFileExcel(organizationId, entryId).then((response) => {
      const filenameFromHeaders = getFilenameFromHeaders(response.headers);
      DownloadBlob(response.data, `${filename}-${filenameFromHeaders ?? 'error-logs.xlsx'}`);
    });
  };
  const getFileInCSV = (historyEntry) => {
    ApiIntegration.downloadPipelineHistoryEntryFile(organizationId, historyEntry.id).then((blob) => {
      DownloadBlob(blob, historyEntry.zipName);
    });
  };
  return { getErrorLogInExcel, getFileInCSV };
};
