import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BugReportIcon from '@mui/icons-material/BugReport';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataIntegrationSwitchButton } from 'components/DataIntegration';
import PropTypes from 'prop-types';
import { useEffect, useReducer, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { PIPELINESTATUS } from 'state/commons/Constants';
import {
  useDataIntegrationGetUploadStatus,
  useDataIntegrationSubPipelinesLoadingStatus
} from 'state/hooks/DataIntegration.hooks';
import theme from 'theme';
import { useGetHistoryFileFromPipeline } from 'utils/hooks';

function reducer(state, action) {
  let file = null;
  if (action.payload) {
    file = new FormData();
    file.append('file', action.payload);
  }
  const data =
    action.entryId || file
      ? {
          file,
          name: action.payload?.name || action.name,
          entryId: action.entryId
        }
      : null;

  switch (action.type) {
    case 'create':
      return { ...state, create: data };
    case 'update':
      return { ...state, update: data };
    case 'delete':
      return { ...state, delete: data };
    default:
      throw new Error();
  }
}

function generateStateData(lastPipelineRunsByAction, action) {
  const pipelineRun = lastPipelineRunsByAction[action[0].toUpperCase() + action.substring(1)];
  return {
    type: action,
    payload: null,
    name: pipelineRun?.zipName,
    entryId: pipelineRun?.id
  };
}

export default function DataIntegrationCardImport({
  additionalParameters,
  cardData,
  removePipelineHistoryEntry,
  selectedAction,
  setFinancialLifecycle,
  uploadPipelineFile,
  setIsFilePending
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const initialState = { create: null, update: null, delete: null };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.zip, .rar, .7zip'
  });
  const [selectedState, setSelectedState] = useState(null);
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState(true);
  const [cancelBtnDisabled, setCancelBtnDisabled] = useState(true);
  const [dropZoneDisabled, setDropZoneDisabled] = useState(true);
  const { getErrorLogInExcel } = useGetHistoryFileFromPipeline();
  const entryInError = cardData.history.find((entry) => entry.status === 'Error');

  const [radioButtonChecked, setRadioButtonChecked] = useState(false);
  const isFinanceCard = cardData.type === 'finance';
  useEffect(() => {
    dispatch({ type: selectedAction.name, payload: acceptedFiles[0] });
  }, [acceptedFiles]);

  const isUploadLoading = useDataIntegrationSubPipelinesLoadingStatus(cardData.type);
  const canUploadFiles = useDataIntegrationGetUploadStatus();

  const hasFileInState = state.create || state.update || state.delete;

  useEffect(() => {
    setIsFilePending(!!hasFileInState);
  }, [hasFileInState]);

  useEffect(() => {
    dispatch(generateStateData(cardData.lastPipelineRunsByAction, 'create'));
    dispatch(generateStateData(cardData.lastPipelineRunsByAction, 'update'));
    dispatch(generateStateData(cardData.lastPipelineRunsByAction, 'delete'));
  }, [cardData.lastPipelineRunsByAction]);

  useEffect(() => {
    const currentState = state[selectedAction.name];
    const pipelineInProgress = cardData.status === PIPELINESTATUS.RUNNING;
    setSelectedState(currentState);
    setUploadBtnDisabled(
      !canUploadFiles || isUploadLoading || pipelineInProgress || currentState == null || !currentState.file
    );
    setCancelBtnDisabled(pipelineInProgress || currentState == null || !(currentState.file || currentState.entryId));
    setDropZoneDisabled(!canUploadFiles || pipelineInProgress || currentState?.name);
  }, [selectedAction, state, cardData, isUploadLoading]);

  const getCurrentFileNameFromAction = (action) => {
    if (state.create?.name && action === 'create') {
      return state.create.name;
    }
    if (state.update?.name && action === 'update') {
      return state.update.name;
    }
    if (state.delete?.name && action === 'delete') {
      return state.delete.name;
    }
    return null;
  };

  // set actions from menu in dataIntegrations card
  const handleAction = ({ name }) => {
    switch (name) {
      case 'create':
        uploadPipelineFile(state.create.file, cardData.type, 'create', additionalParameters);
        break;
      case 'update':
        uploadPipelineFile(state.update.file, cardData.type, 'update', additionalParameters);
        break;
      case 'delete':
        uploadPipelineFile(state.delete.file, cardData.type, 'delete');
        break;
      default:
        break;
    }
  };

  const clearFile = () => {
    if (selectedState?.entryId) {
      removePipelineHistoryEntry(selectedState.entryId, cardData.type);
    }
    dispatch({ type: selectedAction.name, payload: null });
  };

  function handleValueChange(e) {
    const isChecked = e.target.checked;
    setRadioButtonChecked(isChecked);
    setFinancialLifecycle(isChecked ? 'assetFamilies' : 'financeLifeCycle');
  }

  function handleClick(e) {
    e.stopPropagation();
  }

  const displayErrorButton = entryInError && hasFileInState;
  const displayLoaderButton = isUploadLoading && hasFileInState;
  const displayActionButton = !isUploadLoading && selectedAction?.name && hasFileInState;

  return (
    <div className={classes.importContainer}>
      <div className={classes.importArea}>
        {isFinanceCard && (
          <div style={{ display: 'flex', width: '100%', marginLeft: 'auto' }}>
            <DataIntegrationSwitchButton
              checked={radioButtonChecked}
              labels={{
                main: 'type',
                left: 'assetFamilies',
                right: 'financialLifecycle'
              }}
              onChange={handleValueChange}
              onClick={handleClick}
            />
          </div>
        )}
        <h2 style={{ marginBottom: '1rem' }}>
          {t(`views.dataIntegration.actions.${selectedAction.name}`).toUpperCase()}
        </h2>
        <label htmlFor='fileInput' style={{ cursor: 'pointer' }}>
          {t('views.dataIntegration.import.userSelectFile')}
        </label>
        <input
          accept='.zip, .rar, .7zip'
          disabled={dropZoneDisabled}
          id='fileInput'
          name='fileInput'
          type='file'
          onChange={(e) => {
            dispatch({ type: selectedAction.name, payload: e.target.files[0] });
          }}
        />
        <span className={classes.textBlack}>{t('views.dataIntegration.import.or')}</span>

        <div className={classes.dropZone} {...getRootProps()}>
          <input {...getInputProps()} disabled={dropZoneDisabled} />
          <InsertDriveFileIcon />
          <div className={classes.dropZoneText}>
            <span>{t('views.dataIntegration.import.fileToImport')} : </span>
            <span>{getCurrentFileNameFromAction(selectedAction.name)}</span>
          </div>
        </div>
        <div className={classes.btnForImport}>
          {selectedAction && (
            <>
              {displayErrorButton && (
                <Button
                  color='primary'
                  startIcon={<BugReportIcon />}
                  title={t('layouts.tabs.dataintegration.downloadErrorFile')}
                  variant='contained'
                  onClick={() => getErrorLogInExcel(entryInError.id, entryInError.zipName)}
                >
                  {t('layouts.tabs.dataintegration.downloadErrorFile')}
                </Button>
              )}
              {displayLoaderButton && (
                <Button
                  color='primary'
                  disabled={uploadBtnDisabled}
                  variant='contained'
                  onClick={() => handleAction(selectedAction)}
                >
                  <CircularProgress className={classes.progress} size={20} />
                </Button>
              )}
              {displayActionButton && (
                <Button
                  color='primary'
                  disabled={uploadBtnDisabled}
                  sx={{ color: uploadBtnDisabled ? 'grey' : 'black' }}
                  variant='outlined'
                  onClick={() => handleAction(selectedAction)}
                >
                  <>
                    {t(`views.dataIntegration.actions.${selectedAction.name}`)}
                    {selectedAction.icon}
                  </>
                </Button>
              )}
              <Button color='primary' disabled={cancelBtnDisabled} variant='outlined' onClick={clearFile}>
                {t('views.dataIntegration.actions.remove')} <AddCircleOutlineIcon className={classes.cancel} />
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

DataIntegrationCardImport.propTypes = {
  additionalParameters: PropTypes.object,
  cardData: PropTypes.object.isRequired,
  removePipelineHistoryEntry: PropTypes.func.isRequired,
  selectedAction: PropTypes.object.isRequired,
  setFinancialLifecycle: PropTypes.func.isRequired,
  setIsFilePending: PropTypes.func.isRequired,
  uploadPipelineFile: PropTypes.func.isRequired
};

DataIntegrationCardImport.defaultProps = {
  additionalParameters: {}
};

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    width: '100%',
    height: '100%',
    paddingTop: 24
  },
  importCard: {
    marginTop: 24
  },
  flexContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 !important',
    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.gradient,
    width: 44,
    height: 44,
    borderRadius: 44,
    marginRight: 24,
    '& svg': {
      color: theme.palette.nexans.white
    }
  },
  cardData: {
    '& h3': {
      color: theme.palette.text.black
    },
    '& p': {
      color: theme.palette.text.disabled
    }
  },
  importContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  importArea: {
    width: '82%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `1px dashed ${theme.palette.primary.main}`,
    backgroundColor: '#F6CFCE33',
    borderRadius: 10,
    margin: '24px 0',
    padding: 24,
    '& > span, & > label': {
      fontSize: 18
    },
    '& > input[type=file]': {
      display: 'none'
    },
    '& > button': {
      alignSelf: 'end',
      marginTop: 24
    }
  },
  or: {
    fontSize: 20,
    color: theme.palette.text.black,
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  textBlack: {
    marginTop: 12,
    color: theme.palette.text.black
  },
  dropZone: {
    width: '100%',
    minHeight: 125,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    border: `1px dashed ${theme.palette.primary.main}`,
    backgroundColor: '#F6CFCE33',
    borderRadius: 10,
    margin: '24px 0',
    padding: 24,
    '& > svg': {
      marginRight: 12,
      fontSize: 45
    }
  },
  dropZoneText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  btnForImport: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '& > button': {
      margin: 8
    },
    '& > button.MuiButton-contained.Mui-disabled': {
      background: `${theme.palette.nexans.white} !important`,
      '& > span': {
        color: `${theme.palette.text.disabled} !important`
      }
    },
    '& > .MuiButton-outlinedPrimary': {
      backgroundColor: 'white',
      borderRadius: 50,
      borderColor: theme.palette.primary.main
    }
  },
  cancel: {
    marginLeft: 8,
    transform: 'rotate(45deg)'
  }
}));
