import { POWER_BI_WORKSPACE_ID } from 'config/AppInstance';
import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getPowerBiEmbedInfoUrl = (organizationId) => `${getBasePath(organizationId)}/powerbi/getembedinfo`;
const ExportToFileRoute = `https://api.powerbi.com/v1.0/myorg/groups/${POWER_BI_WORKSPACE_ID}/reports`;

let headers = {};
let reportList = [];

const fetchPowerBIInfo = async (organizationId) => {
  let data;
  await axios
    .get(getPowerBiEmbedInfoUrl(organizationId))
    .then((response) => {
      data = response.data;
      headers = response.data.accesses.headers;
      reportList = response.data.accesses.reportsInfo;
    })
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });
  return data;
};

const getReportsList = async () => {
  await fetchPowerBIInfo();
  const reportsArray = [];

  Object.keys(reportList).forEach((key) => {
    reportsArray.push(reportList[key]);
  });

  return reportsArray;
};

// Get url link by calling the PowerBI REST API
const getExportIdFromReportId = async (reportId, docType, state) => {
  const exportId = await fetch(`${ExportToFileRoute}/${reportId}/ExportTo`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      format: docType,
      powerBIReportConfiguration: state
        ? {
            defaultBookmark: {
              state
            }
          }
        : {}
    })
  });

  const exportIdJSON = await exportId.json();
  return exportIdJSON.id;
};

const getExportStatus = async (reportId, exportId) => {
  const status = await getExportToFileStatus(reportId, exportId);
  return status.status;
};

const getExportToFileStatus = async (reportId, exportId) => {
  const response = await fetch(`${ExportToFileRoute}/${reportId}/exports/${exportId}`, {
    method: 'GET',
    headers
  });
  return response.json();
};

const getExportToFileStream = async (reportId, exportId) => {
  const response = await fetch(`${ExportToFileRoute}/${reportId}/exports/${exportId}/file`, {
    method: 'GET',
    headers
  });
  return response;
};

export const ApiPowerBI = {
  fetchPowerBIInfo,
  getExportIdFromReportId,
  getExportStatus,
  getExportToFileStream,
  getReportsList
};
