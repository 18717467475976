import { FormControlLabel, Grid, Switch } from '@mui/material';
import { ExtraParametersUtils } from '../../ExtraParametersUtils';

const create = (t, parameterData, parametersState, setParametersState, editMode) => {
  const disabled = ExtraParametersUtils.GetDisabled(parameterData, parametersState, editMode);
  const displayed = ExtraParametersUtils.GetDisplayed(parameterData, parametersState);
  const hided = ExtraParametersUtils.GetHided(parameterData);
  const itemSize = ExtraParametersUtils.GetItemSize(parameterData);

  function setValue() {
    const newValue = parametersState[parameterData.id] ? !parametersState[parameterData.id] : true;

    let newState = {
      ...parametersState,
      [parameterData.id]: newValue
    };

    // Check dispatch result & update newState
    newState = ExtraParametersUtils.DispatchResult(parameterData, newState, newValue);

    setParametersState(newState);
  }

  return !hided ? (
    <Grid item={true} key={`grid-item-${parameterData.id}`} md={itemSize} style={displayed}>
      <FormControlLabel
        control={<Switch checked={parametersState[parameterData.id]} color='primary' onChange={() => setValue()} />}
        disabled={disabled}
        key={parameterData.id}
        label={t(`solution.parameters.${parameterData.id}`, parameterData.id)}
        labelPlacement='start'
        style={{ marginLeft: '0px' }}
      />
    </Grid>
  ) : null;
};

export const MaterialToggleInputFactory = {
  create
};
