import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { FINANCE_ACTIONS_KEY } from '../../commons/FinanceConstants';

export const financeInitialState = {
  networkLevel: { list: [] },
  operatingForecast: {
    list: []
  },
  wacc: {
    list: []
  },
  waccScenario: {
    list: []
  },
  operatingForecastScenario: {
    list: []
  }
};

export const networkLevelReducer = createReducer(financeInitialState.networkLevel, (builder) => {
  builder.addCase(FINANCE_ACTIONS_KEY.SET_ALL_NETWORK_LEVEL, (state, action) => {
    state.list = action.networkLevelList;
  });
});
export const operatingForecastReducer = createReducer(financeInitialState.operatingForecast, (builder) => {
  builder.addCase(FINANCE_ACTIONS_KEY.SET_ALL_OPERATING_FORECAST, (state, action) => {
    state.list = action.operatingForecastList;
  });
});
export const operatingForecastScenarioReducer = createReducer(financeInitialState.operatingForecast, (builder) => {
  builder.addCase(FINANCE_ACTIONS_KEY.SET_CURRENT_SCENARIO_OPERATING_FORECAST, (state, action) => {
    state.list = action.operatingForecastList;
  });
});
export const waccReducer = createReducer(financeInitialState.wacc, (builder) => {
  builder.addCase(FINANCE_ACTIONS_KEY.SET_ALL_WACC, (state, action) => {
    state.list = action.waccList;
  });
});
export const waccScenarioReducer = createReducer(financeInitialState.wacc, (builder) => {
  builder.addCase(FINANCE_ACTIONS_KEY.SET_CURRENT_SCENARIO_WACC, (state, action) => {
    state.list = action.waccList;
  });
});

export const financeReducer = combineReducers({
  networkLevel: networkLevelReducer,
  operatingForecast: operatingForecastReducer,
  wacc: waccReducer,
  waccScenario: waccScenarioReducer,
  operatingForecastScenario: operatingForecastScenarioReducer
});
