export const DIALOG_ACTIONS_KEY = {
  OPEN_DIALOG: 'OPEN_DIALOG'
};

const SUBSET_CREATION_OR_EDIT_DIALOG_TYPE = 'SubsetCreationOrEditDialogType';
const SUBSET_DELETION_DIALOG_TYPE = 'SubsetDeletionDialogType';

export const DIALOG_TYPE = {
  SUBSET_CREATION_OR_EDIT_DIALOG_TYPE,
  SUBSET_DELETION_DIALOG_TYPE
};
