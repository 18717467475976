import { AxiosError } from 'axios';
import { ReactNode } from 'react';
import { Error } from '../common/Error';
import { ErrorBoundary } from '../common/ErrorBoundary';
import { Loader } from '../common/Loader';

interface IParams {
  children: ReactNode;
  skeleton?: ReactNode;
  error?: AxiosError;
  isLoading: boolean;
  errorNode?: ReactNode;
}

export const HandleErrorAndLoading = (props: IParams) => {
  const { children, error, errorNode, isLoading, skeleton } = props;
  const showSkeleton = !error && isLoading;

  let element = children;

  if (error) {
    element = errorNode ?? <Error errorMessage={error.message} status={error.response?.status} />;
  } else if (showSkeleton) {
    element = skeleton ?? <Loader />;
  }

  return <ErrorBoundary>{element ?? <div />}</ErrorBoundary>;
};
