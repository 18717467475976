import i18next from 'i18next';
import { Component, ReactNode } from 'react';
import { Error } from '../Error';

interface IState {
  hasError: boolean;
}

interface IParams {
  children: ReactNode;
}

export class ErrorBoundary extends Component<IParams, IState> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { state, props } = this;

    if (state.hasError) {
      return <Error errorMessage={i18next.t<string>('commoncomponents.error:genericError')} />;
    }

    return props.children;
  }
}
