import CheckCircle from '@mui/icons-material/CheckCircle';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import InputComponentFactory from 'components/InputComponentFactory';
import { useEffect } from 'react';

const DEFAULT_MIN_VALUE = 0;
const DEFAULT_STEP_VALUE = 1;

const create = (
  t,
  customClasses,
  parameterData,
  parametersState,
  setParametersState,
  editMode,
  parametersGroupDataId,
  extraConfig
) => {
  const budgetList = extraConfig.budgetList;
  const selectedBudget = extraConfig.selectedBudget;
  const setSelectedBudget = extraConfig.setSelectedBudget;
  const selectedBudgetType = extraConfig.selectedBudgetType;
  const setSelectedBudgetType = extraConfig.setSelectedBudgetType;

  const scenarioStart = parametersState['parameter_scenario_global_date_de_debut_scenario']
    ? parametersState['parameter_scenario_global_date_de_debut_scenario']
    : new Date().getFullYear();
  const scenarioEnd = parametersState['parameter_scenario_global_date_de_debut_scenario']
    ? parametersState['parameter_scenario_global_date_de_fin_scenario']
    : new Date().getFullYear();

  // INITIALIZER
  if (
    parameterData.id === 'parameter_scenario_budget_list' &&
    parametersState[parameterData.id] &&
    parametersState[parameterData.id].length === 0 &&
    budgetList.length > 0
  ) {
    setParametersState({
      ...parametersState,
      [parameterData.id]: [...budgetList]
    });
  }

  useEffect(() => {
    if (
      parameterData.id === 'parameter_scenario_budget_list' &&
      parametersState[parameterData.id] &&
      budgetList.length > 0 &&
      parametersState[parameterData.id].length > 0
    ) {
      syncBudgetsWithScenarioDates();
    }
  }, [budgetList]);

  // CHECK & SYNC BUDGETS
  if (checkNeedSyncBudgets()) {
    syncBudgetsWithScenarioDates();
  }

  // Function to check if budgets need to be sync according scenario's dates
  function checkNeedSyncBudgets() {
    if (parametersState[parameterData.id]) {
      return parametersState[parameterData.id].some((budget) => {
        // Check if every year in budget are in scenario's dates
        if (
          Object.keys(budget?.AvailableQuantityByYear).some((year) => !(year >= scenarioStart && year <= scenarioEnd))
        ) {
          return true;
        }
        // Check if every year in scenario's date are in budget
        for (let iYear = scenarioStart; iYear <= scenarioEnd; iYear += 1) {
          if (!Object.keys(budget?.AvailableQuantityByYear).some((year) => parseInt(year) === iYear)) {
            return true;
          }
        }
        // All checked are valids, no need to sync
        return false;
      });
    }
    // No need to sync
    return false;
  }

  // Function to update budgets according scenario's dates
  function syncBudgetsWithScenarioDates() {
    const updatedBudgets = budgetList.map((budget) => {
      const updatedBudget = { ...budget };
      const AvailableQuantityByYear = {};
      const MinimalQuantityLevelByYear = {};
      for (let iYear = scenarioStart; iYear <= scenarioEnd; iYear += 1) {
        AvailableQuantityByYear[iYear] =
          budget?.AvailableQuantityByYear?.[iYear] !== undefined ? budget.AvailableQuantityByYear[iYear] : 0;

        MinimalQuantityLevelByYear[iYear] =
          budget?.MinimalQuantityLevelByYear?.[iYear] && budgetList ? budget.MinimalQuantityLevelByYear[iYear] : 0;
      }
      updatedBudget.AvailableQuantityByYear = AvailableQuantityByYear;
      updatedBudget.MinimalQuantityLevelByYear = MinimalQuantityLevelByYear;
      return updatedBudget;
    });
    // Update budgets
    setParametersState({
      ...parametersState,
      [parameterData.id]: [...updatedBudgets]
    });

    // Update selected budget
    if (selectedBudget) {
      const updatedSelectedBudget = updatedBudgets.find((budget) => budget.$dtId === selectedBudget.$dtId);
      if (updatedSelectedBudget) {
        setSelectedBudget(updatedSelectedBudget);
      }
    }
  }

  const displayDetails = selectedBudget !== null;
  const displayAvailableQuantityByYear =
    selectedBudget?.Constrained && parametersState?.parameter_scenario_global_contrainte_budgetaire;

  // UPDATE
  // Function to update selectedBudget and BudgetList
  function updateSelectedBudget(budget) {
    setParametersState({
      ...parametersState,
      [parameterData.id]: [
        ...parametersState[parameterData.id].map((b) => (b.$dtId === selectedBudget.$dtId ? budget : b))
      ]
    });
    setSelectedBudget(budget);
  }

  // GETTERS
  // Function to get BudgetList filtered by BudgetType
  function getFilteredBudgetList() {
    return parametersState[parameterData.id]
      ? [...parametersState[parameterData.id].filter((b) => b.CostType === selectedBudgetType)]
      : [];
  }

  // Function to get Open value
  // Sum of Capex value (as CostRate) and Opex value is egal to 100
  function getOpexValue() {
    if (selectedBudget && selectedBudget.CostRate === 1) {
      return 0;
    }
    if (selectedBudget?.CostRate && selectedBudget.CostRate < 1) {
      return selectedBudget.CostRate < 1 ? 100 - selectedBudget.CostRate * 100 : 100 - selectedBudget.CostRate;
    }
    if (selectedBudget && selectedBudget.CostRate === 0) {
      return 100;
    }
    // return 0;
  }

  // Function to get capex value from opex value
  // Due to float, value are converted to Integer
  function getCapexValue() {
    if (selectedBudget.CostRate === 1) return 100;
    return selectedBudget.CostRate < 1 ? Math.round(selectedBudget.CostRate * 100) : selectedBudget.CostRate;
  }

  // Function to get start year of fast filling feature
  function getStartYear() {
    return selectedBudget.StartYear ? selectedBudget.StartYear : getDefaultStartYear();
  }

  // Function to get end year of fast filling feature
  function getEndYear() {
    return selectedBudget.EndYear ? selectedBudget.EndYear : getDefaultEndYear();
  }

  // Function to get default start year of fast filling feature by getting the lowest year in AvailableQuantityByYear
  function getDefaultStartYear() {
    return parseInt(
      Object.keys(selectedBudget.AvailableQuantityByYear).sort((yearA, yearB) => (yearA < yearB ? -1 : 1))[0]
    );
  }

  // Function to get default start year of fast filling feature by getting the highest year in AvailableQuantityByYear
  function getDefaultEndYear() {
    return parseInt(
      Object.keys(selectedBudget.AvailableQuantityByYear).sort((yearA, yearB) => (yearA < yearB ? 1 : -1))[0]
    );
  }

  // Function to get AvailavleQuantityYear sorted by year
  function getSortedAvailableQuantityByYear() {
    return Object.keys(selectedBudget.AvailableQuantityByYear).sort((yearA, yearB) => (yearA < yearB ? -1 : 1));
  }

  function createTableByYearsFromScenarioDates() {
    const table = [];
    const sortedAvailableQuantityByYear = getSortedAvailableQuantityByYear();
    for (let iYear = scenarioStart; iYear <= scenarioEnd; iYear += 1) {
      sortedAvailableQuantityByYear[iYear] ? table.push(sortedAvailableQuantityByYear[iYear]) : table.push(iYear);
    }
    return table;
  }

  // HANDLERS
  // Function to handle click on Budget
  function handleClickItem(budget) {
    if (budget.$dtId === selectedBudget?.$dtId) {
      setSelectedBudget(null);
    } else {
      setSelectedBudget(budget);
    }
  }

  // Function to handle change of BudgetType filter
  function handleChangeFilterBudgetType(filter) {
    setSelectedBudgetType(filter);
    setSelectedBudget(null);
  }

  // Function to handle change of StartYear value to set fast filling feature
  function handleChangeStartYear(StartYear) {
    if (
      !StartYear ||
      StartYear < getDefaultStartYear() ||
      StartYear > (selectedBudget.EndYear ? selectedBudget.EndYear : getDefaultEndYear())
    ) {
      updateSelectedBudget({
        ...selectedBudget,
        StartYear: getDefaultStartYear()
      });
    } else {
      updateSelectedBudget({
        ...selectedBudget,
        StartYear: parseInt(StartYear)
      });
    }
  }

  // Function to handle change of EndYear value to set fast filling
  function handleChangeEndYear(EndYear) {
    if (
      !EndYear ||
      EndYear > getDefaultEndYear() ||
      EndYear < (selectedBudget.StartYear ? selectedBudget.StartYear : getDefaultStartYear())
    ) {
      updateSelectedBudget({
        ...selectedBudget,
        EndYear: getDefaultEndYear()
      });
    } else {
      updateSelectedBudget({
        ...selectedBudget,
        EndYear: parseInt(EndYear)
      });
    }
  }

  // Function to handle change of CustomBudget value to set fast filling feature
  function handleChangeCustomBudget(CustomBudget) {
    updateSelectedBudget({
      ...selectedBudget,
      CustomBudget: CustomBudget ? parseFloat(CustomBudget) : 0
    });
  }

  // Function to handleChange of CostRate value
  function handleChangeCostRate(CostRate) {
    updateSelectedBudget({
      ...selectedBudget,
      CostRate
    });
  }

  // Function to handle change of Constrained value
  function handleChangeConstrained() {
    updateSelectedBudget({
      ...selectedBudget,
      Constrained: !selectedBudget.Constrained
    });
  }

  // Function to handle click on fast filling feature button
  function handleClickFastFilling() {
    let newAvailableQuantityByYear = {
      ...selectedBudget.AvailableQuantityByYear
    };
    Object.keys(newAvailableQuantityByYear).forEach((year) => {
      if (
        parseInt(year) >= (selectedBudget.StartYear ? selectedBudget.StartYear : getDefaultStartYear()) &&
        parseInt(year) <= (selectedBudget.EndYear ? selectedBudget.EndYear : getDefaultEndYear())
      ) {
        newAvailableQuantityByYear = {
          ...newAvailableQuantityByYear,
          [year]: selectedBudget.CustomBudget || 0
        };
      }
    });
    updateSelectedBudget({
      ...selectedBudget,
      AvailableQuantityByYear: {
        ...selectedBudget.AvailableQuantityByYear,
        ...newAvailableQuantityByYear
      }
    });
  }

  // Function to handle change of Quantity per Year
  function handleChangeYearQuantity(Year, Quantity) {
    updateSelectedBudget({
      ...selectedBudget,
      AvailableQuantityByYear: {
        ...selectedBudget.AvailableQuantityByYear,
        [Year]: parseInt(Quantity)
      }
    });
  }

  return (
    <Grid
      alignContent='flex-start'
      container
      direction='row'
      justifyContent='flex-start'
      key={`grid-container-${parametersGroupDataId}`}
    >
      <Grid item key={`grid-col-0-${parametersGroupDataId}`} xs={3}>
        <Grid
          item
          key={`grid-col-1-row-0-${parametersGroupDataId}parameter_scenario_budget_selected`}
          md={12}
          style={{ paddingBottom: '2rem' }}
        >
          <FormControl fullWidth key={`${parametersGroupDataId}budget-type`} variant='standard'>
            <InputLabel id={`input-label-${parametersGroupDataId}budget-type`}>
              {t('commoncomponents.scenario-form.budget-tab.budget-type')}
            </InputLabel>
            <Select
              disableUnderline
              displayEmpty
              id={`input-${parametersGroupDataId}budget-type`}
              labelId={`input-label-${parametersGroupDataId}budget-type`}
              value={selectedBudgetType}
              variant='standard'
              onChange={(event) => handleChangeFilterBudgetType(event.target.value)}
            >
              <MenuItem key={`menu-item-${parametersGroupDataId}-budget-type-0`} value={'Workforce'}>
                {t('commoncomponents.scenario-form.budget-tab.workforce')}
              </MenuItem>
              <MenuItem key={`menu-item-${parametersGroupDataId}-budget-type-1`} value={'Suppliers'}>
                {t('commoncomponents.scenario-form.budget-tab.suppliers')}
              </MenuItem>
            </Select>
          </FormControl>
          <List disabled={!editMode} style={{ maxHeight: '100%', overflow: 'auto' }}>
            {getFilteredBudgetList().map((budget, index) => (
              <ListItem
                button
                divider={index + 1 !== parametersState[parameterData.id].length}
                key={`list-item-${budget.$dtId}`}
                selected={selectedBudget?.$dtId === budget.$dtId}
                onClick={() => handleClickItem(budget)}
              >
                <ListItemText key={`list-item-text-${budget.$dtId}`} primary={budget.$dtId} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <Grid item key={`grid-col-1-${parametersGroupDataId}`} sx={{ paddingLeft: '2rem' }} xs={9}>
        {displayDetails ? (
          [
            <Grid
              alignContent='flex-start'
              alignItems='center'
              container
              direction='row'
              justifyContent='flex-start'
              key={`grid-col-1-row-0-${parametersGroupDataId}`}
            >
              <Grid item key={`grid-col-1-row-0-${parametersGroupDataId}list-label`} md={2}>
                <Typography
                  className={!editMode ? customClasses.textDisabled : null}
                  id={`input-label-id${parametersGroupDataId}list`}
                >
                  {t(`commoncomponents.scenario-form.budget-tab.list-${selectedBudgetType}`)}
                </Typography>
              </Grid>
              <Grid item key={`grid-col-1-row-0-${parametersGroupDataId}list-values`} md={4}>
                <ButtonGroup disabled={!editMode} variant='text'>
                  {selectedBudget?.CatKeyLabels &&
                    Object.keys(selectedBudget.CatKeyLabels).map((catKey) => (
                      <Button
                        disabled
                        key={`${parametersGroupDataId}categories-values${selectedBudget.CatKeyLabels[catKey]}`}
                      >
                        {selectedBudget.CatKeyLabels[catKey]}
                      </Button>
                    ))}
                </ButtonGroup>
              </Grid>
            </Grid>,
            <Grid
              alignContent='flex-start'
              alignItems='center'
              container
              direction='row'
              justifyContent='flex-start'
              key={`grid-col-1-row-1-${parametersGroupDataId}`}
            >
              <Grid item key={`grid-col-1-row-0-${parametersGroupDataId}ratio-capex-opex`} md={4}>
                <Typography
                  className={!editMode ? customClasses.textDisabled : null}
                  id={`input-label-id${parametersGroupDataId}ratio-capex-opex`}
                >
                  {t('commoncomponents.scenario-form.budget-tab.ratio-capex-opex')}
                </Typography>
              </Grid>
              <Grid item key={`grid-col-1-row-3-${parametersGroupDataId}-ratio-capex`} md={1}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        {t('commoncomponents.scenario-form.budget-tab.prefix-ratio-capex-opex')}
                      </InputAdornment>
                    )
                  }}
                  disabled={!editMode}
                  fullWidth
                  inputProps={{
                    min: DEFAULT_MIN_VALUE,
                    max: 100,
                    step: DEFAULT_STEP_VALUE
                  }}
                  key={`${parametersGroupDataId}-ratio-capex`}
                  label={t('commoncomponents.scenario-form.budget-tab.ratio-capex')}
                  type='number'
                  value={getCapexValue()}
                  variant='standard'
                  onChange={(event) => handleChangeCostRate(event.target.value / 100)}
                />
              </Grid>
              <Grid item key={`grid-col-1-row-3-${parametersGroupDataId}-ratio-opex`} md={1}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        {t('commoncomponents.scenario-form.budget-tab.prefix-ratio-capex-opex')}
                      </InputAdornment>
                    )
                  }}
                  disabled
                  fullWidth
                  key={`${parametersGroupDataId}-ratio-opex`}
                  label={t('commoncomponents.scenario-form.budget-tab.ratio-opex')}
                  type='number'
                  value={getOpexValue()}
                  variant='standard'
                />
              </Grid>
            </Grid>,
            <Grid
              alignContent='flex-start'
              alignItems='center'
              container
              direction='row'
              justifyContent='flex-start'
              key={`grid-col-1-row-4-${parametersGroupDataId}`}
            >
              <Grid
                item
                key={`grid-col-1-row-4-${parametersGroupDataId}budget-constraint`}
                md={6}
                style={{ display: 'true' }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedBudget.Constrained}
                      color='primary'
                      onChange={() => handleChangeConstrained()}
                    />
                  }
                  disabled={!(parametersState?.parameter_scenario_global_contrainte_budgetaire && editMode)}
                  key={`${parametersGroupDataId}-budget-constraint`}
                  label={t('commoncomponents.scenario-form.budget-tab.budget-constraint')}
                  labelPlacement='start'
                  style={{ marginLeft: '0px' }}
                />
                {selectedBudget.Constrained}
              </Grid>
            </Grid>,

            <Grid
              alignContent='flex-start'
              container
              direction='row'
              justifyContent='flex-start'
              key={`grid-col-1-row-5-${parametersGroupDataId}`}
              sx={{
                opacity:
                  selectedBudget.Constrained && parametersState?.parameter_scenario_global_contrainte_budgetaire ? 1 : 0
              }}
            >
              <Grid item key={`grid-col-1-row-5-${parametersGroupDataId}-budget-array`} md={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead className={customClasses.tableHeader}>
                      <TableRow>
                        <TableCell align='center' style={{ width: '20px' }}>
                          {t('commoncomponents.scenario-form.budget-tab.budget-arr-years')}
                        </TableCell>
                        {createTableByYearsFromScenarioDates().map((year, index) => (
                          <TableCell
                            align='center'
                            key={`table-cell-budget-year-${index}`}
                            style={{ minWidth: '5rem' }}
                          >
                            {year}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        className={
                          !editMode ? customClasses.tableRowBackgroundDisabled : customClasses.tableRowBackgroundEnabled
                        }
                      >
                        <TableCell align='center' style={{ width: '20px' }}>
                          {t('commoncomponents.scenario-form.budget-tab.budget-arr-budget')}
                        </TableCell>
                        {createTableByYearsFromScenarioDates().map((year, index) => (
                          <TableCell
                            align='center'
                            key={`table-cell-budget-value-${index}`}
                            style={{
                              minWidth: `${
                                selectedBudget?.AvailableQuantityByYear[year]?.toString().length * 0.8 || 1
                              }rem`
                            }}
                          >
                            <TextField
                              disabled={!editMode}
                              inputProps={{
                                min: selectedBudget.MinimalQuantityLevelByYear[year]
                                  ? selectedBudget.MinimalQuantityLevelByYear[year]
                                  : DEFAULT_MIN_VALUE,
                                step: DEFAULT_STEP_VALUE
                              }}
                              key={`${parametersGroupDataId}-startYear`}
                              type='number'
                              value={selectedBudget.AvailableQuantityByYear[year]}
                              variant='standard'
                              onChange={(event) => handleChangeYearQuantity(year, event.target.value)}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>,
            displayAvailableQuantityByYear && (
              <Grid
                alignContent='flex-start'
                alignItems='center'
                container
                direction='row'
                justifyContent='flex-start'
                key={`grid-col-1-row-3-${parametersGroupDataId}`}
                spacing={2}
                sx={{ marginTop: '1rem' }}
              >
                <Grid item key={`grid-col-1-row-3-${parametersGroupDataId}fast-filling`} md={3}>
                  <Typography
                    className={!editMode ? customClasses.textDisabled : null}
                    id={`input-label-id${parametersGroupDataId}fast-filling`}
                  >
                    {t('commoncomponents.scenario-form.budget-tab.fast-filling')}
                  </Typography>
                </Grid>
                <Grid item key={`grid-col-1-row-3-${parametersGroupDataId}-startYear`} md={2}>
                  <InputComponentFactory
                    editProps={{
                      type: 'dateYear',
                      placeholder: t('commoncomponents.scenario-form.budget-tab.start'),
                      maxDate: selectedBudget.EndYear ? selectedBudget.EndYear : getDefaultEndYear(),
                      disabled: !editMode
                    }}
                    key={`${parametersGroupDataId}-startYear`}
                    value={getStartYear()}
                    onChange={(newValue) => {
                      handleChangeStartYear(newValue);
                    }}
                  />
                </Grid>
                <Grid item key={`grid-col-1-row-3-${parametersGroupDataId}-end-year`} md={2}>
                  <InputComponentFactory
                    editProps={{
                      type: 'dateYear',
                      placeholder: t('commoncomponents.scenario-form.budget-tab.end'),
                      minDate: selectedBudget.StartYear ? selectedBudget.StartYear : getDefaultStartYear(),
                      disabled: !editMode
                    }}
                    key={`${parametersGroupDataId}-endYear`}
                    value={getEndYear()}
                    onChange={(newValue) => {
                      handleChangeEndYear(newValue);
                    }}
                  />
                </Grid>
                <Grid item key={`grid-col-1-row-3-${parametersGroupDataId}-custom-budget`} md={2}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          {t('commoncomponents.scenario-form.budget-tab.prefix-custom-budget')}
                        </InputAdornment>
                      )
                    }}
                    disabled={!editMode}
                    inputProps={{
                      min: DEFAULT_MIN_VALUE,
                      step: DEFAULT_STEP_VALUE
                    }}
                    key={`${parametersGroupDataId}-budget`}
                    label={t('commoncomponents.scenario-form.budget-tab.custom-budget') || 'Custom Budget'}
                    type='number'
                    value={selectedBudget.CustomBudget || 0}
                    variant='standard'
                    onChange={(event) => handleChangeCustomBudget(event.target.value)}
                  />
                </Grid>
                <Grid item key={`grid-col-1-row-4-${parametersGroupDataId}-apply-fast-filling`} md={1}>
                  <IconButton
                    aria-label={t('commoncomponents.scenario-form.budget-tab.apply-fast-filling')}
                    disabled={!editMode}
                    size='large'
                    onClick={() => handleClickFastFilling()}
                  >
                    <CheckCircle color={!editMode ? 'disabled' : 'primary'} />
                  </IconButton>
                </Grid>
              </Grid>
            )
          ]
        ) : (
          <Grid item key={`grid-col-1-row-3-${parametersGroupDataId}-no-budget`} md={12} sx={{ display: 'contents' }}>
            <Paper className={customClasses.unselectItemBox} elevation={0}>
              <Typography className={customClasses.unselectItemText}>
                {t('commoncomponents.scenario-form.budget-tab.unselected-item')}
              </Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const BudgetListFactory = {
  create
};
