import { Paper } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { DataGrid, frFR, gridClasses } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

const AbacusTable = ({ rows }) => {
  const { i18n } = useTranslation();
  const classes = useStyles();

  const columns = rows[0]
    ? Object.keys(rows[0]).map((key) => ({
        field: key,
        headerName: key,
        minWidth: 200,
        maxWidth: 300,
        sortable: true
      }))
    : [];

  const rowsWithIds = rows.map((row, index) => ({ id: index, ...row }));

  return (
    <div className={classes.tableContainer}>
      <Paper className={classes.tablePaper} data-testid='abacus-table'>
        <StripedDataGrid
          autoHeight
          className={classes.datagrid}
          columns={columns}
          filterMode='client'
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25
              }
            }
          }}
          localeText={i18n.language === 'fr' ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
          pageSize={5}
          rows={rowsWithIds}
        />
      </Paper>
    </div>
  );
};

export default AbacusTable;

AbacusTable.propTypes = {
  rows: PropTypes.array.isRequired
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDataGrid-row': {
      backgroundColor: theme.palette.nexans.white
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: theme.palette.nexans.primary,
      color: 'white'
    }
  },
  datagrid: {
    width: '100%',
    overflowX: 'auto'
  },
  tableContainer: {
    boxSizing: 'border-box'
  },
  tablePaper: {
    backgroundColor: theme.palette.nexans.white,
    alignItems: 'center'
  }
}));
const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.nexans.primary, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.nexans.primary, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.nexans.primary, ODD_OPACITY + theme.palette.action.selectedOpacity)
        }
      }
    }
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: theme.palette.nexans.primary,
    color: 'white',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '& .MuiButtonBase-root': {
      color: 'white'
    },
    '& .MuiDataGrid-iconButtonContainer': { display: 'none' }
  },
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none'
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold !important'
  }
}));
