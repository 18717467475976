import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useMemo, useState } from 'react';

export const useSimpleDialog = () => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [action, setAction] = useState(null);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const SimpleDialog = useMemo(() => {
    const DialogComponent = () => {
      return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id='simple-dialog-title'>{title}</DialogTitle>
          <DialogContentText style={{ alignSelf: 'center' }}>{content}</DialogContentText>
          <DialogContent style={{ alignSelf: 'center' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={(e) => {
                if (action) {
                  action();
                }
                handleClose(e);
              }}
            >
              Run Loop
            </Button>
          </DialogContent>
        </Dialog>
      );
    };
    return DialogComponent;
  }, [handleOpen, handleClose]);

  return {
    SimpleDialog,
    handleOpen,
    handleClose,
    setTitle,
    setContent,
    setAction
  };
};
