import { ApiTechnicalPolicy } from 'api/ApiTechnicalPolicy';
import { call, put, takeEvery } from 'redux-saga/effects';
import { TECHNICAL_POLICY_ACTIONS_KEY } from 'state/commons/TechnicalPolicyConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* importTechnicalPoliciesFile(action) {
  const file = action.file;
  try {
    yield put({
      type: TECHNICAL_POLICY_ACTIONS_KEY.SET_TECHNICAL_POLICIES_IMPORT_ERRORS,
      errors: null
    });
    yield put({
      type: TECHNICAL_POLICY_ACTIONS_KEY.SET_TECHNICAL_POLICIES_IMPORT_UPLOADING,
      uploading: true
    });
    const organizationId = yield selectOrganizationId();
    yield call(ApiTechnicalPolicy.importTechnicalPoliciesFile, organizationId, file);
    yield put({
      type: TECHNICAL_POLICY_ACTIONS_KEY.GET_ALL_TECHNICAL_POLICIES,
      params: { keyword: null, perPage: 5, page: 1 }
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: TECHNICAL_POLICY_ACTIONS_KEY.SET_TECHNICAL_POLICIES_IMPORT_ERRORS,
      errors: e.response?.data
    });
  }
  yield put({
    type: TECHNICAL_POLICY_ACTIONS_KEY.SET_TECHNICAL_POLICIES_IMPORT_UPLOADING,
    uploading: false
  });
}

function* UploadTechnicalPoliciesFile() {
  yield takeEvery(TECHNICAL_POLICY_ACTIONS_KEY.UPLOAD_TECHNICAL_POLICIES, importTechnicalPoliciesFile);
}

export default UploadTechnicalPoliciesFile;
