const LAW_VALUE_WEIBULL = 'Weibull';
const LAW_VALUE_GOMPERTZ = 'Gompertz';

/*
Function GetWeibullParameters
============================
Input : 
============================
  m  (number) : Durée de vie minimale
  M  (number) : Durée de vie maximale
  Tm (number) : Taux de défaillance minimale
  TM (number) : Taux de défaillance maximale
Output :
=============================
  (object) : {
    k  : Paramètre de forme
    lambda : Paramètre d'échelle
  }
*/
function GetWeibullParameters(lifespanMin, lifespanMax, failureRateMin, failureRateMax) {
  let shape = null;
  let scale = null;

  const m = lifespanMin;
  const M = lifespanMax;
  const Tm = failureRateMin;
  const TM = failureRateMax;

  const k = 1 + Math.log(Tm / TM) / Math.log(m / M);
  const lambda = m * (k / (m * Tm)) ** (1 / k);

  if (k) shape = k;
  if (lambda) scale = lambda;

  return {
    shape,
    scale
  };
}

/*
Function GetGompertzParameters
============================
Input : 
============================
  m  (number) : Durée de vie minimale
  M  (number) : Durée de vie maximale
  Tm (number) : Taux de défaillance minimale
  TM (number) : Taux de défaillance maximale
Output :
=============================
  (object) : {
    k  : Paramètre de forme
    lambda : Paramètre d'échelle
  }
*/

function GetGompertzParameters(lifespanMin, lifespanMax, failureRateMin, failureRateMax) {
  let shape = null;
  let scale = null;

  const m = lifespanMin;
  const M = lifespanMax;
  const Tm = failureRateMin;
  const TM = failureRateMax;

  const b = Math.log(TM / Tm) / (M - m);
  const n = Tm / (b * Math.exp(b * m));

  if (n) shape = n;
  if (b) scale = b;

  return {
    shape,
    scale
  };
}

/*
Function : TriggerLaw
Aim : Return the newState with the law triggered
*/
function TriggerLaw(newState, newStateMapping, law) {
  const lifespanMin = newState[newStateMapping.lifespanMin];
  const lifespanMax = newState[newStateMapping.lifespanMax];
  const failureRateMin = newState[newStateMapping.failureRateMin];
  const failureRateMax = newState[newStateMapping.failureRateMax];
  if (lifespanMin !== 0 && lifespanMax !== 0 && failureRateMin !== 0 && failureRateMax !== 0 && law) {
    const lawFunction = law === LAW_VALUE_WEIBULL ? GetWeibullParameters : GetGompertzParameters;
    const lawParameters = lawFunction(lifespanMin, lifespanMax, failureRateMin, failureRateMax);

    newState = {
      ...newState,
      [newStateMapping.shape]: lawParameters.shape ? lawParameters.shape : null,
      [newStateMapping.scale]: lawParameters.scale ? lawParameters.scale : null
    };
  } else {
    newState = {
      ...newState,
      [newStateMapping.shape]: null,
      [newStateMapping.scale]: null
    };
  }
  return newState;
}

export const LawProbabilityUtils = {
  LAW_VALUE_WEIBULL,
  LAW_VALUE_GOMPERTZ,
  GetWeibullParameters,
  GetGompertzParameters,
  TriggerLaw
};
