import { Paper, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ApiAsset } from 'api/ApiAsset';
import { EditTable } from 'components';
import PropTypes from 'prop-types';
import { AGINGMODELENUM } from 'state/commons/Constants';
import { useOrganizationId } from 'state/hooks/Workspace.hooks';
import theme from 'theme';
import DownloadBlob from 'utils/hooks/DownloadBlob.hook';

const useStyles = makeStyles(() => ({
  tableContainer: {
    margin: '24px auto',
    width: '98%'
  },
  tablePaper: {
    backgroundColor: theme.palette.nexans.white,
    alignItems: 'center'
  },
  skeleton: {
    width: '100%',
    height: '20vh',
    backgroundColor: 'rgb(0 0 0 / 11%)'
  }
}));

function AssetRegistryFamilyDetailTab(props) {
  const classes = useStyles();
  const { asset, id, assetAgingModel, isSelectedAssetLoading, 'data-testid': testId } = props;
  const organizationId = useOrganizationId();

  function mapAssetToRow(family) {
    return {
      assetName: family.$dtId,
      assetQuantity: family.Quantity,
      assetAgingFamily: id,
      assetLocation: family.ExtraArea,
      assetInstallationYear: family.InstallationYear,
      assetAge: new Date().getFullYear() - family.InstallationYear,
      assetHealthScore: family.HealthScore,
      assetEnvironmentContext: family.EnvironmentalConsequencesFactor,
      assetDateOfLastSpecificAction: family.DateOfLastSpecificAction,
      assetDateOfLastStateRecording: family.DateOfLastStateRecording,
      assetKnownApparentAge: family.KnownApparentAge,
      assetSubLocation: family.ExtraAreaLevel2,
      assetSaidi15Min: family.SAIDI15min,
      assetFinancialConsequencesFactor: family.FinancialConsequencesFactor,
      assetNetworkConsequencesFactor: family.NetworkConsequencesFactor,
      assetEnvironmentalConsequencesFactor: family.EnvironmentalConsequencesFactor,
      assetRateLevel: family.EnvironmentAgingRate
    };
  }

  function mapAssetToDataTable() {
    return asset.map((row) => mapAssetToRow(row));
  }

  const exportAssetsRegistry = () => {
    ApiAsset.exportAssetsRegistry(organizationId, id).then((blob) => {
      DownloadBlob(blob, 'assets_registry');
    });
  };

  const familyTableSettings = {
    colsTranslationKeys: [
      'assetName',
      'assetQuantity',
      'assetAgingFamily',
      'assetInstallationYear',
      'assetAge',
      // assetAgingModel === 'CNAIM' ? 'assetHealthScore' : 'assetApparentAge',
      assetAgingModel === AGINGMODELENUM.CNAIM ? 'assetHealthScore' : 'assetApparentAge',
      // assetAgingModel === 'CNAIM' ? 'assetKnownHealthScore' : 'assetKnownApparentAge',
      assetAgingModel === AGINGMODELENUM.CNAIM ? 'assetKnownHealthScore' : 'assetKnownApparentAge',
      'assetEnvironmentContext',
      'assetDateOfLastSpecificAction',
      'assetDateOfLastStateRecording',
      'assetLocation',
      'assetSubLocation',
      'assetSaidi15Min',
      'assetFinancialConsequencesFactor',
      'assetNetworkConsequencesFactor',
      'EnvironmentalConsequencesFactor',
      'assetRateLevel'
    ],
    cols: [
      'assetName',
      'assetQuantity',
      'assetAgingFamily',
      'assetInstallationYear',
      'assetAge',
      'assetHealthScore',
      'assetKnownApparentAge',
      'assetEnvironmentContext',
      'assetDateOfLastSpecificAction',
      'assetDateOfLastStateRecording',
      'assetLocation',
      'assetSubLocation',
      'assetSaidi15Min',
      'assetFinancialConsequencesFactor',
      'assetNetworkConsequencesFactor',
      'assetEnvironmentalConsequencesFactor',
      'assetRateLevel'
    ],
    colProps: [
      { type: 'string' },
      { type: 'string' },
      { type: 'string' },
      { type: 'string' },
      { type: 'string' },
      { type: 'string' },
      { type: 'string' },
      { type: 'string' },
      { type: 'date' },
      { type: 'date' },
      { type: 'string' },
      { type: 'string' },
      { type: 'string' },
      { type: 'string' },
      { type: 'string' },
      { type: 'string' },
      { type: 'string' }
    ],
    flex: {
      assetName: 4
    },
    isAlwaysEdit: false,
    useNativePagination: true
  };

  return (
    <div className={classes.tableContainer}>
      <Paper className={classes.tablePaper}>
        {isSelectedAssetLoading ? (
          <Skeleton className={classes.skeleton} variant='rectangular' />
        ) : (
          <EditTable
            assetRegistry={true}
            data={mapAssetToDataTable()}
            data-testid={testId}
            loading={isSelectedAssetLoading}
            tableSettings={familyTableSettings}
            toolbar={{
              visible: true,
              handleClickOnExport: exportAssetsRegistry
            }}
          />
        )}
      </Paper>
    </div>
  );
}

AssetRegistryFamilyDetailTab.propTypes = {
  asset: PropTypes.array.isRequired,
  'data-testid': PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  assetAgingModel: PropTypes.string.isRequired,
  isSelectedAssetLoading: PropTypes.bool.isRequired
};

export default AssetRegistryFamilyDetailTab;
