import { CircularProgress, Grid } from '@mui/material';
import { SimpleTwoActionsDialogContent } from 'components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OperationListCard from './OperationListCard.component';
import OperationRow from './OperationRow.component';

function OperationsListContent({
  operationsListLoading,
  operationsList,
  deleteOperation,
  getOperation,
  getTechnicalPolicy,
  getPredeterminedProject
}) {
  const { t } = useTranslation();
  const [showDeleteOperation, setShowDeleteOperation] = useState(null);

  function handleDeleteOperation(operation) {
    deleteOperation(operation);
    setShowDeleteOperation(null);
  }

  if (operationsList?.length <= 0) {
    return (
      <Grid alignItems='center' container={true} direction='column' justifyContent='center' style={{ height: '25vh' }}>
        <Grid
          item={true}
          style={{
            height: '40vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          xs={12}
        >
          <span>{t('views.library.operation.noOperation')}</span>
        </Grid>
      </Grid>
    );
  }

  if (operationsListLoading) {
    return (
      <Grid alignItems='center' container={true} direction='column' justifyContent='center' style={{ height: '25vh' }}>
        <Grid
          item={true}
          style={{
            height: '40vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          xs={12}
        >
          <CircularProgress size='5rem' />
        </Grid>
      </Grid>
    );
  }

  return (
    !operationsListLoading &&
    operationsList?.map((operation, operationIndex) => (
      <div key={`operation-${operation.$dtId + operationIndex}`}>
        <OperationListCard
          getOperation={getOperation}
          getPredeterminedProject={getPredeterminedProject}
          getTechnicalPolicy={getTechnicalPolicy}
          operation={operation}
          operationIndex={operationIndex}
          setShowDeleteOperation={setShowDeleteOperation}
        />

        <SimpleTwoActionsDialogContent
          content={
            <OperationRow
              getPredeterminedProject={getPredeterminedProject}
              getTechnicalPolicy={getTechnicalPolicy}
              operation={operation}
              options={{
                backgroundColor: 'white',
                withWarning: true
              }}
            />
          }
          handleClickOnButton1={() => setShowDeleteOperation(null)}
          handleClickOnButton2={() => handleDeleteOperation(operation)}
          id='delete-confirmation'
          key={`delete-confirmation-${operationIndex}`}
          labels={{
            title: t('genericcomponent.dialog.library.operation.title', 'Confirm data suppression ?'),
            body: t('genericcomponent.dialog.library.operation.body', 'Would you like to delete this data ?'),
            button1: t('genericcomponent.dialog.library.operation.cancel', 'Cancel'),
            button2: t('genericcomponent.dialog.library.operation.delete', 'Delete'),
            ariaLabelledby: 'confirm-delete-dialog'
          }}
          open={showDeleteOperation === operationIndex}
        />
      </div>
    ))
  );
}

OperationsListContent.propTypes = {
  operationsListLoading: PropTypes.bool.isRequired,
  operationsList: PropTypes.array.isRequired,
  deleteOperation: PropTypes.func.isRequired,
  getOperation: PropTypes.func.isRequired,
  getTechnicalPolicy: PropTypes.func.isRequired,
  getPredeterminedProject: PropTypes.func.isRequired,
  refetchOperationsList: PropTypes.func.isRequired
};

export default OperationsListContent;
