import { ScenarioUtils } from '@cosmotech/core';
import { ScenarioManagerTreeList } from '@cosmotech/ui';
import { Box, DialogContent, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRequestIsLoading } from 'state/hooks/Request.hooks';
import { getFirstScenarioMaster } from 'utils/helpers/SortScenarioListUtils';
import { WORKSPACE_ID } from '../../config/AppInstance';

function moveScenario(moveData) {
  const scenarioId = moveData.node?.id;
  const newParentId = moveData.nextParentNode?.id;
  console.warn(
    `Trying to move scenario ${scenarioId} under scenario ${newParentId}. This feature is not implemented yet.`
  );
}

const ScenarioManager = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isLoading = useRequestIsLoading();

  const labels = {
    status: t('commoncomponents.scenariomanager.treelist.node.status.label'),
    successful: t('commoncomponents.scenariomanager.treelist.node.status.successful'),
    running: t('commoncomponents.scenariomanager.treelist.node.status.running'),
    failed: t('commoncomponents.scenariomanager.treelist.node.status.failed'),
    created: t('commoncomponents.scenariomanager.treelist.node.status.created'),
    delete: t('commoncomponents.scenariomanager.treelist.node.action.delete'),
    redirect: t('commoncomponents.scenariomanager.treelist.node.action.redirect'),
    scenarioRename: {
      title: t('commoncomponents.dialog.create.scenario.input.scenarioname.label'),
      errors: {
        emptyScenarioName: t('commoncomponents.dialog.create.scenario.input.scenarioname.error.empty'),
        forbiddenCharsInScenarioName: t(
          'commoncomponents.dialog.create.scenario.input.scenarioname.error.forbiddenchars'
        ),
        existingScenarioName: t('commoncomponents.dialog.create.scenario.input.scenarioname.error.existing')
      }
    },
    deleteDialog: {
      description: t(
        'commoncomponents.dialog.confirm.delete.description',
        'The scenario will be deleted. If this scenario has children, ' +
          'then its parent will become the new parent of all these scenarios.'
      ),
      cancel: t('commoncomponents.dialog.confirm.delete.button.cancel', 'Cancel'),
      confirm: t('commoncomponents.dialog.confirm.delete.button.confirm', 'Confirm')
    },
    searchField: t('commoncomponents.scenariomanager.treelist.node.text.search'),
    toolbar: {
      expandAll: t('commoncomponents.scenariomanager.toolbar.expandAll', 'Expand all'),
      expandTree: t('commoncomponents.scenariomanager.toolbar.expandTree', 'Expand tree'),
      collapseAll: t('commoncomponents.scenariomanager.toolbar.collapseAll', 'Collapse all')
    },
    validationStatus: {
      rejected: t('views.scenario.validation.rejected', 'Rejected'),
      validated: t('views.scenario.validation.validated', 'Validated')
    }
  };

  const {
    currentScenario,
    datasets,
    deleteScenario,
    renameScenario,
    findScenarioById,
    scenarios,
    resetCurrentScenario,
    setCurrentScenario,
    user
  } = props;

  const getScenariolistAfterDelete = (idOfScenarioToDelete) => {
    const scenarioListAfterDelete = scenarios
      .map((scenario) => {
        const newScenario = { ...scenario };
        if (newScenario.parentId === idOfScenarioToDelete) {
          newScenario.parentId = currentScenario.parentId;
        }
        return newScenario;
      })
      .filter((scenario) => scenario.id !== idOfScenarioToDelete);

    return scenarioListAfterDelete;
  };

  function onScenarioDelete(scenarioId) {
    const lastScenarioDelete = scenarios.length === 1;
    deleteScenario(WORKSPACE_ID, scenarioId);
    if (scenarioId === currentScenario.id) {
      if (lastScenarioDelete) {
        resetCurrentScenario();
      } else {
        const scenarioListAfterDelete = getScenariolistAfterDelete(scenarioId);
        const firstMasterScenario = getFirstScenarioMaster(scenarioListAfterDelete);
        setCurrentScenario(firstMasterScenario);
      }
    }
  }

  function onScenarioRename(scenarioId, newScenarioName) {
    if (scenarioId === currentScenario.id) {
      setCurrentScenario({ name: newScenarioName });
    }
    renameScenario(WORKSPACE_ID, scenarioId, newScenarioName);
  }

  function checkScenarioNameValue(newScenarioName) {
    const errorKey = ScenarioUtils.scenarioNameIsValid(newScenarioName, scenarios);
    if (errorKey) {
      const errorLabel = labels.scenarioRename.errors[errorKey];
      if (!errorLabel) {
        console.warn('Scenario error label key is broken !');
        return 'Scenario name is invalid';
      }
      return errorLabel;
    }
    return null;
  }

  function buildDatasetLabel(datasetList) {
    return t('commoncomponents.scenariomanager.treelist.node.dataset', { count: datasetList?.length || 0 });
  }

  function buildScenarioNameToDelete(scenarioName) {
    return t('commoncomponents.dialog.confirm.delete.title', "Remove scenario '{{scenarioName}}'?", {
      scenarioName
    });
  }

  const navigate = useNavigate();

  const isWaitingForRedirection = useRef(false);
  useEffect(() => {
    if (isWaitingForRedirection.current === true) {
      navigate('/scenario');
      isWaitingForRedirection.current = false;
    }
  }, [currentScenario]);

  const onScenarioRedirect = (scenarioId) => {
    isWaitingForRedirection.current = true;
    findScenarioById(WORKSPACE_ID, scenarioId);
  };

  const isAdminByDefault = (userId, security) =>
    security.default === 'admin' &&
    security.accessControlList.find((u) => u.role !== 'admin' && u.id === userId) === undefined;

  const isAdminUserInAccessControlList = (userId, security) =>
    security.accessControlList.find((u) => u.role === 'admin' && u.id === userId) !== undefined;

  return (
    <>
      <Box className={classes.root}>
        <ScenarioManagerTreeList
          buildDatasetInfo={buildDatasetLabel}
          buildScenarioNameToDelete={buildScenarioNameToDelete}
          canUserDeleteScenario={(scenario) =>
            isAdminByDefault(user.userName, scenario.security) ||
            isAdminUserInAccessControlList(user.userName, scenario.security) ||
            user.userName === scenario.ownerName
          }
          checkScenarioNameValue={checkScenarioNameValue}
          currentScenarioId={currentScenario?.id}
          datasets={datasets}
          deleteScenario={onScenarioDelete}
          labels={labels}
          moveScenario={moveScenario}
          scenarios={scenarios}
          userId={user.userId}
          onScenarioRedirect={onScenarioRedirect}
          onScenarioRename={onScenarioRename}
        />
      </Box>
      <Dialog open={isLoading}>
        <DialogContent>
          <Stack alignItems='center' spacing={3}>
            <Typography>{t('views.scenarioManager.deletingScenario')}</Typography>
            <CircularProgress />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

ScenarioManager.propTypes = {
  currentScenario: PropTypes.object,
  datasets: PropTypes.array.isRequired,
  deleteScenario: PropTypes.func.isRequired,
  renameScenario: PropTypes.func.isRequired,
  findScenarioById: PropTypes.func.isRequired,
  scenarios: PropTypes.array.isRequired,
  resetCurrentScenario: PropTypes.func.isRequired,
  setCurrentScenario: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default ScenarioManager;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    margin: 'auto',
    height: '100%',
    width: '100%',

    '& .MuiPaper-root': {
      backgroundColor: theme.palette.nexans.lightGrey
    },
    '& .MuiPaper-root:first-of-type': {
      backgroundColor: `${theme.palette.nexans.lightGrey} !important`,
      '& .MuiPaper-root:nth-child(1)': {
        backgroundColor: 'white !important'
      }
    }
  }
}));
