import { ApiIntegration } from 'api/ApiIntegration';
import { call, put, takeEvery } from 'redux-saga/effects';
import { DATAINTEGRATION_ACTIONS_KEY } from '../../../commons/DataIntegrationConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* deleteAllData(action) {
  const { pipelineType } = action;
  try {
    const organizationId = yield selectOrganizationId();
    const response = yield call(ApiIntegration.deleteAllPipelineData, organizationId, pipelineType);
    if (response) {
      yield put({
        type: DATAINTEGRATION_ACTIONS_KEY.GET_PIPELINE_HISTORY,
        pipelineType
      });
      yield put({
        type: DATAINTEGRATION_ACTIONS_KEY.GET_SUBPIPELINES_DATA
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* deleteAllPipelineData() {
  yield takeEvery(DATAINTEGRATION_ACTIONS_KEY.DELETE_ALL_PIPELINE_DATA, deleteAllData);
}

export default deleteAllPipelineData;
