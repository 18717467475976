import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePowerBIInfo } from 'state/hooks/PowerBI.hooks';
import { useCurrentScenarioData } from 'state/hooks/Scenario.hooks';

import { SCENARIO_RUN_LOG_TYPE } from 'config/AppConfiguration';

import { LOG_TYPES } from 'services/scenarioRun/ScenarioRunConstants';
import ScenarioRunService from '../../services/scenarioRun/ScenarioRunService';

import { useOrganizationId } from 'state/hooks/Workspace.hooks';

import { getReportLabels } from './labels';

export const useCurrentScenarioPowerBiReport = () => {
  const { t, i18n } = useTranslation();

  const currentScenarioData = useCurrentScenarioData();
  const reports = usePowerBIInfo();

  const organizationId = useOrganizationId();

  const downloadLogsFile = useCallback(
    () =>
      currentScenarioData?.lastRun
        ? ScenarioRunService.downloadLogsFile(
            organizationId,
            currentScenarioData.lastRun,
            LOG_TYPES[SCENARIO_RUN_LOG_TYPE]
          )
        : null,
    [currentScenarioData, organizationId]
  );

  const reportLabels = useMemo(() => getReportLabels(t), [t]);
  const language = useMemo(() => i18n.language, [i18n.language]);

  return {
    currentScenarioData,
    reportLabels,
    reports,
    language,
    downloadLogsFile
  };
};
