import { FormControlLabel, Switch, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

export default function DataIntegrationSwitchButton({ checked, onChange, onClick, labels }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.outlineContainer}>
      <label className={classes.label} htmlFor={labels.left}>
        {t(`views.dataIntegration.kpi.${labels.main}`)}
      </label>
      <FormControlLabel
        control={
          <Switch checked={checked} className={classes.switch} color='primary' onChange={onChange} onClick={onClick} />
        }
        label={
          <Typography className={classes.formControlLabel}>
            {checked ? t(`views.dataIntegration.kpi.${labels.left}`) : t(`views.dataIntegration.kpi.${labels.right}`)}
          </Typography>
        }
        labelPlacement='end'
      />
    </div>
  );
}

DataIntegrationSwitchButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  labels: PropTypes.object.isRequired
};

const useStyles = makeStyles(() => ({
  outlineContainer: {
    display: 'flex',
    padding: '0.3rem 0.2rem 0.3rem 0.8rem',
    border: '1px solid',
    borderColor: theme.palette.nexans.primary,
    borderRadius: '10px'
  },
  label: {
    position: 'absolute',
    top: '-.5rem',
    left: '20px',
    fontSize: '.5rem',
    backgroundColor: theme.palette.nexans.white,
    padding: '0 2px',
    color: theme.palette.nexans.primary
  },
  formControlLabel: {
    color: theme.palette.nexans.black
  }
}));
