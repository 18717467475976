import { GetApp as GetAppIcon, PlayCircleOutline as PlayCircleOutlineIcon } from '@mui/icons-material';
import { Button, Card, CircularProgress, IconButton, Modal, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { ApiIntegration } from 'api/ApiIntegration/ApiIntegration';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PIPELINESTATUS } from 'state/commons/Constants';
import {
  useDataIntegrationCanRunIntegration,
  useDataIntegrationGetLatestRun,
  useDataIntegrationIsMainPipelineRunning,
  useDataIntegrationRunMainPipeline,
  useGetDataIntegrationSubPipelines
} from 'state/hooks/DataIntegration.hooks';
import { useOrganizationId } from 'state/hooks/Workspace.hooks';
import theme from 'theme';
import { DateUtils } from 'utils/helpers';
import DownloadBlob from 'utils/hooks/DownloadBlob.hook';
import { getIconFromPipelineStatus } from './dataIntegration.assets';

const downloadErrorfile = (organizationId, runId, fileName) => {
  ApiIntegration.downloadPipelineErrorFile(organizationId, runId).then((blob) => {
    DownloadBlob(blob, fileName);
  });
};

export default function DataIntegrationMainInfosBar() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const isMainPipelineRunning = useDataIntegrationIsMainPipelineRunning();
  const canRunIntegration = useDataIntegrationCanRunIntegration();
  const subPipelines = useGetDataIntegrationSubPipelines();
  const runMainPipeline = useDataIntegrationRunMainPipeline();
  const { errorMessages, integrationDate, message, status, runId } = useDataIntegrationGetLatestRun();
  const organizationId = useOrganizationId();

  const nextPipelineRuns = Object.keys(subPipelines).flatMap((key) => {
    const subPipeline = subPipelines[key];
    return Object.keys(subPipeline.lastPipelineRunsByAction).map((i) => {
      return subPipeline.lastPipelineRunsByAction[i];
    });
  });

  const subPipelineStatus = Object.values(subPipelines).map(
    (subPipeline) => subPipeline.lastPipelineRunsByAction.Create?.status
  );
  const isSubPipelineRunning = !!subPipelineStatus.find(
    (status) =>
      status === PIPELINESTATUS.RUNNING ||
      status === PIPELINESTATUS.PREPARINGIMPORT ||
      status === PIPELINESTATUS.UPLOADING
  );

  const handleOpen = () => {
    if (message?.content || errorMessages?.length > 0) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <DataIntegrationMainInfosBarContainer data-testid='di-maininfobar-container'>
        <SubInfosContainer>
          <h1>{t('views.dataIntegration.kpi.lastUpdate')}</h1>
          <div className='subInfosMainRow'>
            <div className='subInfosDataRow'>
              <div>
                {integrationDate && (
                  <>
                    <div className='bold'>{t('views.dataIntegration.kpi.date')}</div>
                    <div data-testid='di-info-bar-last-updated'>
                      {DateUtils.formatDateIsoToDDMMYYYYhhmm(integrationDate)}
                    </div>
                  </>
                )}
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='bold'>{t('views.dataIntegration.kpi.status')}</div>
                {status && (
                  <div data-testid='di-info-bar-status'>
                    {t(`layouts.tabs.dataintegration.status.${status}`, status)}
                  </div>
                )}
                <div>
                  <StatusIconButton
                    message={message?.content || errorMessages}
                    status={status || ''}
                    onClick={handleOpen}
                  />
                </div>
              </div>

              <ButtonContainer>
                <RunPipelineButton
                  isDisabled={!canRunIntegration}
                  isLoading={isMainPipelineRunning}
                  isSubPipelineRunning={isSubPipelineRunning}
                  onClick={() => runMainPipeline()}
                />
              </ButtonContainer>
            </div>
            <br />
            <div className='subInfosDataRow'>
              <div>
                <div className='bold'>{t('views.dataIntegration.kpi.nextAction')}</div>
                <div>
                  {(nextPipelineRuns?.length > 0 &&
                    nextPipelineRuns.map((run) => (
                      <span data-testid='di-info-bar-next-action' key={`${run.action}-${run.type}`}>
                        {`${t(`views.dataIntegration.history.action.${run.action}`)}: ${t(
                          `views.dataIntegration.history.type.${run.type}`
                        )}`}
                      </span>
                    ))) ||
                    '-'}
                </div>
              </div>
            </div>
          </div>
        </SubInfosContainer>
      </DataIntegrationMainInfosBarContainer>
      <Modal
        aria-describedby='data-integration-modal-description'
        aria-labelledby='data-integration-modal-title'
        open={open}
        onClose={handleClose}
      >
        <div style={getModalStyle()}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <span>{`${t('layouts.tabs.dataintegration.sourceFile')} ${message?.errorFileName}`}</span>
            <Button
              title={t('layouts.tabs.dataintegration.downloadErrorFile')}
              onClick={() => downloadErrorfile(organizationId, runId, message?.errorFileName)}
            >
              <GetAppIcon />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

function StatusIconButton({ status, onClick, message }) {
  return (
    <Tooltip title={message}>
      <IconButton aria-label='settings' size='large' title='' onClick={onClick}>
        {getIconFromPipelineStatus(status || '')}
      </IconButton>
    </Tooltip>
  );
}

StatusIconButton.propTypes = {
  status: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
};

function RunPipelineButton({ isLoading, onClick, isDisabled, isSubPipelineRunning }) {
  const { t } = useTranslation();

  return (
    <Button
      color={isDisabled ? 'secondary' : 'primary'}
      data-testid='di-info-bar-button-run-pipeline'
      disabled={isDisabled}
      variant='contained'
      onClick={onClick}
    >
      {isSubPipelineRunning || isLoading ? (
        <>
          <CircularProgress color='inherit' size='1rem' style={{ color: 'white', marginRight: '1rem' }} />{' '}
          <span>{t('layouts.tabs.dataintegration.pipelineRunning')}</span>
        </>
      ) : (
        <>
          <PlayCircleOutlineIcon style={{ marginRight: '1rem', marginLeft: 0 }} />{' '}
          <span>{t('layouts.tabs.dataintegration.runPipeline')}</span>
        </>
      )}
    </Button>
  );
}

RunPipelineButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubPipelineRunning: PropTypes.bool.isRequired
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    borderColor: theme.palette.nexans.lightGreyBg,
    backgroundColor: theme.palette.nexans.lightGreyBg,
    borderRadius: '10px',
    top: `${top}%`,
    left: `${left}%`,
    position: 'absolute',
    width: 'fit-content',
    padding: '1rem 1rem'
  };
}

const DataIntegrationMainInfosBarContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '2rem 3rem 1rem 3rem',
  width: '100%'
}));

const SubInfosContainer = styled('div')(() => ({
  color: `${theme.palette.text.secondary} !important`,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '& .subInfosMainRow': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

  '& .subInfosDataRow': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1rem',
    fontWeight: '400',
    justifyContent: 'space-between',

    '& .bold': {
      fontWeight: '600'
    },
    '& div': {
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem 0.5rem'
    }
  }
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex'
}));
