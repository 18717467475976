import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static propTypes = {
    children: PropTypes.node.isRequired
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    this.setState({ error, errorInfo });
  }

  handleReload = () => {
    window.location.reload();
  };

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            bgcolor: '#f1f1f1'
          }}
        >
          <Box sx={{ maxWidth: '80%' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 4
              }}
            >
              <img
                alt='Nexans logo'
                height={64}
                src='/logo-nobackground-200.png'
                style={{ marginRight: '16px' }}
                width={64}
              />
              <ErrorIcon sx={{ color: 'error.main', fontSize: 48 }} />
              <Typography variant='h2'>Oops! Something went wrong.</Typography>
            </Box>
            {error && (
              <Box sx={{ mb: 4 }}>
                <Typography sx={{ mb: 2 }} variant='body1'>
                  Error message:
                </Typography>
                <Typography sx={{ bgcolor: '#ffffff', p: 2 }} variant='body2'>
                  {error.toString()}
                </Typography>
              </Box>
            )}
            {errorInfo && (
              <Box sx={{ mb: 4 }}>
                <Typography sx={{ mb: 2 }} variant='body1'>
                  Component stack trace:
                </Typography>
                <Typography sx={{ bgcolor: '#ffffff', p: 2 }} variant='body2'>
                  {errorInfo.componentStack}
                </Typography>
              </Box>
            )}
            <Button variant='contained' onClick={this.handleReload}>
              Reload Page
            </Button>
          </Box>
        </Box>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
