import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  dispatchGetAllPredeterminedProjectList,
  dispatchGetAssetGroupByAssetClass,
  dispatchPredeterminedProjectIsNameExist,
  dispatchResetPredeterminedProject,
  dispatchSetSelectedPredeterminedProject
} from 'state/dispatchers/predeterminedProject/predeterminedProjectDispatcher';
import { dispatchGetAllScenarioPredeterminedProjectList } from 'state/dispatchers/scenarioPredeterminedProject/ScenarioPredeterminedProjectDispatcher';

export const useGetAllScenarioProjectList = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchGetAllScenarioPredeterminedProjectList()), [dispatch]);
};

export const useGetAllProjectList = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchGetAllPredeterminedProjectList()), [dispatch]);
};

export const useGetAssetGroupByAssetClass = () => {
  const dispatch = useDispatch();
  return useCallback((assetClass) => dispatch(dispatchGetAssetGroupByAssetClass(assetClass)), [dispatch]);
};

export const useSetSelectedProject = () => {
  const dispatch = useDispatch();
  return useCallback((techpol) => dispatch(dispatchSetSelectedPredeterminedProject(techpol)), [dispatch]);
};
export const useResetProject = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchResetPredeterminedProject()), [dispatch]);
};
export const useCheckIfProjectNameExists = () => {
  const dispatch = useDispatch();
  return useCallback(
    (name, oldProject, newProject) => dispatch(dispatchPredeterminedProjectIsNameExist(name, oldProject, newProject)),
    [dispatch]
  );
};

export const useScenarioProjectList = () => {
  return useSelector((state) => state.scenarioPredeterminedProject.list);
};
export const useSelectedProject = () => {
  return useSelector((state) => state.predeterminedProject.selectedPredeterminedProject.data);
};
export const useIsProjectNameAvailable = () => {
  return useSelector((state) => state.predeterminedProject.isNameAvailable);
};
export const useProjectEnums = () => {
  return useSelector((state) => state.predeterminedProject.enums);
};
export const useProjectAssetGroupByAssetClass = () => {
  return useSelector((state) => state.predeterminedProject.assetGroupsByAssetClass.data);
};

export const useProjectList = () => {
  return useSelector((state) => state.predeterminedProject.list);
};
