import { ToggleButton as MuiToggleButton, ToggleButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import { isColorDark } from 'utils/helpers/ColorsUtils';

export default function SwitchButton({
  initialButtonValue,
  orientation = 'horizontal',
  primaryButton,
  secondaryButton,
  onAlignmentChange,
  backgroundColor = theme.palette.nexans.primary
}) {
  const [alignment, setAlignment] = useState(initialButtonValue);
  const { t } = useTranslation();
  const classes = useStyles({ backgroundColor });

  function handleChange(event, newAlignment) {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      onAlignmentChange?.(newAlignment);
    }
  }

  return (
    <ToggleButtonGroup
      aria-label='of-key'
      color='primary'
      exclusive={true}
      orientation={orientation}
      sx={{ display: 'flex', marginTop: '2rem', marginRight: '1rem' }}
      value={alignment}
      onChange={handleChange}
    >
      <MuiToggleButton
        className={classes.toggleButton}
        data-testid={`switch-button-${primaryButton.value}`}
        value={primaryButton.value}
      >
        {t(primaryButton.label)}
      </MuiToggleButton>
      <MuiToggleButton
        className={classes.toggleButton}
        data-testid={`switch-button-${secondaryButton.value}`}
        value={secondaryButton.value}
      >
        {t(secondaryButton.label)}
      </MuiToggleButton>
    </ToggleButtonGroup>
  );
}

SwitchButton.propTypes = {
  backgroundColor: PropTypes.string,
  orientation: PropTypes.string,
  initialButtonValue: PropTypes.string,
  primaryButton: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  secondaryButton: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  onAlignmentChange: PropTypes.func.isRequired
};

const useStyles = makeStyles({
  toggleButton: {
    '&.Mui-selected': {
      backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : 'lightBlue'),
      color: (props) => (isColorDark(props.backgroundColor) ? 'white' : 'unset'),
      '&:hover': {
        backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : 'lightBlue')
      }
    }
  }
});
