import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getSubsetUrl = (organizationId) => `${getBasePath(organizationId)}/SubSet`;

const fetchAllSubset = (organizationId) =>
  axios.get(`${getSubsetUrl(organizationId)}/getsubsets`).then((response) => response.data);

const createSubset = (organizationId, subset) =>
  axios.post(getSubsetUrl(organizationId), subset).then((response) => response.data);

const editSubset = (organizationId, subset) =>
  axios.post(getSubsetUrl(organizationId), subset).then((response) => response.data);

const deleteSubset = (organizationId, idSubset) => {
  const urlWithId = `${getSubsetUrl(organizationId)}?idSubset=${idSubset}`;
  return axios.delete(urlWithId).then((response) => response.data);
};

export const ApiSubset = {
  createSubset,
  editSubset,
  deleteSubset,
  fetchAllSubset
};
