import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  dispatchDownloadCnaimFile,
  dispatchGetAbacusTable,
  dispatchGetCnaimFilesMetadata,
  dispatchSetCnaimFilesMetadata,
  dispatchUploadCnaimFile
} from 'state/dispatchers/cnaim/CnaimDispatcher';

export const useFetchAllCnaimMetadata = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchGetCnaimFilesMetadata()), [dispatch]);
};
export const useSetCnaimMetadata = () => {
  const dispatch = useDispatch();
  return useCallback((list) => dispatch(dispatchSetCnaimFilesMetadata(list)), [dispatch]);
};
export const useDownloadCnaimFile = () => {
  const dispatch = useDispatch();
  return useCallback((fileName) => dispatch(dispatchDownloadCnaimFile(fileName)), [dispatch]);
};

export const useGetCnaimMetadataFromState = () => {
  return useSelector((state) => state.cnaim.cnaimFilesMetadata.list);
};

export const useGetCnaimStatusFromState = () => {
  return useSelector((state) => state.cnaim.cnaimFilesMetadata.status);
};

export const useUploadCnaimFile = () => {
  const dispatch = useDispatch();
  return useCallback((file, fileName) => dispatch(dispatchUploadCnaimFile(file, fileName)), [dispatch]);
};

export const useFetchAbacusTable = () => {
  const dispatch = useDispatch();
  return useCallback((fileName) => dispatch(dispatchGetAbacusTable(fileName)), [dispatch]);
};

export const useGetAbacusTableFromState = () => {
  return useSelector((state) => state.cnaim.cnaimFilesMetadata.abacusTable);
};
