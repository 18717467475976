/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { ClickAwayListener, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SimpleTwoActionsDialogContent } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

const ScenarioDescription = ({ editMode, changeEditMode, updateScenarioDescription, currentScenario, workspaceId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showDiscardConfirmationPopup, setShowDiscardConfirmationPopup] = useState(false);
  const [showUpdateConfirmationPopup, setShowUpdateConfirmationPopup] = useState(false);
  const [descriptionFormValue, setDescriptionFormValue] = useState(
    currentScenario.data.description ? currentScenario.data.description : ''
  );

  const discardLocalChanges = () =>
    setDescriptionFormValue(currentScenario.data.description ? currentScenario.data.description : ' ');
  const update = () => updateScenarioDescription(workspaceId, currentScenario.data.id, descriptionFormValue);

  useEffect(() => {
    discardLocalChanges();
  }, [currentScenario]);

  const cancelDiscard = () => setShowDiscardConfirmationPopup(false);
  const confirmDiscard = () => {
    setShowDiscardConfirmationPopup(false);
    changeEditMode(false);
    discardLocalChanges();
  };

  const cancelUpdate = () => setShowUpdateConfirmationPopup(false);
  const confirmUpdate = () => {
    setShowUpdateConfirmationPopup(false);
    changeEditMode(false);
    update();
  };

  const handleClickOnEdit = () => {
    changeEditMode(true);
  };
  const handleChangeDescriptionFormValue = (event) => setDescriptionFormValue(event.target.value);

  function handleClickAway(event) {
    event.stopPropagation();
    if (editMode) {
      changeEditMode(false);
      update();
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <Grid item={true} md={12}>
        <div className={classes.container} onClick={handleClickOnEdit}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <TextField
              disabled={!editMode}
              fullWidth={true}
              id='textfield-scenario-description'
              label={t('genericcomponent.text.scenario.description.title', 'Description')}
              maxRows={4}
              minRows={1}
              multiline={true}
              placeholder={t('genericcomponent.text.scenario.description.placeholder', 'Please enter a description')}
              value={descriptionFormValue}
              variant='outlined'
              onChange={handleChangeDescriptionFormValue}
            />
          </ClickAwayListener>
        </div>
      </Grid>
      <SimpleTwoActionsDialogContent
        handleClickOnButton1={cancelDiscard}
        handleClickOnButton2={confirmDiscard}
        id={'discard-changes-description'}
        labels={{
          title: t(
            'genericcomponent.dialog.scenario.description-discard-confirmation.title',
            'Discard current modifications ?'
          ),
          body: t(
            'genericcomponent.dialog.scenario.description-discard-confirmation.body',
            'You will lose all your current changes made on the scenario description.'
          ),
          button1: t('genericcomponent.dialog.scenario.description-discard-confirmation.button.cancel', 'Cancel'),
          button2: t('genericcomponent.dialog.scenario.description-discard-confirmation.button.validate', 'Discard'),
          ariaLabelledby: 'discard-changes-dialog',
          button2BGColor: '#062F4F'
        }}
        open={showDiscardConfirmationPopup}
      />
      <SimpleTwoActionsDialogContent
        handleClickOnButton1={cancelUpdate}
        handleClickOnButton2={confirmUpdate}
        id={'update-changes-description'}
        labels={{
          title: t(
            'genericcomponent.dialog.scenario.description-update-confirmation.title',
            'Confirm description modification ?'
          ),
          body: t(
            'genericcomponent.dialog.scenario.description-update-confirmation.body',
            'Would you like to save scenario description modification ?'
          ),
          button1: t('genericcomponent.dialog.scenario.description-update-confirmation.button.cancel', 'Cancel'),
          button2: t('genericcomponent.dialog.scenario.description-update-confirmation.button.validate', 'Update'),
          ariaLabelledby: 'confirm-update-dialog'
        }}
        open={showUpdateConfirmationPopup}
      />
    </div>
  );
};

ScenarioDescription.propTypes = {
  editMode: PropTypes.bool.isRequired,
  changeEditMode: PropTypes.func.isRequired,
  updateScenarioDescription: PropTypes.func.isRequired,
  currentScenario: PropTypes.object.isRequired,
  workspaceId: PropTypes.string.isRequired
};

export default ScenarioDescription;

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'white',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
    marginTop: '0',
    width: '100%',

    '& .MuiFormLabel-root ': {
      backgroundColor: 'white',
      border: '1px solid lightgray',
      borderBottomColor: 'white',
      borderRadius: '5px',
      marginLeft: '-0.34rem',
      paddingTop: '0.2rem',
      textAlign: 'center',
      width: '5rem',

      '&:hover': {
        borderColor: '#062F4F'
      }
    }
  },
  rightBar: {
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    margin: `0 ${theme.spacing(3)}`
  }
}));
