import { useSelector } from 'react-redux';

export const useFamilyList = () => {
  return useSelector((state) => state.family.list.data);
};
export const useAgingFamilyList = () => {
  return useSelector((state) => state.family.list.data.map((item) => item.$dtId));
};
export const useCurrentFamily = () => {
  return useSelector((state) => state.family.current);
};
export const usecurrentScenarioAgingModel = () =>
  useSelector((state) => state.dataIntegration.subPipelines.assets.kpi?.main?.agingModel);
