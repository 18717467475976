// Redux Action (equivalent to dispatch function)
import { FINANCE_ACTIONS_KEY } from '../../commons/FinanceConstants';

export const dispatchGetAllNetworkLevel = () => ({
  type: FINANCE_ACTIONS_KEY.GET_ALL_NETWORK_LEVEL
});

export const dispatchSetAllNetworkLevel = (networkLevelList) => ({
  type: FINANCE_ACTIONS_KEY.SET_ALL_NETWORK_LEVEL,
  networkLevelList
});

export const dispatchGetAllOperatingForecast = () => ({
  type: FINANCE_ACTIONS_KEY.GET_ALL_OPERATING_FORECAST
});

export const dispatchSetAllOperatingForecast = (operatingForecastList) => ({
  type: FINANCE_ACTIONS_KEY.SET_ALL_OPERATING_FORECAST,
  operatingForecastList
});
export const dispatchSetCurrentScenarioOperatingForecast = (operatingForecastList) => ({
  type: FINANCE_ACTIONS_KEY.SET_CURRENT_SCENARIO_OPERATING_FORECAST,
  operatingForecastList
});

export const dispatchGetAllWacc = () => ({
  type: FINANCE_ACTIONS_KEY.GET_ALL_WACC
});

export const dispatchSetAllWacc = (waccList) => ({
  type: FINANCE_ACTIONS_KEY.SET_ALL_WACC,
  waccList
});
export const dispatchSetCurrentScenarioWacc = (waccList) => ({
  type: FINANCE_ACTIONS_KEY.SET_CURRENT_SCENARIO_WACC,
  waccList
});

export const dispatchUpdateFinanceTab = (financeTab) => ({
  type: FINANCE_ACTIONS_KEY.UPDATE_FINANCE_TAB,
  financeTab
});
