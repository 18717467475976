import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getHumanResourcesUrl = (organizationId) => `${getBasePath(organizationId)}/Teams`;

const fetchAllHumanResources = (organizationId) => {
  return axios
    .get(getHumanResourcesUrl(organizationId))
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });
};

export const ApiHumanResource = {
  fetchAllHumanResources
};
