import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { DATAINTEGRATION_ACTIONS_KEY } from '../../commons/DataIntegrationConstants';

const subPipeplineInitialState = {
  id: null,
  type: null,
  icon: null,
  status: null,
  lastRunUpdated: null,
  lastPipelineRunsByAction: {},
  kpi: { main: { agingModel: null, progression: null }, modelState: { data: {}, interactions: {} } },
  history: [],
  isLoading: false
};

export const dataIntegrationBySubPipelinesInitialState = {
  assets: {
    ...subPipeplineInitialState,
    id: 'dataintegration-type-00-assets',
    type: 'assets',
    icon: 'DeviceHubIcon',
    kpi: {
      ...subPipeplineInitialState.kpi,
      modelState: {
        data: {
          countEquipmentComponents: null,
          countFamilies: null,
          countClasses: null
        },
        interactions: {
          countComponentsComponents: {
            count: null,
            kpi: {
              name: 'kpiComponents',
              value: null
            }
          },
          countEquipmentComponentsWithFamily: {
            count: null,
            kpi: {
              name: 'kpiAssetsFamilies',
              value: null
            }
          },
          countEquipmenComponentsWithAssetClass: {
            count: null,
            kpi: {
              name: 'kpiAssetsClasses',
              value: null
            }
          }
        }
      }
    }
  },
  operation: {
    ...subPipeplineInitialState,
    id: 'dataintegration-type-05-sustainment-actions',
    type: 'operation',
    icon: 'BuildIcon',
    kpi: {
      ...subPipeplineInitialState.kpi,
      modelState: {
        data: {
          countOperations: null,
          countTechnicalPolicies: null,
          countProjects: null
        },
        interactions: {
          countClassesWithOperations: {
            count: null,
            kpi: {
              name: 'kpiOperationsClasses',
              value: null
            }
          },
          countOperationsWithTechnicalPolicies: {
            count: null,
            kpi: {
              name: 'kpiOperationsTechnicalPolicies',
              value: null
            }
          },
          countOperationsWithProjects: {
            count: null,
            kpi: {
              name: 'kpiOperationsProjects',
              value: null
            }
          }
        }
      }
    }
  },
  budget: {
    ...subPipeplineInitialState,
    id: 'dataintegration-type-01-budget',
    type: 'budget',
    icon: 'DateRangeIcon',
    kpi: {
      ...subPipeplineInitialState.kpi,
      modelState: {
        data: {
          countBudgets: null
        },
        interactions: {
          countAssetsWithBudget: {
            count: null,
            kpi: {
              name: 'kpiAssetsWithBudget',
              value: null
            }
          },
          countOperationsWithBudget: {
            count: null,
            kpi: {
              name: 'kpiOperationsWithBudget',
              value: null
            }
          },
          countTechnicalPoliciesWithBudget: {
            count: null,
            kpi: {
              name: 'kpiTechnicalPoliciesWithBudget',
              value: null
            }
          },
          countProjectsWithBudget: {
            count: null,
            kpi: {
              name: 'kpiProjectsWithBudget',
              value: null
            }
          },
          countTechnicalPoliciesOperationsGroupsWithBudget: {
            kpi: {
              name: 'kpiTechnicalPoliciesOperationsGroupsWithBudget',
              value: null
            }
          },
          countProjectsOperationsGroupsWithBudget: {
            kpi: {
              name: 'kpiProjectsOperationsGroupsWithBudget',
              value: null
            }
          },
          countTaggedBudgets: {
            count: null,
            kpi: {
              name: 'kpiTaggedBudgets',
              value: null
            }
          }
        }
      }
    }
  },
  humanResources: {
    ...subPipeplineInitialState,
    id: 'dataintegration-type-02-human-resources',
    type: 'humanResources',
    icon: 'PermIdentityIcon',
    kpi: {
      ...subPipeplineInitialState.kpi,
      modelState: {
        data: {
          countTeams: null
        },
        interactions: {
          countComponentsWithTeamConstraint: {
            count: null,
            kpi: {
              name: 'kpiHrAssets',
              value: null
            }
          },
          countOperationsWithTeamConstraint: {
            count: null,
            kpi: {
              name: 'kpiHrOperations',
              value: null
            }
          },
          countTaggedTeams: {
            count: null,
            kpi: {
              name: 'kpiTaggedTeams',
              value: null
            }
          }
        }
      }
    }
  },
  finance: {
    ...subPipeplineInitialState,
    id: 'dataintegration-type-04-finance',
    type: 'finance',
    icon: 'AttachMoneyIcon',
    kpi: {
      ...subPipeplineInitialState.kpi,
      modelState: {
        data: {
          hasWacc: null,
          hasOperatingForecast: null
        },
        interactions: {
          countFamiliesWithFinancialData: {
            count: null,
            kpi: {
              name: 'kpiFamiliesWithFinancialData',
              value: null
            }
          }
        }
      }
    }
  }
};

export const dataIntegrationLastestRunInitialState = {
  list: [],
  errorMessages: [],
  integrationDate: null,
  runId: null,
  status: null,
  subStepHistory: []
};
export const dataIntegrationPipelineStatusInitialState = {
  isLoading: false,
  canRunIntegration: false,
  canUploadFiles: false
};

export const dataIntegrationSubPipelinesReducer = createReducer(
  dataIntegrationBySubPipelinesInitialState,
  (builder) => {
    builder.addCase(DATAINTEGRATION_ACTIONS_KEY.SET_PIPELINE_HISTORY, (state, action) => {
      state[action.historyData.type].history = action.historyData.history;
    });
    builder.addCase(DATAINTEGRATION_ACTIONS_KEY.SET_AGING_MODEL, (state, action) => {
      state.assets.kpi.main.agingModel = action.agingModel;
    });
    builder.addCase(DATAINTEGRATION_ACTIONS_KEY.RESET_AGING_MODEL, (state) => {
      state.assets.kpi.main.agingModel = 'CNAIM';
    });
    builder.addCase(DATAINTEGRATION_ACTIONS_KEY.SET_SUBPIPELINES_DATA, (state, action) => {
      const subPipelines = action.subPipelines;
      if (subPipelines?.length) {
        subPipelines.forEach((subPipeline) => {
          const type = subPipeline.type[0].toLowerCase() + subPipeline.type.substring(1);
          if (state[type]) {
            state[type].status = subPipeline.pipelineStatus;
            state[type].lastRunUpdated = subPipeline.lastRunUpdated;
            state[type].lastPipelineRunsByAction = subPipeline.lastPipelineRunsByAction;
          }
        });
      }
    });
    builder.addCase(DATAINTEGRATION_ACTIONS_KEY.SET_KPIS, (state, action) => {
      const subPipelinesKpis = action.subPipelinesKpis;
      const types = {
        integrationAssetKpi: 'assets',
        integrationBudgetKpi: 'budget',
        integrationTeamKpi: 'humanResources',
        integrationOperationKpi: 'operation',
        integrationFinanceKpi: 'finance'
      };

      Object.keys(subPipelinesKpis).forEach((key) => {
        if (types[key]) {
          const subPipelineKpis = subPipelinesKpis[key];
          const type = types[key];
          state[type].kpi.main.progression = subPipelineKpis.progression;

          const interactions = state[type].kpi.modelState.interactions;
          Object.keys(subPipelineKpis)
            .filter((x) => Object.keys(interactions).includes(x))
            .forEach((key) => {
              const value = subPipelineKpis[key];
              const data = interactions[key];
              // eslint-disable-next-line no-prototype-builtins
              if (data.hasOwnProperty('count')) {
                data.count = value;
              }
              data.kpi.value = subPipelineKpis[data.kpi.name];
            });
          const modelStateData = state[type].kpi.modelState.data;
          Object.keys(subPipelineKpis)
            .filter((x) => Object.keys(modelStateData).includes(x))
            .forEach((key) => {
              const value = subPipelineKpis[key];
              modelStateData[key] = value;
            });
        }
      });
    });
    builder.addCase(DATAINTEGRATION_ACTIONS_KEY.SET_SUBPIPELINES_LOADING, (state, action) => {
      state[action.pipelineType].isLoading = action.isLoading;
    });
  }
);

export const dataIntegrationLastestRunReducer = createReducer(dataIntegrationLastestRunInitialState, (builder) => {
  builder.addCase(DATAINTEGRATION_ACTIONS_KEY.SET_PIPELINE_LASTEST_RUN_DATA, (state, action) => {
    state.list = action.lastestRun;
  });
  builder.addCase(DATAINTEGRATION_ACTIONS_KEY.SET_MAIN_PIPELINE_DATA, (state, action) => {
    state.errorMessages = action.mainPipeline.errorMessages;
    state.integrationDate = action.mainPipeline.integrationDate;
    state.runId = action.mainPipeline.runId;
    state.status = action.mainPipeline.status;
    state.subStepHistory = action.mainPipeline.subStepHistory;
  });
});

export const dataIntegrationPipelineStatusReducer = createReducer(
  dataIntegrationPipelineStatusInitialState,
  (builder) => {
    builder.addCase(DATAINTEGRATION_ACTIONS_KEY.SET_LOADING, (state, action) => {
      state.isLoading = action.isLoading;
    });
    builder.addCase(DATAINTEGRATION_ACTIONS_KEY.SET_CAN_RUN_INTEGRATION, (state, action) => {
      state.canRunIntegration = action.canRunIntegration;
    });
    builder.addCase(DATAINTEGRATION_ACTIONS_KEY.SET_CAN_UPLOAD_FILES, (state, action) => {
      state.canUploadFiles = action.canUploadFiles;
    });
  }
);

export const dataIntegrationErrorReducer = createReducer(null, (builder) => {
  builder.addCase(DATAINTEGRATION_ACTIONS_KEY.SET_ERROR, (state, action) => action.error);
});

export const dataIntegrationReducer = combineReducers({
  lastestRun: dataIntegrationLastestRunReducer,
  subPipelines: dataIntegrationSubPipelinesReducer,
  status: dataIntegrationPipelineStatusReducer,
  error: dataIntegrationErrorReducer
});
