import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useCallback } from 'react';
import { useStyles } from './style';

interface ISearchButtonProps {
  placeholder?: string;
  value?: unknown;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onSearch?: VoidFunction;
  onClear?: VoidFunction;
}

const SearchButton = (props: ISearchButtonProps) => {
  const { placeholder, value, onChange, onSearch, onClear } = props;
  const classes = useStyles();
  const onKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (evt.key === 'Enter') {
        evt.preventDefault();
        onSearch?.();
      }
    },
    [onSearch]
  );

  return (
    <Paper className={classes.searchInput} component='form'>
      <InputBase
        className={classes.searchInputField}
        data-testid='search-input'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {onClear && (
        <IconButton
          aria-label='clear'
          onClick={() => {
            onClear?.();
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {onSearch && (
        <IconButton
          aria-label='search'
          className={classes.searchIcon}
          data-testid='search-button'
          size='large'
          type='button'
          onClick={onSearch}
        >
          <SearchIcon />
        </IconButton>
      )}
    </Paper>
  );
};

export default SearchButton;
