import { Grid, InputAdornment, InputLabel, TextField } from '@mui/material';
import { NumberUtils } from 'utils/helpers';
import { GENERIC_VAR_TYPES_DEFAULT_VALUES } from 'utils/scenarioParameters/generic/DefaultValues';
import { ExtraParametersUtils } from '../../ExtraParametersUtils';

const DEFAULT_MIN_VALUE = -1e10 + 1;
const DEFAULT_MAX_VALUE = 1e10 - 1;
const DEFAULT_STEP_VALUE = 1;

const UNAUTHORIZED_KEYS = ['e', ','];

function getMinValue(parameterData) {
  if (parameterData.minValue === null || parameterData.minValue === undefined) {
    return DEFAULT_MIN_VALUE;
  } else {
    return parameterData.minValue;
  }
}

function getMaxValue(parameterData, parametersState) {
  if (parameterData.maxValue !== undefined && parameterData.maxValue !== null) {
    return parameterData.maxValue;
  }

  if (
    parameterData.customMaxValue !== undefined &&
    parameterData.customMaxValue !== null &&
    typeof parameterData.customMaxValue.get === 'function'
  ) {
    const fromParameterValue = parametersState[parameterData.customMaxValue?.fromParameterId];

    if (fromParameterValue !== undefined) {
      return parameterData.customMaxValue.get(fromParameterValue);
    }
  }

  return DEFAULT_MAX_VALUE;
}

function getStepValue(parameterData) {
  if (parameterData.stepValue === null || parameterData.stepValue === undefined) {
    return DEFAULT_STEP_VALUE;
  }
  return parameterData.stepValue;
}

function getAdornment(parameterData) {
  if (parameterData.prefixe === null || parameterData.prefixe === undefined) {
    return {};
  }
  return {
    endAdornment: <InputAdornment position='start'>{parameterData.prefixe}</InputAdornment>
  };
}

const create = (t, parameterData, parametersState, setParametersState, editMode) => {
  const inputProps = {
    min: getMinValue(parameterData),
    max: getMaxValue(parameterData, parametersState),
    step: getStepValue(parameterData),
    'data-testid': parameterData.testId
  };

  const adornment = getAdornment(parameterData);

  const disabled = ExtraParametersUtils.GetDisabled(parameterData, parametersState, editMode);
  const displayed = ExtraParametersUtils.GetDisplayed(parameterData, parametersState);
  const itemSize = ExtraParametersUtils.GetItemSize(parameterData);

  const value = NumberUtils.getNormalAnnotation(parametersState[parameterData.id]);

  // Used to overwrite to have this specific scenario key use a date with a type number
  if (
    (parameterData.id === 'parameter_scenario_global_date_de_debut_scenario' ||
      parameterData.id === 'parameter_scenario_global_date_de_fin_scenario') &&
    parametersState[parameterData.id] === GENERIC_VAR_TYPES_DEFAULT_VALUES.number
  ) {
    setValue(new Date().getFullYear().toString());
  }

  if (typeof value === 'number' && value < getMinValue(parameterData)) setValue(getMinValue(parameterData));
  if (typeof value === 'number' && value > getMaxValue(parameterData, parametersState))
    setValue(getMaxValue(parameterData, parametersState));

  function setValue(newValue) {
    if (parseFloat(newValue) > inputProps.max) {
      newValue = inputProps.max;
    } else if (parseFloat(newValue) < inputProps.min) {
      newValue = inputProps.min;
    }
    newValue = NumberUtils.getNormalAnnotation(newValue);

    let newState = {
      ...parametersState,
      [parameterData.id]: newValue || ''
    };

    // Check dispatch result & update newState
    newState = ExtraParametersUtils.DispatchResult(parameterData, newState, newValue);

    setParametersState(newState);
  }

  function handleOnChange(newValue) {
    if (parameterData.isInteger) {
      const valueAsNumber = parseInt(newValue);
      if (!isNaN(valueAsNumber)) setValue(valueAsNumber);
    } else {
      setValue(newValue);
    }
  }

  return (
    <Grid item={true} key={`grid-item-${parameterData.id}`} md={itemSize} style={displayed}>
      <InputLabel disabled={disabled} style={{ paddingLeft: 0 }}>
        {t(`solution.parameters.${parameterData.id}`, parameterData.id)}
      </InputLabel>
      <TextField
        InputProps={adornment}
        disabled={disabled}
        fullWidth={true}
        inputProps={inputProps}
        key={parameterData.id}
        type='number'
        value={value}
        variant='standard'
        onChange={(event) => {
          handleOnChange(event.target.value);
        }}
        onKeyDown={(e) => {
          UNAUTHORIZED_KEYS.includes(e.key) && e.preventDefault();
        }}
      />
    </Grid>
  );
};

export const MaterialNumberInputFactory = {
  create
};
