import { Button, Card, Container, Link, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { useOktaAuth } from '@okta/okta-react';
import { SUPPORT_URL } from 'config/AppConfiguration';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import { TranslationUtils } from 'utils/helpers';

const SignIn = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { oktaAuth } = useOktaAuth();

  const login = async () => oktaAuth.signInWithRedirect();

  return (
    <StyledContainer>
      <StyledCard>
        <div style={{ textAlign: 'center' }}>
          <Logo alt='Nexans' src='/logo-nobackground-200.png' />
          <SignInButton sx={{ fontSize: '13px' }} variant='contained' onClick={login}>
            {t('genericcomponent.button.login.okta.title')}
          </SignInButton>

          <Link
            href={SUPPORT_URL}
            sx={{
              color: theme.palette.signin.button,
              textDecoration: 'none',
              display: 'block',
              marginTop: '1rem',
              textAlign: 'center'
            }}
            underline='hover'
          >
            {t('commoncomponents.text.contact.get.account')}
          </Link>

          <Select
            className={classes.languageSelect}
            disableUnderline
            sx={{ minWidth: '5rem', marginTop: '2rem' }}
            value={i18n.language}
            variant='standard'
            onChange={(event) => TranslationUtils.changeLanguage(event.target.value, i18n)}
          >
            <MenuItem value={'en'}>English</MenuItem>
            <MenuItem value={'fr'}>Français</MenuItem>
          </Select>
        </div>
      </StyledCard>
    </StyledContainer>
  );
};

export default SignIn;

const StyledContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  minWidth: '100vw',
  backgroundColor: theme.palette.signin.background
});

const StyledCard = styled(Card)({
  backgroundColor: theme.palette.nexans.whiteBg,
  borderRadius: 5,
  padding: '3rem',
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)'
});

const Logo = styled('img')({
  display: 'block',
  margin: '0 auto'
});

const SignInButton = styled(Button)({
  backgroundColor: theme.palette.signin.button,
  color: theme.palette.nexans.whiteBg,
  padding: '0.5rem 2rem',
  fontSize: '1.25rem',
  borderRadius: 5,
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: theme.palette.signin.buttonHover,
    textDecoration: 'none',
    color: theme.palette.nexans.whiteBg
  }
});

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.signInPage,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('xl')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.background.signInPage,
    height: '100%',
    display: 'flex',
    margin: '7rem 8rem',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${theme.picture.auth})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
    marginTop: '2%'
  },
  quoteText: {
    color: theme.palette.text.white,
    fontWeight: 300
  },
  btnText: {
    '& p': {
      color: `${theme.palette.text.black} !important`
    }
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.text.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xl')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingTop: 100,
    paddingLeft: 100,
    paddingRight: 100,
    flexBasis: 700,
    [theme.breakpoints.down('xl')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  contentFooter: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  title: {
    marginTop: theme.spacing(3),
    color: theme.palette.text.white
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  contact: {
    marginLeft: '10px',
    marginTop: '5px',
    color: theme.palette.text.white
  },
  formControl: {
    fontSize: '11px'
  },
  languageSelect: {
    fontSize: '11px',
    color: theme.palette.text.white
  },
  copyrightText: {
    marginLeft: '8px',
    color: theme.palette.text.white
  }
}));
