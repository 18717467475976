export const ColorsUtils = [
  '#E74C3C',
  '#9B59B6',
  '#3498DB',
  '#1ABC9C',
  '#2ECC71',
  '#F1C40F',
  '#F39C12',
  '#D35400',
  '#85929E'
];

export const DefaultPredeterminedProjectColor = '#3A3A3A';

export const isColorDark = (hexColor) => {
  if (!hexColor) return false;
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance < 0.5;
};
