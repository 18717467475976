import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ASSET_ACTIONS_KEY } from 'state/commons/AssetConstants';
import { ApiFamily } from '../../../../api/ApiFamily';
import { FAMILY_ACTIONS_KEY } from '../../../commons/FamilyConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllFamiliesData() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiFamily.fetchAllFamilies, organizationId);
    const search = yield select((state) => state.family.list.search);
    yield put({ type: ASSET_ACTIONS_KEY.RESET_SELECTED_ASSET_SEARCH });
    if (data.length > 0) {
      let updatedData = data;
      if (search) {
        updatedData = data.filter((family) => family?.$dtId?.toLowerCase().includes(search.toLowerCase()));
      }
      yield put({
        type: FAMILY_ACTIONS_KEY.SET_ALL_FAMILIES,
        families: updatedData
      });
      yield put({
        type: FAMILY_ACTIONS_KEY.SET_CURRENT_FAMILY,
        family: updatedData.length > 0 ? updatedData[0] : null
      });
    } else {
      yield put({
        type: FAMILY_ACTIONS_KEY.SET_ALL_FAMILIES,
        families: []
      });
      yield put({
        type: FAMILY_ACTIONS_KEY.SET_CURRENT_FAMILY,
        family: null
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllFamiliesData() {
  yield takeEvery(FAMILY_ACTIONS_KEY.GET_ALL_FAMILIES, getAllFamiliesData);
}

export default findAllFamiliesData;
