/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SimplePowerBIReportEmbed } from '../PowerBIReport/SimplePowerBIReportEmbed.component';

import { USE_POWER_BI_WITH_USER_CREDENTIALS } from 'config/AppConfiguration';
import { DASHBOARDS_LIST_CONFIG } from 'config/Dashboards';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  dashboardContainer: {
    height: '100%',
    backgroundColor: 'transparent',
    padding: 0,
    margin: '5px 16px'
  },
  dashboard: {
    height: '100%',
    backgroundColor: 'transparent',
    '& > div': {
      '& div:nth-of-type(2)': {
        '& div:first-child': {
          position: 'absolute'
        }
      }
    }
  }
}));

function DashboardTabPanelPBI(props) {
  const classes = useStyles();

  const { children, index, title, reports, scenario, lang, labels, ...other } = props;

  return (
    <Card className={classes.dashboardContainer}>
      <div
        aria-labelledby={`vertical-tab-${index}`}
        className={classes.dashboard}
        id={`vertical-tabpanel-${index}`}
        role='tabpanel'
        {...other}
      >
        <SimplePowerBIReportEmbed
          alwaysShowReports={true}
          index={index}
          labels={labels}
          lang={lang}
          reportConfiguration={DASHBOARDS_LIST_CONFIG}
          reports={reports}
          scenario={scenario}
          useAAD={USE_POWER_BI_WITH_USER_CREDENTIALS}
        />
      </div>
    </Card>
  );
}

DashboardTabPanelPBI.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  scenario: PropTypes.object,
  reports: PropTypes.object.isRequired,
  labels: PropTypes.object
};

export default DashboardTabPanelPBI;
