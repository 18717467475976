import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiAsset } from '../../../../api/ApiAsset';
import { ASSET_CLASSES_ACTIONS_KEY } from '../../../commons/AssetClassesConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllAssetClassesData() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiAsset.fetchAllAssetClasses, organizationId);

    if (data.length > 0) {
      yield put({
        type: ASSET_CLASSES_ACTIONS_KEY.SET_ALL_ASSET_CLASSES,
        assetClasses: data
      });
      yield put({
        type: ASSET_CLASSES_ACTIONS_KEY.SET_SELECTED_ASSET_CLASS,
        assetClass: data[0]
      });
    } else {
      yield put({
        type: ASSET_CLASSES_ACTIONS_KEY.SET_ALL_ASSET_CLASSES,
        assetClasses: []
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllAssetClassesData() {
  yield takeEvery(ASSET_CLASSES_ACTIONS_KEY.GET_ALL_ASSET_CLASSES, getAllAssetClassesData);
}

export default findAllAssetClassesData;
