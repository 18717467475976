import { ApiCnaim } from 'api/ApiCnaim';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CNAIM_ACTIONS_KEY } from 'state/commons/CnaimConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* getAbacusTable(action) {
  const { fileName } = action;

  try {
    const organizationId = yield selectOrganizationId();
    const response = yield call(ApiCnaim.getAbacusTable, organizationId, fileName);
    yield put({
      type: CNAIM_ACTIONS_KEY.SET_ABACUS_TABLE,
      abacusTableData: response
    });
  } catch (e) {
    yield put({
      type: CNAIM_ACTIONS_KEY.SET_ABACUS_TABLE,
      abacusTableData: []
    });
  }
}

function* getAbacusTableData() {
  yield takeEvery(CNAIM_ACTIONS_KEY.GET_ABACUS_TABLE, getAbacusTable);
}

export default getAbacusTableData;
