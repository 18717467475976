import { call, put, select, takeEvery } from 'redux-saga/effects';
import { API_RESPONSE_ACTIONS_KEY, OPERATION_ACTIONS_KEY } from 'state/commons';
import { ApiOperation } from '../../../../api/ApiOperation';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* deleteOperation(action) {
  const operation = action.operation;
  const assetClass = yield select((state) => state.assetClasses.selected.data);
  let response = '';

  try {
    const organizationId = yield selectOrganizationId();

    if ((operation.id || operation.Id) && assetClass) {
      response = yield call(ApiOperation.deleteOperation, organizationId, operation.id || operation.Id);
      const data = yield call(ApiOperation.fetchOperationsTree, organizationId, assetClass);
      if (data?.length > 0) {
        yield put({
          type: OPERATION_ACTIONS_KEY.SET_ALL_OPERATIONS_BY_ASSETCLASS,
          operationsByAssetClass: data
        });
      }
    } else {
      // Case where orphans operations are deleted
      response = yield call(ApiOperation.deleteOperation, organizationId, operation.id || operation.Id);
      const data = yield call(ApiOperation.fetchOperationsTree, organizationId, '');
      if (data?.length > 0) {
        yield put({
          type: OPERATION_ACTIONS_KEY.SET_ALL_OPERATIONS_BY_ASSETCLASS,
          operationsByAssetClass: data
        });
      }
    }
    yield put({
      type: API_RESPONSE_ACTIONS_KEY.SET_API_STATUS_DATA,
      response,
      actionType: action.type
    });
  } catch (e) {
    yield put({
      type: API_RESPONSE_ACTIONS_KEY.SET_API_STATUS_DATA,
      response: e.response,
      actionType: action.type
    });
  }
}

function* deleteOperationData() {
  yield takeEvery(OPERATION_ACTIONS_KEY.DELETE_OPERATION, deleteOperation);
}

export default deleteOperationData;
