import { USE_POWER_BI_WITH_USER_CREDENTIALS } from 'config/AppConfiguration';
import { SCENARIO_DASHBOARD_CONFIG } from 'config/Dashboards';
import { useSelector } from 'react-redux';
import { useCurrentScenarioPowerBiReport } from './CurrentScenarioPowerBiReport.hook';
import { SimplePowerBIReportEmbed } from './SimplePowerBIReportEmbed.component';

const CurrentScenarioPowerBiReport = ({ reportConfiguration, iframeRatio, index }) => {
  const { currentScenarioData, downloadLogsFile, language, reportLabels, reports } = useCurrentScenarioPowerBiReport();

  const currentScenarioAgingModel = useSelector(
    (state) => state.dataIntegration.subPipelines.assets.kpi?.main?.agingModel
  );

  return (
    <SimplePowerBIReportEmbed
      downloadLogsFile={downloadLogsFile}
      iframeRatio={iframeRatio}
      index={index}
      labels={reportLabels}
      lang={language}
      reportConfiguration={[
        {
          ...reportConfiguration[0],
          // overwrite report id for scenario according to
          // scenario's agingModel
          reportId:
            currentScenarioAgingModel && currentScenarioAgingModel === 'Cnaim'
              ? SCENARIO_DASHBOARD_CONFIG[0].reportId.healthScoreModel
              : SCENARIO_DASHBOARD_CONFIG[0].reportId.effectiveAgeModel
        }
      ]}
      reports={reports}
      scenario={currentScenarioData}
      useAAD={USE_POWER_BI_WITH_USER_CREDENTIALS}
    />
  );
};

CurrentScenarioPowerBiReport.propTypes = {
  reportConfiguration: SimplePowerBIReportEmbed.propTypes.reportConfiguration,
  iframeRatio: SimplePowerBIReportEmbed.propTypes.iframeRatio,
  index: SimplePowerBIReportEmbed.propTypes.index
};

export default CurrentScenarioPowerBiReport;
