// Azure App registration details
export const AZURE_TENANT_ID = '2f7e641d-35dd-4a72-91ef-9c34810f3219';
export const APP_REGISTRATION_CLIENT_ID = 'b5ac99b3-5da2-4b52-ad73-71e89fe055e2';
export const APPLICATION_INSIGHTS_INSTRUMENTATION_KEY = 'e162a564-d8e3-4380-a5b9-16744870000e';

// API information
export const COSMOTECH_API_SCOPE = 'api://dd97f1be-b73b-4ed6-b0f0-0045ebe025c7/platform';
export const DEFAULT_BASE_PATH = 'https://api.sams.nexans.com/v2';

// MiddleWare API
export const MIDDLEWARE_DEFAULT_BASE_PATH = 'https://api.sams-webapp.nexans.com';

// Cosmo solution identifiers
export const ORGANIZATION_ID = 'o-dqd9d2l0xem3';
export const WORKSPACE_ID = 'w-pq6q44wz1vm8';
export const POWER_BI_WORKSPACE_ID = '3ec00248-4ce4-4c4f-a85c-375b982ca155';
export const POWER_BI_API_DEFAULT_SCOPE = 'https://analysis.windows.net/powerbi/api/.default';

// Okta auth configuration
export const OKTA_CLIENT_ID = '0oa83h9ai3KAiFJZM417';
export const OKTA_ISSUER = 'https://ciam.nexans.com/oauth2/aus8x4968cHAfCJ5l417';

// Power BI Report IDs
export const PBI_REPORT_IDS = {
  Scenario: {
    healthScoreModel: 'c2f8e4ac-7b0f-47ac-a8d3-07d5792eafe5',
    effectiveAgeModel: 'c2f8e4ac-7b0f-47ac-a8d3-07d5792eafe5'
  },
  Overview: 'bd7cb64f-1dfc-4010-b63e-5e92b0224702',
  ScenarioComparison: '6aa4ae5a-f595-42b5-9e67-8fadf26931c9',
  ScenarioAnalysis: '3b5beac8-9726-423b-8683-6d49831c9a08',
  OperationAnalysis: '475c2643-a6dd-4182-8d93-95e46761b224',
  CustomReports: 'af3623b0-dc46-487a-b024-f88236248fff'
};

// Subset feature
export let ENABLE_SUBSET_FEATURE = 'false';
