import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiBudget } from '../../../../api/ApiBudget';
import { BUDGET_ACTIONS_KEY } from '../../../commons/BudgetConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllBudgetsData() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiBudget.fetchAllBudgets, organizationId);
    if (data.length > 0) {
      yield put({
        type: BUDGET_ACTIONS_KEY.SET_ALL_BUDGETS,
        budgets: data
      });
    } else {
      yield put({
        type: BUDGET_ACTIONS_KEY.SET_ALL_BUDGETS,
        budgets: []
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllBudgetsData() {
  yield takeEvery(BUDGET_ACTIONS_KEY.GET_ALL_BUDGETS, getAllBudgetsData);
}

export default findAllBudgetsData;
