import { createReducer } from '@reduxjs/toolkit';
import { SUBSET_ACTIONS_KEY } from 'state/commons/SubsetConstants';

export const subsetInitialState = {
  selected: null,
  list: []
};

export const subsetReducer = createReducer(subsetInitialState, (builder) => {
  builder.addCase(SUBSET_ACTIONS_KEY.EDIT_SUBSET, (state, action) => {
    state.selected = action.subset;
  });
  builder.addCase(SUBSET_ACTIONS_KEY.SET_CURRENT_SUBSET, (state, action) => {
    state.selected = action.subset;
  });
  builder.addCase(SUBSET_ACTIONS_KEY.SET_SUBSET_LIST, (state, action) => {
    state.list = action.subsetList;
  });
});
