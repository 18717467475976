import { ApiCnaim } from 'api/ApiCnaim';
import { call, put, takeEvery } from 'redux-saga/effects';
import { i18next } from 'services/config/i18next';
import { CNAIM_ACTIONS_KEY } from 'state/commons/CnaimConstants';
import { NOTIFICATION_ACTIONS_KEY } from 'state/commons/NotificationConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* uploadCnaimFile(action) {
  const { file, fileName } = action;

  try {
    const organizationId = yield selectOrganizationId();
    const response = yield call(ApiCnaim.uploadCnaimFile, organizationId, file, fileName);

    if (response.status === 200) {
      yield put({
        type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
        message: `${i18next.t('views.library.cnaim.upload')} ✅ `,
        isOpen: true
      });
      // Reset upload error if any
      yield put({
        type: CNAIM_ACTIONS_KEY.SET_UPLOAD_ERROR,
        error: null
      });
    } else {
      yield put({
        type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
        message: `${i18next.t('views.library.cnaim.upload')}: ${response.statusText} ❌`,
        isOpen: true
      });
      // Set upload error
      yield put({
        type: CNAIM_ACTIONS_KEY.SET_UPLOAD_ERROR,
        error: `${i18next.t('views.library.cnaim.upload')}: ${response.statusText}`
      });
    }
  } catch (e) {
    yield put({
      type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
      message: `${i18next.t('views.library.cnaim.upload')} ❌`,
      isOpen: true
    });
    yield put({
      type: CNAIM_ACTIONS_KEY.SET_UPLOAD_ERROR,
      error: e.message
    });
  }
}

function* uploadCnaimFileData() {
  yield takeEvery(CNAIM_ACTIONS_KEY.UPLOAD_FILE, uploadCnaimFile);
}

export default uploadCnaimFileData;
