import CheckCircle from '@mui/icons-material/CheckCircle';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import InputComponentFactory from 'components/InputComponentFactory';
import { IYearRange } from 'models/Date.model';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { palette } from 'theme/nexans';

interface IParams {
  editMode?: boolean;
  customValueLabel: string;
  maxCustomValue?: number;
  minCustomValue?: number;
  defaultYearRange: IYearRange;
  dataTestId: string;
  unit?: string;
  onFastFillingButtonClicked: (yearRange: IYearRange, customValue: number) => void;
}

export default function FastFilling(props: IParams) {
  const {
    defaultYearRange,
    editMode,
    customValueLabel,
    maxCustomValue,
    minCustomValue,
    dataTestId,
    unit,
    onFastFillingButtonClicked
  } = props;

  const { t } = useTranslation();
  const [customStartYear, setCustomStartYear] = useState(defaultYearRange.start);
  const [customEndYear, setCustomEndYear] = useState(defaultYearRange.end);
  const [customInput, setCustomInput] = useState<number | undefined>(getDefaultCustomValue());

  useEffect(() => {
    setCustomStartYear(defaultYearRange.start);
  }, [defaultYearRange.start]);

  useEffect(() => {
    setCustomEndYear(defaultYearRange.end);
  }, [defaultYearRange.end]);

  const isCustomValueInputValid = useCallback(() => {
    if (customInput === undefined) return false;

    if (minCustomValue && customInput < minCustomValue) return false;
    if (maxCustomValue && customInput > maxCustomValue) return false;

    return true;
  }, [customInput, maxCustomValue, minCustomValue]);

  if (minCustomValue && maxCustomValue && minCustomValue > maxCustomValue) {
    throw new Error('maxCustomValue must be greater than minCustomValue');
  }

  function getDefaultCustomValue(): number {
    let defaultCustomValue = 0;

    if (minCustomValue) defaultCustomValue = Math.max(minCustomValue, defaultCustomValue);
    if (maxCustomValue) defaultCustomValue = Math.min(maxCustomValue, defaultCustomValue);

    return defaultCustomValue;
  }

  const endAdornment = unit ? <InputAdornment position='start'>{unit}</InputAdornment> : undefined;
  const isValidateButtonDisabled = !editMode || !isCustomValueInputValid();

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '1rem', gap: '2rem' }}>
      <Typography color={editMode ? undefined : palette.nexans.grey}>
        {t<string>('commoncomponents.scenario-form.budget-tab.fast-filling')}
      </Typography>
      <InputComponentFactory
        editProps={{
          type: 'dateYear',
          placeholder: t('commoncomponents.scenario-form.budget-tab.start'),
          maxDate: customEndYear,
          minDateMessage: t('commoncomponents.scenario-form.finance-tab.fastFillingMinDateError'),
          maxDateMessage: t('commoncomponents.scenario-form.finance-tab.fastFillingMaxDateError'),
          disabled: !editMode,
          testId: 'fast-filling-start-date'
        }}
        value={customStartYear}
        onChange={(newValue) => setCustomStartYear(newValue)}
      />
      <InputComponentFactory
        editProps={{
          type: 'dateYear',
          placeholder: t('commoncomponents.scenario-form.budget-tab.end'),
          minDate: customStartYear,
          disabled: !editMode,
          minDateMessage: t('commoncomponents.scenario-form.finance-tab.fastFillingMinDateError'),
          maxDateMessage: t('commoncomponents.scenario-form.finance-tab.fastFillingMaxDateError'),
          testId: 'fast-filling-end-date'
        }}
        value={customEndYear}
        onChange={(newValue) => setCustomEndYear(newValue)}
      />
      <NumericFormat
        InputProps={{
          endAdornment
        }}
        allowNegative={minCustomValue === undefined || (minCustomValue !== undefined && minCustomValue < 0)}
        customInput={TextField}
        data-testid='fast-filling-custom-value'
        decimalScale={2}
        disabled={!editMode}
        isAllowed={({ formattedValue, floatValue }) => {
          if (formattedValue === '') return true;

          if (floatValue && maxCustomValue && floatValue > maxCustomValue) return false;

          return true;
        }}
        label={customValueLabel}
        thousandSeparator=' '
        value={customInput}
        variant='standard'
        onValueChange={(values) => {
          setCustomInput(values.floatValue);
        }}
      />
      <IconButton
        aria-label='fast-filling'
        data-testid={dataTestId}
        disabled={isValidateButtonDisabled}
        size='large'
        onClick={() => {
          if (isCustomValueInputValid() && customInput)
            onFastFillingButtonClicked({ start: customStartYear, end: customEndYear }, customInput);
        }}
      >
        <CheckCircle color={isValidateButtonDisabled ? 'disabled' : 'primary'} />
      </IconButton>
    </div>
  );
}
