// Available FAMILY actions
export const FAMILY_ACTIONS_KEY = {
  GET_ALL_FAMILIES: 'GET_ALL_FAMILIES',
  SET_ALL_FAMILIES: 'SET_ALL_FAMILIES',
  UPDATE_ALL_FAMILIES: 'UPDATE_ALL_FAMILIES',
  GET_CURRENT_FAMILY: 'GET_CURRENT_FAMILY',
  SET_CURRENT_FAMILY: 'SET_CURRENT_FAMILY',
  UPDATE_CURRENT_FAMILY: 'UPDATE_CURRENT_FAMILY',
  FIND_FAMILY_BY_ID: 'FIND_FAMILY_BY_ID',
  GET_ALL_FAMILIES_BY_ASSET_CLASS: 'GET_ALL_FAMILIES_BY_ASSET_CLASS',
  SET_ALL_FAMILIES_BY_ASSET_CLASS: 'SET_ALL_FAMILIES_BY_ASSET_CLASS',
  SET_ALL_FAMILIES_SEARCH: 'SET_ALL_FAMILIES_SEARCH'
};
