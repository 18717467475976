import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getTechnicalPolicyUrl = (organizationId) => `${getBasePath(organizationId)}/TechnicalPolicy`;
const getOdataTechnicalPolicyUrl = (organizationId) => `${getBasePath(organizationId)}/odata/TechnicalPolicy`;

const fetchAllScenarioTechnicalPolicies = (organizationId) =>
  axios
    .get(getTechnicalPolicyUrl(organizationId))
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const fetchAllTechnicalPolicies = (organizationId, params) => {
  let query = '?$count=true';
  if (params) {
    if (params.perPage !== null) {
      query = `${query}&$top=${params.perPage}`;
    }
    if (params.page !== null && params.page) {
      query = `${query}&$skip=${params.perPage * (params.page - 1)}`;
    }
    if (params.keyword !== null) {
      query = `${query}&keyword=${params.keyword}`;
    }
  }
  return axios
    .get(getOdataTechnicalPolicyUrl(organizationId) + query)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });
};

const updateTechnicalPolicy = (organizationId, policy) =>
  axios
    .patch(getTechnicalPolicyUrl(organizationId), policy)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const postTechnicalPolicy = (organizationId, policy) => axios.post(getTechnicalPolicyUrl(organizationId), policy);

const deleteTechnicalPolicy = (organizationId, policy) =>
  axios
    .delete(`${getTechnicalPolicyUrl(organizationId)}/${policy.id}`)
    .then((response) => response)
    .catch((error) => {
      console.error('Error ========>', error);
      return error;
    });

const getTechnicalPolicy = (organizationId, id) =>
  axios
    .get(`${getTechnicalPolicyUrl(organizationId)}/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const fetchAllTechnicalPoliciesEnums = (organizationId) =>
  axios
    .get(`${getTechnicalPolicyUrl(organizationId)}/EnumValues`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return {};
    });

const exportTechnicalPolicies = async (organizationId) =>
  axios
    .get(`${getTechnicalPolicyUrl(organizationId)}/Export`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/zip'
      }
    })
    .then((response) => response.data);

const importTechnicalPoliciesFile = async (organizationId, file) =>
  axios.post(`${getTechnicalPolicyUrl(organizationId)}/Import`, file).then((response) => response.data);

const isNameExist = (organizationId, name) =>
  axios
    .get(`${getTechnicalPolicyUrl(organizationId)}/IsNameExist/${name}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return {};
    });

export const ApiTechnicalPolicy = {
  deleteTechnicalPolicy,
  exportTechnicalPolicies,
  fetchAllScenarioTechnicalPolicies,
  fetchAllTechnicalPolicies,
  fetchAllTechnicalPoliciesEnums,
  getTechnicalPolicy,
  importTechnicalPoliciesFile,
  isNameExist,
  postTechnicalPolicy,
  updateTechnicalPolicy
};
