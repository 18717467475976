import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchGetAllAssetKPI } from 'state/dispatchers/asset/assetDispatcher';

export const useAssetGetAllAssetsKpi = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchGetAllAssetKPI()), [dispatch]);
};

export const useFamilyKpiForAllAssets = () => {
  return useSelector((state) => state.asset.allAssets.kpi);
};
export const useFamilyKpiForSelectedFamily = () => {
  return useSelector((state) => state.finance.operatingForecast?.list);
};
