import EventIcon from '@mui/icons-material/Event';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Avatar, Badge, Chip, Grid, Modal, Tooltip, Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { PredeterminedProjectsTable } from 'components';
import { groupBy } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

export default function PredeterminedProjectGantt(props) {
  const {
    currentScenarioStartDate,
    currentScenarioEndDate,
    predeterminedProjectList,
    setSelectedPredeterminedProject,
    setDisplayForm,
    getValidPredeterminedProjectEvents,
    handleGanttEditAction,
    parametersState,
    setParametersState,
    parameterData
  } = props;
  const [chipData, setChipData] = useState({});
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [selectedProjectList, setSelectedProjectList] = useState(null);
  const [validGanttList, setValidGanttList] = useState(getValidPredeterminedProjectEvents());
  const classes = useStyles();
  const { t } = useTranslation();

  const [plannedProjectsGanttPage, setPlannedProjectsGanttPage] = useState(0);
  const PLANNED_PROJECT_PER_PAGE = 10;

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      borderColor: theme.palette.nexans.lightGreyBg,
      backgroundColor: theme.palette.nexans.lightGreyBg,
      borderRadius: '10px',
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

  const projectTotalPeriod = currentScenarioEndDate - currentScenarioStartDate;

  useEffect(() => {
    setValidGanttList(getValidPredeterminedProjectEvents());
  }, [predeterminedProjectList, getValidPredeterminedProjectEvents]);

  const handleOpen = (projectList) => {
    if (projectList && projectList.length > 0) {
      setSelectedProjectList(projectList);
      setOpen(true);
    }
  };

  const editProject = (project) => {
    const event = validGanttList.filter((item) => item.id === project.id);
    setSelectedPredeterminedProject(event[0]);
    handleGanttEditAction(event[0]);
    setDisplayForm('predetermined-project');
    setOpen(false);
  };

  const deleteProject = (project) => {
    setOpen(false);
    setParametersState({
      ...parametersState,
      [parameterData.id]: [
        ...parametersState[parameterData.id].filter((validGanttProject) => validGanttProject.id !== project.id)
      ]
    });
  };

  const formatProjectListForTimeline = (projectList) => {
    const unsortedListOfProject = groupBy(projectList, 'PlannedDate');
    const sortedList = Object.keys(unsortedListOfProject)
      .sort()
      .reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = unsortedListOfProject[key];
        return obj;
      }, {});

    // format date to compare with years only
    const formatedDateList = Object.fromEntries(
      Object.entries(sortedList).map(([key, value]) => [key.substring(0, 4), value])
    );

    return formatedDateList;
  };

  const handleClose = () => {
    setOpen(false);
  };

  // compare projects date fron the current scenario period
  const arrayOfYears = Array(currentScenarioEndDate - currentScenarioStartDate + 1)
    .fill(currentScenarioStartDate)
    .map((year, index) => year + index);

  // group project by year and create array for Mui Chips
  useEffect(() => {
    const list = formatProjectListForTimeline(validGanttList);
    setChipData(list);
  }, [
    predeterminedProjectList,
    currentScenarioStartDate,
    currentScenarioEndDate,
    projectTotalPeriod,
    validGanttList,
    selectedProjectList,
    getValidPredeterminedProjectEvents
  ]);

  const mapPredeterminedProjectToDataTableRow = (predeterminedProject) => ({
    id: predeterminedProject.id,
    Name: predeterminedProject.$dtId,
    AssetClass: predeterminedProject.AssetClass,
    EquipmentGroup: predeterminedProject.OnEquipmentGroup,
    Operation: predeterminedProject.Operation,
    Date: predeterminedProject.PlannedDate
  });

  const mapPredeterminedProjectsListToDataTable = () =>
    // surcharge list from gantt if user is in gantt
    selectedProjectList && selectedProjectList.length > 0
      ? selectedProjectList
          .slice(
            plannedProjectsGanttPage * PLANNED_PROJECT_PER_PAGE,
            plannedProjectsGanttPage * PLANNED_PROJECT_PER_PAGE + PLANNED_PROJECT_PER_PAGE
          )
          .map((predeterminedProject) => mapPredeterminedProjectToDataTableRow(predeterminedProject))
      : predeterminedProjectList.data.map((predeterminedProject) =>
          mapPredeterminedProjectToDataTableRow(predeterminedProject)
        );

  const getTooltipContent = (chipDataOfYear) => {
    if (!chipDataOfYear) {
      return '';
    }

    const maxDisplaybleProjects = 20;
    const displayableProjectsContent = chipDataOfYear
      .slice(0, Math.min(maxDisplaybleProjects, chipDataOfYear.length))
      .map((project) => project.$dtId)
      .join(', ');

    return chipDataOfYear.length <= maxDisplaybleProjects
      ? displayableProjectsContent
      : displayableProjectsContent + '...';
  };

  return (
    <div className={classes.container}>
      <Grid container={true}>
        <Grid item={true} md={2}>
          <div className={classes.indicatorContainer}>
            <Avatar className={classes.indicator}>
              <EventIcon />
            </Avatar>
            <Typography>{t('views.library.predeterminedproject.title')}</Typography>
            <RefreshIcon />
          </div>
        </Grid>
        <Grid item={true} md={10} style={{ alignSelf: 'center' }}>
          {chipData && Object.keys(chipData).length > 0 && (
            <div className={classes.chipsRow}>
              {arrayOfYears.map((year) => (
                <StyledBadge
                  badgeContent={chipData[year]?.length}
                  color='primary'
                  key={year}
                  overlap='rectangular'
                  style={{
                    opacity: chipData[year] ? 1 : 0,
                    cursor: 'default'
                  }}
                >
                  <Tooltip
                    disableHoverListener={!chipData[year]}
                    key={year}
                    style={{ cursor: !chipData[year] ? 'default' : 'pointer' }}
                    title={getTooltipContent(chipData[year])}
                  >
                    <Chip
                      className={classes.chip}
                      disabled={!chipData[year]}
                      label={chipData[year] ? year : ''}
                      size='small'
                      style={{
                        opacity: chipData[year] ? 1 : 0,
                        cursor: !chipData[year] ? 'default' : 'pointer'
                      }}
                      onClick={() => handleOpen(chipData[year])}
                    />
                  </Tooltip>
                </StyledBadge>
              ))}
            </div>
          )}
        </Grid>
      </Grid>
      <Modal
        aria-describedby='simple-modal-description'
        aria-labelledby='simple-modal-title'
        open={open}
        onClose={handleClose}
      >
        <div className={classes.paper} style={modalStyle}>
          {selectedProjectList && selectedProjectList.length > 0 && (
            <PredeterminedProjectsTable
              currentPage={plannedProjectsGanttPage + 1}
              isMultiDelete={true}
              loading={false}
              openDeletePredeterminedProject={deleteProject}
              openEditPredeterminedProject={editProject}
              pageCount={Math.ceil(selectedProjectList.length / PLANNED_PROJECT_PER_PAGE) - 1}
              predeterminedProjectsList={mapPredeterminedProjectsListToDataTable()}
              refreshDataUsingPagination={setPlannedProjectsGanttPage}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

PredeterminedProjectGantt.defaultProps = {
  currentScenarioStartDate: 2000,
  currentScenarioEndDate: 2000
};

PredeterminedProjectGantt.propTypes = {
  currentScenarioEndDate: PropTypes.number,
  currentScenarioStartDate: PropTypes.number,
  getValidPredeterminedProjectEvents: PropTypes.func.isRequired,
  handleGanttEditAction: PropTypes.func.isRequired,
  parameterData: PropTypes.object.isRequired,
  parametersState: PropTypes.object.isRequired,
  predeterminedProjectList: PropTypes.array.isRequired,
  setDisplayForm: PropTypes.func.isRequired,
  setParametersState: PropTypes.func.isRequired,
  setSelectedPredeterminedProject: PropTypes.func.isRequired
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '89vw',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: '0.5rem',
    margin: 0,
    border: '1px solid lightgrey',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    backgroundColor: 'lightgrey'
  },
  indicatorContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    marginRight: '6rem',
    alignItems: 'center'
  },
  indicator: {
    display: 'flex',
    color: theme.palette.nexans.primary,
    backgroundColor: theme.palette.nexans.white
  },
  chipsRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-around',

    '& .MuiBadge-colorPrimary': {
      color: 'white'
    }
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: '#f5f5f5'
  },
  paper: {
    position: 'absolute',
    width: 'fit-content',
    minWidth: '80vw',
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '3rem 1rem',
    '& .MuiDataGrid-dataContainer': {
      backgroundColor: 'white'
    }
  }
}));

const StyledBadge = withStyles(() => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}))(Badge);
