import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiAsset } from '../../../../api/ApiAsset';
import { ASSET_ACTIONS_KEY } from '../../../commons/AssetConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllAssetsKPIData() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiAsset.getAssetsKPI, organizationId);
    const data2 = yield call(ApiAsset.getAssetsKPIGroupedByAssetFamily, organizationId);
    if (Object.keys(data).length > 0 && data2.length > 0) {
      const formattedAverage = Number.isInteger(data.avgHealthScore)
        ? data.avgHealthScore
        : data.avgHealthScore.toFixed(1);

      yield put({
        type: ASSET_ACTIONS_KEY.SET_ALL_ASSETS_KPI,
        kpi: {
          averageApparentAge: formattedAverage,
          healthScoreAvg: formattedAverage,
          countComponent: data.countComponent,
          countGroup: data.countGroup,
          countFamily: data.countFamily,
          groupedByFamily: data2 || []
        }
      });
    } else {
      yield put({
        type: ASSET_ACTIONS_KEY.SET_ALL_ASSETS_KPI,
        kpi: {}
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllAssetsKPIData() {
  yield takeEvery(ASSET_ACTIONS_KEY.GET_ALL_ASSETS_KPI, getAllAssetsKPIData);
}

export default findAllAssetsKPIData;
