// Available CNAIM actions
export const CNAIM_ACTIONS_KEY = {
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  GET_ABACUS_TABLE: 'GET_ABACUS_TABLE',
  GET_CNAIM_METADATA: 'GET_CNAIM_METADATA',
  SET_ABACUS_TABLE: 'SET_ABACUS_TABLE',
  SET_CNAIM_METADATA: 'SET_CNAIM_METADATA',
  SET_DOWNLOAD_ERROR: 'SET_DOWNLOAD_ERROR',
  SET_UPLOAD_ERROR: 'SET_UPLOAD_ERROR',
  UPLOAD_FILE: 'UPLOAD_FILE'
};
