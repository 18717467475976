import EditTable from 'components/EditTable';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function TechnicalPolicyCriterion(props) {
  const { t } = useTranslation();

  const { criterionTableSettings, setAreCriterionsNameValid, criterions, updateCriterions } = props;

  const criterionsTableTraductions = {
    deleteDialog: {
      title: 'genericcomponent.dialog.conditions.title',
      body: 'genericcomponent.dialog.conditions.body',
      cancel: 'genericcomponent.dialog.conditions.cancel',
      delete: 'genericcomponent.dialog.conditions.delete'
    }
  };

  const isNullOrEmpty = (data) => data === undefined || data === null || data.toString()?.trim() === '';

  const hasDuplicateNamesOnLastElement = useCallback(
    (criteria, index) => {
      const duplicateNames = criterions
        .map((c, i) => ({
          criteriaName: c.Name,
          index: i
        }))
        .filter((c) => c.criteriaName === criteria.Name);

      return duplicateNames.length > 1 && duplicateNames.at(-1)?.index === index;
    },
    [criterions]
  );

  const criterionNameErrorMessages = useMemo(
    () =>
      criterions.map((criteria, index) => {
        if (isNullOrEmpty(criteria.Name)) return t('views.library.technicalpolicy.empty');
        if (hasDuplicateNamesOnLastElement(criteria, index)) return t('views.library.technicalpolicy.alreadyExist');
        return null;
      }),
    [criterions, hasDuplicateNamesOnLastElement, t]
  );

  useEffect(() => {
    const hasAnyCriterionNameErrors = criterionNameErrorMessages.some((message) => message !== null);
    setAreCriterionsNameValid(!hasAnyCriterionNameErrors);
  }, [criterionNameErrorMessages, setAreCriterionsNameValid]);

  // Function to edit criterions by updating a criteria specified property with the newValue, or to delete criteria
  function criterionHandleChange(newValue, index, property) {
    if (index !== null && index !== undefined && property) {
      const updatedCriterion = criterions.map((criteria, criteriaIndex) => {
        if (index === criteriaIndex) {
          let updatedCriteria = { ...criteria, [property]: newValue };
          const isHiddenBoolean = newValue === 'Failure';
          switch (property) {
            case 'Proportion':
              updatedCriteria = {
                ...updatedCriteria,
                Threshold: newValue === 'Percentage' ? 1 : 0
              };
              break;
            case 'EquipmentComponentAttribute':
              updatedCriteria = {
                ...updatedCriteria,
                Operator: '',
                ReferenceAttribute: '',
                Value: isHiddenBoolean ? 1 : null,
                ValueAsString: null
              };
              break;
            case 'ReferenceAttribute':
              if (newValue !== 'Min' || newValue !== 'Max') {
                updatedCriteria = {
                  ...updatedCriteria,
                  Value: null,
                  ValueAsString: null
                };
              }
              break;
            case 'Value':
              updatedCriteria = {
                ...updatedCriteria,
                ValueAsString: null
              };
              break;
            case 'ValueAsString':
              updatedCriteria = {
                ...updatedCriteria,
                Value: null
              };
              break;
            default:
              break;
          }
          return updatedCriteria;
        }
        return criteria;
      });
      updateCriterions(updatedCriterion);
    } else {
      const criteriaToDelete = newValue;
      deleteCriteria(criteriaToDelete);
    }
  }

  // Function to delete criteria
  function deleteCriteria(criteria) {
    updateCriterions([...criterions.filter((c) => c.$dtId !== criteria.$dtId)]);
  }

  function getCriterionToDataTable() {
    if (criterions && criterions.length > 0) {
      return criterions.map((criteria) => ({
        ...criteria,
        Proportion: criteria.Threshold ? 'Percentage' : 'At least one'
      }));
    }
    return [];
  }

  return (
    <EditTable
      data={getCriterionToDataTable()}
      tableSettings={{ ...criterionTableSettings, errorMessages: criterionNameErrorMessages }}
      traductions={criterionsTableTraductions}
      onChange={criterionHandleChange}
    />
  );
}

TechnicalPolicyCriterion.propTypes = {
  criterionTableSettings: PropTypes.object.isRequired,
  criterions: PropTypes.array.isRequired,
  setAreCriterionsNameValid: PropTypes.func.isRequired,
  updateCriterions: PropTypes.func.isRequired
};

export default TechnicalPolicyCriterion;
