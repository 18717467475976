import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import GetAppIcon from '@mui/icons-material/GetApp';
import SearchIcon from '@mui/icons-material/Search';
import { Button, IconButton, InputBase, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TechnicalPoliciesTable } from 'components';
import InputFile from 'components/InputFile/InputFile.component';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';

function TechnicalPoliciesLibrary(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    technicalPolicyList,
    technicalPoliciesImport,
    getAllTechnicalPolicyList,
    deleteTechnicalPolicy,
    resetTechnicalPolicy,
    setTechnicalPoliciesSearch,
    setTechnicalPoliciesPage,
    getOperation,
    uploadTechnicalPolicies
  } = props;

  function navigateToLibrary() {
    navigate({ pathname: '/library' });
  }

  function openCreateTechnicalPolicy() {
    resetTechnicalPolicy();
    navigate({ pathname: '/technical-policy' });
  }

  function openEditTechnicalPolicy(technicalPolicy) {
    navigate({
      pathname: `/technical-policy${technicalPolicy ? `/${technicalPolicy.id}` : ''}`
    });
  }

  function openDeleteTechnicalPolicy(technicalPolicy) {
    deleteTechnicalPolicy(technicalPolicy);
  }

  function mapTechnicalPolicyToDataTableRow(technicalPolicy) {
    return {
      id: technicalPolicy.Id,
      Name: technicalPolicy.DtId,
      AssetClass: technicalPolicy.AssetClass,
      Type: technicalPolicy.Type,
      Period:
        technicalPolicy.YearStart && technicalPolicy.YearEnd
          ? `${technicalPolicy.YearStart}-${technicalPolicy.YearEnd}`
          : null,
      Operation: technicalPolicy.ActionToTrigger
    };
  }

  function mapTechnicalPoliciesListToDataTable() {
    return technicalPolicyList.data.map((technicalPolicy) => mapTechnicalPolicyToDataTableRow(technicalPolicy));
  }

  const onEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getAllTechnicalPolicyList();
    }
  };

  return (
    <>
      <div className={classes.topBar}>
        <div style={{ flex: 1 }}>
          <Button className='backBtn' color='primary' variant='contained' onClick={() => navigateToLibrary()}>
            <ArrowBackIcon /> {t('layouts.back')}
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div className={classes.flexSearch}>
            <Paper className={classes.searchInput} component='form'>
              <InputBase
                className={classes.searchInputField}
                value={technicalPolicyList.search}
                onChange={(event) => setTechnicalPoliciesSearch(event.target.value)}
                onKeyDown={onEnterPress}
              />
              <IconButton
                aria-label='search'
                className={classes.searchIcon}
                size='large'
                type='button'
                onClick={getAllTechnicalPolicyList}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <div className={classes.buttonContainerRight}>
            <InputFile
              acceptFilesFormat='application/zip'
              error={technicalPoliciesImport.errors}
              importFile={uploadTechnicalPolicies}
              loading={technicalPoliciesImport.uploading}
            >
              {t('commoncomponents.import.action')} <GetAppIcon />
            </InputFile>
            <Button color='primary' variant='contained' onClick={() => openCreateTechnicalPolicy()}>
              {t('views.library.technicalpolicy.newTechnicalpolicy')} <ControlPointIcon />
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.tableContainer}>
        <Paper className={classes.tablePaper}>
          <TechnicalPoliciesTable
            currentPage={technicalPolicyList.page}
            elementPerPage={technicalPolicyList.elementPerPage}
            getOperation={getOperation}
            loading={technicalPolicyList.loading}
            openDeleteTechnicalPolicy={openDeleteTechnicalPolicy}
            openEditTechnicalPolicy={openEditTechnicalPolicy}
            pageCount={technicalPolicyList.pageCount}
            refreshDataUsingPagination={setTechnicalPoliciesPage}
            technicalPoliciesList={mapTechnicalPoliciesListToDataTable()}
          />
        </Paper>
      </div>
    </>
  );
}

TechnicalPoliciesLibrary.propTypes = {
  technicalPolicyList: PropTypes.object.isRequired,
  technicalPoliciesImport: PropTypes.object.isRequired,

  deleteTechnicalPolicy: PropTypes.func.isRequired,
  getAllTechnicalPolicyList: PropTypes.func.isRequired,
  resetTechnicalPolicy: PropTypes.func.isRequired,
  setTechnicalPoliciesSearch: PropTypes.func.isRequired,
  setTechnicalPoliciesPage: PropTypes.func.isRequired,
  getOperation: PropTypes.func.isRequired,
  uploadTechnicalPolicies: PropTypes.func.isRequired
};

export default TechnicalPoliciesLibrary;

const useStyles = makeStyles(() => ({
  topBar: {
    display: 'flex',
    width: '90%',
    margin: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0 0'
  },
  flexSearch: {
    display: 'flex'
  },
  buttonContainerRight: {
    display: 'flex',
    flex: 2,
    alignSelf: 'center',
    gap: '1rem'
  },
  searchInput: {
    backgroundColor: theme.palette.nexans.white,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    marginRight: 24
  },
  searchInputField: {
    padding: 8,
    flex: 1,
    color: 'black'
  },
  searchIcon: {
    background: theme.palette.background.gradient,
    color: theme.palette.nexans.white,
    borderRadius: '0 10px 10px 0'
  },
  tableContainer: {
    width: '90%',
    margin: '20px auto 0'
  },
  tablePaper: {
    background: theme.palette.nexans.white
  }
}));
