import { Button, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

const useStyles = makeStyles(() => ({
  paper: {
    padding: theme.spacing(1),
    width: '100%'
  },
  import: {
    '&:disabled': {
      background: 'rgba(0, 0, 0, 0.12)'
    }
  },
  popover: {
    marginTop: '5px',
    width: '400px'
  }
}));

function InputFile({ children, acceptFilesFormat, loading, error, importFile }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const hiddenFileInput = useRef();
  const anchorEl = useRef();
  const [open, setOpen] = useState(!loading && error);
  useEffect(() => setOpen(() => !loading && error), [loading, error]);

  const uploadFileFunction = (event) => {
    const file = event.target.files[0];
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
    const data = new FormData();
    data.append('file', file);
    importFile(data);
  };
  const onButtonClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <Button
        disabled={loading}
        className={classes.import}
        variant='contained'
        onClick={onButtonClick}
        color='primary'
        ref={anchorEl}
      >
        {children}
      </Button>
      <input
        type='file'
        accept={acceptFilesFormat}
        ref={hiddenFileInput}
        onChange={uploadFileFunction}
        style={{ display: 'none' }}
      />
      <Popover
        id='popover'
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div className={classes.rows}>
          {error &&
            Object.entries(error.errors).map(([key, value], index) => (
              <li key={`import-errors-${key - index}`}>
                <b>{`${t(`commoncomponents.import.errors.${key}`)} : `}</b>
                {value.map((errorValue, indexValue) => {
                  return (
                    <span key={`import-errors-${key}-${indexValue}`}>
                      {errorValue}
                      {indexValue !== value.length - 1 && <br />}
                    </span>
                  );
                })}
              </li>
            ))}
        </div>
      </Popover>
    </>
  );
}

InputFile.defaultProps = {
  error: null
};

InputFile.propTypes = {
  acceptFilesFormat: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  importFile: PropTypes.func.isRequired
};

export default InputFile;
