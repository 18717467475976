import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { ApiOperation } from '../../../../api/ApiOperation';
import { ASSET_CLASSES_ACTIONS_KEY } from '../../../commons/AssetClassesConstants';
import { OPERATION_ACTIONS_KEY } from '../../../commons/OperationConstants';
import { selectOrganizationId } from '../../sagaUtils';

const NUMBER_OF_OPERATIONS_BY_ASSET_CLASS_PER_PAGE = 10;

function* setAllOperationsByAssetClass(organizationId, assetClassFromState, search, page, numberPerPage) {
  yield put({
    type: OPERATION_ACTIONS_KEY.SET_OPERATIONS_BY_ASSETCLASS_LOADING,
    isLoading: true
  });

  const data = yield call(
    ApiOperation.fetchOperationsTree,
    organizationId,
    assetClassFromState,
    search,
    page,
    numberPerPage
  );

  yield put({
    type: OPERATION_ACTIONS_KEY.SET_ALL_OPERATIONS_BY_ASSETCLASS,
    operationsByAssetClass: data?.values.length > 0 ? data.values : [],
    page,
    search,
    totalPage: Math.ceil((data?.totalCount ?? 0) / numberPerPage)
  });

  yield put({
    type: OPERATION_ACTIONS_KEY.SET_OPERATIONS_BY_ASSETCLASS_LOADING,
    isLoading: false
  });
}

export function* setSearchOfAllOperationsByAssetClass(action) {
  const search = action.search;

  try {
    const organizationId = yield selectOrganizationId();
    const numberPerPage = NUMBER_OF_OPERATIONS_BY_ASSET_CLASS_PER_PAGE;
    const page = 1;
    const assetClassFromState = yield select((state) => state.assetClasses.selected.data);

    yield setAllOperationsByAssetClass(organizationId, assetClassFromState, search, page, numberPerPage);
  } catch (e) {
    console.error(e);
  }
}

export function* setPageOfAllOperationsByAssetClass(action) {
  const page = action.page;

  try {
    const organizationId = yield selectOrganizationId();
    const numberPerPage = NUMBER_OF_OPERATIONS_BY_ASSET_CLASS_PER_PAGE;
    const assetClassFromState = yield select((state) => state.assetClasses.selected.data);
    const search = yield select((state) => state.operation.operationsByAssetClass.search);

    yield setAllOperationsByAssetClass(organizationId, assetClassFromState, search, page, numberPerPage);
  } catch (e) {
    console.error(e);
  }
}

export function* findAllOperationsByAssetClass(action) {
  const assetClass = action.assetClass;

  try {
    const assetClassFromState = yield select((state) => state.assetClasses.selected.data);

    yield put({
      type: OPERATION_ACTIONS_KEY.SET_OPERATIONS_BY_ASSETCLASS_LOADING,
      isLoading: true
    });

    if (assetClass || assetClassFromState) {
      if (action.type === OPERATION_ACTIONS_KEY.UPDATE_OPERATION) yield delay(3000);

      const organizationId = yield selectOrganizationId();

      const search = '';
      const page = 1;
      const numberPerPage = NUMBER_OF_OPERATIONS_BY_ASSET_CLASS_PER_PAGE;

      const data = yield call(
        ApiOperation.fetchOperationsTree,
        organizationId,
        assetClass ? assetClass : assetClassFromState,
        search,
        page,
        numberPerPage
      );

      yield put({
        type: OPERATION_ACTIONS_KEY.SET_ALL_OPERATIONS_BY_ASSETCLASS,
        operationsByAssetClass: data?.values.length > 0 ? data.values : [],
        page,
        search,
        totalPage: Math.ceil((data?.totalCount ?? 0) / numberPerPage)
      });
    }

    yield put({
      type: OPERATION_ACTIONS_KEY.SET_OPERATIONS_BY_ASSETCLASS_LOADING,
      isLoading: false
    });
  } catch (e) {
    console.error(e);
  }
}

function* findAllOperationsByAssetClassData() {
  yield takeEvery(OPERATION_ACTIONS_KEY.GET_ALL_OPERATIONS, findAllOperationsByAssetClass);
  yield takeEvery(ASSET_CLASSES_ACTIONS_KEY.SET_SELECTED_ASSET_CLASS, findAllOperationsByAssetClass);
  yield takeEvery(OPERATION_ACTIONS_KEY.EXECUTE_OPERATIONS_SEARCH, findAllOperationsByAssetClass);
  yield takeEvery(OPERATION_ACTIONS_KEY.UPDATE_OPERATION, findAllOperationsByAssetClass);
  yield takeEvery(OPERATION_ACTIONS_KEY.SET_OPERATIONS_BY_ASSETCLASS_PAGE, setPageOfAllOperationsByAssetClass);
  yield takeEvery(OPERATION_ACTIONS_KEY.SET_OPERATIONS_BY_ASSETCLASS_SEARCH, setSearchOfAllOperationsByAssetClass);
}

export default findAllOperationsByAssetClassData;
