import {
  CancelOutlined as CancelOutlinedIcon,
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  GetApp as GetAppIcon,
  HourglassDisabledOutlined as HourglassDisabledOutlinedIcon,
  LoopOutlined as LoopOutlinedIcon
} from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BuildIcon from '@mui/icons-material/Build';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import InfoIcon from '@mui/icons-material/Info';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import SyncIcon from '@mui/icons-material/Sync';
import CircularProgress from '@mui/material/CircularProgress';
import { PIPELINESTATUS } from 'state/commons/Constants';
import theme from 'theme';

const createAction = { name: 'create', icon: <GetAppIcon />, disabled: false };
const updateAction = { name: 'update', icon: <EditIcon />, disabled: false };
const deleteAction = { name: 'delete', icon: <DeleteIcon />, disabled: false };
const deleteAllAction = {
  name: 'deleteAll',
  icon: <DeleteIcon />,
  disabled: false
};
const exportAction = {
  name: 'export',
  icon: <OpenInBrowserIcon />,
  disabled: false
};
const syncAction = { name: 'sync', icon: <SyncIcon />, disabled: true };
const historyAction = {
  name: 'history',
  icon: <HistoryIcon />,
  disabled: false
};
const infoAction = { name: 'info', icon: <InfoIcon />, disabled: false };

const allActions = [
  createAction,
  updateAction,
  deleteAction,
  deleteAllAction,
  exportAction,
  syncAction,
  historyAction,
  infoAction
];

export const GetMenuActionsByPipeline = (pipelineType) => {
  switch (pipelineType) {
    case 'finance':
      return [updateAction, exportAction, syncAction, historyAction, infoAction];
    default:
      return allActions;
  }
};

export function getIconFromPipelineStatus(status) {
  switch (status) {
    case PIPELINESTATUS.RUNNING:
      return <CircularProgress color='primary' size='1rem' />;
    case PIPELINESTATUS.PREPARINGIMPORT:
      return <LoopOutlinedIcon fontSize='large' style={{ color: theme.palette.nexans.grey }} />;
    case PIPELINESTATUS.SUCCESS:
      return <CheckCircleOutlineOutlinedIcon fontSize='large' style={{ color: theme.palette.nexans.greenSuccess }} />;
    case PIPELINESTATUS.ERROR:
      return <CancelOutlinedIcon fontSize='large' style={{ color: theme.palette.nexans.redError }} />;
    case PIPELINESTATUS.NONE:
      return <HourglassDisabledOutlinedIcon size='1rem' style={{ color: theme.palette.nexans.grey }} />;
    default:
      return <LoopOutlinedIcon fontSize='large' style={{ color: theme.palette.nexans.grey }} />;
  }
}

export const icons = {
  DeviceHubIcon,
  DateRangeIcon,
  PermIdentityIcon,
  SettingsInputComponentIcon,
  AttachMoneyIcon,
  BuildIcon,
  GetAppIcon,
  EditIcon,
  DeleteIcon,
  OpenInBrowserIcon,
  SyncIcon,
  HistoryIcon,
  InfoIcon
};
