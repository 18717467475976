import { Add as AddIcon, Assignment as AssignmentIcon, Build as EventIcon } from '@mui/icons-material';
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { defaultPredeterminedProject } from 'state/reducers/predeterminedProject/predeterminedProjectReducer';
import { defaultTechnicalPolicy } from 'state/reducers/technicalPolicy/technicalPolicyReducer';
import theme from 'theme';
import { StringUtils } from 'utils/helpers';

function OperationRow(props) {
  const { operation, options, getTechnicalPolicy, getPredeterminedProject } = props;
  const { t } = useTranslation();
  const { backgroundColor, withWarning } = options;
  const navigate = useNavigate();
  const classes = useStyles();

  function navigateToItem(type, item) {
    if (type === 'technicalPolicy') {
      getTechnicalPolicy(item.id);
      navigate({ pathname: '/technical-policy' });
    } else if (type === 'predeterminedProject') {
      getPredeterminedProject(item.id);
      navigate({ pathname: '/predetermined-project' });
    }
  }

  const addTechnicalPolicy = (op) => {
    const data = {
      ...defaultTechnicalPolicy,
      AssetClass: op.AssetClass,
      ActionToTrigger: op.DtId
    };
    getTechnicalPolicy(null, data);
    navigate({
      pathname: '/technical-policy'
    });
  };

  const addPredeterminedProject = (op) => {
    const data = {
      ...defaultPredeterminedProject,
      AssetClass: op.AssetClass,
      Operation: op.DtId
    };
    getPredeterminedProject(null, data);
    navigate({
      pathname: '/predetermined-project'
    });
  };

  return (
    <>
      {withWarning && (
        <div className={classes.warning}>
          {operation.technicalPolicies?.length > 0 && (
            <Typography>{t('genericcomponent.dialog.library.operation.contentPoltech')}</Typography>
          )}

          {operation.predeterminedProjects?.length > 0 && (
            <Typography> {t('genericcomponent.dialog.library.operation.contentPDP')}</Typography>
          )}

          {operation.technicalPolicies?.length > 0 && operation.predeterminedProjectList?.length > 0 && (
            <Typography> {t('genericcomponent.dialog.library.operation.contentPolTechAndPDP')}</Typography>
          )}
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around'
        }}
      >
        <div className={classes.listColumn}>
          <div className={classes.subTitle}>
            <span>{t('views.library.operation.technicalPolicyList')}</span>

            <IconButton
              color='primary'
              edge='end'
              key='AddIconTechnicalPolicy'
              size='small'
              onClick={() => addTechnicalPolicy(operation)}
            >
              <AddIcon />
            </IconButton>
          </div>
          <div className={classes.listContent} style={{ backgroundColor }}>
            <List>
              {operation.technicalPolicies?.length > 0 &&
                operation.technicalPolicies.map((technicalPolicy) => (
                  <ListItem
                    className={classes.listItem}
                    key={`operation-tp-${technicalPolicy.$dtId}`}
                    onClick={() => navigateToItem('technicalPolicy', technicalPolicy)}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.iconContainer} size='small'>
                        <AssignmentIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={StringUtils.removesUnderscoreReplaceSpace(technicalPolicy.$dtId)} />
                  </ListItem>
                ))}
              {operation.technicalPolicies?.length <= 0 && (
                <div style={{ textAlign: 'center' }}>{t('views.library.operation.noTechnicalProject')}</div>
              )}
            </List>
          </div>
        </div>

        <div className={classes.listColumn}>
          <div className={classes.subTitle}>
            <span>{t('views.library.operation.predeterminedProjectList')}</span>

            <IconButton
              color='primary'
              edge='end'
              key='AddIconPredeterminedProject'
              size='small'
              onClick={() => addPredeterminedProject(operation)}
            >
              <AddIcon />
            </IconButton>
          </div>
          <div className={classes.listContent} style={{ backgroundColor }}>
            <List>
              {operation.predeterminedProjects?.length > 0 &&
                operation.predeterminedProjects.map((predeterminedProject) => (
                  <ListItem
                    className={classes.listItem}
                    key={`operation-pdp-${predeterminedProject.$dtId}`}
                    onClick={() => navigateToItem('predeterminedProject', predeterminedProject)}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.iconContainer} size='small'>
                        <EventIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={StringUtils.removesUnderscoreReplaceSpace(predeterminedProject.$dtId)} />
                  </ListItem>
                ))}
              {operation.predeterminedProjects?.length <= 0 && (
                <div style={{ textAlign: 'center' }}>{t('views.library.operation.noProject')}</div>
              )}
            </List>
          </div>
        </div>
      </div>
    </>
  );
}

OperationRow.propTypes = {
  operation: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  getTechnicalPolicy: PropTypes.func.isRequired,
  getPredeterminedProject: PropTypes.func.isRequired
};

export default OperationRow;

const useStyles = makeStyles(() => ({
  subTitle: {
    alignSelf: 'start',
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  listContent: {
    borderRadius: '10px',
    backgroundColor: theme.palette.nexans.lightGreyBg,
    '& span': {
      color: theme.palette.nexans.black
    }
  },
  listColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '40%',
    marginTop: '1rem'
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFFFFF !important'
    }
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.gradient,
    width: 44,
    height: 44,
    borderRadius: 44,
    marginRight: 24,
    '& svg': {
      color: theme.palette.nexans.white
    }
  },
  warning: {
    marginTop: '1rem',
    '& .MuiTypography-body1': {
      color: '#062F4F !important'
    }
  }
}));
