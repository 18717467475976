import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ApiTechnicalPolicy } from '../../../../api/ApiTechnicalPolicy';
import { TECHNICAL_POLICY_ACTIONS_KEY } from '../../../commons/TechnicalPolicyConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllTechnicalPoliciesData() {
  const keyword = yield select((state) => state.technicalPolicy.list.search);
  const perPage = yield select((state) => state.technicalPolicy.list.elementPerPage);
  const page = yield select((state) => state.technicalPolicy.list.page);

  const params = {
    keyword: keyword ? keyword : null,
    perPage,
    page
  };

  try {
    yield put({
      type: TECHNICAL_POLICY_ACTIONS_KEY.SET_TECHNICAL_POLICIES_LOADING,
      loading: true
    });

    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiTechnicalPolicy.fetchAllTechnicalPolicies, organizationId, params);

    let pageCount = 0;

    if (data.value.length > 0) {
      pageCount = data['@odata.count'] > perPage ? data['@odata.count'] / perPage : 1;
      yield put({
        type: TECHNICAL_POLICY_ACTIONS_KEY.SET_ALL_TECHNICAL_POLICIES,
        technicalPolicies: data.value
      });
      yield put({
        type: TECHNICAL_POLICY_ACTIONS_KEY.SET_TECHNICAL_POLICIES_PAGE_COUNT,
        pageCount
      });
    } else {
      yield put({
        type: TECHNICAL_POLICY_ACTIONS_KEY.SET_ALL_TECHNICAL_POLICIES,
        technicalPolicies: []
      });
      yield put({
        type: TECHNICAL_POLICY_ACTIONS_KEY.RESET_TECHNICAL_POLICIES_PAGE
      });
      yield put({
        type: TECHNICAL_POLICY_ACTIONS_KEY.SET_TECHNICAL_POLICIES_PAGE_COUNT,
        pageCount
      });
    }

    yield put({
      type: TECHNICAL_POLICY_ACTIONS_KEY.SET_TECHNICAL_POLICIES_LOADING,
      loading: false
    });
  } catch (e) {
    console.error(e);
  }
}

function* findAllTechnicalPoliciesData() {
  yield takeEvery(TECHNICAL_POLICY_ACTIONS_KEY.GET_ALL_TECHNICAL_POLICIES, getAllTechnicalPoliciesData);
  yield takeEvery(TECHNICAL_POLICY_ACTIONS_KEY.SET_TECHNICAL_POLICIES_PAGE, getAllTechnicalPoliciesData);
}

export default findAllTechnicalPoliciesData;
