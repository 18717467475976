import { ApiPredeterminedProject } from 'api/ApiPredeterminedProject';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { PREDETERMINED_PROJECT_ACTIONS_KEY } from 'state/commons/PredeterminedProjectConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllPredeterminedProjectsData() {
  const keyword = yield select((state) => state.predeterminedProject.list.search);
  const perPage = yield select((state) => state.predeterminedProject.list.elementPerPage);
  const page = yield select((state) => state.predeterminedProject.list.page);

  const params = {
    keyword: keyword ? keyword : null,
    perPage,
    page
  };

  try {
    yield put({
      type: PREDETERMINED_PROJECT_ACTIONS_KEY.SET_PREDETERMINED_PROJECTS_LOADING,
      loading: true
    });

    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiPredeterminedProject.fetchAllPredeterminedProjects, organizationId, params);
    let pageCount = 0;

    if (data.value?.length > 0) {
      // round to next integer page count
      // pageCount = data['@odata.count'] >= perPage ? data['@odata.count'] / perPage : 1;

      pageCount = Math.ceil(data['@odata.count'] >= perPage ? data['@odata.count'] / perPage : 1);
      yield put({
        type: PREDETERMINED_PROJECT_ACTIONS_KEY.SET_ALL_PREDETERMINED_PROJECTS,
        predeterminedProjects: data.value
      });
      yield put({
        type: PREDETERMINED_PROJECT_ACTIONS_KEY.SET_PREDETERMINED_PROJECTS_PAGE_COUNT,
        pageCount
      });
    } else {
      yield put({
        type: PREDETERMINED_PROJECT_ACTIONS_KEY.SET_ALL_PREDETERMINED_PROJECTS,
        predeterminedProjects: []
      });
      yield put({
        type: PREDETERMINED_PROJECT_ACTIONS_KEY.RESET_PREDETERMINED_PROJECTS_PAGE
      });
      yield put({
        type: PREDETERMINED_PROJECT_ACTIONS_KEY.SET_PREDETERMINED_PROJECTS_PAGE_COUNT,
        pageCount
      });
    }

    yield put({
      type: PREDETERMINED_PROJECT_ACTIONS_KEY.SET_PREDETERMINED_PROJECTS_LOADING,
      loading: false
    });
  } catch (e) {
    console.error(e);
  }
}

function* findAllPredeterminedProjectsData() {
  yield takeEvery(PREDETERMINED_PROJECT_ACTIONS_KEY.GET_ALL_PREDETERMINED_PROJECTS, getAllPredeterminedProjectsData);
  yield takeEvery(PREDETERMINED_PROJECT_ACTIONS_KEY.SET_PREDETERMINED_PROJECTS_PAGE, getAllPredeterminedProjectsData);
}

export default findAllPredeterminedProjectsData;
