import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiBudget } from '../../../../api/ApiBudget';
import { BUDGET_ACTIONS_KEY } from '../../../commons/BudgetConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllBudgetCategoriesData() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiBudget.fetchAllBudgetCategories, organizationId);
    if (data.length > 0) {
      yield put({
        type: BUDGET_ACTIONS_KEY.SET_ALL_BUDGET_CATEGORIES,
        budgetsCategoriesEnum: data
      });
    } else {
      yield put({
        type: BUDGET_ACTIONS_KEY.SET_ALL_BUDGET_CATEGORIES,
        budgetsCategoriesEnum: []
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllBudgetCategoriesData() {
  yield takeEvery(BUDGET_ACTIONS_KEY.GET_ALL_BUDGET_CATEGORIES, getAllBudgetCategoriesData);
}

export default findAllBudgetCategoriesData;
