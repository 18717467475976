import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';

export function setupStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: ['REQUEST_LOG_IN']
        }
      }).concat(sagaMiddleware)
  });

  sagaMiddleware.run(rootSaga);

  return store;
}

const applicationStore = setupStore();

export default applicationStore;
