import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getAssetUrl = (organizationId) => `${getBasePath(organizationId)}/Assets`;
const getFamiliesUrl = (organizationId) => `${getBasePath(organizationId)}/Families`;

const getAsset = (organizationId, id) =>
  axios
    .get(`${getAssetUrl(organizationId)}/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const getAssetsKPI = (organizationId) =>
  axios
    .get(`${getFamiliesUrl(organizationId)}/Kpi`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const getAssetsKPIGroupedByAssetFamily = (organizationId) =>
  axios
    .get(`${getAssetUrl(organizationId)}/Kpi`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const fetchAllAssetClasses = (organizationId) =>
  axios
    .get(`${getAssetUrl(organizationId)}/GetAssetClasses`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const fetchEquipmentGroupNameByAssetClass = async (organizationId, assetClass) =>
  axios
    .get(`${getAssetUrl(organizationId)}/GetEquipmentGroupNameByAssetClass/${assetClass}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const fetchAllRatingLevelsByAssetClass = (organizationId, assetClass) =>
  axios
    .get(`${getAssetUrl(organizationId)}/GetRatingLevels/${assetClass}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return {};
    });

const exportAssetsRegistry = async (organizationId, assetFamily) =>
  axios
    .get(`${getAssetUrl(organizationId)}/Export/${assetFamily}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/zip'
      }
    })
    .then((response) => response.data);

export const ApiAsset = {
  exportAssetsRegistry,
  fetchEquipmentGroupNameByAssetClass,
  getAsset,
  getAssetsKPI,
  getAssetsKPIGroupedByAssetFamily,
  fetchAllAssetClasses,
  fetchAllRatingLevelsByAssetClass
};
