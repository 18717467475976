import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

export default function DateInputComponent(props) {
  const { id, label, format, value, dateProps, changeSelectedDate, testId } = props;

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    let parsedValue;
    if (typeof value === 'number') {
      parsedValue = value.toString();
    } else if (value instanceof Date && format === 'yyyy') {
      parsedValue = value.getFullYear().toString();
    } else {
      parsedValue = value;
    }
    setInputValue(parsedValue ?? '');
  }, [value, format]);

  const handleYearChange = (event) => {
    const newInputValue = event.target.value;

    if (newInputValue.length > 4) {
      return;
    }

    setInputValue(newInputValue);
    if (newInputValue.match(/^\d{4}$/)) {
      const newYear = new Date(parseInt(newInputValue), 0);

      if (dateProps.maxDate !== undefined && dateProps.maxDate !== null && newYear > new Date(dateProps.maxDate, 0)) {
        setError(dateProps.minDateMessage);
        return;
      }

      if (dateProps.minDate !== undefined && dateProps.minDate !== null && newYear < new Date(dateProps.minDate, 0)) {
        setError(dateProps.maxDateMessage);
        return;
      }

      // If no errors, clear error state and call onChange
      setError(null);
      changeSelectedDate(newYear.getFullYear()); // Pass only the year to the parent component
    } else {
      // Invalid date format
      setError(dateProps.invalidDateMessage);
    }
  };

  return (
    <Grid id={dateProps.id} item xs={3}>
      <TextField
        data-testid={dateProps.testId}
        disabled={dateProps.disabled}
        error={!!error}
        helperText={error}
        id={`date-input-${id}`}
        label={label}
        size='small'
        sx={{ flexGrow: 1 }}
        value={inputValue}
        variant='standard'
        onChange={handleYearChange}
      />
    </Grid>
  );
}

DateInputComponent.propTypes = {
  // BasicDateInput's id
  id: PropTypes.string.isRequired,
  // BasicDateInput's label
  label: PropTypes.string,
  // Tooltip text
  tooltipText: PropTypes.string,
  // BasicDateInput's value
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // BasicDateInput's date format
  format: PropTypes.string,
  // Function used when the user changes the BasicDateInput value
  changeSelectedDate: PropTypes.func.isRequired,
  // Additional props that you can specify for the BasicDateInput's input
  dateProps: PropTypes.object.isRequired,
  // Error object that contains the type of error and its message
  error: PropTypes.object,
  // Test id for the BasicDateInput
  testId: PropTypes.string
};

DateInputComponent.defaultProps = {
  format: 'yyyy',
  isDirty: false,
  testId: 'date-input'
};
