import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiSkill } from '../../../../api/ApiSkill';
import { SKILL_ACTIONS_KEY } from '../../../commons/SkillConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* findAllSkills() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiSkill.fetchAllSkills, organizationId);
    if (data.length > 0) {
      yield put({
        type: SKILL_ACTIONS_KEY.SET_ALL_SKILLS,
        skills: data
      });
    } else {
      yield put({
        type: SKILL_ACTIONS_KEY.SET_ALL_SKILLS,
        skills: []
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllSkillsData() {
  yield takeEvery(SKILL_ACTIONS_KEY.GET_ALL_SKILLS, findAllSkills);
}

export default findAllSkillsData;
