export const concatAndRemovesDuplicatesWithTwoArrays = (array1, array2) => {
  // array1 is original data and array 2 is user's overwritten data usually

  const updatedList = array1.map((source) => {
    // check if the year exists in the operatingForecastScenarioList and the network level exists in the operatingForecastScenarioList
    // and add scenarioId and simulationRun

    const found = array2.find((el) => el.year === source.year && el.networkLevel === source.networkLevel);
    return found ? found : source;
  });

  // concat both source and current and removes duplicates
  const mixedArray = [...updatedList, ...array2];

  const filtered = mixedArray.filter(
    (item, index, self) => index === self.findIndex((t) => t.year === item.year && t.networkLevel === item.networkLevel)
  );

  // sort by networkLevel and year
  filtered.sort((a, b) => {
    if (a.networkLevel < b.networkLevel) {
      return -1;
    }
    if (a.networkLevel > b.networkLevel) {
      return 1;
    }
    if (a.year < b.year) {
      return -1;
    }
    if (a.year > b.year) {
      return 1;
    }
    return 0;
  });

  return filtered;
};
