import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchGetAllScenarioTechnicalPolicyList } from 'state/dispatchers/scenarioTechnicalPolicy/ScenarioTechnicalPolicyDispatcher';
import {
  dispatchGetAllTechnicalPolicyList,
  dispatchResetTechnicalPolicy,
  dispatchSetSelectedTechnicalPolicy,
  dispatchTechnicalPolicyIsNameExist
} from 'state/dispatchers/technicalPolicy/technicalPolicyDispatcher';

export const useGetAllTechnicalPolicyList = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchGetAllTechnicalPolicyList()), [dispatch]);
};
export const useGetAllScenarioTechnicalPolicyList = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchGetAllScenarioTechnicalPolicyList()), [dispatch]);
};

export const useSetSelectedTechnicalPolicy = () => {
  const dispatch = useDispatch();
  return useCallback((techpol) => dispatch(dispatchSetSelectedTechnicalPolicy(techpol)), [dispatch]);
};
export const useResetTechnicalPolicy = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchResetTechnicalPolicy()), [dispatch]);
};
export const useCheckIfTechnicalPolicyNameExists = () => {
  const dispatch = useDispatch();
  return useCallback(
    (name, oldTechPol, newTechPol) => dispatch(dispatchTechnicalPolicyIsNameExist(name, oldTechPol, newTechPol)),
    [dispatch]
  );
};

export const useScenarioTechnicalPolicyList = () => {
  return useSelector((state) => state.scenarioTechnicalPolicy.list.data);
};
export const useSelectedTechnicalPolicy = () => {
  return useSelector((state) => state.technicalPolicy.selectedTechnicalPolicy.data);
};
export const useIsTechnicalPolicyNameAvailable = () => {
  return useSelector((state) => state.technicalPolicy.isNameAvailable);
};
export const useTechnicalPolicyEnums = () => {
  return useSelector((state) => state.technicalPolicy.enums.data);
};

export const useTechnicalPolicyList = () => {
  return useSelector((state) => state.technicalPolicy.list);
};
