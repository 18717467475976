import { useCallback } from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { dispatchShowDialog } from 'state/dispatchers/dialog/dialogDispatcher';

interface DialogRootState extends DefaultRootState {
  dialog: {
    isVisible: boolean;
    dialogType?: string;
    params?: unknown;
  };
}

export const useDialogStatus = (dialogType: string): boolean =>
  useSelector((state: DialogRootState) => {
    if (state.dialog.dialogType === dialogType) return state.dialog.isVisible;
    else return false;
  });

export const useDialogParams = <T>(): T | undefined =>
  useSelector((state: DialogRootState) => state.dialog.params as T);

export const useHideDialog = () => {
  const dispatch = useDispatch();
  const dialogParams = useDialogParams();

  return useCallback(() => dispatch(dispatchShowDialog(false, dialogParams)), [dialogParams, dispatch]);
};

export const useShowDialog = () => {
  const dispatch = useDispatch();
  return useCallback(
    (dialogType: string, params?: unknown) => dispatch(dispatchShowDialog(true, params, dialogType)),
    [dispatch]
  );
};
