import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_CLIENT_ID, OKTA_ISSUER } from 'config/AppInstance';

export const oktaAuth = new OktaAuth({
  clientId: OKTA_CLIENT_ID,
  issuer: OKTA_ISSUER,
  redirectUri: `${window.location.origin}/sign-in/callback`,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  pkce: true,
  tokenManager: {
    autoRenew: true,
    autoRemove: true,
    syncStorage: true
  }
});
