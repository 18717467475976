import { ApiSubset } from 'api/ApiSubset/ApiSubset';
import { call, put, takeLatest } from 'redux-saga/effects';
import { i18next } from 'services/config/i18next';
import { NOTIFICATION_ACTIONS_KEY, SUBSET_ACTIONS_KEY } from 'state/commons';
import { selectOrganizationId } from '../sagaUtils';

export function* deleteSubsetSaga(action) {
  try {
    if (action.idSubset) {
      const organizationId = yield selectOrganizationId();
      yield call(ApiSubset.deleteSubset, organizationId, action.idSubset);
      yield put({
        type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
        message: i18next.t('layouts.tabs.assetregistry.subset.deleted'),
        isOpen: true
      });
      yield put({
        type: SUBSET_ACTIONS_KEY.FETCH_ALL_SUBSET
      });
    }
  } catch (error) {
    yield put({
      type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
      message: i18next.t('layouts.tabs.assetregistry.subset.errorInDeletion'),
      isOpen: true
    });
  }
}

function* deleteSubset() {
  yield takeLatest(SUBSET_ACTIONS_KEY.DELETE_SUBSET, deleteSubsetSaga);
}

export default deleteSubset;
