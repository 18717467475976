import { ApiIntegration } from 'api/ApiIntegration';
import { call, put, takeEvery } from 'redux-saga/effects';
import { DATAINTEGRATION_ACTIONS_KEY } from '../../../commons/DataIntegrationConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* fetchSubPipelinesData() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiIntegration.fetchSubPipelines, organizationId);
    yield put({
      type: DATAINTEGRATION_ACTIONS_KEY.SET_SUBPIPELINES_DATA,
      subPipelines: data?.pipelineModels?.length ? data.pipelineModels : []
    });

    yield put({
      type: DATAINTEGRATION_ACTIONS_KEY.SET_CAN_RUN_INTEGRATION,
      canRunIntegration: data?.canRunIntegration
    });
    yield put({
      type: DATAINTEGRATION_ACTIONS_KEY.SET_CAN_UPLOAD_FILES,
      canUploadFiles: data?.canUploadFiles
    });
  } catch (e) {
    console.error(e);
  }
}

function* getSubPipelinesData() {
  yield takeEvery(DATAINTEGRATION_ACTIONS_KEY.GET_SUBPIPELINES_DATA, fetchSubPipelinesData);
}

export default getSubPipelinesData;
