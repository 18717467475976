import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiTechnicalPolicy } from '../../../../api/ApiTechnicalPolicy';
import { TECHNICAL_POLICY_ACTIONS_KEY } from '../../../commons/TechnicalPolicyConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* findAllTechnicalPoliciesEnums() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiTechnicalPolicy.fetchAllTechnicalPoliciesEnums, organizationId);
    if (data) {
      yield put({
        type: TECHNICAL_POLICY_ACTIONS_KEY.SET_ALL_TECHNICAL_POLICIES_ENUMS,
        enums: data
      });
    } else {
      yield put({
        type: TECHNICAL_POLICY_ACTIONS_KEY.SET_ALL_TECHNICAL_POLICIES_ENUMS,
        enums: {}
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* getAllTechnicalPoliciesEnums() {
  yield takeEvery(TECHNICAL_POLICY_ACTIONS_KEY.GET_ALL_TECHNICAL_POLICIES_ENUMS, findAllTechnicalPoliciesEnums);
}

export default getAllTechnicalPoliciesEnums;
