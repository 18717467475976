import { ApiScenario } from 'api/ApiScenario';
import { call, put, takeEvery } from 'redux-saga/effects';
import { formatParametersFromApi } from 'utils/helpers/ApiUtils';
import { STATUSES } from '../../../commons/Constants';
import { SCENARIO_ACTIONS_KEY } from '../../../commons/ScenarioConstants';
import { selectOrganizationId } from '../../sagaUtils';
import { getAllScenariosData } from '../FindAllScenarios/FindAllScenariosData';

export function* updateScenarioDescription(action) {
  try {
    const organizationId = yield selectOrganizationId();
    const workspaceId = action.workspaceId;
    const scenarioId = action.scenarioId;
    const scenarioDescription = action.scenarioDescription;

    // Get scenario by ID
    const { data } = yield call(ApiScenario.findScenarioById, organizationId, workspaceId, scenarioId);
    // Create expected modified scenario
    const expectedNewData = { ...data, description: scenarioDescription };
    // Update scenario
    const { data: updateData } = yield call(
      ApiScenario.updateScenario,
      organizationId,
      workspaceId,
      scenarioId,
      expectedNewData
    );

    updateData.parametersValues = formatParametersFromApi(updateData.parametersValues);

    // Refresh all scenario
    yield call(getAllScenariosData, workspaceId);
    // Refresh the current scenario
    yield put({
      type: SCENARIO_ACTIONS_KEY.SET_CURRENT_SCENARIO,
      status: STATUSES.SUCCESS,
      scenario: updateData
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: SCENARIO_ACTIONS_KEY.SET_CURRENT_SCENARIO,
      status: STATUSES.ERROR,
      scenario: null
    });
  }
}

function* updateScenarioDescriptionData() {
  yield takeEvery(SCENARIO_ACTIONS_KEY.UPDATE_SCENARIO_DESCRIPTION, updateScenarioDescription);
}

export default updateScenarioDescriptionData;
