import InputComponentFactory from 'components/InputComponentFactory';

const create = (t, parameterData, parametersState, setParametersState, editMode, containerProps) => {
  const scenarioStartDateId = 'parameter_scenario_global_date_de_debut_scenario';
  const scenarionEndDateId = 'parameter_scenario_global_date_de_fin_scenario';

  const scenarioStart = parametersState[scenarioStartDateId];
  const scenarioEnd = parametersState[scenarionEndDateId];

  let scenarioDateRange = { minDate: undefined, maxDate: undefined };
  if (parameterData.id === scenarioStartDateId) scenarioDateRange.maxDate = scenarioEnd;
  if (parameterData.id === scenarionEndDateId) scenarioDateRange.minDate = scenarioStart;

  return (
    <InputComponentFactory
      data-cy={parameterData.dataCy} // Optional data for cypress
      editProps={{
        type: 'dateYear',
        placeholder: t(`solution.parameters.${parameterData.id}`, parameterData.id),
        minDate: parameterData.minValue ?? scenarioDateRange.minDate,
        maxDate: parameterData.maxValue ?? scenarioDateRange.maxDate,
        minDateMessage: t('genericcomponent.dateInput.error.minDateMessage'),
        maxDateMessage: t('genericcomponent.dateInput.error.maxDateMessage'),
        invalidDateMessage: t('genericcomponent.dateInput.error.invalidDateMessage'),
        disabled: !editMode
      }}
      index={parameterData.id}
      value={parametersState[parameterData.id]}
      onChange={(newValue) => {
        setParametersState((currentParametersState) => ({
          ...currentParametersState,
          [parameterData.id]: newValue
        }));
      }}
    />
  );
};

export const BasicDateInputFactory = {
  create
};
