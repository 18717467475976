import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { CNAIM_ACTIONS_KEY } from '../../commons/CnaimConstants';

export const cnaimInitialState = {
  list: [],
  abacusTable: [],
  status: {
    upload: {
      loading: false,
      error: null
    },
    download: {
      loading: false,
      error: null
    }
  }
};

export const cnaimFileMetadataListReducer = createReducer(cnaimInitialState, (builder) => {
  builder.addCase(CNAIM_ACTIONS_KEY.SET_CNAIM_METADATA, (state, action) => {
    state.list = action.cnaimFilesMetadataData;
  });
  builder.addCase(CNAIM_ACTIONS_KEY.SET_UPLOAD_ERROR, (state, action) => {
    state.status.upload.error = action.error;
  });
  builder.addCase(CNAIM_ACTIONS_KEY.SET_DOWNLOAD_ERROR, (state, action) => {
    state.status.download.error = action.error;
  });
  builder.addCase(CNAIM_ACTIONS_KEY.SET_ABACUS_TABLE, (state, action) => {
    state.abacusTable = action.abacusTableData;
  });
});

export const CnaimReducer = combineReducers({
  cnaimFilesMetadata: cnaimFileMetadataListReducer
});
