import { put, takeEvery } from 'redux-saga/effects';
import { i18next } from 'services/config/i18next';
import { API_RESPONSE_ACTIONS_KEY, NOTIFICATION_ACTIONS_KEY } from 'state/commons';

const isRequestSuccessful = (status) => status >= 200 && status < 300;
const isRequestInClientError = (status) => status >= 400 && status < 500;
const isRequestInServerError = (status) => status >= 500 && status < 600;

export function getIconStatus(status) {
  if (isRequestSuccessful(status)) return '✅';
  if (isRequestInClientError(status)) return '⚠️';
  if (isRequestInServerError(status)) return '❌';
  return '❓';
}

const MESSAGE_TYPE_MAP = {
  SET_TECHNICAL_POLICY: 'genericcomponent.dialog.library.technicalpolicy.create.title',
  DELETE_OPERATION: 'genericcomponent.dialog.library.operation.title',
  DELETE_TECHNICAL_POLICY: 'genericcomponent.dialog.library.technicalpolicy.delete.title',
  DELETE_PREDETERMINED_PROJECT: 'genericcomponent.dialog.library.predeterminedproject.title',
  UPLOAD_PIPELINE_FILE: 'genericcomponent.dialog.library.dataIntegration.uploadFile'
};

function getMessageFromType(type, defaultMsg) {
  return MESSAGE_TYPE_MAP[type] || defaultMsg;
}

export function* displayApiResponseInNotification(action) {
  const { status, statusText } = action.response;
  const actionType = action.actionType;
  const message = action?.response?.data?.message ?? action.response.data;

  // Special case: For the UPLOAD_PIPELINE_FILE action type, we only show a notification if the request wasn't successful.
  if (actionType === 'UPLOAD_PIPELINE_FILE' && isRequestSuccessful(status)) {
    return;
  }

  const notificationMessage = `${statusText}: ${i18next.t(getMessageFromType(actionType, message))} ${getIconStatus(
    status
  )}`;

  yield put({
    type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
    message: notificationMessage,
    isOpen: true
  });
}

function* displayApiResponseData() {
  yield takeEvery(API_RESPONSE_ACTIONS_KEY.SET_API_STATUS_DATA, displayApiResponseInNotification);
}

export default displayApiResponseData;
