import DeleteIcon from '@mui/icons-material/Delete';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { Button } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function EditTableToolbar(props) {
  const { t } = useTranslation();
  const { handleClickOnExport, onDeleteClick, isDeleteAvailable } = props;
  const [exportBtnVisible, setExportBtnVisible] = useState(handleClickOnExport != null);

  useEffect(() => {
    setExportBtnVisible(handleClickOnExport != null);
  }, [handleClickOnExport]);

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {exportBtnVisible && (
        <Button startIcon={<OpenInBrowserIcon />} onClick={handleClickOnExport}>
          {t('genericcomponent.edittable.toolbar.export')}
        </Button>
      )}
      {onDeleteClick && (
        <Button startIcon={<DeleteIcon />} onClick={isDeleteAvailable && onDeleteClick} disabled={!isDeleteAvailable}>
          {t('genericcomponent.edittable.toolbar.delete')}
        </Button>
      )}
    </GridToolbarContainer>
  );
}

EditTableToolbar.defaultProps = {
  handleClickOnExport: null,
  onDeleteClick: null,
  isDeleteAvailable: false
};

EditTableToolbar.propTypes = {
  handleClickOnExport: PropTypes.func,
  onDeleteClick: PropTypes.func,
  isDeleteAvailable: PropTypes.bool
};

export default EditTableToolbar;
