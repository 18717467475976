import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SCENARIO_ACTIONS_KEY } from 'state/commons/ScenarioConstants';
import {
  dispatchCreateScenario,
  dispatchFindScenarioById,
  dispatchLaunchScenario,
  dispatchSetCurrentScenario,
  dispatchSetCurrentScenarioParametersState,
  dispatchUpdateAndLaunchScenario
} from 'state/dispatchers/scenario/ScenarioDispatcher';

export const useScenarioList = () => {
  return useSelector((state) => state.scenario.list);
};

export const useCurrentScenario = () => {
  return useSelector((state) => state.scenario.current);
};

export const useCurrentScenarioStateParameters = () => {
  return useSelector((state) => state.scenario.current.parametersState);
};

export const useCurrentScenarioData = () => {
  return useSelector((state) => state.scenario.current?.data);
};

export const useFindScenarioById = () => {
  const dispatch = useDispatch();
  return useCallback(
    (workspaceId, scenarioId) => dispatch(dispatchFindScenarioById(workspaceId, scenarioId)),
    [dispatch]
  );
};

export const useCreateScenario = () => {
  const dispatch = useDispatch();
  return useCallback((workspaceId, scenario) => dispatch(dispatchCreateScenario(workspaceId, scenario)), [dispatch]);
};

export const useUpdateCurrentScenario = () => {
  const dispatch = useDispatch();
  return useCallback((scenario) => dispatch(dispatchSetCurrentScenario(scenario)), [dispatch]);
};

export const useSetScenarioParametersState = () => {
  const dispatch = useDispatch();
  return useCallback(
    (parametersState) => dispatch(dispatchSetCurrentScenarioParametersState(parametersState)),
    [dispatch]
  );
};

export const useUpdateAndLaunchScenario = () => {
  const dispatch = useDispatch();
  return useCallback(
    (workspaceId, scenarioId, scenarioParameters) =>
      dispatch(dispatchUpdateAndLaunchScenario(workspaceId, scenarioId, scenarioParameters)),
    [dispatch]
  );
};

export const useLaunchScenario = () => {
  const dispatch = useDispatch();
  return useCallback(
    (workspaceId, scenarioId) => dispatch(dispatchLaunchScenario(workspaceId, scenarioId)),
    [dispatch]
  );
};

const dispatchRunSimulationLoopWithScenarioId = () => ({
  type: SCENARIO_ACTIONS_KEY.RUN_SIMULATION_LOOP
});

export const useRunSimulationLoopWithScenarioId = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchRunSimulationLoopWithScenarioId()), [dispatch]);
};
