import { ApiPredeterminedProject } from 'api/ApiPredeterminedProject';
import { call, put, takeEvery } from 'redux-saga/effects';
import { API_RESPONSE_ACTIONS_KEY, PREDETERMINED_PROJECT_ACTIONS_KEY } from 'state/commons';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* deleteAllPredeterminedProjects(action) {
  let response = '';

  try {
    yield put({
      type: PREDETERMINED_PROJECT_ACTIONS_KEY.DELETE_ALL_PREDETERMINED_PROJECTS_LOADING,
      loading: true
    });

    const organizationId = yield selectOrganizationId();
    response = yield call(ApiPredeterminedProject.deleteAllPredeterminedProjects, organizationId);

    yield put({
      type: PREDETERMINED_PROJECT_ACTIONS_KEY.DELETE_ALL_PREDETERMINED_PROJECTS_LOADING,
      loading: false
    });

    yield put({
      type: PREDETERMINED_PROJECT_ACTIONS_KEY.GET_ALL_PREDETERMINED_PROJECTS,
      params: { keyword: null, perPage: 5, page: 1 }
    });
    yield put({
      type: API_RESPONSE_ACTIONS_KEY.SET_API_STATUS_DATA,
      response,
      actionType: action.type
    });
  } catch (e) {
    yield put({
      type: API_RESPONSE_ACTIONS_KEY.SET_API_STATUS_DATA,
      response: e.response,
      actionType: action.type
    });
  } finally {
    yield put({
      type: PREDETERMINED_PROJECT_ACTIONS_KEY.DELETE_ALL_PREDETERMINED_PROJECTS_LOADING,
      loading: false
    });
  }
}

function* deleteAllPredeterminedProjectsData() {
  yield takeEvery(PREDETERMINED_PROJECT_ACTIONS_KEY.DELETE_ALL_PREDETERMINED_PROJECTS, deleteAllPredeterminedProjects);
}

export default deleteAllPredeterminedProjectsData;
