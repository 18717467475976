import AddIcon from '@mui/icons-material/Add';
import { Box, Checkbox, Divider, FormControlLabel, IconButton, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const CustomPaper = ({
  children,
  onToggleSelectAll,
  onSearchSelect,
  isAllSelected,
  autoCompleteSearchText,
  autoCompleteFilteredOptions,
  ...paperProps
}) => {
  const { t } = useTranslation();

  const isAutocompleteFilteredAgingFamiliesDisabled = autoCompleteFilteredOptions.current.length === 0;

  return (
    <Paper {...paperProps} onMouseDown={(event) => event.preventDefault()}>
      <>
        <Box pl={3.5} py={0.5} onMouseDown={(e) => e.preventDefault()}>
          {autoCompleteSearchText.current !== '' && !isAllSelected ? (
            <FormControlLabel
              control={
                <IconButton>
                  <AddIcon />
                </IconButton>
              }
              disabled={isAutocompleteFilteredAgingFamiliesDisabled}
              label={
                <Typography
                  fontWeight='bold'
                  sx={{
                    color: (theme) =>
                      isAutocompleteFilteredAgingFamiliesDisabled ? theme.palette.text.disabled : undefined
                  }}
                >
                  {t('layouts.tabs.assetregistry.subset.selectFilteredAgingFamilies', {
                    text: autoCompleteSearchText.current
                  })}
                </Typography>
              }
              onClick={(e) => {
                e.preventDefault();
                onSearchSelect?.(e);
              }}
            />
          ) : (
            <FormControlLabel
              control={<Checkbox checked={isAllSelected} />}
              label={t('layouts.tabs.assetregistry.subset.selectAll')}
              onClick={(e) => {
                e.preventDefault();
                onToggleSelectAll?.(!isAllSelected);
              }}
            />
          )}
        </Box>
        <Divider />
      </>
      {children}
    </Paper>
  );
};

CustomPaper.propTypes = {
  children: PropTypes.array,
  onToggleSelectAll: PropTypes.func,
  onSearchSelect: PropTypes.func,
  isAllSelected: PropTypes.bool.isRequired,
  autoCompleteSearchText: PropTypes.shape({ current: PropTypes.any }).isRequired,
  autoCompleteFilteredOptions: PropTypes.shape({ current: PropTypes.any }).isRequired
};
