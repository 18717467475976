import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import { NumberUtils, StringUtils } from 'utils/helpers';

function AssetRegistryFamiliesCardList({ kpiByFamily, familyList, selectFamily, 'data-testid': testId }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const foundKpi = useCallback((family) => kpiByFamily?.find((el) => el.family === family.$dtId), [kpiByFamily]);

  function displayYearsIfAgingModelIsNotHealthScore(family) {
    if (family.AgingMethodology === 'HealthScore')
      return NumberUtils.formatNumberToFloatOrExponential(foundKpi(family)?.healthScoreAvg || '-', 1);

    const averageAge = NumberUtils.formatNumberToFloatOrExponential(foundKpi(family)?.healthScoreAvg ?? 0, 1);

    return t('views.assetRegistry.infobar.year', { count: averageAge });
  }

  return (
    <Grid className={classes.gridContainer} container={true} data-testid={testId}>
      {kpiByFamily &&
        familyList?.map((family) => (
          <Grid item={true} key={family.$dtId + family.id} md={3} xs={4}>
            <Card className={classes.cardFamily} onClick={() => selectFamily(family.$dtId)}>
              <CardContent className={classes.cardFamilyContent}>
                <div className={classes.iconContainer}>
                  <DeviceHubIcon />
                </div>
                <div className={classes.familyInfo}>
                  <h2 title={StringUtils.removesUnderscoreReplaceSpace(family.$dtId)}>
                    {StringUtils.removesUnderscoreReplaceSpace(family.$dtId)}
                  </h2>

                  <div className={classes.cardSubInfoContainer}>
                    <div className={classes.cardSubInfosRow}>
                      <Typography align='left' className={classes.cardSubInfosTitle} noWrap={true} variant='inherit'>
                        {t('views.assetRegistry.infobar.components')}
                      </Typography>
                      <Typography align='left' className={classes.cardSubInfosValue} noWrap={true} variant='inherit'>
                        {foundKpi(family)?.count}
                      </Typography>
                    </div>

                    <div className={classes.cardSubInfosRow}>
                      <Typography align='left' className={classes.cardSubInfosTitle} noWrap={true} variant='inherit'>
                        {family.AgingMethodology === 'HealthScore'
                          ? t('views.assetRegistry.infobar.avgHealthScore')
                          : t('views.assetRegistry.infobar.averageLifeSpan')}
                      </Typography>
                      <Typography align='left' className={classes.cardSubInfosValue} noWrap={true} variant='inherit'>
                        {displayYearsIfAgingModelIsNotHealthScore(family)}
                      </Typography>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
}

AssetRegistryFamiliesCardList.propTypes = {
  familyList: PropTypes.array.isRequired,
  selectFamily: PropTypes.func.isRequired,
  kpiByFamily: PropTypes.array.isRequired,
  'data-testid': PropTypes.string.isRequired
};

export default AssetRegistryFamiliesCardList;

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: '24px'
  },
  cardFamily: {
    backgroundColor: theme.palette.background.card,
    width: '90%',
    margin: '35px auto',
    cursor: 'pointer'
  },
  cardFamilyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.gradient,
    width: 44,
    height: 44,
    borderRadius: 44,
    marginRight: 24,
    '& svg': {
      color: theme.palette.nexans.white
    }
  },
  familyInfo: {
    display: 'flex',
    flexDirection: 'column',
    bottom: '-0.6rem',
    position: 'relative',

    '& h2': {
      maxWidth: '12vw',
      color: theme.palette.text.black,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '& span': {
      color: theme.palette.text.disabled
    }
  },
  tableContainer: {
    margin: '24px auto',
    width: '90%'
  },
  cardSubInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0.4rem'
  },
  cardSubInfosRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem'
  },
  cardSubInfosTitle: {
    display: 'flex',
    fontWeight: '500',
    color: theme.palette.text.primary
  },
  cardSubInfosValue: {
    display: 'flex',
    fontWeight: 'bold',
    color: theme.palette.text.secondary
  }
}));
