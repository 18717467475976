import { IDataset, IRunTemplate, IScenario } from 'models';

export const getCurrentScenarioRunTemplate = (currentScenario: IScenario, runTemplates: IRunTemplate[]) => {
  const currentRunTemplateId = currentScenario?.runTemplateId;
  return runTemplates.find((runTemplate) => runTemplate.id === currentRunTemplateId) || null;
};

export const isDialogDataValid = (
  scenarioName: string,
  scenarioNameFieldError: string | null,
  isMaster: boolean,
  scenarioType: IRunTemplate | null,
  parentScenario: IScenario | null,
  dataset: IDataset
) => {
  const validScenarioName = scenarioName.length !== 0 && scenarioNameFieldError === null;
  const validScenarioType = scenarioType && Object.keys(scenarioType).length !== 0;
  const validParentScenario = parentScenario && Object.keys(parentScenario).length !== 0;
  const validDataset = dataset && Object.keys(dataset).length !== 0;

  if (isMaster) {
    return validScenarioName && validScenarioType;
  }

  return validScenarioName && validScenarioType && validParentScenario && validDataset;
};
