import { ErrorRounded as ErrorIcon, WarningRounded as WarningIcon } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { EHttpStatusCode } from 'api/apiConstants';
import { useTranslation } from 'react-i18next';

interface IParams {
  errorMessage?: string;
  status?: number;
}

export const Error = (props: IParams) => {
  const { errorMessage, status } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const is404 = status === EHttpStatusCode.NotFound;

  return (
    <div className={classes.error}>
      {!is404 && <ErrorIcon className={classes.icon} color='primary' />}
      {is404 && (
        <>
          <WarningIcon className={classes.icon} color='error' />
          <Typography variant='h5'>{t<string>('commoncomponents.error.notFound')}</Typography>
        </>
      )}
      <Typography variant='h6'>{errorMessage ?? t<string>('commoncomponents.error.genericError')}</Typography>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  error: {
    textAlign: 'center'
  },
  icon: {
    fontSize: 50
  }
}));
