import { SUBSET_ACTIONS_KEY } from 'state/commons/SubsetConstants';

export const dispatchCreateSubset = (subset) => ({
  type: SUBSET_ACTIONS_KEY.CREATE_SUBSET,
  subset
});
export const dispatchEditSubset = (subset) => ({
  type: SUBSET_ACTIONS_KEY.EDIT_SUBSET,
  subset
});
export const dispatchDeleteSubset = (idSubset) => ({
  type: SUBSET_ACTIONS_KEY.DELETE_SUBSET,
  idSubset
});
export const dispatchFetchSubsetList = () => ({
  type: SUBSET_ACTIONS_KEY.FETCH_ALL_SUBSET
});
export const dispatchSetSubsetList = (subsetList) => ({
  type: SUBSET_ACTIONS_KEY.SET_SUBSET_LIST,
  subsetList
});
