import EditIcon from '@mui/icons-material/Edit';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Button, CircularProgress, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentScenarioData } from 'state/hooks/Scenario.hooks';
function NormalModeButton({
  compacted,
  editDisabled,
  handleClickOnEdit,
  handleClickOnCancelScenario,
  handleClickOnLaunchScenario,
  runDisabled,
  scenarioScope
}) {
  const { t } = useTranslation();
  const [hasCancelled, setHasCancelled] = useState(false);
  const { state } = useCurrentScenarioData();

  useEffect(() => {
    if (state !== 'Running') {
      setHasCancelled(false);
    }
  }, [state]);

  return (
    <Grid container={true} spacing={1} alignItems='center'>
      <Grid item={true}>
        {compacted ? (
          <Button
            data-cy='edit-description-button'
            startIcon={<EditIcon />}
            variant='contained'
            color='primary'
            onClick={handleClickOnEdit}
            disabled={editDisabled}
            style={{
              position: 'absolute',
              right: '18%',
              top: '6.45rem',
              opacity: editDisabled ? 0.5 : 1,
              cursor: editDisabled ? 'not-allowed' : 'pointer'
            }}
          />
        ) : (
          <Button
            data-cy='edit-parameters-button'
            startIcon={<EditIcon />}
            variant='contained'
            color='primary'
            onClick={handleClickOnEdit}
            disabled={editDisabled}
            style={{
              cursor: editDisabled ? 'not-allowed' : 'pointer',
              opacity: editDisabled ? 0.5 : 1
            }}
          >
            {t('commoncomponents.button.scenario.parameters.edit', 'Edit')}
          </Button>
        )}
      </Grid>
      {scenarioScope && (
        <Grid item={true}>
          {runDisabled ? (
            <Button
              data-cy='cancel-scenario-button'
              startIcon={!hasCancelled && <PlayCircleOutlineIcon />}
              variant='contained'
              color='primary'
              onClick={(event) => {
                setHasCancelled(true);
                handleClickOnCancelScenario(event);
              }}
            >
              {hasCancelled && state === 'Running' ? (
                <CircularProgress size='1rem' style={{ color: 'white' }} />
              ) : (
                t('commoncomponents.button.scenario.parameters.cancel', 'Cancel Scenario')
              )}
            </Button>
          ) : (
            <Button
              data-cy='launch-scenario-button'
              startIcon={<PlayCircleOutlineIcon />}
              variant='contained'
              color='primary'
              onClick={handleClickOnLaunchScenario}
              disabled={runDisabled}
              style={{
                cursor: editDisabled ? 'not-allowed' : 'pointer',

                opacity: editDisabled ? 0.5 : 1
              }}
            >
              {t('commoncomponents.button.scenario.parameters.launch', 'Launch Scenario')}
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
}

NormalModeButton.propTypes = {
  handleClickOnEdit: PropTypes.func.isRequired,
  editDisabled: PropTypes.bool.isRequired,
  compacted: PropTypes.bool,
  scenarioScope: PropTypes.bool,
  handleClickOnLaunchScenario: PropTypes.func,
  handleClickOnCancelScenario: PropTypes.func,
  runDisabled: PropTypes.bool
};

NormalModeButton.defaultProps = {
  compacted: false,
  scenarioScope: false,
  handleClickOnLaunchScenario: Function.prototype,
  handleClickOnCancelScenario: Function.prototype,
  runDisabled: false
};

export default NormalModeButton;
