import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { ExtraParametersUtils } from '../../ExtraParametersUtils';

const create = (t, parameterData, parametersState, setParametersState, editMode) => {
  let enumValues = parameterData.enumValues;

  const disabled = ExtraParametersUtils.GetDisabled(parameterData, parametersState, editMode);
  const displayed = ExtraParametersUtils.GetDisplayed(parameterData, parametersState);
  const itemSize = ExtraParametersUtils.GetItemSize(parameterData);

  if (!enumValues) {
    console.warn(
      `Enum values are not defined for scenario parameter "${parameterData.id}".\nPlease provide an array in the "enumValues" field for this parameter in the parameters configuration file.`
    );
    enumValues = [];
  }

  function setValue(newValue) {
    let newState = {
      ...parametersState,
      [parameterData.id]: newValue
    };

    // Check & set dispatch result
    newState = ExtraParametersUtils.DispatchResult(parameterData, newState, newValue);

    setParametersState(newState);
  }

  if (parametersState[parameterData.id] === null) {
    setValue(parametersState[parameterData.id] || enumValues?.[0]?.value || '');
  }

  return (
    <Grid item={true} key={`grid-item-${parameterData.id}`} md={itemSize} style={displayed}>
      <FormControl disabled={disabled} fullWidth={true} key={parameterData.id} variant='standard'>
        <InputLabel id={`input-label-${parameterData.id}`}>
          {t(`solution.parameters.${parameterData.id}`, parameterData.id)}
        </InputLabel>
        <Select
          id={`input-${parameterData.id}`}
          labelId={`input-label-${parameterData.id}`}
          value={parametersState[parameterData.id]}
          variant='standard'
          onChange={(event) => setValue(event.target.value)}
        >
          {enumValues.map((enumValue) => (
            <MenuItem key={`menu-item-${parameterData.id}-${enumValue.key}`} value={enumValue.value}>
              {enumValue.key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export const MaterialEnumInputFactory = {
  create
};
