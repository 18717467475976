import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from '@mui/material/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function OperationSelector(props) {
  const { t } = useTranslation();

  const { disabled, id, options, testId, value, onChange, onInputChange } = props;

  return (
    <Autocomplete
      data-testid={testId}
      disablePortal
      disabled={disabled}
      filterOptions={(x) => x}
      fullWidth
      id={id}
      inputProps={{
        'data-testid': testId
      }}
      noOptionsText={t('views.library.predeterminedproject.noResults')}
      options={options}
      renderInput={(params) => (
        <TextField {...params} fullWidth placeholder={t('views.library.operation.searchOperation')} size='small' />
      )}
      slotProps={{
        popper: {
          sx: { zIndex: 999 }
        }
      }}
      style={{ marginTop: 16 }}
      value={value}
      variant='standard'
      onChange={(event, newValue) => onChange(newValue)}
      onInputChange={debounce((event, newInputValue) => onInputChange(newInputValue), 300)}
    />
  );
}

OperationSelector.propTypes = {
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired
};

export default OperationSelector;
