import { Auth } from '@cosmotech/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import { PROFILES } from '../../../../config/Profiles';
import { AUTH_ACTIONS_KEY, AUTH_STATUS } from '../../../commons/AuthConstants';

const _extractPermissionsFromRoles = (roles) => {
  let permissions = [];
  if (roles) {
    for (const role of roles) {
      permissions = [...new Set([...permissions, ...PROFILES[role]])];
    }
  }
  return permissions;
};

// Generator function to fetch authentication data
export function* tryLogIn(action) {
  // Get the provider from action allows to use data from
  // native okta hooks
  const { provider } = action;
  try {
    if (provider?.authStateManager && provider?.token) {
      const user = yield call(provider.token.getUserInfo);
      const userPermissions = _extractPermissionsFromRoles(action?.userRoles);

      // If the user is authenticated, set the auth data
      yield put({
        type: AUTH_ACTIONS_KEY.SET_AUTH_DATA,
        userId: user.sub,
        userName: user.preferred_username,
        profilePic: Auth.getUserPicUrl(),
        roles: [],
        permissions: userPermissions,
        status: AUTH_STATUS.AUTHENTICATED
      });
    } else {
      yield put({
        type: AUTH_ACTIONS_KEY.SET_AUTH_DATA,
        userId: '',
        userName: '',
        profilePic: '',
        roles: [],
        permissions: [],
        status: AUTH_STATUS.DENIED
      });
    }
  } catch (error) {
    console.error(error);
  }
}

// Watch authentication actions
function* logIn() {
  yield takeEvery(AUTH_ACTIONS_KEY.REQUEST_LOG_IN, tryLogIn);
}

export default logIn;
