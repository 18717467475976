import { createReducer } from '@reduxjs/toolkit';
import { NOTIFICATION_ACTIONS_KEY } from '../../commons/NotificationConstants';

export const notificationInitialState = {
  message: '',
  isOpen: false
};

export const notificationReducer = createReducer(notificationInitialState, (builder) => {
  builder
    .addCase(NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION, (state, action) => {
      state.message = action.message;
      state.isOpen = true;
    })
    .addCase(NOTIFICATION_ACTIONS_KEY.CLOSE_NOTIFICATION, (state) => {
      state.message = '';
      state.isOpen = false;
    });
});
