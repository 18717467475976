import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getScenarioUrl = (organizationId: string, workspaceId: string) =>
  `${getBasePath(organizationId)}/workspaces/${workspaceId}/scenarios`;

const findAllScenarios = (organizationId: string, workspaceId: string) =>
  axios.get(getScenarioUrl(organizationId, workspaceId));

const findScenarioById = (organizationId: string, workspaceId: string, scenarioId: string) =>
  axios.get(`${getScenarioUrl(organizationId, workspaceId)}/${scenarioId}`);

const createScenario = (organizationId: string, workspaceId: string, scenario: unknown) =>
  axios.post(getScenarioUrl(organizationId, workspaceId), scenario);

const updateScenario = (organizationId: string, workspaceId: string, scenarioId: string, scenario: unknown) =>
  axios.patch(`${getScenarioUrl(organizationId, workspaceId)}/${scenarioId}`, scenario);

const deleteScenario = (organizationId: string, workspaceId: string, scenarioId: string) =>
  axios.delete(`${getScenarioUrl(organizationId, workspaceId)}/${scenarioId}`);

export const ApiScenario = {
  findAllScenarios,
  findScenarioById,
  createScenario,
  updateScenario,
  deleteScenario
};
