import { PictureAsPdf as PictureAsPdfIcon, Save as SaveIcon } from '@mui/icons-material';
import { Button, Menu, MenuItem, Modal, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as PPTICON } from 'assets/svg/ppt.svg';
import DashboardExportReportRow from 'components/Dashboards/DashboardExportReportRow.component';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

function DashboardExportButtonsPBI({ isSidebarExpanded, reportList }) {
  const styleProps = {
    flexDirection: isSidebarExpanded ? 'row' : 'column',
    display: 'flex',
    padding: isSidebarExpanded ? '0.7rem 0rem' : '0rem'
  };
  const classes = useStyles(styleProps);
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedReport, setSelectedReport] = useState(reportList[0]);
  const { t } = useTranslation();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const selectReportFromMenu = (report) => {
    setSelectedReport(report);
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      borderColor: theme.palette.nexans.lightGreyBg,
      backgroundColor: theme.palette.nexans.lightGreyBg,
      borderRadius: '10px',
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        id='exportButton'
        role='button'
        className={classes.buttonsContainer}
        onClick={handleOpen}
        onKeyPress={handleOpen}
        tabIndex={0}
      >
        <div className={classes.buttonTitle}>
          {isSidebarExpanded ? (
            t('genericcomponent.modal.report.exportTo')
          ) : (
            <>
              <Tooltip title='Export to PDF'>
                <PictureAsPdfIcon />
              </Tooltip>
              <Tooltip title='Export to PPT'>
                <PPTICON
                  style={{
                    color: 'white',
                    justifyContent: 'center',
                    maxHeight: '1.4rem',
                    maxWidth: '1.4rem'
                  }}
                />
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.rows}>
            <Button
              aria-controls='simple-menu'
              aria-haspopup='true'
              variant='outlined'
              onClick={handleMenuClick}
              className={classes.menu}
              startIcon={<SaveIcon />}
            >
              {selectedReport ? selectedReport.reportName : 'Select Report'}
            </Button>

            <Menu
              id='simple-menu'
              anchorEl={anchorEl}
              keepMounted={true}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              className={classes.root}
            >
              {reportList.map((report) => (
                <MenuItem
                  key={report.reportId}
                  onClick={() => selectReportFromMenu(report)}
                  className={classes.menuItem}
                >
                  {report.reportName}
                </MenuItem>
              ))}
            </Menu>
            {selectedReport?.reportId && (
              <DashboardExportReportRow report={selectedReport} key={selectedReport.reportId} closeMenu={handleClose} />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

DashboardExportButtonsPBI.propTypes = {
  isSidebarExpanded: PropTypes.bool.isRequired,
  reportList: PropTypes.any.isRequired
};

export default DashboardExportButtonsPBI;

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: 'white'
    }
  },
  buttonsContainer: {
    display: 'flex',
    width: '90% !important',
    margin: '1rem auto',
    justifyContent: 'center !important',
    flexDirection: (styleProps) => styleProps.flexDirection,
    gap: '1rem',
    alignItems: 'center',
    padding: (styleProps) => styleProps.padding,
    borderRadius: '10px',
    color: 'white',
    backgroundColor: theme.palette.nexans.primary
  },
  buttonTitle: {
    textAlign: 'center',
    display: (styleProps) => styleProps.display,
    fontSize: '18px',
    cursor: 'pointer',
    flexDirection: (styleProps) => styleProps.flexDirection
  },
  paper: {
    position: 'absolute',
    width: 'fit-content',
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '3rem 1rem'
  },
  rows: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem'
  },
  menu: {
    backgroundColor: 'white',
    borderRadius: '10px',
    color: 'black',
    fontSize: '18px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  menuItem: {
    borderRadius: '10px',
    color: 'black',
    fontSize: '18px'
  },
  exportButton: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '18px',
    borderRadius: '10px'
  }
}));
