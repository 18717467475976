import { ApiSubset } from 'api/ApiSubset';
import { ENABLE_SUBSET_FEATURE } from 'config/AppInstance';
import { call, put, takeLatest } from 'redux-saga/effects';
import { SUBSET_ACTIONS_KEY } from 'state/commons';
import { selectOrganizationId } from '../sagaUtils';

export function* fetchSubsetListSaga() {
  if (ENABLE_SUBSET_FEATURE !== 'true') return;

  try {
    const organizationId = yield selectOrganizationId();
    const subsetList = yield call(ApiSubset.fetchAllSubset, organizationId);

    yield put({
      type: SUBSET_ACTIONS_KEY.SET_SUBSET_LIST,
      subsetList: subsetList
    });
  } catch (error) {
    yield put({
      type: SUBSET_ACTIONS_KEY.SET_SUBSET_LIST,
      subsetList: []
    });
  }
}

function* getSubsetList() {
  yield takeLatest(SUBSET_ACTIONS_KEY.FETCH_ALL_SUBSET, fetchSubsetListSaga);
}

export default getSubsetList;
