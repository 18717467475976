import { call, put, takeEvery } from 'redux-saga/effects';
import { CNAIM_ACTIONS_KEY } from 'state/commons/CnaimConstants';
import { ApiCnaim } from '../../../../api/ApiCnaim';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* fetchCnaimFileMetadata() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiCnaim.fetchAllCnaimFilesMetadata, organizationId);
    if (data && data.length > 0) {
      yield put({
        type: CNAIM_ACTIONS_KEY.SET_CNAIM_METADATA,
        cnaimFilesMetadataData: data
      });
    } else {
      yield put({
        type: CNAIM_ACTIONS_KEY.SET_CNAIM_METADATA,
        cnaimFilesMetadataData: []
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* getCnaimFilesMetadataData() {
  yield takeEvery(CNAIM_ACTIONS_KEY.GET_CNAIM_METADATA, fetchCnaimFileMetadata);
}

export default getCnaimFilesMetadataData;
