import { ApiIntegration } from 'api/ApiIntegration';
import { call, put, takeEvery } from 'redux-saga/effects';
import { DATAINTEGRATION_ACTIONS_KEY } from '../../../commons/DataIntegrationConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* updateAgingModel(action) {
  const { agingModelValue } = action;

  try {
    const organizationId = yield selectOrganizationId();
    yield call(ApiIntegration.updateAgingModel, organizationId, agingModelValue);
    yield put({
      type: DATAINTEGRATION_ACTIONS_KEY.SET_AGING_MODEL,
      agingModel: agingModelValue
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: DATAINTEGRATION_ACTIONS_KEY.RESET_AGING_MODEL
    });
  }
}

function* updateAgingModelData() {
  yield takeEvery(DATAINTEGRATION_ACTIONS_KEY.UPDATE_AGING_MODEL, updateAgingModel);
}

export default updateAgingModelData;
