import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getIntegrationUrl = (organizationId) => `${getBasePath(organizationId)}/Teams/GetSkills`;

const fetchAllSkills = (organizationId) =>
  axios
    .get(getIntegrationUrl(organizationId))
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

export const ApiSkill = {
  fetchAllSkills
};
