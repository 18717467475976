// Available DATAINTEGRATION actions
export const DATAINTEGRATION_ACTIONS_KEY = {
  DELETE_ALL_PIPELINE_DATA: 'DELETE_ALL_PIPELINE_DATA',
  DELETE_PIPELINE_HISTORY_RUN_DATA: 'DELETE_PIPELINE_HISTORY_RUN_DATA',
  GET_AGING_MODEL: 'GET_AGING_MODEL',
  GET_KPIS: 'GET_KPIS',
  GET_PIPELINE_HISTORY: 'GET_PIPELINE_HISTORY',
  GET_PIPELINE_LASTEST_RUN_DATA: 'GET_PIPELINE_LASTEST_RUN_DATA',
  GET_SUBPIPELINES_DATA: 'GET_SUBPIPELINES_DATA',
  REMOVE_PIPELINE_HISTORY_ENTRY: 'REMOVE_PIPELINE_HISTORY_ENTRY',
  RESET_AGING_MODEL: 'RESET_AGING_MODEL',
  RUN_MAIN_PIPELINE: 'RUN_MAIN_PIPELINE',
  SET_AGING_MODEL: 'SET_AGING_MODEL',
  SET_CAN_RUN_INTEGRATION: 'SET_CAN_RUN_INTEGRATION',
  SET_CAN_UPLOAD_FILES: 'SET_CAN_UPLOAD_FILES',
  SET_MAIN_PIPELINE_DATA: 'SET_MAIN_PIPELINE_DATA',
  SET_ERROR: 'SET_ERROR',
  SET_KPIS: 'SET_KPIS',
  SET_LOADING: 'SET_LOADING',
  SET_PIPELINE_HISTORY: 'SET_PIPELINE_HISTORY',
  SET_PIPELINE_LASTEST_RUN_DATA: 'SET_PIPELINE_LASTEST_RUN_DATA',
  SET_SUBPIPELINES_DATA: 'SET_SUBPIPELINES_DATA',
  SET_SUBPIPELINES_LOADING: 'SET_SUBPIPELINES_LOADING',
  UPDATE_AGING_MODEL: 'UPDATE_AGING_MODEL',
  UPLOAD_PIPELINE_FILE: 'UPLOAD_PIPELINE_FILE'
};
