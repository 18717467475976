import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiPredeterminedProject } from '../../../../api/ApiPredeterminedProject';
import { SCENARIO_PREDETERMINED_PROJECT_ACTIONS_KEY } from '../../../commons/ScenarioPredeterminedProjectConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllScenarioPredeterminedProjectsData() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiPredeterminedProject.fetchAllScenarioPredeterminedProjects, organizationId);
    if (data.length > 0) {
      yield put({
        type: SCENARIO_PREDETERMINED_PROJECT_ACTIONS_KEY.SET_ALL_SCENARIO_PREDETERMINED_PROJECTS,
        scenarioPredeterminedProjects: data
      });
    } else {
      yield put({
        type: SCENARIO_PREDETERMINED_PROJECT_ACTIONS_KEY.SET_ALL_SCENARIO_PREDETERMINED_PROJECTS,
        scenarioPredeterminedProjects: []
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllScenarioPredeterminedProjectsData() {
  yield takeEvery(
    SCENARIO_PREDETERMINED_PROJECT_ACTIONS_KEY.GET_ALL_SCENARIO_PREDETERMINED_PROJECTS,
    getAllScenarioPredeterminedProjectsData
  );
}

export default findAllScenarioPredeterminedProjectsData;
