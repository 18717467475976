// Available TECHNICAL POLICY actions
export const TECHNICAL_POLICY_ACTIONS_KEY = {
  DELETE_TECHNICAL_POLICY: 'DELETE_TECHNICAL_POLICY',
  FIND_TECHNICAL_POLICY_BY_ID: 'FIND_TECHNICAL_POLICY_BY_ID',
  GET_ALL_TECHNICAL_POLICIES_ENUMS: 'GET_ALL_TECHNICAL_POLICIES_ENUMS',
  GET_ALL_TECHNICAL_POLICIES: 'GET_ALL_TECHNICAL_POLICIES',
  GET_TECHNICAL_POLICY: 'GET_TECHNICAL_POLICY',
  GET_ALL_FAMILIES_BY_ASSET_CLASS: 'GET_ALL_FAMILIES_BY_ASSET_CLASS',
  RESET_TECHNICAL_POLICIES_PAGE: 'RESET_TECHNICAL_POLICIES_PAGE',
  RESET_TECHNICAL_POLICY: 'RESET_TECHNICAL_POLICY',
  SET_ALL_TECHNICAL_POLICIES_ENUMS: 'SET_ALL_TECHNICAL_POLICIES_ENUMS',
  SET_ALL_TECHNICAL_POLICIES: 'SET_ALL_TECHNICAL_POLICIES',
  SET_SELECTED_TECHNICAL_POLICY: 'SET_SELECTED_TECHNICAL_POLICY',
  SET_TECHNICAL_POLICIES_ELEMENT_PER_PAGE: 'SET_TECHNICAL_POLICIES_ELEMENT_PER_PAGE',
  SET_TECHNICAL_POLICIES_LOADING: 'SET_TECHNICAL_POLICIES_LOADING',
  SET_TECHNICAL_POLICIES_PAGE_COUNT: 'SET_TECHNICAL_POLICIES_PAGE_COUNT',
  SET_TECHNICAL_POLICIES_PAGE: 'SET_TECHNICAL_POLICIES_PAGE',
  SET_TECHNICAL_POLICIES_SEARCH: 'SET_TECHNICAL_POLICIES_SEARCH',
  SET_TECHNICAL_POLICIES_IMPORT_ERRORS: 'SET_TECHNICAL_POLICIES_IMPORT_ERRORS',
  SET_TECHNICAL_POLICIES_IMPORT_UPLOADING: 'SET_TECHNICAL_POLICIES_IMPORT_UPLOADING',
  SET_TECHNICAL_POLICY: 'SET_TECHNICAL_POLICY',
  TECHNICAL_POLICY_IS_NAME_EXIST: 'TECHNICAL_POLICY_IS_NAME_EXIST',
  UPDATE_ALL_TECHNICAL_POLICIES: 'UPDATE_ALL_TECHNICAL_POLICIES',
  UPDATE_CURRENT_TECHNICAL_POLICY: 'UPDATE_CURRENT_TECHNICAL_POLICY',
  UPDATE_TECHNICAL_POLICY_IS_NAME_AVAILABLE_STATUS: 'UPDATE_TECHNICAL_POLICY_IS_NAME_AVAILABLE_STATUS',
  UPDATE_TECHNICAL_POLICY_IS_NAME_AVAILABLE: 'UPDATE_TECHNICAL_POLICY_IS_NAME_AVAILABLE',
  UPLOAD_TECHNICAL_POLICIES: 'UPLOAD_TECHNICAL_POLICIES'
};
