import CheckCircle from '@mui/icons-material/CheckCircle';
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import InputComponentFactory from 'components/InputComponentFactory';
import { useEffect } from 'react';
import { getValueFromListOrFallback } from 'utils/helpers';

const DEFAULT_MIN_VALUE = 0;
const DEFAULT_STEP_VALUE = 1;

const create = (
  t,
  customClasses,
  parameterData,
  parametersState,
  setParametersState,
  editMode,
  parametersGroupDataId,
  extraConfig
) => {
  const humanResourceList = extraConfig.humanResourceList;
  const selectedHumanResource = extraConfig.selectedHumanResource;
  const setSelectedHumanResource = extraConfig.setSelectedHumanResource;

  const scenarioStart = parametersState['parameter_scenario_global_date_de_debut_scenario']
    ? parametersState['parameter_scenario_global_date_de_debut_scenario']
    : new Date().getFullYear();
  const scenarioEnd = parametersState['parameter_scenario_global_date_de_debut_scenario']
    ? parametersState['parameter_scenario_global_date_de_fin_scenario']
    : new Date().getFullYear();

  // INITIALIZER
  if (
    parameterData.id === 'parameter_scenario_human_resource_list' &&
    parametersState[parameterData.id] &&
    parametersState[parameterData.id].length === 0 &&
    humanResourceList.length > 0
  ) {
    setParametersState({
      ...parametersState,
      [parameterData.id]: [...humanResourceList]
    });
  }

  useEffect(() => {
    if (
      parameterData.id === 'parameter_scenario_human_resource_list' &&
      parametersState[parameterData.id] &&
      humanResourceList.length > 0 &&
      parametersState[parameterData.id].length > 0
    ) {
      syncHumanResourcesWithScenarioDates();
    }
  }, [humanResourceList]);

  // CHECK & SYNC BUDGETS
  if (checkNeedSyncHumanResources()) {
    syncHumanResourcesWithScenarioDates();
  }

  // Function to check if human resources need to be sync according scenario's dates
  function checkNeedSyncHumanResources() {
    if (parametersState[parameterData.id] && parameterData.id === 'parameter_scenario_human_resource_list') {
      return parametersState[parameterData.id].some((humanResource) => {
        // Check if every year in human resource are in scenario's dates
        if (
          Object.keys(humanResource?.AvailableQuantityByYear).some(
            (year) => !(year >= scenarioStart && year <= scenarioEnd)
          )
        ) {
          return true;
        }
        // Check if every year in scenario's date are in human resource
        for (let iYear = scenarioStart; iYear <= scenarioEnd; iYear += 1) {
          if (!Object.keys(humanResource?.AvailableQuantityByYear).some((year) => parseInt(year) === iYear)) {
            return true;
          }
        }
        // All checked are valids, no need to sync
        return false;
      });
    }
    // No need to sync
    return false;
  }

  // Function to update human resources according scenario's dates
  function syncHumanResourcesWithScenarioDates() {
    // Generate updated human resources
    const updatedHumanResources = parametersState[parameterData.id].map((humanResource) => {
      const updatedHumanResource = { ...humanResource };
      const AvailableQuantityByYear = {};
      const MinimalQuantityLevelByYear = {};
      for (let iYear = scenarioStart; iYear <= scenarioEnd; iYear += 1) {
        // Set edited user value from paramsState or default value from api to all years in scenario
        AvailableQuantityByYear[iYear] =
          humanResource?.AvailableQuantityByYear[iYear] &&
          humanResourceList &&
          !!humanResourceList.length &&
          Object.keys(humanResourceList[0].AvailableQuantityByYear)[0]
            ? humanResource?.AvailableQuantityByYear[iYear]
            : // if scenarioDate not present in api data, autofill with last know value
              getValueFromListOrFallback(iYear, humanResource, humanResourceList);

        MinimalQuantityLevelByYear[iYear] = humanResource.MinimalQuantityLevelByYear[iYear]
          ? humanResource.MinimalQuantityLevelByYear[iYear]
          : 0;
      }
      updatedHumanResource.AvailableQuantityByYear = AvailableQuantityByYear;
      updatedHumanResource.MinimalQuantityLevelByYear = MinimalQuantityLevelByYear;
      return updatedHumanResource;
    });

    // Update human resources
    setParametersState({
      ...parametersState,
      [parameterData.id]: [...updatedHumanResources]
    });

    // Update selected human resource
    if (selectedHumanResource) {
      const updatedSelectedHumanResource = updatedHumanResources.find(
        (humanResource) => humanResource.$dtId === selectedHumanResource.$dtId
      );
      if (updatedSelectedHumanResource) {
        setSelectedHumanResource(updatedSelectedHumanResource);
      }
    }
  }

  const displayDetails = selectedHumanResource !== null;
  const displayAvailableQuantityByYear =
    selectedHumanResource?.Constrained && parametersState?.parameter_scenario_global_contrainte_rh;

  // UPDATE
  // Function to update selectedHumanResource and HumanResourceList
  function updateSelectedHumanResource(humanResource) {
    setParametersState({
      ...parametersState,
      [parameterData.id]: [
        ...parametersState[parameterData.id].map((f) => (f.$dtId === selectedHumanResource.$dtId ? humanResource : f))
      ]
    });
    setSelectedHumanResource(humanResource);
  }

  // GETTERS
  // Function to get start year of fast filling feature
  function getStartYear() {
    return selectedHumanResource.StartYear ? selectedHumanResource.StartYear : getDefaultStartYear();
  }

  // Function to get end year of fast filling feature
  function getEndYear() {
    return selectedHumanResource.EndYear ? selectedHumanResource.EndYear : getDefaultEndYear();
  }

  // Function to get default start year of fast filling feature by getting the lowest year in AvailableQuantityByYear
  function getDefaultStartYear() {
    return parseInt(
      Object.keys(selectedHumanResource.AvailableQuantityByYear).sort((yearA, yearB) => (yearA < yearB ? -1 : 1))[0]
    );
  }

  // Function to get default start year of fast filling feature by getting the highest year in AvailableQuantityByYear
  function getDefaultEndYear() {
    return parseInt(
      Object.keys(selectedHumanResource.AvailableQuantityByYear).sort((yearA, yearB) => (yearA < yearB ? 1 : -1))[0]
    );
  }

  // Function to get AvailableQuantityYear sorted by year
  function getSortedAvailableQuantityByYear() {
    return Object.keys(selectedHumanResource.AvailableQuantityByYear).sort((yearA, yearB) => (yearA < yearB ? -1 : 1));
  }

  function createTableByYearsFromScenarioDates() {
    const table = [];
    const sortedAvailableQuantityByYear = getSortedAvailableQuantityByYear();
    for (let iYear = scenarioStart; iYear <= scenarioEnd; iYear += 1) {
      sortedAvailableQuantityByYear[iYear] ? table.push(sortedAvailableQuantityByYear[iYear]) : table.push(iYear);
    }
    return table;
  }

  // Function to get CustomBudget value of fast fill
  function getCustomBudget() {
    return selectedHumanResource?.CustomBudget ? selectedHumanResource.CustomBudget : 0;
  }

  // HANDLERS
  // Function to handle click on HumanResource
  function handleClickItem(humanResource) {
    if (humanResource.$dtId === selectedHumanResource?.$dtId) {
      setSelectedHumanResource(null);
    } else {
      setSelectedHumanResource(humanResource);
    }
  }

  // Function to handle change of Constrained value
  function handleChangeConstrained() {
    updateSelectedHumanResource({
      ...selectedHumanResource,
      Constrained: !selectedHumanResource.Constrained
    });
  }

  // Function to handle change of CostRate value
  function handleChangeCostRate(CostRate) {
    updateSelectedHumanResource({
      ...selectedHumanResource,
      CostRate
    });
  }

  // Function to handle change of StartYear value to set fast filling feature
  function handleChangeStartYear(StartYear) {
    if (
      !StartYear ||
      StartYear < getDefaultStartYear() ||
      StartYear > (selectedHumanResource.EndYear ? selectedHumanResource.EndYear : getDefaultEndYear())
    ) {
      updateSelectedHumanResource({
        ...selectedHumanResource,
        StartYear: getDefaultStartYear()
      });
    } else {
      updateSelectedHumanResource({
        ...selectedHumanResource,
        StartYear: parseInt(StartYear)
      });
    }
  }

  // Function to handle change of EndYear value to set fast filling
  function handleChangeEndYear(EndYear) {
    if (
      !EndYear ||
      EndYear > getDefaultEndYear() ||
      EndYear < (selectedHumanResource.StartYear ? selectedHumanResource.StartYear : getDefaultStartYear())
    ) {
      updateSelectedHumanResource({
        ...selectedHumanResource,
        EndYear: getDefaultEndYear()
      });
    } else {
      updateSelectedHumanResource({
        ...selectedHumanResource,
        EndYear: parseInt(EndYear)
      });
    }
  }

  // Function to handle change of CustomBudget value to set fast filling feature
  function handleChangeCustomBudget(CustomBudget) {
    updateSelectedHumanResource({
      ...selectedHumanResource,
      CustomBudget: CustomBudget ? parseFloat(CustomBudget) : 0
    });
  }

  // Function to handle click on fast filling feature button
  function handleClickFastFilling() {
    let newAvailableQuantityByYear = {
      ...selectedHumanResource.AvailableQuantityByYear
    };
    Object.keys(newAvailableQuantityByYear).forEach((year) => {
      if (
        parseInt(year) >= (selectedHumanResource.StartYear ? selectedHumanResource.StartYear : getDefaultStartYear()) &&
        parseInt(year) <= (selectedHumanResource.EndYear ? selectedHumanResource.EndYear : getDefaultEndYear())
      ) {
        newAvailableQuantityByYear = {
          ...newAvailableQuantityByYear,
          [year]: getCustomBudget()
        };
      }
    });
    updateSelectedHumanResource({
      ...selectedHumanResource,
      AvailableQuantityByYear: {
        ...selectedHumanResource.AvailableQuantityByYear,
        ...newAvailableQuantityByYear
      }
    });
  }

  // Function to handle change of Quantity per Year
  function handleChangeYearQuantity(Year, Quantity) {
    updateSelectedHumanResource({
      ...selectedHumanResource,
      AvailableQuantityByYear: {
        ...selectedHumanResource.AvailableQuantityByYear,
        [Year]: parseInt(Quantity)
      }
    });
  }

  return (
    <Grid
      alignContent='flex-start'
      container={true}
      direction='row'
      justifyContent='flex-start'
      key={`grid-container-${parametersGroupDataId}`}
      spacing={0}
    >
      <Grid item={true} key={`grid-col-0-${parametersGroupDataId}`} md={3}>
        {parametersState[parameterData.id] && parametersState[parameterData.id].length > 0 && (
          <Grid
            item={true}
            key={`grid-col-0-row-0-${parametersGroupDataId}parameter_scenario_human_resource_selected`}
            md={12}
            style={{ maxHeight: '351px', overflow: 'auto' }}
          >
            <List disabled={!editMode} style={{ maxHeight: '100%', overflow: 'auto' }}>
              {parametersState[parameterData.id].map((humanResource, index) => (
                <ListItem
                  button={true}
                  divider={index + 1 !== parametersState[parameterData.id].length}
                  key={`list-item-${humanResource.$dtId}`}
                  selected={selectedHumanResource?.$dtId === humanResource.$dtId}
                  onClick={() => handleClickItem(humanResource)}
                >
                  <ListItemText key={`list-item-text-${humanResource.$dtId}`} primary={humanResource.$dtId} />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
      </Grid>
      <Grid item={true} key={`grid-col-1-${parametersGroupDataId}`} md={9}>
        {displayDetails ? (
          <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column', gap: '2rem' }}>
            <Grid
              alignContent='flex-start'
              alignItems='center'
              container={true}
              direction='row'
              justifyContent='flex-start'
              key={`grid-col-1-row-0-${parametersGroupDataId}`}
              spacing={0}
            >
              <Grid item={true} key={`grid-col-1-row-0-${parametersGroupDataId}categories`} md={2}>
                <Typography
                  className={!editMode ? customClasses.textDisabled : null}
                  id={`input-label-id${parametersGroupDataId}cat-key`}
                >
                  {t('commoncomponents.scenario-form.human-resource-tab.categories')}
                </Typography>
              </Grid>
              <Grid item={true} key={`grid-col-1-row-0-${parametersGroupDataId}categories-values`} md={10}>
                <ButtonGroup disabled={!editMode} style={{ display: 'flex', flexWrap: 'wrap' }} variant='text'>
                  {Object.keys(selectedHumanResource.CatKeyLabels).map((catKey) => (
                    <Button
                      disabled={true}
                      key={`${parametersGroupDataId}categories-values${selectedHumanResource.CatKeyLabels[catKey]}`}
                    >
                      {selectedHumanResource.CatKeyLabels[catKey]}
                    </Button>
                  ))}
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid
              alignContent='flex-start'
              alignItems='center'
              container={true}
              direction='row'
              justifyContent='flex-start'
              key={`grid-col-1-row-1-${parametersGroupDataId}`}
              spacing={1}
            >
              <Grid
                item={true}
                key={`grid-col-1-row-1-${parametersGroupDataId}-daily-cost`}
                md={6}
                style={{ maxWidth: '15rem' }}
              >
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        {t('commoncomponents.scenario-form.human-resource-tab.prefix-euro')}
                      </InputAdornment>
                    )
                  }}
                  disabled={!editMode}
                  fullWidth={true}
                  inputProps={{
                    min: DEFAULT_MIN_VALUE,
                    step: 10
                  }}
                  key={`${parametersGroupDataId}-daily-cost`}
                  label={t('commoncomponents.scenario-form.human-resource-tab.daily-cost')}
                  type='number'
                  value={selectedHumanResource.CostRate ? selectedHumanResource.CostRate : DEFAULT_MIN_VALUE}
                  variant='standard'
                  onChange={(event) => handleChangeCostRate(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              alignContent='flex-start'
              alignItems='center'
              container={true}
              direction='row'
              justifyContent='flex-start'
              key={`grid-col-1-row-2-${parametersGroupDataId}`}
              spacing={1}
            >
              <Grid
                item={true}
                key={`grid-col-1-row-2-${parametersGroupDataId}contrainte-etp`}
                md={6}
                style={{ display: 'true' }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedHumanResource.Constrained}
                      color='primary'
                      onChange={() => handleChangeConstrained()}
                    />
                  }
                  disabled={!(parametersState?.parameter_scenario_global_contrainte_rh && editMode)}
                  key={`${parametersGroupDataId}-contrainte-etp`}
                  label={t('commoncomponents.scenario-form.human-resource-tab.etp-constraint')}
                  labelPlacement='start'
                  style={{ marginLeft: '0px' }}
                />
              </Grid>
            </Grid>
            {displayAvailableQuantityByYear && (
              <Grid
                alignContent='flex-start'
                container={true}
                direction='row'
                justifyContent='flex-start'
                key={`grid-col-1-row-3-${parametersGroupDataId}`}
                spacing={1}
              >
                <Grid item={true} key={`grid-col-1-row-3-${parametersGroupDataId}-budget-array`} md={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead className={customClasses.tableHeader}>
                        <TableRow>
                          <TableCell align='center' style={{ width: '20px' }}>
                            {t('commoncomponents.scenario-form.human-resource-tab.years')}
                          </TableCell>
                          {createTableByYearsFromScenarioDates().map((year, index) => (
                            <TableCell align='center' key={`table-cell-etp-year-${index}`} style={{ minWidth: '5rem' }}>
                              {year}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          className={
                            !editMode
                              ? customClasses.tableRowBackgroundDisabled
                              : customClasses.tableRowBackgroundEnabled
                          }
                        >
                          <TableCell align='center' style={{ width: '20px' }}>
                            {t('commoncomponents.scenario-form.human-resource-tab.budget')}
                          </TableCell>
                          {createTableByYearsFromScenarioDates().map((year, index) => (
                            <TableCell
                              align='center'
                              key={`table-cell-etp-value-${index}`}
                              style={{
                                minWidth: `${
                                  selectedHumanResource?.AvailableQuantityByYear[year]?.toString().length * 0.8 || 1
                                }rem`
                              }}
                            >
                              <TextField
                                disabled={!editMode}
                                inputProps={{
                                  min: DEFAULT_MIN_VALUE,
                                  step: DEFAULT_STEP_VALUE
                                }}
                                key={`${parametersGroupDataId}-startYear`}
                                type='number'
                                value={selectedHumanResource.AvailableQuantityByYear[year]}
                                variant='standard'
                                onChange={(event) => handleChangeYearQuantity(year, event.target.value)}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
            {displayAvailableQuantityByYear && (
              <Grid
                alignContent='flex-start'
                alignItems='center'
                container={true}
                direction='row'
                justifyContent='flex-start'
                key={`grid-col-1-row-4-${parametersGroupDataId}`}
                spacing={1}
              >
                <Grid item={true} key={`grid-col-1-row-4-${parametersGroupDataId}fast-filling`} md={3}>
                  <Typography
                    className={!editMode ? customClasses.textDisabled : null}
                    id={`input-label-id${parametersGroupDataId}fast-filling`}
                  >
                    {t('commoncomponents.scenario-form.human-resource-tab.fast-filling')}
                  </Typography>
                </Grid>
                <Grid item={true} key={`grid-col-1-row-4-${parametersGroupDataId}-startYear`} md={2}>
                  <InputComponentFactory
                    editProps={{
                      type: 'dateYear',
                      placeholder: t('commoncomponents.scenario-form.human-resource-tab.start'),
                      maxDate: selectedHumanResource.EndYear ? selectedHumanResource.EndYear : getDefaultEndYear(),
                      disabled: !editMode
                    }}
                    key={`${parametersGroupDataId}-startYear`}
                    value={getStartYear()}
                    onChange={(newValue) => handleChangeStartYear(newValue)}
                  />
                </Grid>
                <Grid item={true} key={`grid-col-1-row-4-${parametersGroupDataId}-end-year`} md={2}>
                  <InputComponentFactory
                    editProps={{
                      type: 'dateYear',
                      placeholder: t('commoncomponents.scenario-form.human-resource-tab.end'),
                      minDate: selectedHumanResource.StartYear
                        ? selectedHumanResource.StartYear
                        : getDefaultStartYear(),
                      disabled: !editMode
                    }}
                    key={`${parametersGroupDataId}-endYear`}
                    value={getEndYear()}
                    onChange={(newValue) => handleChangeEndYear(newValue)}
                  />
                </Grid>
                <Grid item={true} key={`grid-col-1-row-4-${parametersGroupDataId}-etp`} md={2}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          {t('commoncomponents.scenario-form.human-resource-tab.prefix-custom-hr')}
                        </InputAdornment>
                      ),
                      min: DEFAULT_MIN_VALUE,
                      step: DEFAULT_STEP_VALUE
                    }}
                    disabled={!editMode}
                    fullWidth={true}
                    key={`${parametersGroupDataId}-etp`}
                    label={t('commoncomponents.scenario-form.human-resource-tab.etp')}
                    type='number'
                    value={getCustomBudget()}
                    variant='standard'
                    onChange={(event) => handleChangeCustomBudget(event.target.value)}
                  />
                </Grid>
                <Grid item={true} key={`grid-col-1-row-4-${parametersGroupDataId}-apply-fast-filling`} md={1}>
                  <IconButton
                    aria-label={t('commoncomponents.scenario-form.human-resource-tab.apply-fast-filling')}
                    disabled={!editMode}
                    size='large'
                    onClick={() => handleClickFastFilling()}
                  >
                    <CheckCircle color={!editMode ? 'disabled' : 'primary'} />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </div>
        ) : (
          <Paper className={customClasses.unselectItemBox} elevation={0}>
            <Typography className={customClasses.unselectItemText}>
              {t('commoncomponents.scenario-form.human-resource-tab.unselected-item')}
            </Typography>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export const HumanResourceListFactory = {
  create
};
