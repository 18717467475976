import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Card, CardActions, CardContent, Collapse, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'theme';
import { StringUtils } from 'utils/helpers';
import OperationRow from './OperationRow.component';

const OperationListCard = ({
  getTechnicalPolicy,
  getPredeterminedProject,
  getOperation,
  operation,
  operationIndex,
  setShowDeleteOperation
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  function openEditOperation(operation) {
    const { Id, id } = operation;
    getOperation(Id || id);
    navigate({
      pathname: `/operation${Id || id ? `/${Id || id}` : ''}`
    });
  }

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  return (
    <Card className={classes.operationItem}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between'
        }}
      >
        <Tooltip title={StringUtils.removesUnderscoreReplaceSpace(operation.$dtId)}>
          <div
            className={classes.title}
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {StringUtils.removesUnderscoreReplaceSpace(operation.$dtId)}
          </div>
        </Tooltip>
        <CardActions disableSpacing={true}>
          <div style={{ display: 'flex' }}>
            <Button variant='text' onClick={() => openEditOperation(operation)}>
              <EditIcon />
            </Button>
            <Button
              disabled={operation.technicalPolicies.length > 0 || operation.predeterminedProjects.length > 0}
              variant='text'
              onClick={() => setShowDeleteOperation(operationIndex)}
            >
              <DeleteIcon />
            </Button>
          </div>
          <IconButton
            aria-expanded={expanded}
            aria-label='show more'
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            size='large'
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon style={{ color: theme.palette.nexans.primary }} />
          </IconButton>
        </CardActions>
      </div>

      <Collapse in={expanded} timeout='auto' unmountOnExit={true}>
        <CardContent>
          <OperationRow
            getPredeterminedProject={getPredeterminedProject}
            getTechnicalPolicy={getTechnicalPolicy}
            operation={operation}
            options={{ backgroundColor: theme.palette.nexans.lightGreyBg }}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
};
export default OperationListCard;

OperationListCard.propTypes = {
  getTechnicalPolicy: PropTypes.func.isRequired,
  getPredeterminedProject: PropTypes.func.isRequired,
  getOperation: PropTypes.func.isRequired,
  operation: PropTypes.object.isRequired,
  operationIndex: PropTypes.number.isRequired,
  setShowDeleteOperation: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
  title: {
    alignSelf: 'center',
    fontSize: '1.2rem'
  },
  operationItem: {
    alignItems: 'center',
    backgroundColor: theme.palette.nexans.white,
    margin: '1rem 0',
    fontSize: 14,
    color: theme.palette.text.black
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}));
