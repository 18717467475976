import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Box, Button, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AssetRegistryFamiliesCardList, AssetRegistryFamilyDetailTab, AssetRegistryInfoBar } from 'components';
import { SearchButton } from 'components/SearchButton';
import SubsetList from 'components/Subset/SubsetList.component';
import { ENABLE_SUBSET_FEATURE } from 'config/AppInstance';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAssetGetAllAssetsKpi } from 'state/hooks/Asset.hooks';

const TABS = {
  ASSET_LIST: 0,
  SUBSET: 1
};

function AssetRegistry(props) {
  const classes = useStyles();
  const {
    assetMetrics,
    familyList,
    selectedAsset,
    agingModel,

    getAsset,
    getAllAssetList,
    setAllFamiliesSearch,
    setSelectedAssetSearch,
    resetSelectedAssetSearch
  } = props;
  const { t } = useTranslation();
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [tabToDisplay, setTabToDisplay] = useState(TABS.ASSET_LIST);

  const getAssetsKPI = useAssetGetAllAssetsKpi();

  useEffect(() => {
    getAssetsKPI();
  }, [getAssetsKPI]);

  const displayAssetRegistryFamilyTab = useMemo(
    () => selectedFamily !== null && selectedAsset.data,
    [selectedFamily, selectedAsset.data]
  );

  const handleTabChange = useCallback((event, newValue) => {
    setTabToDisplay(newValue);
  }, []);

  const getSearchValue = useCallback(
    () => (displayAssetRegistryFamilyTab ? selectedAsset.search : familyList.search),
    [displayAssetRegistryFamilyTab, selectedAsset.search, familyList.search]
  );

  const onChangeSearch = useCallback(
    (search) => {
      if (displayAssetRegistryFamilyTab) {
        setSelectedAssetSearch(search);
      } else {
        setAllFamiliesSearch(search);
      }
    },
    [displayAssetRegistryFamilyTab, setSelectedAssetSearch, setAllFamiliesSearch]
  );

  const onClickSearch = useCallback(() => {
    if (displayAssetRegistryFamilyTab) {
      getAsset(selectedFamily);
    } else {
      getAllAssetList();
    }
  }, [displayAssetRegistryFamilyTab, getAsset, selectedFamily, getAllAssetList]);

  useEffect(() => {
    const controller = new AbortController();
    if (selectedFamily) getAsset(selectedFamily, { signal: controller.signal });
    return () => controller.abort(); // cancel the previous request if the component is unmounted or the selectedFamily changes
  }, [getAsset, selectedFamily]);

  return (
    <div className={classes.root}>
      <div className={classes.flexBox}>
        {!selectedFamily && (
          <AssetRegistryInfoBar assetAgingModel={familyList.data[0]?.AgingMethodology} assetMetrics={assetMetrics} />
        )}
        {selectedFamily && (
          <Button
            className='backBtn'
            color='primary'
            data-testid='back-button'
            variant='contained'
            onClick={() => setSelectedFamily(null)}
          >
            <ArrowBackIcon /> {t('layouts.back')}
          </Button>
        )}
        <h2 className={selectedFamily ? 'familyTitle' : ''} data-testid='family-title'>
          {selectedFamily || ''}
        </h2>
        {tabToDisplay === TABS.ASSET_LIST && (
          <div className={classes.flexBoxButtons}>
            <SearchButton
              value={getSearchValue()}
              onChange={(event) => onChangeSearch(event.target.value)}
              onSearch={onClickSearch}
            />
          </div>
        )}
      </div>
      {!displayAssetRegistryFamilyTab && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs aria-label='asset registry tabs' value={tabToDisplay} onChange={handleTabChange}>
            <Tab data-testid='asset-list-tab' label={t('views.assetRegistry.assetList')} />
            {ENABLE_SUBSET_FEATURE === 'true' && <Tab data-testid='subset-tab' label='Subset' />}
          </Tabs>
        </Box>
      )}
      {tabToDisplay === TABS.ASSET_LIST &&
        (displayAssetRegistryFamilyTab ? (
          <AssetRegistryFamilyDetailTab
            asset={selectedAsset.data}
            assetAgingModel={agingModel}
            data-testid='asset-detail-tab'
            id={selectedFamily}
            isSelectedAssetLoading={selectedAsset.isLoading}
          />
        ) : (
          <AssetRegistryFamiliesCardList
            data-testid='asset-card-list'
            familyList={familyList.data}
            kpiByFamily={assetMetrics.groupedByFamily}
            selectFamily={(id) => {
              resetSelectedAssetSearch();
              setSelectedFamily(id);
            }}
          />
        ))}
      {tabToDisplay === TABS.SUBSET && <SubsetList />}
    </div>
  );
}

AssetRegistry.propTypes = {
  assetMetrics: PropTypes.shape({
    averageApparentAge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    countComponent: PropTypes.number,
    countFamily: PropTypes.number,
    countGroup: PropTypes.number,
    groupedByFamily: PropTypes.array,
    healthScoreAvg: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  familyList: PropTypes.shape({
    data: PropTypes.array,
    search: PropTypes.string
  }).isRequired,
  selectedAsset: PropTypes.shape({
    data: PropTypes.array,
    elementPerPage: PropTypes.number,
    isLoading: PropTypes.bool,
    kpi: PropTypes.object,
    page: PropTypes.number,
    pageCount: PropTypes.number,
    search: PropTypes.string
  }).isRequired,
  agingModel: PropTypes.string.isRequired,

  getAsset: PropTypes.func.isRequired,
  getAllAssetList: PropTypes.func.isRequired,
  setAllFamiliesSearch: PropTypes.func.isRequired,
  setSelectedAssetSearch: PropTypes.func.isRequired,
  resetSelectedAssetSearch: PropTypes.func.isRequired
};

export default AssetRegistry;

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    width: '100%',
    height: '100%',
    paddingTop: '1rem'
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '2rem',
    padding: '0 1.2rem'
  },
  flexBoxButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  }
}));
