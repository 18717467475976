import { Button, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
import OperationsListContent from 'components/OperationsList/OperationsListContent.component';
import { SearchButton } from 'components/SearchButton';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetOperationsListWithOrphans } from 'state/hooks/Operation.hooks';
import theme from 'theme';

function OperationsList(props) {
  const {
    assetClassesList,
    deleteOperation,
    getOperation,
    getPredeterminedProject,
    getTechnicalPolicy,
    operationsByAssetClass,
    selectedAssetClass,
    setSelectedAssetClass,
    setOperationByAssetClassPage,
    setOperationByAssetClassSearch
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [operationWithoutAssetClass, setOperationWithoutAssetClass] = useState([]);
  const [displayOperationsWithoutAssetClass, setDisplayOperationsWithoutAssetClass] = useState(false);
  const [operationSearch, setOperationSearch] = useState(operationsByAssetClass.search);

  useEffect(() => {
    setOperationSearch(operationsByAssetClass.search);
  }, [operationsByAssetClass.search]);

  async function showOperationsWithoutAssetClass() {
    const operationsListWithoutOrphans = await useGetOperationsListWithOrphans();
    setOperationWithoutAssetClass(operationsListWithoutOrphans);

    setDisplayOperationsWithoutAssetClass(true);
  }

  function showAllOperationsByAssetClass(assetClass) {
    setDisplayOperationsWithoutAssetClass(false);
    setSelectedAssetClass(assetClass);
  }

  const operationsListToDisplay = displayOperationsWithoutAssetClass
    ? operationWithoutAssetClass
    : operationsByAssetClass.data;

  const onSearch = () => {
    setOperationByAssetClassSearch(operationSearch);
  };

  return (
    <div className={classes.operationContainer}>
      <div className={classes.assetClassContainer}>
        <h2 className='sub-title'>{t('views.library.operation.assetClass')}</h2>
        <Tabs
          TabIndicatorProps={{
            style: {
              display: 'none'
            }
          }}
          className={classes.assetClassesListTab}
          orientation='vertical'
          value={assetClassesList.findIndex((assetClass) => assetClass === selectedAssetClass)}
          variant='fullWidth'
        >
          {assetClassesList.map((assetClass, index) => (
            <Tab
              aria-controls={`vertical-tab-${assetClass}`}
              id={assetClass}
              key={`tab-${assetClass[index]}-${index}`}
              label={assetClass}
              sx={{ alignItems: 'flex-start' }}
              onClick={() => showAllOperationsByAssetClass(assetClass)}
            />
          ))}
        </Tabs>
        {operationWithoutAssetClass?.length > 0 && (
          <div>
            <Divider style={{ width: '40%', marginBottom: '1rem' }} />
            <Button style={{ backgroundColor: 'white' }} onClick={() => showOperationsWithoutAssetClass()}>
              {t('views.library.operation.operationWithoutAssetClass')}
            </Button>
          </div>
        )}
      </div>
      <Stack className={classes.operationContent}>
        <div className={classes.operationHeader}>
          <Grid alignItems='start' container justifyContent='space-between' spacing={2}>
            <Grid item xs={8}>
              <h2 className='sub-title'>
                {t('views.library.operation.operationsList', {
                  className: selectedAssetClass
                })}
              </h2>
            </Grid>
            <Grid item xs={4}>
              <SearchButton
                placeholder={t('views.library.operation.searchOperation')}
                value={operationSearch}
                onChange={(evt) => setOperationSearch(evt.target.value)}
                onSearch={onSearch}
              />
            </Grid>
          </Grid>
        </div>
        {operationsListToDisplay?.length > 0 && (
          <>
            <Box>
              <Pagination
                className={classes.pagination}
                count={operationsByAssetClass.totalPage}
                disabled={operationsByAssetClass.isLoading}
                page={operationsByAssetClass.page}
                showFirstButton
                showLastButton
                onChange={(_, newPage) => {
                  setOperationByAssetClassPage(newPage);
                }}
              />
            </Box>

            <div className={classes.operationsList}>
              <OperationsListContent
                deleteOperation={deleteOperation}
                getOperation={getOperation}
                getPredeterminedProject={getPredeterminedProject}
                getTechnicalPolicy={getTechnicalPolicy}
                operationsList={operationsListToDisplay}
                operationsListLoading={operationsByAssetClass.isLoading}
              />
            </div>
          </>
        )}
        {operationsListToDisplay?.length === 0 && (
          <Typography className={classes.pagination} variant='h5'>
            {t('views.library.operation.noResults')}
          </Typography>
        )}
      </Stack>
    </div>
  );
}

OperationsList.propTypes = {
  assetClassesList: PropTypes.array.isRequired,
  selectedAssetClass: PropTypes.string.isRequired,
  operationsByAssetClass: PropTypes.object.isRequired,
  deleteOperation: PropTypes.func.isRequired,
  getOperation: PropTypes.func.isRequired,
  getTechnicalPolicy: PropTypes.func.isRequired,
  getPredeterminedProject: PropTypes.func.isRequired,
  setSelectedAssetClass: PropTypes.func.isRequired,
  refetchOperationsList: PropTypes.func.isRequired,
  setOperationByAssetClassPage: PropTypes.func.isRequired,
  setOperationByAssetClassSearch: PropTypes.func.isRequired
};

export default OperationsList;

const useStyles = makeStyles(() => ({
  operationContainer: {
    width: '90%',
    display: 'flex',
    margin: '24px auto',
    justifyContent: 'space-between'
  },
  assetClassContainer: {
    width: '40%',
    '& h2': {
      marginBottom: '1.3rem'
    }
  },
  assetClassesListTab: {
    width: '95%',
    '& button': {
      minWidth: '100%',
      padding: 12,
      color: theme.palette.text.black,
      '& span': {
        fontSize: 16,
        textTransform: 'initial',
        alignItems: 'flex-start'
      },
      '&.Mui-selected': {
        background: theme.palette.background.gradient,
        color: theme.palette.text.white
      }
    }
  },
  operationHeader: {
    marginBottom: '4px'
  },
  operationContent: {
    width: '60%',
    height: '80vh'
  },
  operationsList: {
    overflowY: 'scroll'
  },
  exportBtn: {
    marginRight: '1rem'
  },
  pagination: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: '16px',
    marginBottom: '16px',
    '& button': {
      color: theme.palette.primary.white,
      '&.Mui-selected': {
        color: theme.palette.primary.primary
      }
    }
  }
}));
