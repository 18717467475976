import { TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

export default function FinanceTableHeader({ type, headersYears }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <TableHead className={classes.headerTableContainer}>
      <TableRow>
        <TableCell align='center' style={{ width: '20px', color: 'white' }}>
          {t('commoncomponents.scenario-form.finance-tab.arr-years')}
        </TableCell>
        {headersYears.map((year, index) => (
          <TableCell
            align='center'
            style={{ minWidth: '5rem', color: 'white' }}
            key={`table-header-cell-finance-${type}-year-${index}`}
            data-testid={`finance-${type}-table-header-cell-year-${year}`}
          >
            {year}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

FinanceTableHeader.propTypes = {
  type: PropTypes.string.isRequired,
  headersYears: PropTypes.array.isRequired
};

const useStyles = makeStyles(() => ({
  headerTableContainer: {
    background: theme.palette.nexans.primary
  }
}));
