import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Card, CardContent, Grid } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { OperationSelector, SimpleTwoActionsDialogContent } from 'components';
import InputComponentFactory from 'components/InputComponentFactory';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAssetClasses, useOperationsByAssetClass, useSetSelectedAssetClass } from 'state/hooks/AssetClass.hooks';
import { useBudgetCategoriesEnum } from 'state/hooks/Budget.hooks';
import {
  useCheckIfProjectNameExists,
  useGetAssetGroupByAssetClass,
  useIsProjectNameAvailable,
  useProjectAssetGroupByAssetClass,
  useSelectedProject
} from 'state/hooks/Project.hooks';

import { useSetOperationsByAssetClassSearch } from 'state/hooks/Operation.hooks';

import theme from 'theme';

function PredeterminedProjectForm({ applyUpdate, cancelUpdate }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showMissingFields, setShowMissingfields] = useState(false);

  const assetClassesList = useAssetClasses();
  const assetGroupsByAssetClass = useProjectAssetGroupByAssetClass();
  const operationsByAssetClass = useOperationsByAssetClass();
  const selectedPredeterminedProject = useSelectedProject();
  const getPredeterminedProjectAssetGroupByAssetClass = useGetAssetGroupByAssetClass();
  const setSelectedAssetClass = useSetSelectedAssetClass();

  const checkIsNameExist = useCheckIfProjectNameExists();
  const projectNameAvailable = useIsProjectNameAvailable();
  const isNameAvailable = projectNameAvailable.data;
  const isNameAvailableStatus = projectNameAvailable.status;
  const budgetCategoriesEnum = useBudgetCategoriesEnum();
  const setOperationByAssetClassSearch = useSetOperationsByAssetClassSearch();

  const [predeterminedProjectForm, setPredeterminedProjectForm] = useState({
    $dtId: selectedPredeterminedProject.$dtId,
    $etag: selectedPredeterminedProject.$etag,
    AssetClass: selectedPredeterminedProject.AssetClass,
    OnEquipmentGroup: selectedPredeterminedProject.OnEquipmentGroup,
    BudgetCategories: selectedPredeterminedProject.BudgetCategories,
    id: selectedPredeterminedProject.id,
    IsInactive: selectedPredeterminedProject.IsInactive,
    PlannedDate: selectedPredeterminedProject.PlannedDate,
    Operation: selectedPredeterminedProject.Operation
  });

  useEffect(() => {
    setPredeterminedProjectForm(selectedPredeterminedProject);
  }, [selectedPredeterminedProject]);

  useEffect(() => {
    if (predeterminedProjectForm.AssetClass) {
      setSelectedAssetClass(predeterminedProjectForm.AssetClass);
      getPredeterminedProjectAssetGroupByAssetClass(predeterminedProjectForm.AssetClass);
    }
  }, [predeterminedProjectForm.AssetClass]);

  const getPredeterminedProjectAssetClasses = () =>
    assetClassesList.map((assetClass) => ({
      key: assetClass,
      value: assetClass
    }));

  const getPredeterminedProjectBudgetCategories = () =>
    budgetCategoriesEnum.map((category) => ({
      key: category,
      value: category
    }));

  const getPredeterminedProjectOperationsFromAssetClass = useCallback(
    () => operationsByAssetClass.map((operation) => operation.$dtId),
    [operationsByAssetClass]
  );

  const updatePredeterminedProjectForm = (field, value) => {
    const newValue = value;

    switch (field) {
      case '$dtId': {
        const newProject = {
          ...predeterminedProjectForm,
          [field]: newValue
        };
        // If edit existing form, spare checkName
        if (selectedPredeterminedProject.$dtId !== newProject.$dtId) {
          checkIsNameExist(value, selectedPredeterminedProject, newProject);
        }
        setPredeterminedProjectForm(newProject);
        break;
      }
      default:
        setPredeterminedProjectForm({
          ...predeterminedProjectForm,
          [field]: newValue
        });
        break;
    }
  };

  const isFormValid = () =>
    predeterminedProjectForm.$dtId &&
    isNameAvailable &&
    isNameAvailableStatus &&
    predeterminedProjectForm.PlannedDate &&
    predeterminedProjectForm.PlannedDate >= '1900-01-01' &&
    predeterminedProjectForm.PlannedDate <= '2200-01-01' &&
    predeterminedProjectForm.Operation &&
    predeterminedProjectForm.OnEquipmentGroup;

  function getErrorMessage() {
    if (!isNameAvailable) {
      return t('views.library.predeterminedproject.alreadyExist');
    }
    return null;
  }

  const getMissingFields = () => {
    let missingFields = [];
    if (!predeterminedProjectForm.$dtId)
      missingFields.push(t('genericcomponent.dialog.missingFields.predeterminedProject.$dtId'));
    if (!predeterminedProjectForm.PlannedDate)
      missingFields.push(t('genericcomponent.dialog.missingFields.predeterminedProject.plannedDate'));
    if (!predeterminedProjectForm.AssetClass)
      missingFields.push(t('genericcomponent.dialog.missingFields.predeterminedProject.assetClass'));
    if (!predeterminedProjectForm.OnEquipmentGroup)
      missingFields.push(t('genericcomponent.dialog.missingFields.predeterminedProject.onEquipmentGroup'));
    if (!predeterminedProjectForm.Operation)
      missingFields.push(t('genericcomponent.dialog.missingFields.predeterminedProject.operation'));
    if (getErrorMessage()) {
      missingFields.push(t('views.library.predeterminedproject.alreadyExistLower'));
    }
    if (missingFields.length > 1) {
      const regexp = new RegExp(/, (?![\s\S]*, )/);
      missingFields =
        t('genericcomponent.dialog.missingFields.introduction') +
        missingFields.join(', ').replace(regexp, t('genericcomponent.dialog.missingFields.and'));
    } else if (missingFields.length === 1) {
      missingFields = t('genericcomponent.dialog.missingFields.introduction') + missingFields[0];
    } else {
      missingFields = '';
    }
    return missingFields;
  };

  const validateForm = () => {
    if (isFormValid()) {
      applyUpdate(predeterminedProjectForm);
    } else {
      setShowMissingfields(true);
    }
  };

  return (
    <Card className={classes.cardForm}>
      {selectedPredeterminedProject && (
        <CardContent>
          <h2 className='sub-title'>{t('views.library.predeterminedproject.generalParameters')}</h2>

          <Grid className={classes.formContainer} container={true} key='predetermined-project-form-row-0'>
            <Grid container={true} justifyContent='space-between' spacing={1}>
              <Grid className={classes.gridForm} item={true} key='predetermined-project-form-row-0-col-0' xs={1}>
                <span className={classes.inputLabel}>{t('views.library.predeterminedproject.name')}</span>
              </Grid>

              <Grid className={classes.gridForm} item={true} key='predetermined-project-form-row-0-col-1' xs={2}>
                <InputComponentFactory
                  data={predeterminedProjectForm}
                  editProps={{
                    type: 'string',
                    errorMessage: getErrorMessage()
                  }}
                  index='predetermined-project-form-row-0-input-0'
                  value={predeterminedProjectForm.$dtId || ''}
                  onChange={(event) => updatePredeterminedProjectForm('$dtId', event)}
                />
              </Grid>

              <Grid className={classes.gridForm} item={true} key='predetermined-project-form-row-1-col-2' xs={2}>
                <span className={classes.inputLabel}>{t('views.library.predeterminedproject.budgetCategories')}</span>
              </Grid>

              <Grid className={classes.gridForm} item={true} key='predetermined-project-form-row-1-col-3' xs={2}>
                <InputComponentFactory
                  data={predeterminedProjectForm}
                  editProps={{
                    type: 'multi-select',
                    enumList: getPredeterminedProjectBudgetCategories(),
                    isValueFromDictionary: true
                  }}
                  index='predetermined-project-form-row-1-input-1'
                  value={predeterminedProjectForm.BudgetCategories}
                  onChange={(newValue) => updatePredeterminedProjectForm('BudgetCategories', newValue)}
                />
              </Grid>

              <Grid className={classes.gridForm} item={true} key='predetermined-project-form-row-0-col-4' xs={1}>
                <span className={classes.inputLabel}>{t('views.library.predeterminedproject.plannedDate')}</span>
              </Grid>

              <Grid className={classes.gridForm} item={true} key='predetermined-project-form-row-0-col-5' xs={2}>
                <InputComponentFactory
                  data={predeterminedProjectForm}
                  editProps={{
                    type: 'dateSimple',
                    placeholder: t('views.library.predeterminedproject.plannedDate')
                  }}
                  index='predetermined-project-form-row-0-input-2'
                  value={predeterminedProjectForm.PlannedDate}
                  onChange={(newValue) => updatePredeterminedProjectForm('PlannedDate', newValue)}
                />
              </Grid>
            </Grid>
          </Grid>

          <h2 className='sub-title'>{t('views.library.predeterminedproject.asset')}</h2>

          <Grid container={true} item={true} key='predetermined-project-form-row-1' xs={9}>
            <Grid className={classes.formContainer} container={true}>
              <Grid container={true} item={true} justifyContent='flex-start' xs={5}>
                <Grid className={classes.gridForm} item={true} key='predetermined-project-form-row-1-col-0' xs={5}>
                  <span className={classes.inputLabel}>{t('views.library.predeterminedproject.assetClass')}</span>
                </Grid>
                <Grid className={classes.gridForm} item={true} key='predetermined-project-form-row-1-col-1' xs={6}>
                  <InputComponentFactory
                    data={predeterminedProjectForm}
                    editProps={{
                      type: 'select',
                      enumList: getPredeterminedProjectAssetClasses()
                    }}
                    index='predetermined-project-form-row-1-input-0'
                    value={predeterminedProjectForm.AssetClass}
                    onChange={(newValue) => updatePredeterminedProjectForm('AssetClass', newValue)}
                  />
                </Grid>
              </Grid>

              <Grid container={true} item={true} justifyContent='space-between' xs={6}>
                <Grid
                  className={classes.gridForm}
                  item={true}
                  key='predetermined-project-form-row-1-col-2'
                  style={{ marginLeft: '.7rem' }}
                  xs={4}
                >
                  <span className={classes.inputLabel}>{t('views.library.predeterminedproject.assetGroup')}</span>
                </Grid>
                <Grid
                  className={classes.gridForm}
                  item={true}
                  key='predetermined-project-form-row-1-col-3'
                  style={{ marginRight: '0.5rem' }}
                  xs={7}
                >
                  <Autocomplete
                    disablePortal
                    disabled={!predeterminedProjectForm.AssetClass}
                    filterOptions={createFilterOptions({
                      limit: 10
                    })}
                    fullWidth
                    id='predetermined-project-form-row-1-input-1'
                    noOptionsText={t('views.library.predeterminedproject.noResults')}
                    options={assetGroupsByAssetClass}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder={t('views.library.predeterminedproject.assetGroupSearch')}
                        size='small'
                      />
                    )}
                    slotProps={{
                      popper: {
                        sx: { zIndex: 999 }
                      }
                    }}
                    style={{ marginTop: 16 }}
                    value={predeterminedProjectForm.OnEquipmentGroup || null}
                    variant='standard'
                    onChange={(event, newValue) => {
                      updatePredeterminedProjectForm('OnEquipmentGroup', newValue);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <h2 className='sub-title'>{t('views.library.predeterminedproject.operation')}</h2>

          <Grid className={classes.formContainer} container={true} key='predetermined-project-form-row-3'>
            <Grid container={true} justifyContent='space-between' spacing={2}>
              <Grid className={classes.gridForm} item={true} key='predetermined-project-form-row-3-col-0' xs={3}>
                <OperationSelector
                  disabled={!predeterminedProjectForm.AssetClass}
                  id='predetermined-project-form-row-3-input-0'
                  options={getPredeterminedProjectOperationsFromAssetClass()}
                  value={predeterminedProjectForm.Operation}
                  onChange={(newValue) => {
                    updatePredeterminedProjectForm('Operation', newValue);
                  }}
                  onInputChange={(newInputValue) => {
                    setOperationByAssetClassSearch(newInputValue);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.btnEndForm}>
            <Button color='primary' variant='outlined' onClick={cancelUpdate}>
              {t('views.library.predeterminedproject.cancel')} <AddCircleOutlineIcon className={classes.cancel} />
            </Button>
            <Button
              color={isFormValid() ? 'primary' : 'secondary'}
              type='button'
              variant='contained'
              onClick={validateForm}
            >
              {t('views.library.predeterminedproject.save')} <SaveIcon />
            </Button>
          </div>
        </CardContent>
      )}

      <SimpleTwoActionsDialogContent
        handleClickOnButton1={() => setShowMissingfields(false)}
        handleClickOnButton2={() => setShowMissingfields(false)}
        id='missing-fields'
        key='missing-fields'
        labels={{
          title: t('genericcomponent.dialog.missingFields.title'),
          body: getMissingFields(),
          button1: t('genericcomponent.dialog.missingFields.cancel'),
          button2: t('genericcomponent.dialog.missingFields.ok'),
          ariaLabelledby: 'missing-field-dialog',
          button2BGColor: '#062F4F'
        }}
        open={showMissingFields}
      />
    </Card>
  );
}

PredeterminedProjectForm.propTypes = {
  applyUpdate: PropTypes.func.isRequired,
  cancelUpdate: PropTypes.func.isRequired
};

export default PredeterminedProjectForm;

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    width: '100%',
    height: '100%'
  },
  libraryChoice: {
    margin: 'auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  libraryCard: {
    width: '30vw',
    height: 200,
    display: 'flex',
    margin: '0 24px',
    cursor: 'pointer'
  },
  cardLibraryContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.gradient,
    width: 44,
    height: 44,
    borderRadius: 44,
    marginRight: 24,
    '& svg': {
      color: theme.palette.nexans.white
    }
  },
  tableContainer: {
    width: '90%',
    margin: '20px auto 0'
  },
  topBar: {
    display: 'flex',
    width: '90%',
    margin: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0 0'
  },
  flexSearch: {
    display: 'flex'
  },
  searchInput: {
    backgroundColor: theme.palette.nexans.white,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    marginRight: 24
  },
  searchInputField: {
    padding: 8,
    flex: 1,
    color: 'black'
  },
  searchIcon: {
    background: theme.palette.background.gradient,
    color: theme.palette.nexans.white,
    borderRadius: '0 10px 10px 0'
  },
  cardForm: {
    width: '90%',
    margin: '24px auto',
    borderRadius: '10px'
  },
  formContainer: {
    margin: '24px 0',
    paddingBottom: '2rem'
  },
  inputLabel: {
    color: 'black',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  gridForm: {
    display: 'flex',
    alignItems: 'center'
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 2rem 0 0 '
  },
  radio: {
    margin: 'auto'
  },
  btnEndForm: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 25,
    '& button': {
      borderRadius: 100,
      margin: '0 8px',
      boxShadow:
        '0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11),0 8px 16px rgba(0, 0, 0, 0.11)'
    }
  },
  cancel: {
    marginLeft: 8,
    transform: 'rotate(45deg)'
  },
  operationContainer: {
    width: '80%',
    display: 'flex',
    margin: '24px auto',
    justifyContent: 'space-between'
  },
  assetFamilyList: {
    width: '35%',
    '& button': {
      minWidth: '100%',
      padding: 12,
      color: theme.palette.text.black,
      '& span': {
        fontSize: 16,
        textTransform: 'initial',
        alignItems: 'flex-start'
      },
      '&.Mui-selected': {
        background: theme.palette.background.gradient,
        color: theme.palette.text.white
      }
    }
  },
  operationsList: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    '&[hidden]': {
      display: 'none'
    }
  },
  scrollableOperationList: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 280px)',
    overflowY: 'scroll',
    marginTop: 12,
    padding: '0 8px'
  },
  operationItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '12px 0',
    backgroundColor: theme.palette.nexans.white,
    padding: '24px 12px',
    border: '1px solid #DFDFDF',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    fontSize: 14
  },
  tableOperationFormContainer: {
    margin: '24px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  addBtn: {
    // width: 240,
    borderRadius: 50,
    border: '1.5px solid #062F4F',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    '& svg': {
      marginLeft: 8
    }
  }
}));
