/* eslint-disable react/jsx-props-no-spreading */

import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ContactSupport as ContactSupportIcon
} from '@mui/icons-material';
import { Card, Grid, IconButton, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ApiPowerBI } from 'api/ApiPowerBI/ApiPowerBI';
import DashboardExportButtonsPBI from 'components/Dashboards/DashboardExportButtonsPBI.component';
import DashboardTabPanelPBI from 'components/Dashboards/DashboardTabPanelPBI.component';
import { getReportLabels } from 'components/PowerBIReport/labels';
import { DASHBOARDS_LIST_CONFIG } from 'config/Dashboards';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useOrganizationId } from 'state/hooks/Workspace.hooks';
import theme from 'theme';

const TABS = ['overview', 'scenario-comparison', 'scenario-analysis', 'operation-analysis', 'custom-reports'];

const DEFAULT_MISSING_TITLE = 'MISSING_TITLE_IN_LANGUAGE';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

function Dashboards({ currentScenario, reports }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [sidebarWidth, setSidebarWidth] = useState(2);
  const [filteredReportList, setFilteredReportList] = useState([]);
  const navigate = useNavigate();
  const { tab: tabFromRouter } = useParams();
  const tabIndex = useMemo(() => {
    const index = TABS.findIndex((value) => value === tabFromRouter);
    return index >= 0 ? index : 0;
  }, [tabFromRouter]);

  const organizationId = useOrganizationId();

  const handleChange = (event, newValue) => {
    navigate(`/dashboards/${TABS[newValue]}`);
  };

  const isSidebarExpanded = sidebarWidth === 2;

  const handleSidebarExpansion = () => {
    isSidebarExpanded ? setSidebarWidth(1) : setSidebarWidth(2);
  };

  const dashboardTitle =
    DASHBOARDS_LIST_CONFIG[tabIndex].title[i18n.language] === undefined
      ? DEFAULT_MISSING_TITLE
      : DASHBOARDS_LIST_CONFIG[tabIndex].title[i18n.language];

  const reportLabels = useMemo(() => getReportLabels(t), [t]);

  const getReportList = async () => {
    const response = await ApiPowerBI.fetchPowerBIInfo(organizationId);
    const reportsInfo = response.accesses.reportsInfo;
    return Object.keys(reportsInfo).map((key) => reportsInfo[key]);
  };

  useEffect(() => {
    let mounted = true;

    const getReportListFiltered = async () => {
      const dashboardIdsList = await DASHBOARDS_LIST_CONFIG.map((dashboard) => dashboard.reportId);

      const reportList = await getReportList();

      const filteredReportListReduced = await reportList.reduce((acc, report) => {
        if (dashboardIdsList.includes(report.reportId)) {
          acc.push(report);
        }
        return acc;
      }, []);

      const listOrderedByName = await filteredReportListReduced.sort((p1, p2) =>
        p1.reportName > p2.reportName ? 1 : -1
      );

      const listWithCorrespondingNameFromSidebar = await listOrderedByName.map((report) => ({
        ...report,
        reportName: DASHBOARDS_LIST_CONFIG.find((dashboard) => dashboard.reportId === report.reportId).title[
          i18n.language
        ]
      }));

      if (mounted) setFilteredReportList(await listWithCorrespondingNameFromSidebar);
    };

    getReportListFiltered();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Grid className={classes.root} container={true} direction='row'>
      <Grid item={true} sm={sidebarWidth || 2} style={{ maxWidth: isSidebarExpanded ? null : '5%' }}>
        <Card className={classes.card}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <div className={classes.expanseButton}>
              <IconButton size='large' style={{ color: '#062F4F' }} onClick={handleSidebarExpansion}>
                {isSidebarExpanded ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
              </IconButton>
            </div>
            <Tabs
              aria-label='Library list'
              className={classes.tabs}
              orientation='vertical'
              style={{
                paddingLeft: isSidebarExpanded ? '1rem' : '0.5rem'
              }}
              value={tabIndex}
              variant='scrollable'
              onChange={handleChange}
            >
              {constructDashboardTabs(i18n, isSidebarExpanded)}
            </Tabs>
          </div>
          {filteredReportList.length > 0 && (
            <DashboardExportButtonsPBI isSidebarExpanded={isSidebarExpanded} reportList={filteredReportList} />
          )}
        </Card>
      </Grid>
      <Grid item={true} sm={true}>
        <DashboardTabPanelPBI
          index={tabIndex}
          key={dashboardTitle}
          labels={reportLabels}
          lang={i18n.language}
          reports={reports}
          scenario={currentScenario}
          title={dashboardTitle}
        />
      </Grid>
    </Grid>
  );
}

Dashboards.propTypes = {
  // classes: PropTypes.any,
  currentScenario: PropTypes.object.isRequired,
  reports: PropTypes.object.isRequired
};

const constructDashboardTabs = (i18n, isSidebarExpanded) => {
  const tabs = [];
  DASHBOARDS_LIST_CONFIG.forEach((dashboardConf, index) => {
    const dashboardTitle =
      dashboardConf.title[i18n.language] === undefined ? DEFAULT_MISSING_TITLE : dashboardConf.title[i18n.language];
    const dashboardIcon = dashboardConf.icon || <ContactSupportIcon />;

    // replace text by icon if sidebar is compressed
    isSidebarExpanded
      ? tabs.push(
          <Tab
            icon={dashboardIcon}
            key={dashboardConf.reportId}
            label={dashboardTitle}
            {...a11yProps(dashboardConf.id)}
          />
        )
      : tabs.push(
          <Tab
            icon={dashboardIcon}
            key={`dashboardConf.icons-${index}`}
            {...a11yProps(dashboardConf.id)}
            style={{ justifyContent: 'center !important' }}
          />
        );
  });
  return tabs;
};

export default Dashboards;

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    width: '100%',
    height: '100%',
    padding: '0',
    '& .MuiGrid-item': {
      transition: '0.5s'
    }
  },
  card: {
    padding: '0px',
    height: '100%',
    paddingTop: '8px',
    borderRadius: '0px 10px 10px 0px !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  expanseButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end'
  },
  tabs: {
    width: '100%',
    color: theme.palette.primary.contrastText,
    marginTop: '50px',
    '& .MuiTab-root': {
      minWidth: '2rem',
      minHeight: '5rem',
      width: '100%'
    },
    '& .MuiTabs-indicator': {
      background: theme.palette.background.gradient,
      borderRadius: '3px',
      width: '8px'
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: '1rem',
      textAlign: 'left',
      width: '100%'
    },
    '& .Mui-selected': {
      color: theme.palette.primary.main
    },
    '& button': {
      fontWeight: '700',
      '& span': {
        alignItems: 'self-start'
      }
    }
  }
}));
