import { createAction, createReducer } from '@reduxjs/toolkit';
import { REQUEST_ACTIONS_KEY } from 'state/commons/RequestConstants';

interface IRequestState {
  isLoading: boolean;
}

interface IRequestLoadingAction {
  isLoading: boolean;
}

export const requestInitialState: IRequestState = {
  isLoading: false
};

const setIsLoading = createAction<IRequestLoadingAction>(REQUEST_ACTIONS_KEY.SET_IS_LOADING); //TODO

export const requestReducer = createReducer(requestInitialState, (builder) => {
  builder.addCase(setIsLoading, (state, action) => {
    state.isLoading = action.payload.isLoading;
  });
});
