import { DATAINTEGRATION_ACTIONS_KEY } from '../../commons/DataIntegrationConstants';

export const dispatchRunDataIntegrationMainPipelineData = () => ({
  type: DATAINTEGRATION_ACTIONS_KEY.RUN_MAIN_PIPELINE
});

export const dispatchGetPipelineLastestRunData = () => ({
  type: DATAINTEGRATION_ACTIONS_KEY.GET_PIPELINE_LASTEST_RUN_DATA
});

export const dispatchSetPipelineLastestRunData = (lastestRun) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.SET_PIPELINE_LASTEST_RUN_DATA,
    lastestRun
  };
};

export const dispatchGetSubPipelinesData = () => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.GET_SUBPIPELINES_DATA
  };
};

export const dispatchSetSubPipelinesData = (subPipelines) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.SET_SUBPIPELINES_DATA,
    subPipelines
  };
};

export const dispatchGetPipelineHistory = (pipelineType) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.GET_PIPELINE_HISTORY,
    pipelineType
  };
};

export const dispatchRemovePipelineHistoryEntry = (entryId, pipelineType) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.REMOVE_PIPELINE_HISTORY_ENTRY,
    entryId,
    pipelineType
  };
};

export const dispatchDeletePipelineHistoryRunData = (entryId, pipelineType) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.DELETE_PIPELINE_HISTORY_RUN_DATA,
    entryId,
    pipelineType
  };
};

export const dispatchDeleteAllPipelineData = (pipelineType) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.DELETE_ALL_PIPELINE_DATA,
    pipelineType
  };
};

export const dispatchGetAgingModel = () => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.GET_AGING_MODEL
  };
};

export const dispatchUpdateAgingModel = (agingModelValue) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.UPDATE_AGING_MODEL,
    agingModelValue
  };
};

export const dispatchUploadFileInPipeline = (file, pipelineType, actionType, additionalParameters) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.UPLOAD_PIPELINE_FILE,
    file,
    pipelineType,
    actionType,
    additionalParameters
  };
};

export const dispatchGetPipelineKpis = () => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.GET_KPIS
  };
};

export const dispatchSetUploadFileLoading = (isLoading) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.SET_LOADING,
    isLoading
  };
};

export const dispatchSetUploadFileInPipelinesLoading = (isLoading, pipelineType) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.SET_SUBPIPELINES_LOADING,
    isLoading,
    pipelineType
  };
};

export const dispatchSetError = (error) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.SET_ERROR,
    error
  };
};

export const dispatchSetCanRunIntegration = (canRunIntegration) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.SET_CAN_RUN_INTEGRATION,
    canRunIntegration
  };
};

export const dispatchSetCanUploadFiles = (canUploadFiles) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.SET_CAN_UPLOAD_FILES,
    canUploadFiles
  };
};

export const dispatchSetMainPipelineData = (mainPipeline) => {
  return {
    type: DATAINTEGRATION_ACTIONS_KEY.SET_CAN_UPLOAD_FILES,
    mainPipeline
  };
};
