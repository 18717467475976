import { ConfigUtils } from 'utils/helpers';
import { VAR_TYPES_FACTORIES_MAPPING } from '../../FactoriesMapping';

const create = (
  t,
  customClasses,
  parameterData,
  parametersState,
  setParametersState,
  editMode,
  parametersGroupDataId,
  extraConfig
) => {
  const parameterVarType = ConfigUtils.buildExtendedVarType(parameterData.varType, parameterData.subType);
  let varTypeFactory;

  if (parameterVarType in VAR_TYPES_FACTORIES_MAPPING) {
    varTypeFactory = VAR_TYPES_FACTORIES_MAPPING[parameterVarType];
  } else {
    varTypeFactory = VAR_TYPES_FACTORIES_MAPPING[parameterData.varType];
  }

  if (varTypeFactory === undefined) {
    console.warn(`No factory defined for varType ${parameterVarType}`);
    return null;
  }
  if (varTypeFactory === null) {
    return null;
  }

  return varTypeFactory.create(
    t,
    customClasses,
    parameterData,
    parametersState,
    setParametersState,
    editMode,
    parametersGroupDataId,
    extraConfig
  );
};

export const CustomScenarioParameterInputFactory = {
  create
};
