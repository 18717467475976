import { Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NumberUtils } from 'utils/helpers';

function AssetRegistryInfoBar({ assetAgingModel, assetMetrics }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { averageApparentAge, healthScoreAvg, countGroup, countFamily, countComponent } = assetMetrics;
  const { numberWithSpaces, roundAndConvertToPositiveNestedNumbers } = NumberUtils;

  return (
    <div className={classes.root}>
      <div
        className={classes.cardContainer}
        style={{
          opacity: `${!(averageApparentAge && healthScoreAvg && countGroup && countFamily && countComponent) ? 0 : 1}`
        }}
      >
        <div className={classes.cardItem}>
          <Card>
            <CardContent>
              <Typography
                align='left'
                className={classes.cardTitle}
                gutterBottom={true}
                noWrap={true}
                variant='inherit'
              >
                {t('views.assetRegistry.infobar.classes')}
              </Typography>
              <Typography align='center' className={classes.cardValue} noWrap={true} variant='inherit'>
                {numberWithSpaces(countGroup)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className={classes.cardItem}>
          <Card>
            <CardContent>
              <Typography
                align='left'
                className={classes.cardTitle}
                gutterBottom={true}
                noWrap={true}
                variant='inherit'
              >
                {t('views.assetRegistry.infobar.families')}
              </Typography>
              <Typography align='center' className={classes.cardValue} noWrap={true} variant='inherit'>
                {numberWithSpaces(countFamily)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className={classes.cardItem}>
          <Card>
            <CardContent>
              <Typography
                align='left'
                className={classes.cardTitle}
                gutterBottom={true}
                noWrap={true}
                variant='inherit'
              >
                {t('views.assetRegistry.infobar.components')}
              </Typography>
              <Typography align='center' className={classes.cardValue} noWrap={true} variant='inherit'>
                {numberWithSpaces(countComponent)}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className={classes.cardItem}>
          <Card>
            <CardContent>
              <Typography align='left' className={classes.cardTitle} gutterBottom={true} variant='inherit'>
                {assetAgingModel === 'HealthScore'
                  ? t('views.assetRegistry.infobar.healthScore')
                  : t('views.assetRegistry.infobar.averageLifeSpan')}
              </Typography>
              <Typography align='center' className={classes.cardValue} variant='inherit'>
                {assetAgingModel === 'HealthScore'
                  ? roundAndConvertToPositiveNestedNumbers(healthScoreAvg, 0)
                  : t('views.assetRegistry.infobar.year', {
                      count: roundAndConvertToPositiveNestedNumbers(averageApparentAge, 0)
                    })}
              </Typography>
            </CardContent>
          </Card>
        </div>

        <div className={classes.cardItem}>
          <Card>
            <CardContent>
              <Typography align='left' className={classes.cardTitle} variant='inherit'>
                {t('views.assetRegistry.infobar.agingModel')}
              </Typography>
              <Typography align='center' className={classes.cardValue} variant='inherit'>
                {assetAgingModel === 'HealthScore'
                  ? t('views.assetRegistry.infobar.cnaim')
                  : t('views.assetRegistry.infobar.effectiveAge')}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

AssetRegistryInfoBar.propTypes = {
  assetAgingModel: PropTypes.string.isRequired,
  assetMetrics: PropTypes.shape({
    averageApparentAge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    healthScoreAvg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    countComponent: PropTypes.number.isRequired,
    countFamily: PropTypes.number.isRequired,
    countGroup: PropTypes.number.isRequired
  }).isRequired
};

export default AssetRegistryInfoBar;

const useStyles = makeStyles((_theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    padding: '0 !important'
  },
  cardContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cardItem: {
    display: 'flex',
    gap: '0.3rem',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cardTitle: {
    display: 'flex',
    color: 'grey',
    fontSize: '1rem',
    fontWeight: '500'
  },
  cardValue: {
    display: 'flex',
    color: 'black',
    fontSize: '1.1rem',
    fontWeight: 'bold'
  }
}));
