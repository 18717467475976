import { clientApi as axios } from 'services/ClientApi';
import DownloadBlob from 'utils/hooks/DownloadBlob.hook';
import { getBasePath } from '../ApiUtils';

const getCnaimUrl = (organizationId) => `${getBasePath(organizationId)}/Cnaim`;

const fetchAllCnaimFilesMetadata = async (organizationId) => {
  return axios
    .get(getCnaimUrl(organizationId))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });
};

const uploadCnaimFile = async (organizationId, file, fileName) =>
  axios
    .post(`${getCnaimUrl(organizationId)}/UploadFile?fileName=${fileName}`, file)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });

const downloadCnaimFile = async (organizationId, fileName) =>
  axios
    .get(`${getCnaimUrl(organizationId)}/DownloadFile?fileName=${fileName}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'text/csv'
      }
    })
    .then((response) => {
      DownloadBlob(response.data, fileName);
      return response;
    })
    .catch((error) => {
      return error.response;
    });

const getAbacusTable = async (organizationId, fileName) =>
  axios
    .get(`${getCnaimUrl(organizationId)}/GetAbacusTable/${fileName}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });

export const ApiCnaim = {
  downloadCnaimFile,
  fetchAllCnaimFilesMetadata,
  getAbacusTable,
  uploadCnaimFile
};
