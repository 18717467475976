import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiOperation } from '../../../../api/ApiOperation';
import { OPERATION_ACTIONS_KEY } from '../../../commons/OperationConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllOperationsData() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiOperation.fetchAllOperations, organizationId);
    if (data?.length > 0) {
      yield put({
        type: OPERATION_ACTIONS_KEY.SET_ALL_OPERATIONS,
        operations: data
      });
    } else {
      yield put({
        type: OPERATION_ACTIONS_KEY.SET_ALL_OPERATIONS,
        operations: []
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllOperationsData() {
  yield takeEvery(OPERATION_ACTIONS_KEY.GET_ALL_OPERATIONS, getAllOperationsData);
}

export default findAllOperationsData;
