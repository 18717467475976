import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { BREADCRUMBS_ACTIONS_KEY } from '../../commons/BreadCrumbsConstants';

export const breadCrumbsItemsInitialState = {
  data: []
};

export const breadCumbsItemsReducer = createReducer(breadCrumbsItemsInitialState, (builder) => {
  builder.addCase(BREADCRUMBS_ACTIONS_KEY.SET_BREADCRUMBS_ITEMS, (state, action) => {
    state.data = action.items;
  });
});

export const breadCrumbsReducer = combineReducers({
  items: breadCumbsItemsReducer
});
