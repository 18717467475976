import { ApiCnaim } from 'api/ApiCnaim';
import { call, put, takeEvery } from 'redux-saga/effects';
import { i18next } from 'services/config/i18next';
import { CNAIM_ACTIONS_KEY } from 'state/commons/CnaimConstants';
import { NOTIFICATION_ACTIONS_KEY } from 'state/commons/NotificationConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* downloadCnaimFile(action) {
  const { fileName } = action;

  try {
    const organizationId = yield selectOrganizationId();
    const response = yield call(ApiCnaim.downloadCnaimFile, organizationId, fileName);
    if (response.status === 200) {
      yield put({
        type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
        message: `${i18next.t('views.library.cnaim.download')} ✅ `,
        isOpen: true
      });
      // Reset upload error if any
      yield put({
        type: CNAIM_ACTIONS_KEY.SET_DOWNLOAD_ERROR,
        error: null
      });
    } else {
      yield put({
        type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
        message: `${i18next.t('views.library.cnaim.download')}: ${response.statusText} ❌`,
        isOpen: true
      });
      yield put({
        type: CNAIM_ACTIONS_KEY.SET_DOWNLOAD_ERROR,
        error: `${i18next.t('views.library.cnaim.download')}: ${response.statusText}`
      });
    }
  } catch (e) {
    yield put({
      type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
      message: `${i18next.t('views.library.cnaim.download')} ❌`,
      isOpen: true
    });
    yield put({
      type: CNAIM_ACTIONS_KEY.SET_DOWNLOAD_ERROR,
      error: e.message
    });
  }
}

function* downloadCnaimFileData() {
  yield takeEvery(CNAIM_ACTIONS_KEY.DOWNLOAD_FILE, downloadCnaimFile);
}

export default downloadCnaimFileData;
