import { connect } from 'react-redux';
import {
  dispatchDeleteAllPipelineData,
  dispatchDeletePipelineHistoryRunData,
  dispatchGetPipelineHistory,
  dispatchGetPipelineLastestRunData,
  dispatchRemovePipelineHistoryEntry,
  dispatchRunDataIntegrationMainPipelineData,
  dispatchUpdateAgingModel,
  dispatchUploadFileInPipeline
} from 'state/dispatchers/dataIntegration/DataIntegrationDispatcher';
import { dispatchOpenNotification } from 'state/dispatchers/notification/notificationDispatcher';
import DataIntegration from './DataIntegration.view';

const mapStateToProps = (state) => ({
  dataIntegration: state.dataIntegration,
  mainPipelineLastestRun: {
    errorMessages: state.dataIntegration.lastestRun?.errorMessages,
    integrationDate: state.dataIntegration.lastestRun?.integrationDate,
    runId: state.dataIntegration.lastestRun?.runId,
    status: state.dataIntegration.lastestRun?.status,
    subStepHistory: state.dataIntegration.lastestRun?.subStepHistory
  }
});

const mapDispatchToProps = {
  deleteAllPipelineData: dispatchDeleteAllPipelineData,
  deletePipelineHistoryRunData: dispatchDeletePipelineHistoryRunData,
  getPipelineHistory: dispatchGetPipelineHistory,
  getPipelineLastestRun: dispatchGetPipelineLastestRunData,
  openNotification: dispatchOpenNotification,
  removePipelineHistoryEntry: dispatchRemovePipelineHistoryEntry,
  runMainPipeline: dispatchRunDataIntegrationMainPipelineData,
  updateAgingModel: dispatchUpdateAgingModel,
  uploadPipelineFile: dispatchUploadFileInPipeline
};

export default connect(mapStateToProps, mapDispatchToProps)(DataIntegration);
