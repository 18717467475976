import { makeStyles } from '@mui/styles';
import { DataIntegrationCard, DataIntegrationMainInfosBar } from 'components/DataIntegration';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PIPELINESTATUS } from 'state/commons/Constants';

function DataIntegration({
  dataIntegration,
  deleteAllPipelineData,
  deletePipelineHistoryRunData,
  getPipelineHistory,
  getPipelineLastestRun,
  mainPipelineLastestRun,
  removePipelineHistoryEntry,
  updateAgingModel,
  runMainPipeline,

  uploadPipelineFile
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const subPipelines = dataIntegration.subPipelines;
  const [, setIsFilePending] = useState(false);

  useEffect(() => {
    getPipelineLastestRun();
  }, []);

  useEffect(() => {
    if (mainPipelineLastestRun?.status === PIPELINESTATUS.RUNNING) {
      const interval = setInterval(() => getPipelineLastestRun(), 15000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [mainPipelineLastestRun, runMainPipeline, subPipelines]);

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        {mainPipelineLastestRun && <DataIntegrationMainInfosBar />}
        <div className={classes.titleContainer}>
          <h2 className='sub-title'>{t('views.dataIntegration.title')}</h2>
        </div>
        {Object.values(subPipelines)?.map((card) => (
          <Fragment key={`data-integration-card-${card.id}`}>
            <DataIntegrationCard
              cardData={card}
              classes={classes}
              deleteAllPipelineData={deleteAllPipelineData}
              deletePipelineHistoryRunData={deletePipelineHistoryRunData}
              getPipelineHistory={getPipelineHistory}
              removePipelineHistoryEntry={removePipelineHistoryEntry}
              setIsFilePending={setIsFilePending}
              updateAgingModel={updateAgingModel}
              uploadPipelineFile={uploadPipelineFile}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
}

DataIntegration.propTypes = {
  dataIntegration: PropTypes.object.isRequired,
  deleteAllPipelineData: PropTypes.func.isRequired,
  deletePipelineHistoryRunData: PropTypes.func.isRequired,
  getPipelineHistory: PropTypes.func.isRequired,
  getPipelineLastestRun: PropTypes.func.isRequired,
  mainPipelineLastestRun: PropTypes.shape({
    errorMessages: PropTypes.array,
    integrationDate: PropTypes.string,
    runId: PropTypes.string,
    status: PropTypes.string,
    subStepHistory: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        integrationSubStep: PropTypes.string
      })
    )
  }).isRequired,
  nextPipelineRuns: PropTypes.array,
  removePipelineHistoryEntry: PropTypes.func.isRequired,
  runMainPipeline: PropTypes.func.isRequired,
  updateAgingModel: PropTypes.func.isRequired,
  uploadPipelineFile: PropTypes.func.isRequired
};

export default DataIntegration;

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5rem 0',
    minWidth: '60%',
    gap: '1rem'
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    minWidth: '60%'
  }
}));
