import { FinanceTab } from 'components';

const create = (
  t,
  customClasses,
  parameterData,
  parametersState,
  setParametersState,
  editMode,
  parametersGroupDataId,
  extraConfig
) => (
  <FinanceTab
    parameterData={parameterData}
    parametersState={parametersState}
    editMode={editMode}
    customClasses={customClasses}
  />
);

export const FinanceListFactory = {
  create
};
