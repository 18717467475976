import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getOperationUrl = (organizationId) => `${getBasePath(organizationId)}/Operation`;

const fetchAllOperations = (organizationId) => {
  return axios
    .get(getOperationUrl(organizationId))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });
};

const getOperation = (organizationId, id) =>
  axios
    .get(`${getOperationUrl(organizationId)}/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const createOperation = (organizationId, operation) =>
  axios
    .post(getOperationUrl(organizationId), operation)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return null;
    });

const updateOperation = (organizationId, operation) =>
  axios
    .patch(getOperationUrl(organizationId), operation)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return null;
    });

const deleteOperation = (organizationId, id) =>
  axios
    .delete(`${getOperationUrl(organizationId)}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.error('Error ========>', error);
      return error;
    });

const exportOperations = async (organizationId) =>
  axios
    .get(`${getOperationUrl(organizationId)}/Export`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/zip'
      }
    })
    .then((response) => response.data);

const importOperationsFile = async (organizationId, file) =>
  axios.post(`${getOperationUrl(organizationId)}/Import`, file).then((response) => response.data);

const fetchAllOperationsEnums = (organizationId) =>
  axios
    .get(`${getOperationUrl(organizationId)}/EnumValues`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return {};
    });

const isNameExist = (organizationId, name) =>
  axios
    .get(`${getOperationUrl(organizationId)}/IsNameExist/${name}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return {};
    });

const fetchOperationsTree = (organizationId, assetClass, search, page, numberPerPage) => {
  const skip = (page - 1) * numberPerPage;
  const top = numberPerPage;
  return axios
    .get(`${getOperationUrl(organizationId)}/OperationsTree`, {
      params: { assetClass, search: !search ? undefined : search, skip, top }
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });
};

const fetchOperationsTreeOrphan = (organizationId) =>
  axios
    .get(`${getOperationUrl(organizationId)}/OperationsTreeOrphan`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

export const ApiOperation = {
  createOperation,
  deleteOperation,
  exportOperations,
  fetchAllOperations,
  fetchAllOperationsEnums,
  fetchOperationsTree,
  fetchOperationsTreeOrphan,
  getOperation,
  importOperationsFile,
  isNameExist,
  updateOperation
};
