import { InputAdornment, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import theme from 'theme';

export default function FinanceTableWaccBody({ headersYears, handleChangeValueFromYear, list }) {
  const { t } = useTranslation();
  const minTableValueWidth = (year) => {
    const valueToDisplay = list.find((element) => element.year === year)?.wacc;
    return valueToDisplay ? valueToDisplay.toString().length * 1.5 : 5;
  };

  const displayFormatedValue = (year) => {
    const elementInList = list.find((element) => element.year === year)?.wacc;
    const displayValue = elementInList * 100;
    return displayValue;
  };

  return (
    <TableBody>
      <TableRow key={'table-row-finance-wacc'} sx={{ backgroundColor: theme.palette.background.default }}>
        <TableCell align='center' style={{ width: '20px' }}>
          {t(`commoncomponents.scenario-form.finance-tab.${'value'}`)}
        </TableCell>
        {headersYears.map((year, index) => (
          <TableCell
            align='center'
            style={{
              minWidth: `${minTableValueWidth(year)}rem`,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              border: 'none',
              lineHeight: 'unset !important',
              maxHeight: 'none !important'
            }}
            key={`table-body-cell-finance-wacc-${year}-${index}`}
            data-testid={`finance-wacc-table-body-cell-year-${year}`}
          >
            <NumericFormat
              variant='standard'
              value={displayFormatedValue(year)}
              onChange={(event) => handleChangeValueFromYear(year, event)}
              customInput={TextField}
              allowLeadingZeros={true}
              decimalScale={2}
              isAllowed={({ formattedValue }) =>
                formattedValue === '' || (formattedValue <= 100 && formattedValue >= 0)
              }
              InputProps={{
                endAdornment: <InputAdornment position='start'>%</InputAdornment>
              }}
            />
          </TableCell>
        ))}
      </TableRow>
    </TableBody>
  );
}

FinanceTableWaccBody.propTypes = {
  handleChangeValueFromYear: PropTypes.func.isRequired,
  headersYears: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired
};
