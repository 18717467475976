import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchRunDataIntegrationMainPipelineData } from 'state/dispatchers/dataIntegration/DataIntegrationDispatcher';

export const useDataIntegrationIsMainPipelineRunning = () => {
  return useSelector((state) => state.dataIntegration.status.isLoading);
};
export const useDataIntegrationGetUploadStatus = () => {
  return useSelector((state) => state.dataIntegration.status.canUploadFiles);
};
export const useDataIntegrationCanRunIntegration = () => {
  return useSelector((state) => state.dataIntegration.status.canRunIntegration);
};

export const useDataIntegrationSubPipelinesLoadingStatus = (pipelineType) => {
  return useSelector((state) => state.dataIntegration.subPipelines[pipelineType].isLoading);
};

export const useDataIntegrationGetLatestRun = () => {
  return useSelector((state) => state.dataIntegration.lastestRun);
};

export const useMainPipelineStatus = () => {
  const latestRun = useSelector((state) => state.dataIntegration.lastestRun);
  const { errorMessages, integrationDate, subStepHistory, status, runId } = latestRun;

  return { errorMessages, integrationDate, runId, status, subStepHistory };
};

export const useGetDataIntegrationSubPipelines = () => {
  return useSelector((state) => state.dataIntegration.subPipelines);
};

export const useDataIntegrationRunMainPipeline = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchRunDataIntegrationMainPipelineData()), [dispatch]);
};
