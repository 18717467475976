import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getIntegrationUrl = (organizationId) => `${getBasePath(organizationId)}/Integration`;

const uploadFileToPipeline = async (organizationId, file, pipelineType, CRUDAction) =>
  axios
    .post(`${getIntegrationUrl(organizationId)}/UploadFile?pipelineType=${pipelineType}&action=${CRUDAction}`, file)
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      return error.response;
    });

const downloadPipelineErrorFile = (organizationId, runId) => {
  return fetch(`${getIntegrationUrl(organizationId)}/ExportPipelineRunErrors/${runId}`, {
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'text/csv'
    }
  })
    .then((response) => response.blob())
    .catch((error) => {
      console.error(error);
      return error.response;
    });
};

const fetchLastPipelineRun = async (organizationId) =>
  axios
    .get(`${getIntegrationUrl(organizationId)}/GetLatestRun`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const runMainPipeline = async (organizationId) =>
  axios
    .get(`${getIntegrationUrl(organizationId)}/Run`)
    .then((response) => response)
    .catch((error) => {
      console.error('Error ========>', error);
      return error.response;
    });

const fetchPipelineHistory = async (organizationId, pipelineType) =>
  axios
    .get(`${getIntegrationUrl(organizationId)}/History/Get/${pipelineType}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return [];
    });

const downloadPipelineHistoryEntryFile = async (organizationId, id) =>
  axios
    .get(`${getIntegrationUrl(organizationId)}/History/Download/${id}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/zip'
      }
    })
    .then((response) => response.data);

const downloadPipelineErrorFileExcel = async (organizationId, id) =>
  axios.get(`${getIntegrationUrl(organizationId)}/ExportUploadErrorFile/${id}`, {
    responseType: 'blob'
  });

const removePipelineHistoryEntry = async (organizationId, id) =>
  axios
    .delete(`${getIntegrationUrl(organizationId)}/History/Remove/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.error('Error ========>', error);
      return error.response;
    });

const deletePipelineHistoryRunData = async (organizationId, id) =>
  axios
    .delete(`${getIntegrationUrl(organizationId)}/History/Delete/${id}`)
    .then((response) => response.status === 200 || response.status === 204)
    .catch((error) => {
      console.error('Error ========>', error);
      return error.response;
    });

const fetchAgingModel = async (organizationId) =>
  axios
    .get(`${getIntegrationUrl(organizationId)}/AgingModel/Get`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return [];
    });

const updateAgingModel = async (organizationId, agingModelValue) =>
  axios
    .put(`${getIntegrationUrl(organizationId)}/AgingModel/Set`, { value: agingModelValue })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return [];
    });

const exportPipeline = async (organizationId, pipelineType) =>
  axios
    .get(`${getIntegrationUrl(organizationId)}/Export/${pipelineType}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/zip'
      }
    })
    .then((response) => response.data);

const deleteAllPipelineData = async (organizationId, pipelineType) =>
  axios
    .delete(`${getIntegrationUrl(organizationId)}/DeleteAll/${pipelineType}`)
    .then((response) => response.status === 200 || response.status === 204)
    .catch((error) => {
      console.error('Error ========>', error);
      return error.response;
    });

const fetchSubPipelines = async (organizationId) =>
  axios
    .get(`${getIntegrationUrl(organizationId)}/Pipelines`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return [];
    });

const fetchPipelineKpis = async (organizationId) =>
  axios
    .get(`${getIntegrationUrl(organizationId)}/Kpis`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      return {};
    });

export const ApiIntegration = {
  deleteAllPipelineData,
  deletePipelineHistoryRunData,
  downloadPipelineErrorFile,
  downloadPipelineErrorFileExcel,
  downloadPipelineHistoryEntryFile,
  exportPipeline,
  fetchAgingModel,
  fetchLastPipelineRun,
  fetchPipelineHistory,
  fetchPipelineKpis,
  fetchSubPipelines,
  removePipelineHistoryEntry,
  runMainPipeline,
  updateAgingModel,
  uploadFileToPipeline
};
