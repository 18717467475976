import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  dispatchGetPowerBIEmbedInfo,
  dispatchSetPowerBICurrentReport
} from 'state/dispatchers/powerbi/PowerBIDispatcher';

export const usePowerBIInfo = () => {
  return useSelector((state) => state.powerBI);
};

export const usePowerBIGetEmbedInfo = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchGetPowerBIEmbedInfo()), [dispatch]);
};

export const usePowerBISetCurrentReport = () => {
  const dispatch = useDispatch();
  return useCallback((currentReport) => dispatch(dispatchSetPowerBICurrentReport(currentReport)), [dispatch]);
};
