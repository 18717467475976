import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  dispatchGetAllNetworkLevel,
  dispatchGetAllOperatingForecast,
  dispatchGetAllWacc,
  dispatchSetAllOperatingForecast,
  dispatchSetAllWacc,
  dispatchSetCurrentScenarioOperatingForecast,
  dispatchSetCurrentScenarioWacc,
  dispatchUpdateFinanceTab
} from 'state/dispatchers/finance/FinanceDispatcher';

export const useFinanceGetWaccList = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchGetAllWacc()), [dispatch]);
};
export const useFinanceGetNetworkLevelList = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchGetAllNetworkLevel()), [dispatch]);
};

export const useFinanceGetOperatingForecastList = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchGetAllOperatingForecast()), [dispatch]);
};

export const useFinanceUpdateWaccAndOperatingForecastTables = () => {
  const dispatch = useDispatch();
  return useCallback((financeTab) => dispatch(dispatchUpdateFinanceTab(financeTab)), [dispatch]);
};

export const useFinanceSetWaccList = () => {
  const dispatch = useDispatch();
  return useCallback((waccList) => dispatch(dispatchSetAllWacc(waccList)), [dispatch]);
};
export const useFinanceSetCurrentScenarioWaccList = () => {
  const dispatch = useDispatch();
  return useCallback((waccList) => dispatch(dispatchSetCurrentScenarioWacc(waccList)), [dispatch]);
};

export const useFinanceSetOperatingForecastList = () => {
  const dispatch = useDispatch();
  return useCallback(
    (operatingForecastList) => dispatch(dispatchSetAllOperatingForecast(operatingForecastList)),
    [dispatch]
  );
};
export const useFinanceSetCurrentScenarioOperatingForecastList = () => {
  const dispatch = useDispatch();
  return useCallback(
    (operatingForecastList) => dispatch(dispatchSetCurrentScenarioOperatingForecast(operatingForecastList)),
    [dispatch]
  );
};

export const useFinanceWaccData = () => {
  return useSelector((state) => state.finance.wacc.list);
};
export const useFinanceOperatingForecastData = () => {
  return useSelector((state) => state.finance.operatingForecast?.list);
};
export const useFinanceWaccCurrentScenarioData = () => {
  return useSelector((state) => state.finance.waccScenario?.list);
};
export const useFinanceOperatingForecastCurrentScenarioData = () => {
  return useSelector((state) => state.finance.operatingForecastScenario?.list);
};
export const useFinanceNetworkLevelData = () => {
  return useSelector((state) => state.finance.networkLevel.list);
};
