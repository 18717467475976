import {
  BugReport as BugReportIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  GetApp as GetAppIcon
} from '@mui/icons-material';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { SimpleTwoActionsDialogContent } from 'components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PIPELINEHISTORYSTATUS } from 'state/commons/Constants';
import theme from 'theme';
import { DateUtils } from 'utils/helpers';
import { useGetHistoryFileFromPipeline } from 'utils/hooks';

export default function DataIntegrationHistory({ cardData, removePipelineHistoryEntry, deletePipelineHistoryRunData }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [payloadRemoveEntry, setPayloadRemoveEntry] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [payloadDeleteEntry, setPayloadDeleteEntry] = useState(null);
  const { getErrorLogInExcel, getFileInCSV } = useGetHistoryFileFromPipeline();

  function deleteDataIntegration(historyEntry) {
    setShowDeleteConfirm(true);
    setPayloadDeleteEntry(historyEntry);
  }

  function removeEntry(historyEntry) {
    setShowRemoveConfirm(true);
    setPayloadRemoveEntry(historyEntry);
  }

  return (
    <div className={classes.container}>
      <h2 className={classes.subTitle}>{t('views.dataIntegration.history.title')}</h2>
      {(cardData?.history?.length && (
        <Grid
          alignContent='flex-start'
          container={true}
          direction='row'
          justifyContent='flex-start'
          key='grid-col-1-row-5-history-container'
          spacing={1}
        >
          <Grid item={true} key='history-grid' md={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell>{t('views.dataIntegration.history.date')}</TableCell>
                    <TableCell>{t('views.dataIntegration.history.actionPerformed')}</TableCell>
                    <TableCell>{t('views.dataIntegration.history.fileName')}</TableCell>
                    <TableCell>{t('views.dataIntegration.history.statusTitle')}</TableCell>
                    <TableCell align='center' style={{ width: '240px' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cardData.history.map((entry, index) => (
                    <TableRow
                      className={index % 2 === 1 ? classes.tableRowWhiteBackground : ''}
                      key={`table-row-history-${entry.status + index}`}
                    >
                      <TableCell key={`table-cell-history-date-value-${entry.uploadDate + index}`}>
                        {entry.uploadDate ? DateUtils.formatDateIsoToDDMMYYYYhhmm(entry.uploadDate) : '-'}
                      </TableCell>
                      <TableCell key={`table-cell-history-action-value-${entry.action + index}`}>
                        {t(`views.dataIntegration.history.action.${entry.action}`)}
                      </TableCell>
                      <TableCell key={`table-cell-history-file-value-${entry.zipName}+index}`}>
                        {entry.zipName}
                      </TableCell>
                      <TableCell key={`table-cell-history-status-value-${entry.isDeleted + index}`}>
                        {entry.isDeleted
                          ? t('views.dataIntegration.history.status.Deleted')
                          : t(`views.dataIntegration.history.status.${entry.status || 'None'}`)}
                      </TableCell>
                      <TableCell align='right' key={`table-cell-history-actions-value-${entry.runId + index}`}>
                        <span>
                          {entry.action.toLowerCase() === 'create' &&
                            !entry.isDeleted &&
                            entry.runId &&
                            entry.status === PIPELINEHISTORYSTATUS.SUCCESS && (
                              <Button
                                title={t('views.dataIntegration.actions.tooltips.delete')}
                                onClick={() => deleteDataIntegration(entry)}
                              >
                                <DeleteIcon />
                              </Button>
                            )}
                          {entry.status === 'Error' && (
                            <Button
                              title={t('views.dataIntegration.actions.downloadErrorFile')}
                              onClick={() => getErrorLogInExcel(entry.id, entry.zipName)}
                            >
                              <BugReportIcon />
                            </Button>
                          )}
                          <Button
                            title={t('views.dataIntegration.actions.download')}
                            onClick={() => getFileInCSV(entry)}
                          >
                            <GetAppIcon />
                          </Button>
                          <Button
                            title={t('views.dataIntegration.actions.tooltips.remove')}
                            onClick={() => removeEntry(entry)}
                          >
                            <ClearIcon />
                          </Button>
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )) || (
        <Grid
          className={classes.noData}
          container={true}
          direction='row'
          justifyContent='center'
          key='grid-col-1-row-5-no-history-container'
          spacing={1}
        >
          {t('views.dataIntegration.history.noData')}
        </Grid>
      )}
      <SimpleTwoActionsDialogContent
        handleClickOnButton1={() => setShowRemoveConfirm(false)}
        handleClickOnButton2={() => {
          removePipelineHistoryEntry(payloadRemoveEntry.id, cardData.type);
          setShowRemoveConfirm(false);
          setPayloadRemoveEntry(null);
        }}
        id='remove-confirmation'
        labels={{
          title: t('views.dataIntegration.history.removeConfirm.title'),
          body: t('views.dataIntegration.history.removeConfirm.body'),
          button1: t('views.dataIntegration.history.removeConfirm.cancel'),
          button2: t('views.dataIntegration.history.removeConfirm.remove'),
          ariaLabelledby: 'confirm-delete-dialog',
          button2BGColor: '#062F4F'
        }}
        open={showRemoveConfirm}
      />
      <SimpleTwoActionsDialogContent
        handleClickOnButton1={() => setShowDeleteConfirm(false)}
        handleClickOnButton2={() => {
          deletePipelineHistoryRunData(payloadDeleteEntry.id, cardData.type);
          setShowDeleteConfirm(false);
          setPayloadDeleteEntry(null);
        }}
        id='delete-confirmation'
        labels={{
          title: t('views.dataIntegration.history.deleteConfirm.title'),
          body: t('views.dataIntegration.history.deleteConfirm.body'),
          button1: t('views.dataIntegration.history.deleteConfirm.cancel'),
          button2: t('views.dataIntegration.history.deleteConfirm.delete'),
          ariaLabelledby: 'confirm-delete-dialog',
          button2BGColor: '#062F4F'
        }}
        open={showDeleteConfirm}
      />
    </div>
  );
}

DataIntegrationHistory.propTypes = {
  cardData: PropTypes.object.isRequired,
  removePipelineHistoryEntry: PropTypes.func.isRequired,
  deletePipelineHistoryRunData: PropTypes.func.isRequired
};

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1rem'
  },
  subTitle: {
    marginBottom: '1rem'
  },
  tableHeader: {
    background: theme.palette.background.gradient,
    '& th': {
      color: theme.palette.text.white
    }
  },
  tableContainer: {
    maxHeight: 300,
    marginBottom: '20px'
  },
  tableRowWhiteBackground: {
    backgroundColor: theme.palette.nexans.white
  },
  tableCellTechnicalPolicies: {
    padding: 0
  },
  tableRowBackgroundDisabled: {
    backgroundColor: '#C4C4C4'
  },
  noData: {
    color: theme.palette.text.black,
    fontSize: '1rem',
    fontWeight: 'bold'
  }
}));
