import { ApiIntegration } from 'api/ApiIntegration';
import { call, put, takeEvery } from 'redux-saga/effects';
import { API_RESPONSE_ACTIONS_KEY, DATAINTEGRATION_ACTIONS_KEY } from 'state/commons';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* uploadFileInPipeline(action) {
  const { file, pipelineType, actionType, additionalParameters } = action;
  try {
    let response = '';

    yield put({ type: DATAINTEGRATION_ACTIONS_KEY.SET_LOADING, isLoading: true });
    yield put({ type: DATAINTEGRATION_ACTIONS_KEY.SET_SUBPIPELINES_LOADING, isLoading: true, pipelineType });

    const organizationId = yield selectOrganizationId();
    if (additionalParameters?.type === 'financeLifeCycle' && pipelineType === 'finance') {
      response = yield call(ApiIntegration.uploadFileToPipeline, organizationId, file, pipelineType, 'create');
    } else {
      response = yield call(ApiIntegration.uploadFileToPipeline, organizationId, file, pipelineType, actionType);
    }

    yield put({
      type: API_RESPONSE_ACTIONS_KEY.SET_API_STATUS_DATA,
      response,
      actionType: action.type
    });

    yield put({ type: DATAINTEGRATION_ACTIONS_KEY.SET_LOADING, isLoading: false });
    yield put({ type: DATAINTEGRATION_ACTIONS_KEY.SET_SUBPIPELINES_LOADING, isLoading: false, pipelineType });

    if (response.status === 200) {
      yield put({
        type: DATAINTEGRATION_ACTIONS_KEY.GET_SUBPIPELINES_DATA
      });
    }
    yield put({
      type: DATAINTEGRATION_ACTIONS_KEY.GET_PIPELINE_HISTORY,
      pipelineType
    });
  } catch (e) {
    yield put({
      type: API_RESPONSE_ACTIONS_KEY.SET_API_STATUS_DATA,
      response: e.response,
      actionType: action.type
    });
    console.error(e);
  }
}

function* uploadFileInPipelineData() {
  yield takeEvery(DATAINTEGRATION_ACTIONS_KEY.UPLOAD_PIPELINE_FILE, uploadFileInPipeline);
}

export default uploadFileInPipelineData;
