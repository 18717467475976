import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NumberUtils } from 'utils/helpers';

export default function DataIntegrationKPILong({ cardData }) {
  const classes = useStyles();
  const { t } = useTranslation();

  function displayKpi(kpiKey) {
    const interaction = cardData.kpi.modelState.interactions[kpiKey];
    const countkpi = interaction.count || '';
    const kpiValue = interaction.kpi.value !== null ? NumberUtils.formatNumberToPercentage(interaction.kpi.value) : '';

    if (!(countkpi || kpiValue)) {
      return '-';
    }

    const formattedKpiValue = countkpi ? `(${kpiValue})` : kpiValue;
    return `${countkpi} ${formattedKpiValue}`;
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.subInfos}>
          <div style={{ display: 'flex', minWidth: '50%' }}>
            <div className={classes.column}>
              <div className='sub-title'>{t('views.dataIntegration.kpi.data')}</div>
              {cardData.kpi.modelState.data &&
                Object.keys(cardData.kpi.modelState.data).map((key, index) => (
                  <div className={classes.row} key={`modelState-data-${key + index}`}>
                    <div>{t(`views.dataIntegration.kpi.${key}`)}:</div>
                    <span>
                      {typeof cardData.kpi.modelState.data[key] === 'boolean'
                        ? t(`views.dataIntegration.kpi.${cardData.kpi.modelState.data[key].toString()}`)
                        : cardData.kpi.modelState.data[key]}
                    </span>
                  </div>
                ))}
            </div>
          </div>

          <div style={{ display: 'flex', minWidth: '27%' }}>
            <div className={classes.column}>
              <div className='sub-title'>{t('views.dataIntegration.kpi.interactions')}</div>
              {cardData.kpi.modelState.interactions &&
                Object.keys(cardData.kpi.modelState.interactions).map((key, index) => (
                  <div className={classes.row} key={`modelState-interaction-${key + index}`}>
                    <div>{t(`views.dataIntegration.kpi.${key}`)}:</div>
                    <span>{displayKpi(key)}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DataIntegrationKPILong.propTypes = {
  cardData: PropTypes.object.isRequired
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    width: '82%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 700,
      color: 'black'
    },
    '& > span': {
      fontSize: '1rem',
      fontWeight: 400
    }
  },
  subInfos: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    width: '100%',
    padding: '1rem 0 0 0'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.3rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.4rem',

    '& > div': {
      fontSize: '1rem',
      fontWeight: 700
    },
    '& > span': {
      fontSize: '1rem',
      fontWeight: 400
    }
  }
}));
