import { Button, Divider, Grid, Paper, Table, TableContainer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InputComponentFactory, SwitchButton } from 'components';
import {
  FastFilling,
  FinanceTableHeader,
  FinanceTableOperatingForecastBody,
  FinanceTableWaccBody
} from 'components/ScenarioParameters/tabs/Finance';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetEditingModeStatus } from 'state/hooks/Application.hooks';
import {
  useFinanceGetNetworkLevelList,
  useFinanceGetOperatingForecastList,
  useFinanceGetWaccList,
  useFinanceNetworkLevelData,
  useFinanceOperatingForecastCurrentScenarioData,
  useFinanceOperatingForecastData,
  useFinanceSetCurrentScenarioOperatingForecastList,
  useFinanceSetCurrentScenarioWaccList,
  useFinanceWaccCurrentScenarioData,
  useFinanceWaccData
} from 'state/hooks/Finance.hooks';
import { useCurrentScenarioData } from 'state/hooks/Scenario.hooks';
import theme from 'theme';
import { concatAndRemovesDuplicatesWithTwoArrays } from 'utils/helpers/ArraysUtils';
import { isYearRangeIncluded } from 'utils/helpers/YearRangeUtils';
export default function FinanceTab({ customClasses, parameterData, parametersState }) {
  const editMode = useGetEditingModeStatus();

  const classes = useStyles(editMode);
  const { t } = useTranslation();

  const getAllNetworkLevel = useFinanceGetNetworkLevelList();
  const getAllOperatingForecast = useFinanceGetOperatingForecastList();
  const getAllWacc = useFinanceGetWaccList();
  const setCurrentScenarioWaccList = useFinanceSetCurrentScenarioWaccList();
  const setCurrentScenarioOperatingForecastList = useFinanceSetCurrentScenarioOperatingForecastList();

  const currentScenario = useCurrentScenarioData();
  const networkLevelList = useFinanceNetworkLevelData();
  const operatingForecastCurrent = useFinanceOperatingForecastCurrentScenarioData();
  const operatingForecastList = useFinanceOperatingForecastData();
  const waccCurrent = useFinanceWaccCurrentScenarioData();
  const waccList = useFinanceWaccData();

  const currentScenarioId = currentScenario.id;
  const currentSimulationRun = currentScenario.lastRun?.csmSimulationRun;
  const scenarioStart = parametersState.parameter_scenario_global_date_de_debut_scenario
    ? parametersState.parameter_scenario_global_date_de_debut_scenario
    : new Date().getFullYear();
  const scenarioEnd = parametersState.parameter_scenario_global_date_de_debut_scenario
    ? parametersState.parameter_scenario_global_date_de_fin_scenario
    : new Date().getFullYear();

  const [displayTable, setDisplayTable] = useState('wacc');
  const [alignment, setAlignment] = useState('extraCharges');
  const [operatingForecastListFilteredBySelectedNetworkLevel, setOperatingForecastListFilteredBySelectedNetworkLevel] =
    useState([]);
  const waccListWithExtendedDates = syncWaccListWithScenarioDates();
  const operatingForecastListWithExtendedDates = syncOperatingForecastListWithScenarioDates();
  const [financeForm, setFinanceForm] = useState({
    OperatingForecasts: operatingForecastListWithExtendedDates,
    WACC: waccListWithExtendedDates,
    NetworkLevel: networkLevelList[0]
  });

  // INITIALIZER
  useEffect(() => {
    if (
      parameterData.id === 'parameter_scenario_finance_list' &&
      networkLevelList.length > 0 &&
      operatingForecastList.length > 0 &&
      waccList.length > 0
    ) {
      setFinanceForm({
        OperatingForecasts: operatingForecastListWithExtendedDates,
        WACC: waccListWithExtendedDates,
        NetworkLevel: networkLevelList[0]
      });
    }
  }, [networkLevelList, operatingForecastList, waccList]);

  useEffect(() => {
    if (parameterData.id === 'parameter_scenario_finance_list') {
      getAllNetworkLevel();
      getAllOperatingForecast();
      getAllWacc();
      setTimeout(() => Function.prototype, 3000);
    }
  }, [currentScenario]);

  useEffect(() => {
    if (parameterData.id === 'parameter_scenario_finance_list' && operatingForecastList.length > 0) {
      syncOperatingForecastListWithScenarioDates();
    }
  }, [currentScenarioId, scenarioEnd, scenarioStart, currentSimulationRun, financeForm.NetworkLevel]);

  useEffect(() => {
    setFinanceForm({
      OperatingForecasts: operatingForecastListWithExtendedDates,
      WACC: waccListWithExtendedDates,
      NetworkLevel: financeForm.NetworkLevel
    });
  }, [currentScenarioId, scenarioEnd, scenarioStart, currentSimulationRun, waccList, operatingForecastList]);

  useEffect(() => {
    const filteredResult = operatingForecastListWithExtendedDates
      .filter((element) => element.networkLevel === financeForm.NetworkLevel)
      .map((el) => getDefaultValuesIfElementIsEmpty(el));
    setOperatingForecastListFilteredBySelectedNetworkLevel(filteredResult);
    updateFinanceForm('OperatingForecasts', operatingForecastListWithExtendedDates);
  }, [financeForm.NetworkLevel]);

  useEffect(() => {
    setCurrentScenarioWaccList(financeForm.WACC);
    setCurrentScenarioOperatingForecastList(financeForm.OperatingForecasts);
  }, [financeForm.OperatingForecasts, financeForm.WACC, financeForm.NetworkLevel]);

  function getDefaultValuesIfElementIsEmpty(element) {
    const defaultValueByYear = operatingForecastList?.find(
      (el) => el.networkLevel === 'Default' && el.year === element.year
    );

    const closestDefaultValue = operatingForecastList?.find((el) => el.networkLevel === 'Default');

    function autoFillValues() {
      return defaultValueByYear ? defaultValueByYear : closestDefaultValue || { extraCharges: 0, suppliedEnergy: 0 };
    }

    return element.extraCharges && element.suppliedEnergy
      ? element
      : {
          ...element,
          extraCharges: autoFillValues()?.extraCharges,
          suppliedEnergy: autoFillValues()?.suppliedEnergy
        };
  }

  const waccYearsList = financeForm.WACC.map((el) => el.year);
  const operatingForecastYearsList = operatingForecastListFilteredBySelectedNetworkLevel.map((el) => el.year);

  function getNetworkLevelTypes() {
    return networkLevelList.map((key) => ({
      key: key,
      value: key
    }));
  }

  function fastFillWaccFinanceForm(yearRange, customValue) {
    const newWaccCurrent = waccCurrent.map((item) =>
      isYearRangeIncluded(yearRange, item.year) ? { ...item, wacc: customValue / 100 } : item
    );
    updateFinanceForm('WACC', newWaccCurrent);
  }

  function fastFillOperatingForecastsFinanceForm(yearRange, customValue) {
    const newOperatingForcecast = operatingForecastCurrent.map((item) =>
      isYearRangeIncluded(yearRange, item.year) && financeForm.NetworkLevel === item.networkLevel
        ? { ...item, [alignment]: customValue }
        : item
    );
    updateFinanceForm('OperatingForecasts', newOperatingForcecast);
  }

  function updateFinanceForm(field, value) {
    setFinanceForm({
      ...financeForm,
      [field]: value
    });
  }

  function handleChangeWaccValue(year, event) {
    const { value } = event.target;
    updateFinanceForm(
      'WACC',
      financeForm.WACC.map((el) => (el.year === year ? { ...el, wacc: value / 100 } : el))
    );
  }

  function handleChangeOperatingForecastValue(row, year, event) {
    const { value } = event.target;
    updateFinanceForm(
      'OperatingForecasts',
      financeForm.OperatingForecasts.map((el) => {
        return el.year === year && el.networkLevel === financeForm.NetworkLevel ? { ...el, [row]: value } : el;
      })
    );
  }

  function syncWaccListWithScenarioDates() {
    const array = [];
    const foundGlobal =
      waccCurrent.length > 0
        ? waccCurrent.find((el) => (el.year >= scenarioStart || el.year <= scenarioEnd) && el.wacc > 0)
        : waccList?.find((el) => el.year >= scenarioStart || (el.year <= scenarioEnd && el.wacc > 0));

    // find closest value if no value is found in the scenario
    for (let iYear = scenarioStart; iYear <= scenarioEnd; iYear += 1) {
      const found = waccList?.find((el) => el.year === iYear);

      found
        ? array.push(found)
        : array.push({
            year: iYear,
            wacc: foundGlobal ? foundGlobal.wacc : 0,
            scenarioId: currentScenarioId,
            simulationRun: currentSimulationRun
          });
    }
    return array;
  }

  function syncOperatingForecastListWithScenarioDates() {
    const array = [];
    const concatenedList = concatAndRemovesDuplicatesWithTwoArrays(operatingForecastList, operatingForecastCurrent);

    networkLevelList.forEach((nl) => {
      for (let iYear = scenarioStart - 1; iYear <= scenarioEnd; iYear += 1) {
        const found = concatenedList?.find((el) => el.year === iYear && el.networkLevel === nl);
        const defaultFound = concatenedList?.find((el) => el.year === iYear && el.networkLevel === 'Default');
        const closestDefaultValue = concatenedList
          .filter((el) => el.networkLevel === 'Default')
          .sort((a, b) => Math.abs(a.year - iYear) - Math.abs(b.year - iYear))[0];

        found
          ? array.push(found)
          : array.push({
              networkLevel: nl,
              year: iYear,
              suppliedEnergy: defaultFound?.suppliedEnergy || closestDefaultValue?.suppliedEnergy || 0,
              extraCharges: defaultFound?.extraCharges || closestDefaultValue?.extraCharges || 0,
              scenarioId: currentScenarioId,
              simulationRun: currentSimulationRun
            });
      }
    });
    return array;
  }

  function handleAlignmentChange(newAlignment) {
    setAlignment(newAlignment);
  }

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={2}>
        <div className={classes.leftContainer}>
          <div className={classes.buttonContainer}>
            <Button
              color='primary'
              style={{ opacity: displayTable === 'operating-forecast' ? 0.5 : 1 }}
              variant='contained'
              onClick={() => setDisplayTable('wacc')}
            >
              {t('commoncomponents.scenario-form.finance-tab.wacc')}
            </Button>
            <Button
              color='primary'
              style={{ opacity: displayTable === 'wacc' ? 0.5 : 1 }}
              variant='contained'
              onClick={() => setDisplayTable('operating-forecast')}
            >
              {t('commoncomponents.scenario-form.finance-tab.operatingForecast')}
            </Button>
          </div>
          {networkLevelList.length > 0 && displayTable === 'operating-forecast' && (
            <div style={{ display: 'flex' }}>
              <InputComponentFactory
                data={financeForm}
                editProps={{
                  type: 'select',
                  enumList: getNetworkLevelTypes(),
                  isValueFromDictionary: false
                }}
                index='finance-form-row-1-input-1'
                label='Network level list'
                testId='network-level-selector'
                value={financeForm.NetworkLevel}
                onChange={(newValue) => {
                  updateFinanceForm('NetworkLevel', newValue);
                }}
              />
            </div>
          )}
        </div>
      </Grid>
      <Divider flexItem={true} orientation='vertical' style={{ margin: '0 1rem' }} />
      <Grid
        className={classes.containerDisabled}
        disabled={!editMode}
        item={true}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '70vw',
          overflowX: 'scroll',
          overscrollBehaviorX: 'contain'
        }}
        xs={true}
      >
        {displayTable === 'wacc' && (
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <TableContainer component={Paper}>
              <Table data-testid='wacc-table'>
                <FinanceTableHeader headersYears={waccYearsList} type='wacc' />
                <FinanceTableWaccBody
                  handleChangeValueFromYear={handleChangeWaccValue}
                  headersYears={waccYearsList}
                  list={financeForm.WACC}
                  type='wacc'
                />
              </Table>
            </TableContainer>
            <FastFilling
              customValueLabel={t('commoncomponents.scenario-form.finance-tab.custom-wacc')}
              dataTestId='fast-filling-wacc'
              defaultYearRange={{ start: scenarioStart, end: scenarioEnd }}
              editMode={editMode}
              maxCustomValue={100}
              minCustomValue={0}
              unit='%'
              onFastFillingButtonClicked={fastFillWaccFinanceForm}
            />
          </div>
        )}
        {displayTable === 'operating-forecast' && (
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <TableContainer component={Paper}>
              <Table data-testid='operating-forecast-table'>
                <FinanceTableHeader headersYears={operatingForecastYearsList} type='operating-forecast' />
                <FinanceTableOperatingForecastBody
                  handleChangeValueFromYear={handleChangeOperatingForecastValue}
                  headersYears={operatingForecastYearsList}
                  list={financeForm.OperatingForecasts}
                  selectedNetwokLevel={financeForm.NetworkLevel}
                  type='operating-forecast'
                />
              </Table>
            </TableContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SwitchButton
                initialButtonValue={alignment}
                orientation='vertical'
                primaryButton={{
                  value: 'extraCharges',
                  label: 'commoncomponents.scenario-form.finance-tab.extraCharges'
                }}
                secondaryButton={{
                  value: 'suppliedEnergy',
                  label: 'commoncomponents.scenario-form.finance-tab.suppliedEnergy'
                }}
                onAlignmentChange={handleAlignmentChange}
              />
              <FastFilling
                customValueLabel={t(`commoncomponents.scenario-form.finance-tab.custom-${alignment}`)}
                dataTestId={`fast-filling-${alignment}`}
                defaultYearRange={{ start: scenarioStart, end: scenarioEnd }}
                editMode={editMode}
                onFastFillingButtonClicked={fastFillOperatingForecastsFinanceForm}
              />
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

FinanceTab.propTypes = {
  parametersState: PropTypes.object.isRequired,
  customClasses: PropTypes.object,
  parameterData: PropTypes.object.isRequired
};

const useStyles = makeStyles(() => ({
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    alignSelf: 'center'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    [theme.breakpoints.down('xl')]: {
      flexDirection: 'column'
    }
  },
  containerDisabled: {
    pointerEvents: (editMode) => (editMode ? 'auto' : 'none'),
    opacity: (editMode) => (editMode ? 1 : 0.5),
    cursor: (editMode) => (editMode ? 'pointer' : 'not-allowed')
  },
  headerTableContainer: {
    background: theme.palette.nexans.primary
  }
}));
