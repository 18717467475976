import { ApiPredeterminedProject } from 'api/ApiPredeterminedProject';
import { call, put, takeEvery } from 'redux-saga/effects';
import { PREDETERMINED_PROJECT_ACTIONS_KEY } from 'state/commons/PredeterminedProjectConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* importPredeterminedProjectsFile(action) {
  const file = action.file;
  try {
    yield put({
      type: PREDETERMINED_PROJECT_ACTIONS_KEY.SET_PREDETERMINED_PROJECTS_IMPORT_ERRORS,
      errors: null
    });
    yield put({
      type: PREDETERMINED_PROJECT_ACTIONS_KEY.SET_PREDETERMINED_PROJECTS_IMPORT_UPLOADING,
      uploading: true
    });
    const organizationId = yield selectOrganizationId();
    yield call(ApiPredeterminedProject.importPredeterminedProjectsFile, organizationId, file);
    yield put({
      type: PREDETERMINED_PROJECT_ACTIONS_KEY.GET_ALL_PREDETERMINED_PROJECTS,
      params: { keyword: null, perPage: 5, page: 1 }
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: PREDETERMINED_PROJECT_ACTIONS_KEY.SET_PREDETERMINED_PROJECTS_IMPORT_ERRORS,
      errors: e.response?.data
    });
  }
  yield put({
    type: PREDETERMINED_PROJECT_ACTIONS_KEY.SET_PREDETERMINED_PROJECTS_IMPORT_UPLOADING,
    uploading: false
  });
}

function* UploadPredeterminedProjectsFile() {
  yield takeEvery(PREDETERMINED_PROJECT_ACTIONS_KEY.UPLOAD_PREDETERMINED_PROJECTS, importPredeterminedProjectsFile);
}

export default UploadPredeterminedProjectsFile;
