import { ApiIntegration } from 'api/ApiIntegration';
import { call, put, takeEvery } from 'redux-saga/effects';
import { DATAINTEGRATION_ACTIONS_KEY } from '../../../commons/DataIntegrationConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* deletePipelineEntryRunData(action) {
  const { entryId, pipelineType } = action;
  try {
    const organizationId = yield selectOrganizationId();
    const response = yield call(ApiIntegration.deletePipelineHistoryRunData, organizationId, entryId);
    if (response) {
      yield put({
        type: DATAINTEGRATION_ACTIONS_KEY.GET_PIPELINE_HISTORY,
        pipelineType
      });
      yield put({
        type: DATAINTEGRATION_ACTIONS_KEY.GET_SUBPIPELINES_DATA
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* deletePipelineRunData() {
  yield takeEvery(DATAINTEGRATION_ACTIONS_KEY.DELETE_PIPELINE_HISTORY_RUN_DATA, deletePipelineEntryRunData);
}

export default deletePipelineRunData;
