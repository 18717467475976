import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SimpleTwoActionsDialogContent } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import EditTable from '../EditTable';
import InputComponentFactory from '../InputComponentFactory';

function OperationForm(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showMissingFields, setShowMissingfields] = useState(false);
  const [areActionTasksNameValid, setAreActionTasksNameValid] = useState(true);

  const {
    familiesByAssetClassList,
    isNameAvailable,
    isNameAvailableStatus,
    operationsEnums,
    selectedAssetClass,
    selectedOperation,
    skillsList,

    addBreadCrumbsItems,
    applyUpdate,
    cancelUpdate,
    checkIsNameExist
  } = props;

  const taskTableTraductions = {
    deleteDialog: {
      title: 'genericcomponent.dialog.operation.task.title',
      body: 'genericcomponent.dialog.operation.task.body',
      cancel: 'genericcomponent.dialog.operation.task.cancel',
      delete: 'genericcomponent.dialog.operation.task.delete'
    }
  };

  const [operationForm, setOperationForm] = useState({
    $dtId: selectedOperation.$dtId,
    $etag: selectedOperation.$etag,
    Id: selectedOperation.id,

    AssetClass: selectedOperation.AssetClass,
    NeedsOutage: selectedOperation.NeedsOutage,
    IsLocalAction: selectedOperation.IsLocalAction,
    BudgetCategories: selectedOperation.BudgetCategories,
    CostsAreCapitalCharges: selectedOperation.CostsAreCapitalCharges,
    CostsByRatingLevel: selectedOperation.CostsByRatingLevel,
    SubActionFamilies: selectedOperation.SubActionFamilies,
    ActionTasks: selectedOperation.ActionTasks
  });

  useEffect(() => {
    initializeOperationForm();
  }, [selectedOperation]);

  function initializeOperationForm() {
    const item = selectedOperation.$dtId
      ? selectedOperation.$dtId
      : t('layouts.tabs.library.operations.newOperation.breadcrumb.title');
    addBreadCrumbsItems([item]);
    setOperationForm({ ...selectedOperation, AssetClass: selectedAssetClass });
  }

  function updateOperationForm(field, value) {
    const newValue = value;
    switch (field) {
      case 'IsLocalAction': {
        setOperationForm({
          ...operationForm,
          [field]: operationsEnums.actionScope[newValue] !== undefined ? operationsEnums.actionScope[newValue] : null
        });
        break;
      }
      case '$dtId': {
        const newSubActionFamilies = updateSubActionFamiliesDtId(operationForm.SubActionFamilies, newValue);
        const newOperation = {
          ...operationForm,
          [field]: newValue,
          SubActionFamilies: newSubActionFamilies
        };
        // If edit existing form, spare checkName
        if (operationForm.$dtId !== newOperation.$dtId) {
          checkIsNameExist(value, selectedOperation, newOperation);
        }
        setOperationForm(newOperation);
        break;
      }
      default: {
        setOperationForm({
          ...operationForm,
          [field]: newValue
        });
        break;
      }
    }
  }

  function updateRatingLevel(ratingLevelKey, field, value) {
    const newValue = {
      ...operationForm.CostsByRatingLevel,
      [ratingLevelKey]: {
        ...operationForm.CostsByRatingLevel[ratingLevelKey],
        [field]: value
      }
    };
    updateOperationForm('CostsByRatingLevel', newValue);
  }

  function updateSubActionFamilies(newValue, index, property) {
    if (index !== null && index !== undefined && property) {
      let updatedSubActionFamilies = mapSubActionFamiliesToTable(operationForm.SubActionFamilies).map(
        (subActionFamily, subActionFamilyIndex) => {
          if (index === subActionFamilyIndex) {
            let updateSubActionFamily = {
              ...subActionFamily,
              [property]: newValue
            };
            if (property === 'subActionFamilyType') {
              updateSubActionFamily = {
                ...updateSubActionFamily,
                subActionFamilyImpactOnEquipment: 0,
                subActionFamilyAgingFamily: null
              };
            }
            return updateSubActionFamily;
          }
          return subActionFamily;
        }
      );
      updatedSubActionFamilies = mapTabletoSubActionFamilies(updatedSubActionFamilies);
      updateOperationForm('SubActionFamilies', updatedSubActionFamilies);
    }
  }

  function updateActionTasks(newValue, index, property) {
    if (index !== null && index !== undefined && property) {
      let updatedActionTasks = mapActionTasksToTable(operationForm.ActionTasks).map((actionTask, actionTaskIndex) => {
        if (index === actionTaskIndex) {
          if (
            operationForm.ActionTasks.find((element) => element.$dtId === newValue || element.Name === newValue) &&
            property === 'Name'
          ) {
            setAreActionTasksNameValid(false);
            return {
              ...actionTask,
              [property]: t('genericcomponent.dialog.missingFields.operation.taskNameTaken')
            };
          }
          setAreActionTasksNameValid(true);
          const updateActionTask = {
            ...actionTask,
            [property]: newValue,
            $dtId: newValue
          };
          return updateActionTask;
        }
        return actionTask;
      });
      updatedActionTasks = mapTableToActionTasks(updatedActionTasks);
      updateOperationForm('ActionTasks', updatedActionTasks);
    } else {
      const actionTaskToDelete = mapTableToActionTasks([newValue])[0];
      deleteActionTask(actionTaskToDelete);
    }
  }

  function updateSubActionFamiliesDtId(subActionFamilies, operationDtId) {
    if (!subActionFamilies?.length) return [];

    return subActionFamilies.map((family) => ({
      ...family,
      $dtId: `${operationDtId}-${family.ApplyToAgingFamily}`
    }));
  }

  const isTaskValid = operationForm.ActionTasks?.every(
    (task) => task.Skill && task.ActionDurationPercentage > 0 && task.WorkforceFTE > 0
  );

  function isFormValid() {
    return (
      operationForm.$dtId &&
      operationForm.AssetClass &&
      isNameAvailable &&
      isNameAvailableStatus &&
      operationForm.IsLocalAction !== null &&
      operationForm.IsLocalAction !== undefined &&
      areActionTasksNameValid &&
      isTaskValid
    );
  }

  function getMissingFields() {
    let missingFields = [];
    if (!operationForm.$dtId) missingFields.push(t('genericcomponent.dialog.missingFields.operation.$dtId'));
    if (getErrorMessage()) {
      missingFields.push(t('views.library.operation.alreadyExistLower'));
    }
    if (operationForm.IsLocalAction === null || operationForm.IsLocalAction === undefined)
      missingFields.push(t('genericcomponent.dialog.missingFields.operation.isLocalAction'));
    if (missingFields.length > 1) {
      // eslint-disable-next-line prefer-regex-literals
      const regexp = new RegExp(/, (?![\s\S]*, )/);
      missingFields =
        t('genericcomponent.dialog.missingFields.introduction') +
        missingFields.join(', ').replace(regexp, t('genericcomponent.dialog.missingFields.and'));
    } else if (missingFields.length === 1) {
      missingFields = t('genericcomponent.dialog.missingFields.introduction') + missingFields[0];
    } else if (!areActionTasksNameValid) {
      missingFields = t('genericcomponent.dialog.missingFields.operation.taskNameTaken');
    } else if (!isTaskValid) {
      missingFields = t('genericcomponent.dialog.missingFields.operation.uncompleteTask');
    } else {
      missingFields = '';
    }
    return missingFields;
  }

  function validateForm() {
    if (isFormValid()) {
      const replaceWithRetrofitAgingFamily = operationForm.SubActionFamilies.map((family) => {
        if (family.ReplaceWithRetrofitAgingFamily === 'NoFamilyChange') {
          return {
            ...family,
            ReplaceWithRetrofitAgingFamily: null
          };
        }
        return family;
      });

      const updatedOperationForm = {
        ...operationForm,
        SubActionFamilies: replaceWithRetrofitAgingFamily
      };

      applyUpdate(updatedOperationForm);
    } else {
      setShowMissingfields(true);
    }
  }

  function getActionScopeOptions() {
    let actionScopeOptions = [];
    if (operationsEnums.actionScope) {
      actionScopeOptions = Object.keys(operationsEnums.actionScope).map((key) => ({ key, value: key }));
    }
    return actionScopeOptions;
  }

  function getIsLocalAction() {
    let isLocalAction = null;
    if (!operationsEnums.actionScope) {
      return isLocalAction;
    }
    Object.keys(operationsEnums.actionScope).forEach((actionScopeKey) => {
      if (operationsEnums.actionScope[actionScopeKey] === operationForm.IsLocalAction) {
        isLocalAction = actionScopeKey;
      }
    });
    return isLocalAction;
  }

  function getBudgetCategoriesOptions() {
    let budgetCategoriesOptions = [];
    if (operationsEnums.budgets) {
      budgetCategoriesOptions = operationsEnums.budgets.map((budgetCategory) => ({
        key: budgetCategory,
        value: budgetCategory
      }));
    }
    return budgetCategoriesOptions;
  }

  function getSubActionTypeOptions() {
    let subActionTypeOptions = [];
    if (operationsEnums.subActionTypes) {
      subActionTypeOptions = Object.keys(operationsEnums.subActionTypes).map((typeKey) => ({
        key: typeKey,
        value: operationsEnums.subActionTypes[typeKey]
      }));
    }

    return subActionTypeOptions;
  }

  function getSubActionReplacementAgingFamilyIdOptions() {
    const subActionReplacementAgingFamilyIdOptions = [
      { key: 'NoFamilyChange', value: 'NoFamilyChange' },
      ...familiesByAssetClassList.map((familyByAssetClass) => ({
        key: familyByAssetClass,
        value: familyByAssetClass
      }))
    ];
    return subActionReplacementAgingFamilyIdOptions;
  }

  function getActionTaskSkillOptions() {
    return [
      { key: 'None', value: '' },
      ...skillsList.map((skill) => ({
        key: skill,
        value: skill
      }))
    ];
  }

  function mapSubActionFamiliesToTable(subActionFamilies) {
    return subActionFamilies.map((subActionFamily) => ({
      subActionFamilyDtId: subActionFamily.$dtId,
      subActionFamilyName: subActionFamily.ApplyToAgingFamily,
      subActionFamilyType: subActionFamily.Type,
      subActionFamilyImpactOnEquipment: subActionFamily.ImpactOnEquipment,
      subActionFamilyAgingFamily: subActionFamily.ReplaceWithRetrofitAgingFamily || 'NoFamilyChange'
    }));
  }

  function mapTabletoSubActionFamilies(subActionFamiliesTable) {
    return subActionFamiliesTable.map((row) => ({
      $dtId: row.subActionFamilyDtId ?? `${operationForm.$dtId}-${row.subActionFamilyName}`,
      ApplyToAgingFamily: row.subActionFamilyName,
      Type: row.subActionFamilyType,
      ImpactOnEquipment: row.subActionFamilyImpactOnEquipment,
      ReplaceWithRetrofitAgingFamily: row.subActionFamilyAgingFamily
    }));
  }

  function mapActionTasksToTable(actionTasks) {
    return actionTasks.map((actionTask) => ({
      actionTaskId: actionTask.$dtId,
      actionTaskName: actionTask.Name,
      actionTaskSkill: actionTask.Skill,
      actionTaskActionDurationPercentage: actionTask.ActionDurationPercentage,
      actionTaskWorkforceFTE: actionTask.WorkforceFTE
    }));
  }

  function mapTableToActionTasks(actionTasksTable) {
    return actionTasksTable.map((row) => ({
      $dtId: row.actionTaskId,
      Name: row.actionTaskName,
      Skill: row.actionTaskSkill,
      ActionDurationPercentage: row.actionTaskActionDurationPercentage,
      WorkforceFTE: row.actionTaskWorkforceFTE
    }));
  }

  function getSubActionFamiliesTableSettings() {
    const dtIdSettings = { type: 'info' };
    const TypeSettings = {
      type: 'select',
      enumList: getSubActionTypeOptions()
    };
    const ImpactOnEquipmentSettings = {
      needValueOf: {
        replacement: {
          subActionFamilyType: ['Replacement']
        },
        repairs: {
          subActionFamilyType: ['Repairs']
        },
        others: {
          subActionFamilyType: ['NoAction', 'Inspection', 'Activation', 'Deactivation', 'RepairsWithoutInspection']
        }
      },
      types: {
        replacement: 'none',
        repairs: 'unumber',
        others: 'none'
      },
      infoTexts: {
        replacement: '-',
        others: '-'
      },
      prefix: '%'
    };
    const AgingFamilyIdSettings = {
      needValueOf: {
        replacement: {
          subActionFamilyType: ['Replacement']
        },
        repairs: {
          subActionFamilyType: ['Repairs']
        },
        others: {
          subActionFamilyType: ['NoAction', 'Inspection', 'Activation', 'Deactivation', 'RepairsWithoutInspection']
        }
      },
      types: {
        replacement: 'select',
        repairs: 'none',
        others: 'none'
      },
      infoTexts: {
        repairs: '-',
        others: '-'
      },
      exceptions: {
        replacement: 'subActionFamilyName'
      },
      enumLists: {
        replacement: getSubActionReplacementAgingFamilyIdOptions()
      }
    };
    return {
      cols: [
        'subActionFamilyName',
        'subActionFamilyType',
        'subActionFamilyImpactOnEquipment',
        'subActionFamilyAgingFamily'
      ],
      rowId: 'subActionFamilyName',
      isAlwaysEdit: true,
      colProps: [dtIdSettings, TypeSettings, ImpactOnEquipmentSettings, AgingFamilyIdSettings]
    };
  }

  function canAddActionTask() {
    let checkAddActionTask = true;
    operationForm.ActionTasks.forEach((actionTask) => {
      if (actionTask.$dtId === '' || actionTask.$dtId === null || actionTask.$dtId === undefined) {
        checkAddActionTask = false;
      }
    });
    return checkAddActionTask;
  }

  function addActionTask() {
    if (canAddActionTask()) {
      const newActionTask = {
        $dtId: generationNewActionTaskId(),
        Name: generationNewActionTaskId(),
        ActionDurationPercentage: 0,
        WorkforceFTE: 0
      };
      const updatedActionTasks = [...operationForm.ActionTasks, newActionTask];
      updateOperationForm('ActionTasks', updatedActionTasks);
    }
  }

  function deleteActionTask(actionTask) {
    const updatedActionTasks = operationForm.ActionTasks.filter((a) => a.$dtId !== actionTask.$dtId);
    updateOperationForm('ActionTasks', updatedActionTasks);
  }

  function generationNewActionTaskId(iterationId) {
    let newActionTaskId = t('views.library.operation.newTask');
    if (iterationId) {
      newActionTaskId = `${newActionTaskId} - ${iterationId}`;
    }
    const idUnavailable = operationForm.ActionTasks.findIndex((actionTask) => actionTask.$dtId === newActionTaskId);
    if (idUnavailable >= 0) {
      const nextIteration = iterationId ? iterationId + 1 : 1;
      return generationNewActionTaskId(nextIteration);
    }
    return newActionTaskId;
  }

  function getActionTasksTableSettings() {
    const dtIdSettings = { type: 'string' };
    const SkillSettings = {
      type: 'select',
      enumList: getActionTaskSkillOptions()
    };
    const ActionDurationPercentageSettings = { type: 'nullablePercentage', defaultValue: 0 };
    const WorkforceFTESettings = {
      type: 'unumber',
      prefix: t('views.library.operation.taskWorkForcePrefix'),
      defaultValue: 0
    };
    return {
      cols: ['actionTaskName', 'actionTaskSkill', 'actionTaskActionDurationPercentage', 'actionTaskWorkforceFTE'],
      rowId: 'actionTaskName',
      actions: ['delete'],
      isAlwaysEdit: true,
      colProps: [dtIdSettings, SkillSettings, ActionDurationPercentageSettings, WorkforceFTESettings]
    };
  }

  function getErrorMessage() {
    if (!isNameAvailable) {
      return t('views.library.operation.alreadyExist');
    }
    return null;
  }

  return (
    <Card className={classes.cardForm}>
      {selectedOperation && (
        <CardContent>
          <h2 style={{ marginBottom: '10px' }}>{operationForm.AssetClass}</h2>

          <h2 className='sub-title'>{t('views.library.operation.generalParameters')}</h2>

          <Grid className={classes.formContainer} container={true} key='operation-form-row-0'>
            <Grid className={classes.gridForm} item={true} key='operation-form-row-0-col-0' xs={2}>
              <span className={classes.inputLabel}>{t('views.library.operation.name')}</span>
            </Grid>

            <Grid className={classes.gridForm} item={true} key='operation-form-row-0-col-1' xs={2}>
              <InputComponentFactory
                data={operationForm}
                editProps={{ type: 'string', errorMessage: getErrorMessage() }}
                index='operation-form-row-0-input-0'
                value={operationForm.$dtId ? operationForm.$dtId : ''}
                onChange={(event) => updateOperationForm('$dtId', event)}
              />
            </Grid>

            <Grid className={classes.gridForm} item={true} key='operation-form-row-0-col-2' xs={2}>
              <span className={classes.inputLabel}>{t('views.library.operation.needsOutage')}</span>
            </Grid>

            <Grid className={classes.gridForm} item={true} key='operation-form-row-0-col-3' xs={2}>
              <InputComponentFactory
                data={operationForm}
                editProps={{ type: 'boolean', isBoolean: true }}
                index='operation-form-row-0-input-1'
                value={operationForm.NeedsOutage ? operationForm.NeedsOutage : false}
                onChange={(newValue) => updateOperationForm('NeedsOutage', newValue)}
              />
            </Grid>
          </Grid>

          <Grid className={classes.formContainer} container={true} key='operation-form-row-1'>
            <Grid className={classes.gridForm} item={true} key='operation-form-row-1-col-0' xs={2}>
              <span className={classes.inputLabel}>{t('views.library.operation.actionScope')}</span>
            </Grid>
            <Grid className={classes.gridForm} item={true} key='operation-form-row-1-col-1' xs={2}>
              <InputComponentFactory
                data={operationForm}
                editProps={{
                  type: 'select',
                  enumList: getActionScopeOptions()
                }}
                index='operation-form-row-1-input-0'
                value={getIsLocalAction()}
                onChange={(newValue) => updateOperationForm('IsLocalAction', newValue)}
              />
            </Grid>

            <Grid className={classes.gridForm} item={true} key='operation-form-row-1-col-2' xs={2}>
              <span className={classes.inputLabel}>{t('views.library.operation.budgetCategories')}</span>
            </Grid>

            <Grid className={classes.gridForm} item={true} key='operation-form-row-1-col-3' xs={2}>
              <InputComponentFactory
                data={operationForm}
                editProps={{
                  type: 'multi-select',
                  enumList: getBudgetCategoriesOptions(),
                  isValueFromDictionary: true
                }}
                index='operation-form-row-1-input-1'
                value={operationForm.BudgetCategories}
                onChange={(newValue) => {
                  updateOperationForm('BudgetCategories', newValue);
                }}
              />
            </Grid>
          </Grid>

          <Grid className={classes.formContainer} container={true} key='operation-form-row-2'>
            <Grid className={classes.gridForm} item={true} key='operation-form-row-2-col-0' xs={2}>
              <span className={classes.inputLabel}>{t('views.library.operation.costsAreCapitalCharges')}</span>
            </Grid>

            <Grid className={classes.gridForm} item={true} key='operation-form-row-2-col-1' xs={2}>
              <InputComponentFactory
                data={operationForm}
                editProps={{ type: 'boolean', isBoolean: true }}
                index='operation-form-row-2-input-1'
                value={operationForm.CostsAreCapitalCharges ? operationForm.CostsAreCapitalCharges : false}
                onChange={(newValue) => updateOperationForm('CostsAreCapitalCharges', newValue)}
              />
            </Grid>
          </Grid>

          <h2 className='sub-title'>{t('views.library.operation.parametersByLevels')}</h2>

          {operationForm.CostsByRatingLevel &&
            Object.keys(operationForm.CostsByRatingLevel).map((ratingLevelKey, ratingLevelIndex) => {
              const ratingLevel = operationForm.CostsByRatingLevel[ratingLevelKey];
              return (
                <div key={`ratingLevelIndex-${ratingLevelKey + ratingLevelIndex}`}>
                  <br />
                  <h3>{ratingLevelKey}</h3>

                  <Grid
                    className={classes.formContainer}
                    container={true}
                    key={`operation-form-row-3-level-${ratingLevelIndex}`}
                  >
                    <Grid
                      className={classes.gridForm}
                      item={true}
                      key={`operation-form-row-3-col-0-${ratingLevelIndex}`}
                      xs={2}
                    >
                      <span className={classes.inputLabel}>{t('views.library.operation.ratingLevel.duration')}</span>
                    </Grid>

                    <Grid
                      className={classes.gridForm}
                      item={true}
                      key={`operation-form-row-3-col-1-${ratingLevelIndex}`}
                      xs={2}
                    >
                      <InputComponentFactory
                        data={ratingLevel}
                        editProps={{
                          type: 'number',
                          prefix: t('views.library.operation.ratingLevel.durationPrefix')
                        }}
                        index={`operation-form-row-3-input-0-${ratingLevelIndex}`}
                        value={ratingLevel.Duration ? ratingLevel.Duration : 0}
                        onChange={(event) => updateRatingLevel(ratingLevelKey, 'Duration', event)}
                      />
                    </Grid>

                    <Grid
                      className={classes.gridForm}
                      item={true}
                      key={`operation-form-row-3-col-2-${ratingLevelIndex}`}
                      xs={2}
                    >
                      <span className={classes.inputLabel}>
                        {t('views.library.operation.ratingLevel.supplierCost')}
                      </span>
                    </Grid>

                    <Grid
                      className={classes.gridForm}
                      item={true}
                      key={`operation-form-row-3-col-3-${ratingLevelIndex}`}
                      xs={2}
                    >
                      <InputComponentFactory
                        data={ratingLevel}
                        editProps={{
                          type: 'number',
                          prefix: t('views.library.operation.ratingLevel.supplierCostPrefix')
                        }}
                        index={`operation-form-row-3-input-1-${ratingLevelIndex}`}
                        value={ratingLevel.SupplierCost ? ratingLevel.SupplierCost : 0}
                        onChange={(event) => updateRatingLevel(ratingLevelKey, 'SupplierCost', event)}
                      />
                    </Grid>
                  </Grid>
                </div>
              );
            })}

          <h2 className='sub-title'>{t('views.library.operation.subAction')}</h2>

          <br />

          <EditTable
            data={mapSubActionFamiliesToTable(operationForm.SubActionFamilies)}
            tableSettings={getSubActionFamiliesTableSettings()}
            onChange={updateSubActionFamilies}
          />

          <br />

          <Grid container={true} justifyContent='space-between'>
            <Grid item={true} xs={10}>
              <h2 className='sub-title'>{t('views.library.operation.task')}</h2>
            </Grid>
            <Grid item={true} style={{ textAlign: 'right' }} xs={2}>
              <Button
                className={classes.addBtn}
                color={canAddActionTask() ? 'primary' : 'secondary'}
                variant='outlined'
                onClick={addActionTask}
              >
                {t('views.library.operation.newTask')} <ControlPointIcon />
              </Button>
            </Grid>
          </Grid>

          <br />

          <EditTable
            data={mapActionTasksToTable(operationForm.ActionTasks)}
            tableSettings={getActionTasksTableSettings()}
            traductions={taskTableTraductions}
            onChange={updateActionTasks}
          />

          <div className={classes.btnEndForm}>
            <Button color='primary' variant='outlined' onClick={cancelUpdate}>
              {t('views.library.technicalpolicy.cancel')} <AddCircleOutlineIcon className={classes.cancel} />
            </Button>
            <Button
              color={isFormValid() ? 'primary' : 'secondary'}
              type='button'
              variant='contained'
              onClick={validateForm}
            >
              {t('views.library.operation.save')} <SaveIcon />
            </Button>
          </div>
        </CardContent>
      )}

      <SimpleTwoActionsDialogContent
        handleClickOnButton1={() => setShowMissingfields(false)}
        handleClickOnButton2={() => setShowMissingfields(false)}
        id='missing-fields'
        key='missing-fields'
        labels={{
          title: t('genericcomponent.dialog.missingFields.title'),
          body: getMissingFields(),
          button1: t('genericcomponent.dialog.missingFields.cancel'),
          button2: t('genericcomponent.dialog.missingFields.ok'),
          ariaLabelledby: 'missing-field-dialog',
          button2BGColor: '#062F4F'
        }}
        open={showMissingFields}
      />
    </Card>
  );
}

OperationForm.propTypes = {
  familiesByAssetClassList: PropTypes.array.isRequired,
  isNameAvailable: PropTypes.bool.isRequired,
  isNameAvailableStatus: PropTypes.bool.isRequired,
  operationsEnums: PropTypes.object.isRequired,
  selectedAssetClass: PropTypes.string.isRequired,
  selectedOperation: PropTypes.object.isRequired,
  skillsList: PropTypes.array.isRequired,

  addBreadCrumbsItems: PropTypes.func.isRequired,
  applyUpdate: PropTypes.func.isRequired,
  cancelUpdate: PropTypes.func.isRequired,
  checkIsNameExist: PropTypes.func.isRequired
};

export default OperationForm;

const useStyles = makeStyles(() => ({
  flexSearch: {
    display: 'flex'
  },
  searchInput: {
    backgroundColor: theme.palette.nexans.white,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    marginRight: 24
  },
  searchInputField: {
    padding: 8,
    flex: 1,
    color: 'black'
  },
  searchIcon: {
    background: theme.palette.background.gradient,
    color: theme.palette.nexans.white,
    borderRadius: '0 10px 10px 0'
  },
  cardForm: {
    width: '90%',
    margin: '24px auto',
    borderRadius: '10px'
  },
  formContainer: {
    margin: '24px 0'
  },
  inputLabel: {
    color: 'black'
  },
  gridForm: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px'
  },
  radio: {
    margin: 'auto'
  },
  btnEndForm: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 25,
    '& button': {
      borderRadius: 100,
      margin: '0 8px'
    }
  },
  cancel: {
    marginLeft: 8,
    transform: 'rotate(45deg)'
  },
  addBtn: {
    // width: 240,
    alignSelf: 'end',
    borderRadius: 50,
    border: '1.5px solid #062F4F',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    '& svg': {
      marginLeft: 8
    }
  }
}));
