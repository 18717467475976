import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getFamiliesUrl = (organizationId) => `${getBasePath(organizationId)}/Families`;

const fetchAllFamilies = (organizationId) =>
  axios
    .get(getFamiliesUrl(organizationId))
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const fetchAllFamiliesByAssetClass = (organizationId, assetClass) =>
  axios
    .get(`${getFamiliesUrl(organizationId)}/${assetClass}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const fetchAllNetworkLevel = (organizationId) =>
  axios
    .get(`${getFamiliesUrl(organizationId)}/networklevels`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

export const ApiFamily = {
  fetchAllFamilies,
  fetchAllFamiliesByAssetClass,
  fetchAllNetworkLevel
};
