// Redux Action (equivalent to dispatch function)
import { BREADCRUMBS_ACTIONS_KEY } from '../../commons/BreadCrumbsConstants';

export const dispatchAddBreadCrumbsItems = (items) => ({
  type: BREADCRUMBS_ACTIONS_KEY.ADD_BREADCRUMBS_ITEMS,
  items
});

export const dispatchSetBreadCrumbsItems = (items) => ({
  type: BREADCRUMBS_ACTIONS_KEY.SET_BREADCRUMBS_ITEMS,
  items
});
