import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ApiFinance } from '../../../../api/ApiFinance';
import { FINANCE_ACTIONS_KEY } from '../../../commons/FinanceConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllWacc() {
  try {
    const currentScenarioId = yield select((state) => state.scenario.current.data.id);

    const simulationRunId = yield select((state) => state.scenario.current.data.lastRun?.csmSimulationRun);
    const scenarioStart = yield select(
      (state) =>
        state.scenario.current.data.parametersValues?.find(
          (el) => el.parameterId === 'parameter_scenario_global_date_de_debut_scenario'
        )?.value
    );
    const scenarioEnd = yield select(
      (state) =>
        state.scenario.current.data.parametersValues?.find(
          (el) => el.parameterId === 'parameter_scenario_global_date_de_fin_scenario'
        )?.value
    );

    const getSyncedWaccList = (waccList) => {
      const array = [];
      const foundGlobal = waccList?.find((el) => el.year >= scenarioStart || el.year <= scenarioEnd);
      for (let iYear = scenarioStart; iYear <= scenarioEnd; iYear += 1) {
        const found = waccList?.find((el) => el.year === iYear);

        found
          ? array.push(found)
          : array.push({
              year: iYear,
              wacc: foundGlobal?.wacc || 0,
              scenarioId: currentScenarioId,
              simulationRun: simulationRunId
            });
      }
      return array;
    };

    const organizationId = yield selectOrganizationId();
    const data = simulationRunId
      ? yield call(ApiFinance.fetchAllWaccFromCurrentScenarioId, organizationId, {
          currentScenarioId,
          simulationRunId
        })
      : yield call(ApiFinance.fetchAllWaccFromCurrentScenarioId, organizationId, { currentScenarioId });

    if (data?.length > 0) {
      yield put({
        type: FINANCE_ACTIONS_KEY.SET_ALL_WACC,
        waccList: data
      });
      yield put({
        type: FINANCE_ACTIONS_KEY.SET_CURRENT_SCENARIO_WACC,
        waccList: getSyncedWaccList(data)
      });
    } else {
      yield put({
        type: FINANCE_ACTIONS_KEY.SET_ALL_WACC,
        waccList: []
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllWaccData() {
  yield takeEvery(FINANCE_ACTIONS_KEY.GET_ALL_WACC, getAllWacc);
}

export default findAllWaccData;
