const getCurrentDateYYYYMMDD = () => new Date().toISOString().slice(0, 10);

const formatDateYYYYMMDDtoDDMMYYY = (date) => {
  const dateObj = new Date(date);
  return [padTo2Digits(dateObj.getDate()), padTo2Digits(dateObj.getMonth() + 1), dateObj.getFullYear()].join('-'); //"2021-01-17" --> 17-01-2021
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

const formatDateIsoToDDMMYYYYhhmm = (date) => {
  if (typeof date !== 'string') return '';

  const dateObj = new Date(date);
  return `${[padTo2Digits(dateObj.getDate()), padTo2Digits(dateObj.getMonth() + 1), dateObj.getFullYear()].join(
    '-'
  )} ${[padTo2Digits(dateObj.getHours()), padTo2Digits(dateObj.getMinutes())].join(':')}`.replace(/-/g, '/');
};

export const DateUtils = {
  getCurrentDateYYYYMMDD,
  formatDateYYYYMMDDtoDDMMYYY,
  formatDateIsoToDDMMYYYYhhmm
};
