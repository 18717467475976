function getNormalAnnotation(number) {
  if (isNaN(parseFloat(number))) {
    return '';
  }
  let expo = 0;
  number = number.toString();
  if (number?.includes('e')) {
    expo = parseFloat(number.split('e')[1]);
    if (expo < 0) {
      expo *= -1;
    }
    if (number.includes('.')) {
      expo += number.split('e')[0].split('.')[1].length;
    }
    return parseFloat(number).toFixed(expo);
  }
  return parseFloat(number);
}

function numberWithSpaces(int) {
  if (int === 0) return 0;
  return int ? int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
}

const formatNumberToFloatOrExponential = (number, precision) => {
  if (precision === undefined || precision === null || precision < 0) precision = 5;

  if (number === undefined || number === null) {
    return '';
  }

  if (isNaN(parseFloat(number))) {
    return '';
  }

  return parseFloat(number.toFixed(precision));
};

function formatNumberToPercentage(number) {
  if (number === 0) return '0%';
  if (isNaN(number) || !number) return '';

  return `${Math.round(number * 100)}%`;
}

function roundAndConvertToPositiveNestedNumbers(obj, decimalPlaces = 2) {
  if (Array.isArray(obj)) {
    return obj.map((item) => roundAndConvertToPositiveNestedNumbers(item, decimalPlaces));
  }

  if (typeof obj === 'object' && obj !== null) {
    const newObj = {};
    for (const key in obj) {
      newObj[key] = roundAndConvertToPositiveNestedNumbers(obj[key], decimalPlaces);
    }
    return newObj;
  }

  if (typeof obj === 'number') {
    return obj < 0 ? 0 : Number(obj.toFixed(decimalPlaces));
  }

  if (typeof obj === 'string') {
    const convertedValue = Number(obj.replace(/ /g, ''));
    return convertedValue < 0 ? 0 : Number(convertedValue.toFixed(decimalPlaces));
  }

  return obj;
}

export const NumberUtils = {
  formatNumberToFloatOrExponential,
  formatNumberToPercentage,
  getNormalAnnotation,
  numberWithSpaces,
  roundAndConvertToPositiveNestedNumbers
};
