import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { HandleErrorAndLoading } from 'components/HandleErrorAndLoading';
import { useMemo } from 'react';
import { AGINGMODELENUM } from 'state/commons/Constants';
import { usecurrentScenarioAgingModel, useFamilyList } from 'state/hooks/Family.hooks';
import { useRequestIsLoading } from 'state/hooks/Request.hooks';
import { useCurrentScenario } from 'state/hooks/Scenario.hooks';
import { LawProbabilityUtils } from 'utils/helpers';

const DEFAULT_MIN_VALUE = 0;
const DEFAULT_MAX_VALUE = 1e10 - 1;
const DEFAULT_STEP_VALUE = 1;
const INPUT_MAX_WIDTH = '25rem';

const create = (
  t,
  customClasses,
  parameterData,
  parametersState,
  setParametersState,
  editMode,
  parametersGroupDataId,
  extraConfig
) => {
  const selectedFamily = extraConfig.selectedFamily;
  const setSelectedFamily = extraConfig.setSelectedFamily;

  const familyList = useFamilyList();
  const agingModel = usecurrentScenarioAgingModel();
  const currentScenario = useCurrentScenario();
  const isLoading = useRequestIsLoading();

  const agingFamilies = useMemo(() => parametersState[parameterData.id], [parameterData.id, parametersState]);

  // STEP VALUE
  const stepValue = {
    Shape: 1,
    MinimalAge: 1,
    MaximalAge: 1,
    FailureRateAtMinimalAge: 0.0001,
    FailureRateAtMaximalAge: 0.0001,
    AgingFailureDelay: 0.0001
  };

  // INITIALIZER
  if (parametersState[parameterData.id] && parametersState[parameterData.id].length === 0 && familyList.length > 0) {
    setParametersState({
      ...parametersState,
      [parameterData.id]: [...familyList]
    });
  }

  const displayDetails = selectedFamily !== null;

  // UPDATE
  // Function to update selectedFamily and FamilyList
  function updateSelectedFamily(family) {
    let newFamily = { ...family };
    let newEquipmentAgingLawParameters = {
      ...newFamily.EquipmentAgingLawParameters
    };
    const newStateMapping = {
      law: 'law',
      lifespanMin: 'MinimalAge',
      lifespanMax: 'MaximalAge',
      failureRateMin: 'FailureRateAtMinimalAge',
      failureRateMax: 'FailureRateAtMaximalAge',
      shape: 'Shape',
      scale: 'Scale'
    };

    if (agingModel !== AGINGMODELENUM.CNAIM) {
      newEquipmentAgingLawParameters = LawProbabilityUtils.TriggerLaw(
        newEquipmentAgingLawParameters,
        newStateMapping,
        newFamily.AgingMethodology
      );
      newFamily = {
        ...newFamily,
        EquipmentAgingLawParameters: {
          ...newFamily.EquipmentAgingLawParameters,
          ...newEquipmentAgingLawParameters
        }
      };
    }

    setParametersState({
      ...parametersState,
      [parameterData.id]: [
        ...parametersState[parameterData.id].map((f) => (f.$dtId === selectedFamily.$dtId ? newFamily : f))
      ]
    });
    setSelectedFamily(newFamily);
  }

  // HANDLERS
  // Function to handle click on family
  function handleClickItem(family) {
    if (family.$dtId === selectedFamily?.$dtId) {
      setSelectedFamily(null);
    } else {
      setSelectedFamily(family);
    }
  }

  // Function to handle change of AgingMethodology value
  function familyHandleChangeLaw(value) {
    const newFamily = { ...selectedFamily, AgingMethodology: value };
    updateSelectedFamily(newFamily);
  }

  function familyHandleChangeString(field, value) {
    let newFamily = { ...selectedFamily };
    newFamily = {
      ...newFamily,
      [field]: value
    };
    updateSelectedFamily(newFamily);
  }

  // Function to handle change of AgingFailureDelay, ExternalFailureRatePerYear, EquipmentAgingLawParameters, ExpectedLife,
  // FinancialCostOfFailure, LifeSpan, FinancialCostOfFailure, EnvironmentalCostOfFailure or EnvironmentalCostOfFailure
  function familyHandleChangeNumber(field, value) {
    let newValue = value;
    if (parseFloat(newValue) > DEFAULT_MAX_VALUE) {
      newValue = DEFAULT_MAX_VALUE;
    } else if (parseFloat(newValue) < DEFAULT_MIN_VALUE) {
      newValue = DEFAULT_MIN_VALUE;
    }
    newValue = parseFloat(newValue);
    let newFamily = { ...selectedFamily };
    if (
      field === 'AgingFailureDelay' ||
      field === 'ExternalFailureRatePerYear' ||
      field === 'ExpectedLife' ||
      field === 'LifeSpan' ||
      field === 'FinancialCostOfFailure' ||
      field === 'EnvironmentalCostOfFailure' ||
      field === 'NetworkCostOfFailure' ||
      field === 'RawInvestment'
    ) {
      newFamily = {
        ...newFamily,
        [field]: newValue
      };
    } else {
      newFamily = {
        ...newFamily,
        EquipmentAgingLawParameters: {
          ...newFamily.EquipmentAgingLawParameters,
          [field]: newValue
        }
      };
    }
    updateSelectedFamily(newFamily);
  }

  return (
    <HandleErrorAndLoading isLoading={isLoading}>
      {currentScenario?.data?.subset?.name && (
        <Typography fontWeight='bold'>{currentScenario.data.subset.name}</Typography>
      )}
      <Grid
        alignContent='flex-start'
        container
        direction='row'
        justifyContent='flex-start'
        key={`grid-container-${parametersGroupDataId}`}
        spacing={0}
      >
        <Grid item key={`grid-col-0-${parametersGroupDataId}`} md={3}>
          {agingFamilies.length > 0 && (
            <Grid
              item
              key={`grid-col-1-row-0-${parametersGroupDataId}parameter_scenario_famille_selected`}
              md={11}
              style={{ maxHeight: '351px', overflow: 'auto' }}
            >
              <List disabled={!editMode} style={{ maxHeight: '100%', overflow: 'auto' }}>
                {agingFamilies.map((family, index) => (
                  <ListItem
                    button
                    divider={index + 1 !== agingFamilies.length}
                    key={`list-item-${family.$dtId}`}
                    selected={selectedFamily?.$dtId === family.$dtId}
                    onClick={() => handleClickItem(family)}
                  >
                    <ListItemText key={`list-item-text-${family.$dtId}`} primary={family.$dtId} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
        </Grid>
        <Grid item key={`grid-col-1-${parametersGroupDataId}`} md={9} spacing={2}>
          {displayDetails ? (
            [
              agingModel !== AGINGMODELENUM.CNAIM && (
                <Grid
                  alignContent='flex-start'
                  container
                  direction='row'
                  justifyContent='flex-start'
                  key={`grid-col-1-row-0-${parametersGroupDataId}`}
                  spacing={1}
                >
                  <Grid item key={`grid-col-1-row-0-${parametersGroupDataId}Law`} md={6}>
                    <FormControl disabled={!editMode} fullWidth variant='standard'>
                      <InputLabel id={`input-label-id${parametersGroupDataId}Law`}>
                        {t('commoncomponents.scenario-form.family-tab.law')}
                      </InputLabel>
                      <Select
                        disableUnderline
                        id={`input-id-${parametersGroupDataId}Law`}
                        labelId={`input-label-id${parametersGroupDataId}Law`}
                        style={{ maxWidth: INPUT_MAX_WIDTH }}
                        value={selectedFamily.AgingMethodology}
                        variant='standard'
                        onChange={(event) => familyHandleChangeLaw(event.target.value)}
                      >
                        <MenuItem
                          key={`menu-item-0${parametersGroupDataId}Law`}
                          value={LawProbabilityUtils.LAW_VALUE_WEIBULL}
                        >
                          {LawProbabilityUtils.LAW_VALUE_WEIBULL}
                        </MenuItem>
                        <MenuItem
                          key={`menu-item-1${parametersGroupDataId}Law`}
                          value={LawProbabilityUtils.LAW_VALUE_GOMPERTZ}
                        >
                          {LawProbabilityUtils.LAW_VALUE_GOMPERTZ}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ),

              <Grid
                alignContent='flex-start'
                container
                direction='row'
                justifyContent='flex-start'
                key={`grid-col-1-row-1-${parametersGroupDataId}`}
                spacing={2}
                sx={{ marginBottom: '1rem' }}
              >
                <Grid item key={`grid-col-1-row-1-${parametersGroupDataId}Shape`} md={6}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          {selectedFamily.EquipmentAgingLawParameters.Shape < 0.01 &&
                            selectedFamily.EquipmentAgingLawParameters.Shape > 0 &&
                            selectedFamily.EquipmentAgingLawParameters.Shape.toExponential(2)}
                        </InputAdornment>
                      )
                    }}
                    disabled={!editMode || agingModel !== AGINGMODELENUM.CNAIM}
                    fullWidth
                    inputProps={{
                      step: stepValue.Shape
                    }}
                    key={`${parametersGroupDataId}-Shape`}
                    label={t('commoncomponents.scenario-form.family-tab.shape')}
                    style={{ maxWidth: INPUT_MAX_WIDTH }}
                    type='number'
                    value={Number(selectedFamily.EquipmentAgingLawParameters.Shape)}
                    variant='standard'
                    onChange={(event) => familyHandleChangeNumber('Shape', event.target.value)}
                  />
                </Grid>
                <Grid item key={`grid-col-1-row-1-${parametersGroupDataId}Scale`} md={6}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          {selectedFamily.EquipmentAgingLawParameters.Scale < 0.01 &&
                            selectedFamily.EquipmentAgingLawParameters.Scale > 0 &&
                            selectedFamily.EquipmentAgingLawParameters.Scale.toExponential(2)}
                        </InputAdornment>
                      )
                    }}
                    disabled={!editMode || agingModel !== AGINGMODELENUM.CNAIM}
                    fullWidth
                    key={`${parametersGroupDataId}-Scale`}
                    label={t('commoncomponents.scenario-form.family-tab.scale')}
                    style={{ maxWidth: INPUT_MAX_WIDTH }}
                    type='number'
                    value={Number(selectedFamily.EquipmentAgingLawParameters.Scale)}
                    variant='standard'
                    onChange={(event) => familyHandleChangeNumber('Scale', event.target.value)}
                  />
                </Grid>
              </Grid>,
              agingModel !== AGINGMODELENUM.CNAIM && (
                <Grid
                  alignContent='flex-start'
                  container
                  direction='row'
                  justifyContent='flex-start'
                  key={`grid-col-1-row-2-${parametersGroupDataId}`}
                  spacing={1}
                  sx={{ marginBottom: '1rem' }}
                >
                  <Grid item key={`grid-col-1-row-2-${parametersGroupDataId}MinimalAge`} md={6}>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'>
                            {selectedFamily.EquipmentAgingLawParameters.MinimalAge < 0.01 &&
                              selectedFamily.EquipmentAgingLawParameters.MinimalAge > 0 &&
                              `(${selectedFamily.EquipmentAgingLawParameters.MinimalAge.toExponential(2)})`}
                            {t('commoncomponents.scenario-form.family-tab.prefixYear')}
                          </InputAdornment>
                        )
                      }}
                      disabled={!editMode}
                      fullWidth
                      inputProps={{
                        min: DEFAULT_MIN_VALUE,
                        step: stepValue.MinimalAge
                      }}
                      key={`${parametersGroupDataId}-MinimalAge`}
                      label={t('commoncomponents.scenario-form.family-tab.minimalAge')}
                      style={{ maxWidth: INPUT_MAX_WIDTH }}
                      type='number'
                      value={Number(selectedFamily.EquipmentAgingLawParameters.MinimalAge)}
                      variant='standard'
                      onChange={(event) => familyHandleChangeNumber('MinimalAge', event.target.value)}
                    />
                  </Grid>
                  <Grid item key={`grid-col-1-row-2-${parametersGroupDataId}MaximalAge`} md={6}>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'>
                            {selectedFamily.EquipmentAgingLawParameters.MaximalAge < 0.01 &&
                              selectedFamily.EquipmentAgingLawParameters.MaximalAge > 0 &&
                              `(${selectedFamily.EquipmentAgingLawParameters.MaximalAge.toExponential(2)})`}
                            {t('commoncomponents.scenario-form.family-tab.prefixYear')}
                          </InputAdornment>
                        )
                      }}
                      disabled={!editMode}
                      fullWidth
                      inputProps={{
                        min: DEFAULT_MIN_VALUE,
                        step: stepValue.MaximalAge
                      }}
                      key={`${parametersGroupDataId}-MaximalAge`}
                      label={t('commoncomponents.scenario-form.family-tab.maximalAge')}
                      style={{ maxWidth: INPUT_MAX_WIDTH }}
                      type='number'
                      value={Number(selectedFamily.EquipmentAgingLawParameters.MaximalAge)}
                      variant='standard'
                      onChange={(event) => familyHandleChangeNumber('MaximalAge', event.target.value)}
                    />
                  </Grid>
                </Grid>
              ),
              agingModel !== AGINGMODELENUM.CNAIM && (
                <Grid
                  alignContent='flex-start'
                  container
                  direction='row'
                  justifyContent='flex-start'
                  key={`grid-col-1-row-3-${parametersGroupDataId}`}
                  spacing={1}
                  sx={{ marginBottom: '1rem' }}
                >
                  <Grid item key={`grid-col-1-row-3-${parametersGroupDataId}FailureRateAtMinimalAge`} md={6}>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'>
                            {selectedFamily.EquipmentAgingLawParameters.MaximalAge < 0.01 &&
                              selectedFamily.EquipmentAgingLawParameters.MaximalAge > 0 &&
                              `(${selectedFamily.EquipmentAgingLawParameters.MaximalAge.toExponential(2)})`}
                            {t('commoncomponents.scenario-form.family-tab.prefixPerYear')}
                          </InputAdornment>
                        )
                      }}
                      disabled={!editMode}
                      fullWidth
                      inputProps={{
                        min: DEFAULT_MIN_VALUE,
                        step: stepValue.FailureRateAtMinimalAge
                      }}
                      key={`${parametersGroupDataId}-FailureRateAtMinimalAge`}
                      label={t('commoncomponents.scenario-form.family-tab.failureRateAtMinimalAge')}
                      style={{ maxWidth: INPUT_MAX_WIDTH }}
                      type='number'
                      value={Number(selectedFamily.EquipmentAgingLawParameters.FailureRateAtMinimalAge)}
                      variant='standard'
                      onChange={(event) => familyHandleChangeNumber('FailureRateAtMinimalAge', event.target.value)}
                    />
                  </Grid>
                  <Grid item key={`grid-col-1-row-3-${parametersGroupDataId}FailureRateAtMaximalAge`} md={6}>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'>
                            {selectedFamily.EquipmentAgingLawParameters.FailureRateAtMaximalAge < 0.01 &&
                              selectedFamily.EquipmentAgingLawParameters.FailureRateAtMaximalAge > 0 &&
                              `(${selectedFamily.EquipmentAgingLawParameters.FailureRateAtMaximalAge.toExponential(
                                2
                              )})`}
                            {t('commoncomponents.scenario-form.family-tab.prefixPerYear')}
                          </InputAdornment>
                        )
                      }}
                      disabled={!editMode}
                      fullWidth
                      inputProps={{
                        min: DEFAULT_MIN_VALUE,
                        step: stepValue.FailureRateAtMaximalAge
                      }}
                      key={`${parametersGroupDataId}-FailureRateAtMaximalAge`}
                      label={t('commoncomponents.scenario-form.family-tab.failureRateAtMaximalAge')}
                      style={{ maxWidth: INPUT_MAX_WIDTH }}
                      type='number'
                      value={Number(selectedFamily.EquipmentAgingLawParameters.FailureRateAtMaximalAge)}
                      variant='standard'
                      onChange={(event) => familyHandleChangeNumber('FailureRateAtMaximalAge', event.target.value)}
                    />
                  </Grid>
                </Grid>
              ),
              agingModel !== AGINGMODELENUM.CNAIM && (
                <Grid
                  alignContent='flex-start'
                  container
                  direction='row'
                  justifyContent='flex-start'
                  key={`grid-col-1-row-4-${parametersGroupDataId}`}
                  spacing={1}
                  sx={{ marginBottom: '1rem' }}
                >
                  <Grid item key={`grid-col-1-row-4-${parametersGroupDataId}AgingFailureDelay`} md={6}>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'>
                            {selectedFamily.AgingFailureDelay < 0.01 &&
                              selectedFamily.AgingFailureDelay > 0 &&
                              `(${selectedFamily.AgingFailureDelay.toExponential(2)})`}
                            {t('commoncomponents.scenario-form.family-tab.prefixPerYear')}
                          </InputAdornment>
                        )
                      }}
                      disabled={!editMode}
                      fullWidth
                      inputProps={{
                        min: DEFAULT_MIN_VALUE,
                        step: stepValue.AgingFailureDelay
                      }}
                      key={`${parametersGroupDataId}-AgingFailureDelay`}
                      label={t('commoncomponents.scenario-form.family-tab.agingFailureDelay')}
                      style={{ maxWidth: INPUT_MAX_WIDTH }}
                      type='number'
                      value={Number(selectedFamily.AgingFailureDelay)}
                      variant='standard'
                      onChange={(event) => familyHandleChangeNumber('AgingFailureDelay', event.target.value)}
                    />
                  </Grid>
                  <Grid item key={`grid-col-1-row-4-${parametersGroupDataId}ExternalFailureRatePerYear`} md={6}>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'>
                            {selectedFamily.ExternalFailureRatePerYear < 0.01 &&
                              selectedFamily.ExternalFailureRatePerYear > 0 &&
                              `(${selectedFamily.ExternalFailureRatePerYear.toExponential(2)})`}
                            {t('commoncomponents.scenario-form.family-tab.prefixYear')}
                          </InputAdornment>
                        )
                      }}
                      disabled={!editMode}
                      fullWidth
                      inputProps={{
                        min: DEFAULT_MIN_VALUE,
                        step: DEFAULT_STEP_VALUE
                      }}
                      key={`${parametersGroupDataId}-ExternalFailureRatePerYear`}
                      label={t('commoncomponents.scenario-form.family-tab.externalFailureRatePerYear')}
                      style={{ maxWidth: INPUT_MAX_WIDTH }}
                      type='number'
                      value={Number(selectedFamily.ExternalFailureRatePerYear)}
                      variant='standard'
                      onChange={(event) => familyHandleChangeNumber('ExternalFailureRatePerYear', event.target.value)}
                    />
                  </Grid>
                </Grid>
              ),
              <Grid
                alignContent='flex-start'
                container
                direction='row'
                justifyContent='flex-start'
                key={`grid-col-1-row-5-${parametersGroupDataId}`}
                spacing={1}
                sx={{ marginBottom: '1rem' }}
              >
                {agingModel === AGINGMODELENUM.CNAIM && (
                  <Grid item key={`grid-col-1-row-5-${parametersGroupDataId}ExpectedLife`} md={6}>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'>
                            {selectedFamily.ExpectedLife < 0.01 &&
                              selectedFamily.ExpectedLife > 0 &&
                              `(${selectedFamily.ExpectedLife.toExponential(2)})`}
                            {t('commoncomponents.scenario-form.family-tab.prefixYear')}
                          </InputAdornment>
                        )
                      }}
                      disabled={!editMode}
                      fullWidth
                      inputProps={{
                        min: DEFAULT_MIN_VALUE,
                        step: DEFAULT_STEP_VALUE
                      }}
                      key={`${parametersGroupDataId}-ExpectedLife`}
                      label={t('commoncomponents.scenario-form.family-tab.expectedLife')}
                      style={{ maxWidth: INPUT_MAX_WIDTH }}
                      type='number'
                      value={Number(selectedFamily.ExpectedLife)}
                      variant='standard'
                      onChange={(event) => familyHandleChangeNumber('ExpectedLife', event.target.value)}
                    />
                  </Grid>
                )}
                <Grid item key={`grid-col-1-row-5-${parametersGroupDataId}ExtraNL`} md={6}>
                  <InputLabel disabled={!editMode} style={{ paddingLeft: 0 }}>
                    {t('commoncomponents.scenario-form.family-tab.extraNL')}
                  </InputLabel>
                  <TextField
                    InputLabelProps={{}}
                    disabled={!editMode}
                    fullWidth
                    key={`${parametersGroupDataId}-ExtraNL`}
                    style={{ maxWidth: INPUT_MAX_WIDTH }}
                    type='text'
                    value={selectedFamily.ExtraNL || ''}
                    variant='standard'
                    onChange={(event) => familyHandleChangeString('ExtraNL', event.target.value)}
                  />
                </Grid>
              </Grid>,
              <Grid
                alignContent='flex-start'
                container
                direction='row'
                justifyContent='flex-start'
                key={`grid-col-1-row-6-${parametersGroupDataId}`}
                spacing={1}
                sx={{ marginBottom: '1rem' }}
              >
                <Grid item key={`grid-col-1-row-7-${parametersGroupDataId}RawInvestment`} md={6}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          {'€ '}
                          {selectedFamily.RawInvestment < 0.01 &&
                            selectedFamily.RawInvestment > 0 &&
                            `(${selectedFamily.RawInvestment.toExponential(2)})`}
                        </InputAdornment>
                      )
                    }}
                    disabled={!editMode}
                    fullWidth
                    inputProps={{
                      min: DEFAULT_MIN_VALUE,
                      step: DEFAULT_STEP_VALUE
                    }}
                    key={`${parametersGroupDataId}-RawInvestment`}
                    label={t('commoncomponents.scenario-form.family-tab.rawInvestment')}
                    style={{ maxWidth: INPUT_MAX_WIDTH }}
                    type='number'
                    value={Number(selectedFamily.RawInvestment)}
                    variant='standard'
                    onChange={(event) => familyHandleChangeNumber('RawInvestment', event.target.value)}
                  />
                </Grid>
                <Grid item key={`grid-col-1-row-6-${parametersGroupDataId}LifeSpan`} md={6}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          {`${t('commoncomponents.scenario-form.family-tab.prefixYear')} `}
                          {selectedFamily.LifeSpan < 0.01 &&
                            selectedFamily.LifeSpan > 0 &&
                            `(${selectedFamily.LifeSpan.toExponential(2)})`}
                        </InputAdornment>
                      )
                    }}
                    disabled={!editMode}
                    fullWidth
                    inputProps={{
                      min: DEFAULT_MIN_VALUE,
                      step: DEFAULT_STEP_VALUE
                    }}
                    key={`${parametersGroupDataId}-LifeSpan`}
                    label={t('commoncomponents.scenario-form.family-tab.lifeSpan')}
                    style={{ maxWidth: INPUT_MAX_WIDTH }}
                    type='number'
                    value={Number(selectedFamily.LifeSpan)}
                    variant='standard'
                    onChange={(event) => familyHandleChangeNumber('LifeSpan', event.target.value)}
                  />
                </Grid>
                <Grid item key={`grid-col-1-row-6-${parametersGroupDataId}FinancialCostOfFailure`} md={6}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          {selectedFamily.FinancialCostOfFailure < 0.01 &&
                            selectedFamily.FinancialCostOfFailure > 0 &&
                            `(${selectedFamily.FinancialCostOfFailure.toExponential(2)})`}
                          {t('commoncomponents.scenario-form.family-tab.prefixEuros')}
                        </InputAdornment>
                      )
                    }}
                    disabled={!editMode}
                    fullWidth
                    inputProps={{
                      min: DEFAULT_MIN_VALUE,
                      step: DEFAULT_STEP_VALUE
                    }}
                    key={`${parametersGroupDataId}-FinancialCostOfFailure`}
                    label={t('commoncomponents.scenario-form.family-tab.financialCostOfFailure')}
                    style={{ maxWidth: INPUT_MAX_WIDTH }}
                    type='number'
                    value={Number(selectedFamily.FinancialCostOfFailure)}
                    variant='standard'
                    onChange={(event) => familyHandleChangeNumber('FinancialCostOfFailure', event.target.value)}
                  />
                </Grid>
              </Grid>,
              <Grid
                alignContent='flex-start'
                container
                direction='row'
                justifyContent='flex-start'
                key={`grid-col-1-row-7-${parametersGroupDataId}`}
                spacing={1}
                sx={{ marginBottom: '1rem' }}
              >
                <Grid item key={`grid-col-1-row-7-${parametersGroupDataId}EnvironmentalCostOfFailure`} md={6}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          {selectedFamily.EnvironmentalCostOfFailure < 0.01 &&
                            selectedFamily.EnvironmentalCostOfFailure > 0 &&
                            `(${selectedFamily.EnvironmentalCostOfFailure.toExponential(2)})`}
                          {t('commoncomponents.scenario-form.family-tab.prefixEuros')}
                        </InputAdornment>
                      )
                    }}
                    disabled={!editMode}
                    fullWidth
                    inputProps={{
                      min: DEFAULT_MIN_VALUE,
                      step: DEFAULT_STEP_VALUE
                    }}
                    key={`${parametersGroupDataId}-EnvironmentalCostOfFailure`}
                    label={t('commoncomponents.scenario-form.family-tab.environmentalCostOfFailure')}
                    style={{ maxWidth: INPUT_MAX_WIDTH }}
                    type='number'
                    value={Number(selectedFamily.EnvironmentalCostOfFailure)}
                    variant='standard'
                    onChange={(event) => familyHandleChangeNumber('EnvironmentalCostOfFailure', event.target.value)}
                  />
                </Grid>
                <Grid item key={`grid-col-1-row-7-${parametersGroupDataId}NetworkCostOfFailure`} md={6}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          {selectedFamily.NetworkCostOfFailure < 0.01 &&
                            selectedFamily.NetworkCostOfFailure > 0 &&
                            `(${selectedFamily.NetworkCostOfFailure.toExponential(2)})`}
                          {t('commoncomponents.scenario-form.family-tab.prefixEuros')}
                        </InputAdornment>
                      )
                    }}
                    disabled={!editMode}
                    fullWidth
                    inputProps={{
                      min: DEFAULT_MIN_VALUE,
                      step: DEFAULT_STEP_VALUE
                    }}
                    key={`${parametersGroupDataId}-NetworkCostOfFailure`}
                    label={t('commoncomponents.scenario-form.family-tab.networkCostOfFailure')}
                    style={{ maxWidth: INPUT_MAX_WIDTH }}
                    type='number'
                    value={Number(selectedFamily.NetworkCostOfFailure)}
                    variant='standard'
                    onChange={(event) => familyHandleChangeNumber('NetworkCostOfFailure', event.target.value)}
                  />
                </Grid>
              </Grid>
            ]
          ) : (
            <Grid item md={12} sx={{ display: 'contents' }}>
              <Paper className={customClasses.unselectItemBox} elevation={0}>
                <Typography className={customClasses.unselectItemText}>
                  {t('commoncomponents.scenario-form.family-tab.unselected-item')}
                </Typography>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </HandleErrorAndLoading>
  );
};

export const FamilyListFactory = {
  create
};
