import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TabLayout } from './layouts';
import { AUTH_STATUS } from './state/commons/AuthConstants';
import { STATUSES } from './state/commons/Constants';
import {
  AssetRegistry,
  CnaimTablesList,
  Dashboards,
  DataIntegration,
  Library,
  Loading,
  Operation,
  OperationsLibrary,
  PredeterminedProject,
  PredeterminedProjectsLibrary,
  Scenario,
  ScenarioManager,
  SignIn as SignInView,
  TechnicalPoliciesLibrary,
  TechnicalPolicy,
  Unauthorized as AccessDeniedView
} from './views';

const AppRoutes = ({
  authStatus,
  application,
  logInAction,
  getAllInitialDataAction,
  setApplicationStatusAction,
  authorized,
  tabs
}) => {
  const { authState, oktaAuth } = useOktaAuth();
  const isAuthenticated = useMemo(() => authState?.isAuthenticated, [authState]);
  const isConnecting = useMemo(() => authStatus === AUTH_STATUS.CONNECTING, [authStatus]);
  const applicationStatus = useMemo(() => application.status, [application.status]);

  const previousUrl = sessionStorage.getItem('previousURL');

  useEffect(() => {
    if (isAuthenticated) {
      getAllInitialDataAction();
    } else {
      setApplicationStatusAction(STATUSES.IDLE);
    }
  }, [getAllInitialDataAction, isAuthenticated, setApplicationStatusAction]);

  useEffect(() => {
    if (authStatus === AUTH_STATUS.ANONYMOUS && isAuthenticated) {
      logInAction(oktaAuth);
    }
  }, [authStatus, logInAction, isAuthenticated, oktaAuth]);

  const isLoading = useMemo(
    () =>
      applicationStatus !== STATUSES.ERROR &&
      (applicationStatus === STATUSES.LOADING || applicationStatus === STATUSES.IDLE),
    [applicationStatus]
  );

  if (isConnecting) {
    return <div className='spinner-border text-success' role='status' />;
  }

  if (isAuthenticated && isLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route element={!isAuthenticated ? <SignInView /> : <Navigate replace to={previousUrl || '/scenario'} />} index />
      <Route element={!isAuthenticated ? <LoginCallback /> : <Navigate to={-1} />} path='/sign-in/callback' />
      <Route element={<AccessDeniedView />} path='/accessDenied' />
      <Route
        element={!isAuthenticated ? <SignInView /> : <Navigate replace={true} to={previousUrl || '/scenario'} />}
        index={true}
      />
      <Route element={!isAuthenticated ? <SignInView /> : <Navigate to={-1} />} path='/sign-in' />
      <Route element={<AccessDeniedView />} path='/accessDenied' />
      <Route
        element={
          !isAuthenticated ? (
            <SignInView />
          ) : (
            <TabLayout
              authenticated={isAuthenticated}
              authorized={authorized}
              signInPath='/sign-in'
              tabs={tabs}
              unauthorizedPath='/accessDenied'
            />
          )
        }
        path='/'
      >
        <Route element={<DataIntegration />} path='dataintegration' />
        <Route path='library'>
          <Route element={<OperationsLibrary />} path='operations' />
          <Route element={<TechnicalPoliciesLibrary />} path='technical-policies' />
          <Route element={<PredeterminedProjectsLibrary />} path='predetermined-projects' />
          <Route element={<CnaimTablesList />} path='cnaim' />
          <Route element={<Library />} path='' />
        </Route>
        <Route path='operation'>
          <Route element={<Operation />} path=':id' />
          <Route element={<Operation />} path='' />
        </Route>
        <Route path='technical-policy'>
          <Route element={<TechnicalPolicy />} path=':id' />
          <Route element={<TechnicalPolicy />} path='' />
        </Route>
        <Route path='predetermined-project'>
          <Route element={<PredeterminedProject />} path=':id' />
          <Route element={<PredeterminedProject />} path='' />
        </Route>
        <Route element={<AssetRegistry />} path='assets' />
        <Route path='scenario'>
          <Route element={<Scenario />} path=':tab' />
          <Route element={<Scenario />} path='' />
        </Route>
        <Route element={<ScenarioManager />} path='manager' />
        <Route path='dashboards'>
          <Route element={<Dashboards />} path=':tab' />
          <Route element={<Dashboards />} path='' />
        </Route>
      </Route>
      <Route element={<Navigate to={'/scenario'} />} path='*' />
    </Routes>
  );
};

AppRoutes.propTypes = {
  application: PropTypes.shape({
    status: PropTypes.string.isRequired,
    percentage: PropTypes.number
  }).isRequired,
  authenticated: PropTypes.bool,
  authorized: PropTypes.bool,
  authStatus: PropTypes.string.isRequired,
  getAllInitialDataAction: PropTypes.func.isRequired,
  logInAction: PropTypes.func.isRequired,
  setApplicationStatusAction: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired
};
AppRoutes.defaultProps = {
  authenticated: false,
  authorized: false,
  application: {
    percentage: 0
  }
};

export default AppRoutes;
