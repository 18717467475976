import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getIntegrationUrl = (organizationId) => `${getBasePath(organizationId)}/PredeterminedProject`;
const getIntegrationOdataUrl = (organizationId) => `${getBasePath(organizationId)}/odata/PredeterminedProject`;

const fetchAllScenarioPredeterminedProjects = (organizationId) =>
  axios
    .get(getIntegrationUrl(organizationId))
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const fetchAllPredeterminedProjects = (organizationId, params) => {
  let query = '?$count=true';
  if (params) {
    if (params.perPage !== null) {
      query = `${query}&$top=${params.perPage}`;
    }
    if (params.page !== null) {
      query = `${query}&$skip=${params.perPage * (params.page - 1)}`;
    }
    if (params.keyword !== null) {
      query = `${query}&keyword=${params.keyword}`;
    }
  }
  return axios
    .get(getIntegrationOdataUrl(organizationId) + query)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });
};

const updatePredeterminedProject = (organizationId, policy) =>
  axios
    .patch(getIntegrationUrl(organizationId), policy)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const postPredeterminedProject = (organizationId, policy) =>
  axios
    .post(getIntegrationUrl(organizationId), policy)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const deletePredeterminedProject = (organizationId, project) =>
  axios
    .delete(`${getIntegrationUrl(organizationId)}/${project.id}`)
    .then((response) => response)
    .catch((error) => {
      console.error('Error ========>', error);
      return error;
    });

const deleteAllPredeterminedProjects = (organizationId) =>
  axios
    .delete(`${getIntegrationUrl(organizationId)}/`)
    .then((response) => response)
    .catch((error) => {
      console.error('Error ========>', error);
      return error;
    });

const getPredeterminedProject = (organizationId, id) =>
  axios
    .get(`${getIntegrationUrl(organizationId)}/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const importPredeterminedProjectsFile = async (organizationId, file) =>
  axios.post(`${getIntegrationUrl(organizationId)}/Import`, file).then((response) => response.data);

const exportPredeterminedProjects = async (organizationId) =>
  axios
    .get(`${getIntegrationUrl(organizationId)}/Export`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'text/csv'
      }
    })
    .then((response) => response.data);

const isNameExist = (organizationId, name) =>
  axios
    .get(`${getIntegrationUrl(organizationId)}/IsNameExist/${name}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return {};
    });

export const ApiPredeterminedProject = {
  deletePredeterminedProject,
  deleteAllPredeterminedProjects,
  exportPredeterminedProjects,
  fetchAllPredeterminedProjects,
  fetchAllScenarioPredeterminedProjects,
  getPredeterminedProject,
  importPredeterminedProjectsFile,
  isNameExist,
  postPredeterminedProject,
  updatePredeterminedProject
};
