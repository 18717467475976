import { ApiSubset } from 'api/ApiSubset/ApiSubset';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DIALOG_ACTIONS_KEY, SUBSET_ACTIONS_KEY } from 'state/commons';
import { selectOrganizationId } from '../sagaUtils';

export function* editSubsetSaga(action) {
  try {
    if (action.subset) {
      const organizationId = yield selectOrganizationId();
      yield call(ApiSubset.editSubset, organizationId, action.subset);
      yield put({
        type: SUBSET_ACTIONS_KEY.FETCH_ALL_SUBSET
      });
    }
  } catch (error) {
    yield put({
      type: DIALOG_ACTIONS_KEY.OPEN_DIALOG,
      payload: { isVisible: false }
    });
  }
}

function* editSubset() {
  yield takeLatest(SUBSET_ACTIONS_KEY.EDIT_SUBSET, editSubsetSaga);
}

export default editSubset;
