import { clientApi as axios } from 'services/ClientApi';
import { getBasePath } from '../ApiUtils';

const getBudgetsUrl = (organizationId) => `${getBasePath(organizationId)}/Budgets`;

const fetchAllBudgets = (organizationId) =>
  axios
    .get(getBudgetsUrl(organizationId))
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

const fetchAllBudgetCategories = (organizationId) =>
  axios
    .get(`${getBudgetsUrl(organizationId)}/Categories`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error ========>', error);
      return [];
    });

export const ApiBudget = {
  fetchAllBudgets,
  fetchAllBudgetCategories
};
