import { ApiOperation } from 'api/ApiOperation/ApiOperation';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  dispatchGetOperation,
  dispatchSetOperationsByAssetClassSearch,
  dispatchSetSelectedOperation
} from 'state/dispatchers/operation/operationDispatcher';
import { useOrganizationId } from 'state/hooks/Workspace.hooks';

export const useGetOperation = () => {
  const dispatch = useDispatch();
  return useCallback((operation) => dispatch(dispatchGetOperation(operation)), [dispatch]);
};

export const useGetSelectedOperation = () => {
  return useSelector((state) => state.operation.selected.data);
};

export const useGetOperationByAssetClass = () => {
  return useSelector((state) => state.operation.operationsByAssetClass.data);
};

export const useGetOperationList = () => {
  return useSelector((state) => state.operation.list.data);
};

export const useGetOperationsListWithOrphans = async () => {
  const organizationId = useOrganizationId();
  return await ApiOperation.fetchOperationsTreeOrphan(organizationId);
};

// set selected operation
export const useSetSelectedOperation = () => {
  const dispatch = useDispatch();
  return useCallback((operation) => dispatch(dispatchSetSelectedOperation(operation)), [dispatch]);
};

export const useSetOperationsByAssetClassSearch = () => {
  const dispatch = useDispatch();
  return useCallback((search) => dispatch(dispatchSetOperationsByAssetClassSearch(search)), [dispatch]);
};
