import { makeStyles } from '@mui/styles';
import theme from 'theme';

export const useStyles = makeStyles(() => ({
  searchInput: {
    backgroundColor: theme.palette.nexans.white,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    marginRight: 24
  },
  searchInputField: {
    padding: 8,
    flex: 1,
    color: 'black'
  },
  searchIcon: {
    background: theme.palette.background.gradient,
    color: theme.palette.nexans.white,
    borderRadius: '0 10px 10px 0',
    height: '100%'
  }
}));
