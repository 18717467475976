// Baseline color theme
const themeColors = {
  primary: '#062F4F',
  primaryVariant: '#DFDFDF',
  secondary: '#3A3A3A',
  background: '#DFDFDF',
  backgroundVariant: '#FFFFFF',
  surface: '#FFFFFF',
  error: '#ED513E',
  errorVariant: '#ED513E',
  warning: '#FB8C00',
  success: '#0CAD3A',
  info: '#63AEF4',
  white: '#FFFFFF',
  black: '#000000',
  lightGrey: '#DFDFDF',
  grey: '#999A9D'
};

export default {
  type: 'light',
  nexans: {
    primary: themeColors.primary,
    primaryVariant: themeColors.primaryVariant,
    blackSecondary: themeColors.secondary,
    lightGreyBg: themeColors.background,
    whiteBg: themeColors.backgroundVariant,
    whiteSurface: themeColors.surface,
    redError: themeColors.errorVariant,
    error: themeColors.error,
    orangeWarning: themeColors.warning,
    greenSuccess: themeColors.success,
    blueInfo: themeColors.info,
    white: themeColors.white,
    black: themeColors.black,
    lightGrey: themeColors.lightGrey,
    grey: themeColors.grey,
    backgroundSignInPage: themeColors.backgroundSignInPage,
    backgroundSignInButton: themeColors.backgroundSignInButton
  },
  white: themeColors.white,
  primary: {
    contrastText: themeColors.black,
    main: themeColors.primary,
    dark: themeColors.primaryVariant
  },
  secondary: {
    contrastText: themeColors.grey,
    main: themeColors.secondary
  },
  success: {
    contrastText: themeColors.black,
    main: themeColors.success
  },
  info: {
    contrastText: themeColors.black,
    main: themeColors.info
  },
  warning: {
    contrastText: themeColors.black,
    main: themeColors.warning
  },
  black: {
    contrastText: themeColors.white,
    main: themeColors.black
  },
  error: {
    contrastText: themeColors.black,
    main: themeColors.error
  },
  text: {
    primary: themeColors.primary,
    secondary: themeColors.secondary,
    link: themeColors.primary,
    disabled: themeColors.grey,
    success: themeColors.success,
    warning: themeColors.warning,
    error: themeColors.error,
    info: themeColors.info,
    black: themeColors.black,
    white: themeColors.white
  },
  background: {
    default: themeColors.background,
    paper: themeColors.white,
    secondary: themeColors.backgroundVariant,
    card: themeColors.surface,
    gradient: `linear-gradient(90deg, ${themeColors.primary},#062F4F);`
  },
  signin: {
    background: '#003b5c',
    button: '#0076ce',
    buttonHover: '#005fa3'
  },
  microsoft: {
    main: '#FFFFFF',
    contrastText: '#5E5E5E'
  }
};
