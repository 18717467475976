import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiOperation } from '../../../../api/ApiOperation';
import { OPERATION_ACTIONS_KEY } from '../../../commons/OperationConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* findAllOperationsEnums() {
  try {
    const organizationId = yield selectOrganizationId();

    const data = yield call(ApiOperation.fetchAllOperationsEnums, organizationId);
    if (data) {
      yield put({
        type: OPERATION_ACTIONS_KEY.SET_ALL_OPERATIONS_ENUMS,
        enums: data
      });
    } else {
      yield put({
        type: OPERATION_ACTIONS_KEY.SET_ALL_OPERATIONS_ENUMS,
        enums: {}
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* getAllOperationsEnums() {
  yield takeEvery(OPERATION_ACTIONS_KEY.GET_ALL_OPERATIONS_ENUMS, findAllOperationsEnums);
}

export default getAllOperationsEnums;
