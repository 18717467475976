// Available OPERATION actions
export const OPERATION_ACTIONS_KEY = {
  CREATE_OPERATION: 'CREATE_OPERATION',
  DELETE_OPERATION: 'DELETE_OPERATION',
  EXECUTE_OPERATIONS_SEARCH: 'EXECUTE_OPERATIONS_SEARCH',
  GET_ALL_OLD_OPERATIONS: 'GET_ALL_OLD_OPERATIONS',
  GET_ALL_OPERATIONS_ENUMS: 'GET_ALL_OPERATIONS_ENUMS',
  GET_ALL_OPERATIONS: 'GET_ALL_OPERATIONS',
  GET_OPERATION: 'GET_OPERATION',
  OPERATION_IS_NAME_EXIST: 'OPERATION_IS_NAME_EXIST',
  RESET_SELECTED_OPERATION: 'RESET_SELECTED_OPERATION',
  SET_ALL_OLD_OPERATIONS: 'SET_ALL_OLD_OPERATIONS',
  SET_ALL_OPERATIONS_BY_ASSETCLASS: 'SET_ALL_OPERATIONS_BY_ASSETCLASS',
  SET_OPERATIONS_BY_ASSETCLASS_PAGE: 'SET_OPERATIONS_BY_ASSETCLASS_PAGE',
  SET_OPERATIONS_BY_ASSETCLASS_SEARCH: 'SET_OPERATIONS_BY_ASSETCLASS_SEARCH',
  SET_OPERATIONS_BY_ASSETCLASS_LOADING: 'SET_OPERATIONS_BY_ASSETCLASS_LOADING',
  SET_ALL_OPERATIONS_ENUMS: 'SET_ALL_OPERATIONS_ENUMS',
  SET_ALL_OPERATIONS: 'SET_ALL_OPERATIONS',
  SET_OPERATIONS_LOADING: 'SET_OPERATIONS_LOADING',
  SET_OPERATIONS_IMPORT_ERRORS: 'SET_OPERATIONS_IMPORT_ERRORS',
  SET_OPERATIONS_IMPORT_UPLOADING: 'SET_OPERATIONS_IMPORT_UPLOADING',
  SET_SELECTED_OPERATION: 'SET_SELECTED_OPERATION',
  UPDATE_OPERATION_IS_NAME_AVAILABLE_STATUS: 'UPDATE_OPERATION_IS_NAME_AVAILABLE_STATUS',
  UPDATE_OPERATION_IS_NAME_AVAILABLE: 'UPDATE_OPERATION_IS_NAME_AVAILABLE',
  UPDATE_OPERATION: 'UPDATE_OPERATION',
  UPLOAD_OPERATIONS: 'UPLOAD_OPERATIONS'
};
