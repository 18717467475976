import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchSetEditingMode } from 'state/dispatchers/app/ApplicationDispatcher';

export const useSetEditingMode = () => {
  const dispatch = useDispatch();
  return useCallback((isEditing) => dispatch(dispatchSetEditingMode(isEditing)), [dispatch]);
};

export const useGetEditingModeStatus = () => {
  return useSelector((state) => state.application.isEditing);
};
