import { CNAIM_ACTIONS_KEY } from 'state/commons/CnaimConstants';

export const dispatchGetCnaimFilesMetadata = () => ({
  type: CNAIM_ACTIONS_KEY.GET_CNAIM_METADATA
});

export const dispatchSetCnaimFilesMetadata = (list) => {
  return {
    type: CNAIM_ACTIONS_KEY.SET_CNAIM_METADATA,
    ...list
  };
};

export const dispatchUploadCnaimFile = (file, fileName) => {
  return {
    type: CNAIM_ACTIONS_KEY.UPLOAD_FILE,
    file,
    fileName
  };
};

export const dispatchDownloadCnaimFile = (fileName) => {
  return {
    type: CNAIM_ACTIONS_KEY.DOWNLOAD_FILE,
    fileName
  };
};

export const dispatchGetAbacusTable = (fileName) => {
  return {
    type: CNAIM_ACTIONS_KEY.GET_ABACUS_TABLE,
    fileName
  };
};
