import { SCENARIO_DASHBOARD_CONFIG } from 'config/Dashboards';
import { useMemo } from 'react';

import { useCurrentScenarioData } from 'state/hooks/Scenario.hooks';

export const useScenarioPowerBiReport = () => {
  const currentScenarioData = useCurrentScenarioData();

  const currentScenarioRunTemplateReport = useMemo(
    () =>
      Array.isArray(SCENARIO_DASHBOARD_CONFIG)
        ? SCENARIO_DASHBOARD_CONFIG
        : currentScenarioData?.runTemplateId in SCENARIO_DASHBOARD_CONFIG
        ? [SCENARIO_DASHBOARD_CONFIG[currentScenarioData.runTemplateId]]
        : [],
    [currentScenarioData?.runTemplateId]
  );

  return { currentScenarioData, currentScenarioRunTemplateReport };
};
