import { makeStyles } from '@mui/styles';
import { CircularProgressWithLabel } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import FadeIn from 'react-fade-in';
import { STATUSES } from 'state/commons/Constants';
import theme from 'theme';

const Loading = ({
  authenticated,
  powerBiInfo,
  currentScenario,
  scenarioList,
  workspace,
  solution,
  datasetList,
  application,
  setApplicationStatusAction
}) => {
  const classes = useStyles({
    loadingPercentage: application.loadingPercentage
  });

  const loadingList = [powerBiInfo, currentScenario, scenarioList, workspace, solution, datasetList];
  const hasErrors = (entityStatus) => entityStatus.status === STATUSES.ERROR;

  const errors = useMemo(() => loadingList.filter((item) => hasErrors(item)), loadingList);

  useEffect(() => {
    if (!authenticated) {
      setApplicationStatusAction(STATUSES.IDLE);
    }
  }, [authenticated, setApplicationStatusAction]);

  return (
    <div className={classes.panel} data-cy='loading-component'>
      <FadeIn delay={200}>
        <img alt='logo' height={200} src='/logo-nobackground-200.png' width={200} />
        <div className={classes.loadingIndicatorContainer}>
          <CircularProgressWithLabel value={application.loadingPercentage || 0} />
        </div>
        {errors.length > 0 && <div>{JSON.stringify(errors)}</div>}
      </FadeIn>
    </div>
  );
};

Loading.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  powerBiInfo: PropTypes.object.isRequired,
  currentScenario: PropTypes.object.isRequired,
  scenarioList: PropTypes.object.isRequired,
  workspace: PropTypes.object.isRequired,
  solution: PropTypes.object.isRequired,
  datasetList: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  setApplicationStatusAction: PropTypes.func.isRequired
};

export default Loading;

const useStyles = makeStyles(() => ({
  panel: {
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  loadingIndicatorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
