import { NOTIFICATION_ACTIONS_KEY } from '../../commons/NotificationConstants';

export const dispatchOpenNotification = (message, responseStatus) => ({
  type: NOTIFICATION_ACTIONS_KEY.OPEN_NOTIFICATION,
  message
});

export const dispatchCloseNotification = () => ({
  type: NOTIFICATION_ACTIONS_KEY.CLOSE_NOTIFICATION
});
