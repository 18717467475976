import {
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  CloudQueueOutlined as CloudQueueOutlinedIcon,
  CloudUpload as CloudUploadIcon,
  ErrorOutlined as ErrorOutlinedIcon,
  HourglassDisabledOutlined as HourglassDisabledOutlinedIcon,
  LoopOutlined as LoopOutlinedIcon,
  WatchLaterOutlined as WatchLaterOutlinedIcon
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataIntegrationSwitchButton } from 'components/DataIntegration';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AGINGMODELENUM, PIPELINESTATUS } from 'state/commons/Constants';
import theme from 'theme';
import { DateUtils, NumberUtils } from 'utils/helpers';

function getIconFromStatus(status) {
  switch (status) {
    case PIPELINESTATUS.NONE:
      return <HourglassDisabledOutlinedIcon style={{ color: theme.palette.nexans.blueInfo }} />;
    case PIPELINESTATUS.AWAITING:
      return <WatchLaterOutlinedIcon style={{ color: theme.palette.nexans.blueInfo }} />;
    case PIPELINESTATUS.PREPARINGIMPORT:
      return <CloudQueueOutlinedIcon style={{ color: theme.palette.nexans.blueInfo }} />;
    case PIPELINESTATUS.RUNNING:
      return <LoopOutlinedIcon style={{ color: theme.palette.nexans.blueInfo }} />;
    case PIPELINESTATUS.SUCCESS:
      return <CheckCircleOutlineOutlinedIcon style={{ color: theme.palette.nexans.greenSuccess }} />;
    case PIPELINESTATUS.ERROR:
      return <ErrorOutlinedIcon style={{ color: theme.palette.nexans.redError }} />;
    case PIPELINESTATUS.UPLOADING:
      return <CloudUploadIcon style={{ color: theme.palette.nexans.blueInfo }} />;
    case PIPELINESTATUS.VALIDATINGDATA:
      return <LoopOutlinedIcon style={{ color: theme.palette.nexans.grey }} />;
    default:
      return null;
  }
}

export default function DataIntegrationKPIShort({ cardData, updateAgingModel }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [cnaimChecked, setCnaimChecked] = useState(false);
  const isAssetsCard = cardData.type === 'assets';

  const [errorsList, setErrorsList] = useState([]);

  function formatErrorMessage() {
    return errorsList.length > 0
      ? errorsList.map((item) => (
          <ul key={`${item.id}-error`}>
            {item.errorMessages?.map((error) => (
              <li key={`${item.id}-message`}>{`${item.action}: ${error}`}</li>
            ))}
          </ul>
        ))
      : '';
  }

  useEffect(() => {
    setCnaimChecked(cardData.kpi.main.agingModel === AGINGMODELENUM.CNAIM);
  }, [cardData.kpi.main]);

  useEffect(() => {
    const list = Object.keys(cardData.lastPipelineRunsByAction)
      .map((key) => {
        const run = cardData.lastPipelineRunsByAction[key];
        return {
          id: run.Id,
          action: run.action,
          errorMessages: run.errorMessages
        };
      })
      .filter((item) => item.errorMessages?.length);
    setErrorsList(list);
  }, [cardData.lastPipelineRunsByAction]);

  function handleValueChange(e) {
    const isChecked = e.target.checked;
    setCnaimChecked(isChecked);
    updateAgingModel(isChecked ? AGINGMODELENUM.CNAIM : AGINGMODELENUM.EFFECTIVE_AGE);
  }

  function handleClick(e) {
    // Prevent the card header expander to be triggered
    e.stopPropagation();
  }

  function getPipelineActions() {
    return (
      Object.keys(cardData.lastPipelineRunsByAction)
        .map((key) => {
          const run = cardData.lastPipelineRunsByAction[key];
          const newLocal = `views.dataIntegration.history.action.${run.action}`;
          return `${t(newLocal)} ${run.zipName}`;
        })
        ?.join(' - ') || '-'
    );
  }

  return (
    <>
      <div className={classes.subHeaderContainer}>
        <div className={classes.subInfos}>
          <div style={{ display: 'flex', minWidth: '50%' }}>
            <div className={classes.column}>
              {cardData.type !== 'operation' && (
                <>
                  <div className={classes.row}>
                    <div>{t('views.dataIntegration.kpi.lastUpdate')} :</div>
                    <span>
                      {cardData.lastRunUpdated ? DateUtils.formatDateIsoToDDMMYYYYhhmm(cardData.lastRunUpdated) : '-'}
                    </span>
                  </div>
                  <div className={classes.row} style={{ flexWrap: 'wrap' }}>
                    <div>{t('views.dataIntegration.kpi.action')} :</div>
                    <span>{getPipelineActions()}</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', minWidth: '27%' }}>
            <div className={classes.column}>
              <div
                className={classes.row}
                title={t(`layouts.tabs.dataintegration.status.${cardData.status}` || 'none')}
              >
                {cardData.status && (
                  <Tooltip followCursor={true} title={formatErrorMessage()}>
                    <IconButton aria-label='settings' size='large'>
                      {getIconFromStatus(cardData.status)}
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <div className={classes.row}>
                <div>{t('views.dataIntegration.kpi.progression')} :</div>
                <span>{NumberUtils.formatNumberToPercentage(cardData.kpi.main.progression) ?? '-'}</span>
              </div>
            </div>
          </div>
          <div className={(classes.row, classes.switchButtonContainer)}>
            {isAssetsCard && (
              <DataIntegrationSwitchButton
                checked={isAssetsCard ? cnaimChecked : false}
                labels={{
                  main: 'agingModel',
                  left: 'cnaim',
                  right: 'effectiveAge'
                }}
                onChange={handleValueChange}
                onClick={handleClick}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

DataIntegrationKPIShort.propTypes = {
  cardData: PropTypes.object.isRequired,
  updateAgingModel: PropTypes.func.isRequired
};

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  subHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 700,
      color: 'black',
      alignSelf: 'center'
    },
    '& > span': {
      fontSize: '1rem',
      fontWeight: 400
    }
  },
  subInfos: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    width: '100%',
    padding: '1rem 0 0 0',
    '& > div': {
      alignSelf: 'center'
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.3rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.4rem',

    '& > div': {
      fontSize: '1rem',
      fontWeight: 700
    },
    '& > span': {
      fontSize: '1rem',
      fontWeight: 400
    }
  },
  switchButtonContainer: {
    display: 'flex',
    minWidth: '25%',
    position: 'relative',
    top: '-3rem',
    right: '1rem'
  }
}));
