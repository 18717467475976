import { useSelector } from 'react-redux';

export const useWorkspace = () => {
  return useSelector((state) => state.workspace.current);
};

export const useWorkspaceData = () => {
  return useSelector((state) => state.workspace.current?.data);
};

export const useOrganizationId = () => {
  return useSelector((state) => state.workspace.user.organizations[0]);
};
