import { Button, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SubsetCardList } from 'components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DIALOG_TYPE } from 'state/commons';
import { useShowDialog } from 'state/hooks/Dialog.hooks';
import { useFetchSubsetList, useSubsetList } from 'state/hooks/Subset.hooks';
import theme from 'theme';
import SubsetCreationOrEditionDialog from './SubsetCreationOrEdition/SubsetCreationOrEditionDialog.component';
import { SubsetDeletionConfirmationDialog } from './SubsetDeletion/SubsetDeletionConfirmationDialog';

export default function SubsetList() {
  const classes = useStyles();

  const { t } = useTranslation();
  const subsetList = useSubsetList();
  const fetchSubsetList = useFetchSubsetList();
  const showDialog = useShowDialog();

  useEffect(() => {
    fetchSubsetList();
  }, []);

  return (
    <Grid
      alignContent='flex-start'
      className={classes.container}
      container
      data-testid='subset-content'
      direction='row'
      justifyContent='flex-start'
      spacing={1}
    >
      <Grid data-testid='subset-details-tab' item md={12}>
        <Grid item md={12} sx={{ display: 'contents' }}>
          <Button
            aria-controls='button-create-subset'
            aria-haspopup='true'
            color='primary'
            data-testid='button-create-subset'
            sx={{ float: 'right', margin: '1rem' }}
            variant='contained'
            onClick={() =>
              showDialog(DIALOG_TYPE.SUBSET_CREATION_OR_EDIT_DIALOG_TYPE, { subset: undefined, mode: 'create' })
            }
          >
            {t('layouts.tabs.assetregistry.subset.addSubset')}
          </Button>
        </Grid>
        {subsetList?.length > 0 && <SubsetCardList subsetList={subsetList} />}

        {subsetList.length === 0 && (
          <Grid item md={12} sx={{ display: 'contents' }}>
            <Paper className={classes.unselectItemBox} elevation={0}>
              <Typography className={classes.unselectItemText}>
                {t('layouts.tabs.assetregistry.subset.noData')}
              </Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
      <SubsetCreationOrEditionDialog />
      <SubsetDeletionConfirmationDialog />
    </Grid>
  );
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    padding: '1rem'
  },
  boxContainer: {
    width: '100%',
    height: '100%',
    bgcolor: 'background.paper',
    maxHeight: '70vh'
  },
  unselectItemBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
    border: '5px dashed #DFDFDF',
    borderRadius: '25px',
    background: theme.palette.background.secondary,
    color: '#DFDFDF',
    height: '100%',
    minHeight: '200px'
  },
  unselectItemText: {
    color: '#DFDFDF',
    fontWeight: 'bold'
  }
});
