import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  dispatchCloseNotification,
  dispatchOpenNotification
} from 'state/dispatchers/notification/notificationDispatcher';

export const useOpenNotificationWithMessage = () => {
  const dispatch = useDispatch();
  return useCallback((message) => dispatch(dispatchOpenNotification(message)), [dispatch]);
};
export const useCloseNotification = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(dispatchCloseNotification()), [dispatch]);
};
