/*
Function : GetDisabled
Aim : Return disabled state of input according to the 'editMode', the 'disabledWhen' and 'alwaysDisabled' parameters
Sample : 
    input_a: {
      disabledWhen: {
        'input_b': [false],
      },
    }
SampleDescription : - The input 'input_a' will be disabled only if 'input_b' value is equal to false.
*/
function GetDisabled(parameterData, parametersState, editMode) {
  // By default, Input is disabled
  let disabled = true;
  // Check if input is always disabled
  if (parameterData.alwaysDisabled) {
    return disabled;
  }
  if (parameterData.disabledWhen && (parameterData.disabledWhen !== null || parameterData.disabledWhen !== undefined)) {
    // ... We have to checked that parametersState match against specified disabledWhen
    Object.keys(parameterData.disabledWhen).forEach((key) => {
      // If not okay, we do not displayed the Input
      if (!parameterData.disabledWhen[key].some((value) => value === parametersState[key])) {
        disabled = false;
      }
    });
    return !editMode || (disabled && editMode);
  } else {
    return !editMode;
  }
}

/*
Function : GetDisplayed
Aim : Return displayed state of input according to the 'displayWhen' parameter
Sample : 
    input_a: {
      displayWhen: {
        'input_b': [true],
      }
    },
    input_c: {
      alwaysDisabled: true
    }
SampleDescription : - The input 'input_a' will be displayed only if 'input_b' value is equal to true.
                    - While input 'input_b' will always be disabled
*/
function GetDisplayed(parameterData, parametersState) {
  // By default, Input is displayed
  let displayed = true;
  // But if there's displayWhen specified ...
  if (parameterData.displayWhen && (parameterData.displayWhen !== null || parameterData.displayWhen !== undefined)) {
    // ... We have to checked that parametersState match against specified displayWhen
    Object.keys(parameterData.displayWhen).forEach((key) => {
      // If not okay, we do not displayed the Input
      if (!parameterData.displayWhen[key].some((value) => value === parametersState[key])) {
        displayed = false;
      }
    });
  }
  return displayed ? {} : { display: 'none' };
}

/*
function : GetHided
Aim : Return hided state
Sample : 
    input_a: {
      hided: true
    }
SampleDescription : - The input 'input_a' will be hided 
*/
function GetHided(parameterData) {
  return parameterData?.hided ? parameterData?.hided : false;
}

/*
Function : GetItemSize
Aim : Return itemSize
Sample : 
    input_a: {
      grid: {
        row: 1,
        size: 4,
      },
    }
SampleDescription : - The input 'input_a' size will be equal to 4 
*/
const DEFAULT_ITEM_SIZE = 4;
function GetItemSize(parameterData) {
  if (parameterData.grid === null || parameterData.grid === undefined) {
    return DEFAULT_ITEM_SIZE;
  }
  if (parameterData.grid.size) {
    return parameterData.grid.size;
  }
}
/*
Function : DispatchResult
Aim : Dispatch specified result on an input change
Sample : 
  input_a: {
    dispatchResult: {
      'input_b': {
        equal: true,
      },
      'input_c': {
        equal: false,
        conditions: [{
          on: true,
          dispatch: 'Hello',
        },{
          on: false,
          dispatch: 'Goodbye',
        }]
      },
    },
  },
SampleDescription : - The input 'input_a' will dispatch on 'input_b' the same result
                    - When the 'input_a' is equal TRUE, 'input_a' will dispatch on 'input_c' the value 'Hello'
                    - When the 'input_b' is equal TRUE, 'input_a' will dispatch on 'input_c' the value 'Goodbye'

*/
function DispatchResult(parameterData, newState, newValue) {
  // If there's dispatchResult specified ...
  if (parameterData.dispatchResult && Object.keys(parameterData.dispatchResult).length > 0) {
    Object.keys(parameterData.dispatchResult).forEach((otherParameter) => {
      if (parameterData.dispatchResult[otherParameter].equal) {
        // We have to set the same result on other specified parameters if
        // Parameter 'equal' is set to true
        newState = {
          ...newState,
          [otherParameter]: newValue
        };
      } else {
        // We have to set the specified dispatch result on other specified parameters if
        // Parameter 'equal' is set to false
        // Parameter 'on' is equal to 'newValue'
        parameterData.dispatchResult[otherParameter].conditions.forEach((condition) => {
          if (condition.on === newValue) {
            newState = {
              ...newState,
              [otherParameter]: condition.dispatch
            };
          }
        });
      }
    });
  }
  return newState;
}
export const ExtraParametersUtils = {
  DispatchResult,
  GetDisabled,
  GetDisplayed,
  GetHided,
  GetItemSize
};
