import {
  ArrowBack as ArrowBackIcon,
  CloudDownload as CloudDownloadIcon,
  CloudUpload as CloudUploadIcon,
  RemoveRedEye as PreviewIcon
} from '@mui/icons-material';
import { Alert, Button, List, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AbacusTable } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useDownloadCnaimFile,
  useFetchAbacusTable,
  useFetchAllCnaimMetadata,
  useGetAbacusTableFromState,
  useGetCnaimMetadataFromState,
  useGetCnaimStatusFromState,
  useUploadCnaimFile
} from 'state/hooks/Cnaim.hooks';
import theme from 'theme';

function CnaimTablesList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const cnaimFilesMetadata = useGetCnaimMetadataFromState();
  const cnaimStatus = useGetCnaimStatusFromState();
  const getCnaimFilesMetadata = useFetchAllCnaimMetadata();
  const getAbacusTable = useFetchAbacusTable();
  const abacusTable = useGetAbacusTableFromState();
  const uploadCnaimFile = useUploadCnaimFile();
  const downloadCnaimFile = useDownloadCnaimFile();

  const [cnaimFiles, setCnaimFiles] = useState([]);
  const [abacusData, setAbacusData] = useState([]);

  useEffect(() => {
    getCnaimFilesMetadata();
  }, []);

  useEffect(() => {
    setCnaimFiles(cnaimFilesMetadata);
  }, [cnaimFilesMetadata]);

  function handleNavigateToLibraryClick() {
    navigate({ pathname: '/library' });
  }

  function handlePreviewAbacus(fileName) {
    getAbacusTable(fileName);
  }

  useEffect(() => {
    if (abacusTable) {
      setAbacusData(abacusTable);
    }
  }, [abacusTable]);

  function handleDownloadClick(fileName) {
    return downloadCnaimFile(fileName);
  }

  function handleUploadBtnClick(fileName) {
    const input = document.getElementById(`upload-input-${fileName}`);
    input.click();
  }

  function handleUploadChange(event, fileName) {
    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append('File', event.target.files[0]);
      uploadCnaimFile(formData, fileName);
    }
  }

  return (
    <>
      <div className={classes.topBar}>
        <Button
          className='backBtn'
          color='primary'
          data-testid='abacus-tables-list-back-btn'
          variant='contained'
          onClick={() => handleNavigateToLibraryClick()}
        >
          <ArrowBackIcon /> {t('layouts.back')}
        </Button>
      </div>
      <div className={classes.container}>
        <div className={classes.listContent}>
          <div className={classes.errorContainer}>
            {cnaimStatus?.upload.error && <Alert severity='error'>{cnaimStatus.upload.error}</Alert>}
            {cnaimStatus?.download.error && <Alert severity='error'>{cnaimStatus.download.error}</Alert>}
          </div>
          <List>
            {cnaimFiles?.length > 0 &&
              cnaimFiles.map((cnaimFile) => (
                <ListItem className={classes.listItem} key={`file-item-${cnaimFile.fileName}`}>
                  <ListItemText data-testid={`file-item-${cnaimFile.fileName}`} primary={cnaimFile.fileName} />
                  <Button
                    id={`download-btn-${cnaimFile.fileName}`}
                    title={t('views.library.cnaim.download')}
                    onClick={() => handleDownloadClick(cnaimFile.fileName)}
                  >
                    <CloudDownloadIcon />
                  </Button>
                  <Button
                    id={`upload-btn-${cnaimFile.fileName}`}
                    title={t('views.library.cnaim.upload')}
                    onClick={(e) => handleUploadBtnClick(cnaimFile.fileName)}
                  >
                    <CloudUploadIcon />
                    <input
                      accept='.csv'
                      hidden={true}
                      id={`upload-input-${cnaimFile.fileName}`}
                      name='fileInput'
                      type='file'
                      onChange={(e) => {
                        handleUploadChange(e, cnaimFile.fileName);
                      }}
                    />
                  </Button>
                  <Button
                    data-testid={`preview-btn-${cnaimFile.fileName}`}
                    id={`preview-btn-${cnaimFile.fileName}`}
                    title={t('views.library.cnaim.preview')}
                    onClick={(e) => handlePreviewAbacus(cnaimFile.fileName)}
                  >
                    <PreviewIcon />
                  </Button>
                </ListItem>
              ))}
          </List>
          {cnaimFiles.length === 0 && <div style={{ textAlign: 'center' }}>{t('views.library.cnaim.noFiles')}</div>}
        </div>
        <div className={classes.previewContent}>{abacusData?.length > 0 && <AbacusTable rows={abacusData} />}</div>
      </div>
    </>
  );
}

export default CnaimTablesList;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    margin: '2rem 4rem',
    flexDirection: 'row',
    gap: '2rem'
  },
  errorContainer: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem'
  },
  topBar: {
    display: 'flex',
    width: '90%',
    margin: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0 0'
  },
  listContent: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '550px',
    width: '550px',
    borderRadius: '10px',
    backgroundColor: theme.palette.nexans.lightGreyBg,
    '& span': {
      color: theme.palette.nexans.black
    }
  },
  previewContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '2rem',
    marginTop: '1.4rem',
    overflowX: 'auto'
  },

  listItem: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.nexans.lightGreyBg,
      '&:hover': {
        backgroundColor: theme.palette.nexans.primary,
        '& span': {
          color: `${theme.palette.text.white} !important`
        },
        '& button': {
          color: `${theme.palette.text.white} !important`
        }
      }
    },

    '&:nth-child(even)': {
      backgroundColor: theme.palette.nexans.white,
      '&:hover': {
        backgroundColor: theme.palette.nexans.primary,
        '& span': {
          color: `${theme.palette.text.white} !important`
        },
        '& button': {
          color: `${theme.palette.text.white} !important`
        }
      }
    }
  },
  listColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem'
  }
}));
