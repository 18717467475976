import { PictureAsPdf as PictureAsPdfIcon, Save as SaveIcon } from '@mui/icons-material';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ApiPowerBI } from 'api/ApiPowerBI/ApiPowerBI';
import { ReactComponent as PPTICON } from 'assets/svg/ppt.svg';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePowerBIInfo } from 'state/hooks/PowerBI.hooks';
import theme from 'theme';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem'
  },
  buttonIconContainer: {
    background: 'white',
    display: 'flex',
    padding: '1rem',
    color: 'red',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '50%'
  },
  text: {
    display: 'flex',
    textAlign: 'center'
  },
  buttonContainer: {
    display: 'flex',
    background: (styleProps) => styleProps.background,
    color: 'white',
    flexDirection: 'column',
    padding: '0.5rem 1rem',
    textAlign: 'center',
    gap: '0.5rem',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontSize: '1.2rem',
    borderRadius: '10px',
    justifyContent: 'center'
  }
}));
function DashboardExportReportRow({ report, closeMenu }) {
  const [reportId] = useState(report.reportId);
  const { t } = useTranslation();
  const [exportId, setExportId] = useState(0);
  const [reportStatus, setReportStatus] = useState('NotInitiated');
  const styleProps = {
    background: reportStatus === 'Succeeded' ? theme.palette.nexans.greenSuccess : theme.palette.nexans.primary
  };
  const classes = useStyles(styleProps);

  const powerBiInformations = usePowerBIInfo();

  const handleExportRequest = async (formatDoc) => {
    let state = undefined;
    if (reportId === powerBiInformations.currentReport.reportId) {
      state = powerBiInformations.currentReport.state;
    }

    const response = await ApiPowerBI.getExportIdFromReportId(reportId, formatDoc, state);
    setExportId(response);
    setReportStatus('Initiated');
  };

  const downloadReport = async () => {
    const response = await ApiPowerBI.getExportToFileStream(reportId, exportId);
    response.blob().then((blob) => download(blob, report.reportName));
    closeMenu();
  };

  function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  useEffect(() => {
    if (reportStatus !== 'NotInitiated' && reportStatus !== 'Succeeded' && reportStatus !== 'Failed') {
      const intervalId = setInterval(checkReportStatus, 7000);
      return () => {
        clearInterval(intervalId);
      };
    }
    return null;
  }, [reportStatus]);

  const checkReportStatus = async () => {
    const response = await ApiPowerBI.getExportStatus(reportId, exportId);
    setReportStatus(response);
  };

  return (
    <div className={classes.dialogContainer}>
      <Button type='button' onClick={() => handleExportRequest('PDF')}>
        <div className={classes.buttonIconContainer}>
          <Tooltip title='Export to PDF'>
            <PictureAsPdfIcon fontSize='large' style={{ color: theme.palette.nexans.primary }} />
          </Tooltip>
        </div>
      </Button>
      <Button type='button' onClick={() => handleExportRequest('PPTX')}>
        <div
          className={classes.buttonIconContainer}
          style={{
            maxHeight: '4.3rem',
            maxWidth: '4.3rem',
            alignItems: 'center'
          }}
        >
          <Tooltip title='Export to PPT'>
            <PPTICON style={{ color: theme.palette.nexans.primary }} />
          </Tooltip>
        </div>
      </Button>

      <Button
        className={classes.buttonContainer}
        disabled={reportStatus === 'NotInitiated' && reportStatus !== 'Succeeded'}
        startIcon={
          reportStatus === 'NotInitiated' || reportStatus === 'Succeeded' ? (
            <SaveIcon style={{ color: 'white' }} />
          ) : (
            <CircularProgress style={{ color: 'white' }} />
          )
        }
        style={{ color: 'white' }}
        onClick={downloadReport}
      >
        {reportStatus === 'Succeeded' ? (
          <div className={classes.text}>{t('genericcomponent.modal.report.reportDownload')}</div>
        ) : (
          <div
            style={{
              flexDirection: 'column',
              gap: '1rem',
              marginLeft: '0.6rem'
            }}
          >
            {reportStatus !== 'NotInitiated' && <div>{t('genericcomponent.modal.report.reportStatus')}</div>}
            <div>
              {reportStatus === 'NotInitiated'
                ? t('genericcomponent.modal.report.notInit')
                : t(`genericcomponent.modal.report.exportStatus.${reportStatus}`)}
            </div>
          </div>
        )}
      </Button>
    </div>
  );
}

DashboardExportReportRow.propTypes = {
  report: PropTypes.any.isRequired,
  closeMenu: PropTypes.func.isRequired
};

export default DashboardExportReportRow;
