import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SearchIcon from '@mui/icons-material/Search';
import { Button, IconButton, InputBase, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PredeterminedProjectForm, PredeterminedProjectsTable, SimpleTwoActionsDialogContent } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import theme from 'theme';

function PredeterminedProject(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    createNewPredeterminedProject,
    deletePredeterminedProject,
    getAllPredeterminedProjectList,
    getPredeterminedProject,
    predeterminedProjectList,
    resetPredeterminedProject,
    selectedPredeterminedProject,
    setPredeterminedProjectsPage,
    setPredeterminedProjectsSearch,
    updateCurrentPredeterminedProject,
    getOperation
  } = props;

  const { id: predeterminedProjectId } = useParams();

  useEffect(() => {
    if (!predeterminedProjectId) {
      return;
    }
    getPredeterminedProject(predeterminedProjectId);
  }, [predeterminedProjectId]);

  const [showWarningExitWithoutSave, setShowWarningExitWithoutSave] = useState(false);

  const navigateToPredeterminedProjectsLibrary = () => navigate({ pathname: '/library/predetermined-projects' });

  const openCreatePredeterminedProject = () => {
    resetPredeterminedProject();
    navigate({ pathname: '/predetermined-project' });
  };

  const openEditPredeterminedProject = (predeterminedProject) => {
    navigate({
      pathname: `/predetermined-project${predeterminedProject ? `/${predeterminedProject.id}` : ''}`
    });
  };

  const openDeletePredeterminedProject = (predeterminedProject) => {
    deletePredeterminedProject(predeterminedProject.id);
  };

  const applyUpdate = (predeterminedProject) => {
    if (selectedPredeterminedProject.data.id) {
      updateCurrentPredeterminedProject(predeterminedProject);
      navigateToPredeterminedProjectsLibrary();
    } else {
      createNewPredeterminedProject(predeterminedProject);
      navigateToPredeterminedProjectsLibrary();
    }
  };

  const mapPredeterminedProjectToDataTableRow = (predeterminedProject) => ({
    id: predeterminedProject.Id,
    AssetClass: predeterminedProject.AssetClass,
    Name: predeterminedProject.DtId,
    EquipmentGroup: predeterminedProject.OnEquipmentGroup,
    Operation: predeterminedProject.Operation,
    Date: predeterminedProject.PlannedDate
  });

  const mapPredeterminedProjectsListToDataTable = () =>
    predeterminedProjectList.data.map((predeterminedProject) =>
      mapPredeterminedProjectToDataTableRow(predeterminedProject)
    );

  const handleExitWithoutSave = () => {
    setShowWarningExitWithoutSave(false);
    navigate(-1);
  };

  const onEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getAllPredeterminedProjectList();
    }
  };

  return (
    <>
      <div className={classes.topBar}>
        <Button
          className='backBtn'
          color='primary'
          variant='contained'
          onClick={() => setShowWarningExitWithoutSave(true)}
        >
          <ArrowBackIcon /> {t('layouts.back')}
        </Button>
        <div className={classes.flexSearch}>
          <Paper className={classes.searchInput} component='form'>
            <InputBase
              className={classes.searchInputField}
              value={predeterminedProjectList.search}
              onChange={(event) => setPredeterminedProjectsSearch(event.target.value)}
              onKeyDown={onEnterPress}
            />
            <IconButton
              aria-label='search'
              className={classes.searchIcon}
              size='large'
              type='button'
              onClick={getAllPredeterminedProjectList}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <Button color='primary' variant='contained' onClick={() => openCreatePredeterminedProject()}>
            {t('views.library.predeterminedproject.newPredeterminedProject')} <ControlPointIcon />
          </Button>
        </div>
      </div>
      <PredeterminedProjectForm
        applyUpdate={(predeterminedProject) => applyUpdate(predeterminedProject)}
        cancelUpdate={() => setShowWarningExitWithoutSave(true)}
      />
      <div className={classes.tableContainer}>
        <Paper className={classes.tablePaper}>
          <PredeterminedProjectsTable
            currentPage={predeterminedProjectList.page}
            getOperation={getOperation}
            loading={predeterminedProjectList.loading}
            openDeletePredeterminedProject={openDeletePredeterminedProject}
            openEditPredeterminedProject={openEditPredeterminedProject}
            pageCount={predeterminedProjectList.pageCount}
            predeterminedProjectsList={mapPredeterminedProjectsListToDataTable()}
            refreshDataUsingPagination={setPredeterminedProjectsPage}
          />
        </Paper>
      </div>

      <SimpleTwoActionsDialogContent
        handleClickOnButton1={() => setShowWarningExitWithoutSave(false)}
        handleClickOnButton2={handleExitWithoutSave}
        id='exit-confirmation'
        key='exit-confirmation'
        labels={{
          title: t('genericcomponent.dialog.warningExitWithoutSave.predeterminedproject.title'),
          body: t('genericcomponent.dialog.warningExitWithoutSave.body'),
          button1: t('genericcomponent.dialog.warningExitWithoutSave.cancel'),
          button2: t('genericcomponent.dialog.warningExitWithoutSave.confirm'),
          ariaLabelledby: 'confirm-exit-dialog',
          button2BGColor: '#062F4F'
        }}
        open={showWarningExitWithoutSave}
      />
    </>
  );
}

PredeterminedProject.propTypes = {
  createNewPredeterminedProject: PropTypes.func.isRequired,
  deletePredeterminedProject: PropTypes.func.isRequired,
  getAllPredeterminedProjectList: PropTypes.func.isRequired,
  getOperation: PropTypes.func.isRequired,
  getPredeterminedProject: PropTypes.func.isRequired,
  predeterminedProjectList: PropTypes.object.isRequired,
  resetPredeterminedProject: PropTypes.func.isRequired,
  selectedPredeterminedProject: PropTypes.object.isRequired,
  setPredeterminedProjectsPage: PropTypes.func.isRequired,
  setPredeterminedProjectsSearch: PropTypes.func.isRequired,
  updateCurrentPredeterminedProject: PropTypes.func.isRequired
};

export default PredeterminedProject;

const useStyles = makeStyles(() => ({
  topBar: {
    display: 'flex',
    width: '90%',
    margin: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0 0'
  },
  flexSearch: {
    display: 'flex'
  },
  searchInput: {
    backgroundColor: theme.palette.nexans.white,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    marginRight: 24
  },
  searchInputField: {
    padding: 8,
    flex: 1,
    color: 'black'
  },
  searchIcon: {
    background: theme.palette.background.gradient,
    color: theme.palette.nexans.white,
    borderRadius: '0 10px 10px 0'
  },
  tableContainer: {
    width: '90%',
    margin: '20px auto 0'
  },
  tablePaper: {
    background: theme.palette.nexans.white
  }
}));
