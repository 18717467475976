import axios, { AxiosInstance } from 'axios';
import { oktaAuth } from 'services/config/Auth';

const addInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance?.interceptors.request.use(
    (request) => {
      const accessToken = oktaAuth.getAccessToken();

      if (!accessToken) {
        oktaAuth.signOut();
      } else if (request?.headers) {
        request.headers.Authorization = `Bearer ${accessToken}`;
      }

      return request;
    },
    (error) => {
      console.error(error);
    }
  );

  return axiosInstance;
};

const clientApi = addInterceptors(axios.create());

const createAxiosClientApi = () => addInterceptors(axios.create());

export { clientApi, createAxiosClientApi };
