import { HierarchicalComboBox } from '@cosmotech/ui';
import { Backdrop, Card, CardContent, CircularProgress, Grid, Paper } from '@mui/material';
import { CreateScenarioButton, ScenarioDescription, ScenarioParameters } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AppInsights } from 'services/AppInsights';
import { STATUSES } from 'state/commons/Constants';
import { useSetEditingMode } from 'state/hooks/Application.hooks';
import { sortScenarioList } from 'utils/helpers/SortScenarioListUtils';
import { NAME_VALIDATOR } from 'utils/helpers/ValidationUtils';
import { ScenarioPowerBiReport } from './components';
import { getCreateScenarioDialogLabels } from './labels';
import useStyles from './style';

const appInsights = AppInsights.getInstance();

function Scenario(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tab: tabFromRouter } = useParams();

  const {
    addBreadCrumbsItems,
    addDatasetToStore,
    budgetList,
    createScenario,
    currentScenario,
    datasetList,
    findScenarioById,
    getAllBudgetList,
    getAllFamilyList,
    getAllHumanResourceList,
    getAllOperations,
    getPipelineLastestRun,
    humanResourceList,
    launchScenario,
    operationsList,
    scenarioList,
    solution,
    stopScenario,
    updateAndLaunchScenario,
    updateScenarioDescription,
    user,
    workspace
  } = props;

  const workspaceId = workspace.data?.id;
  const [editMode, setEditMode] = useState(false);
  const [editDescriptionMode, setEditDescriptionMode] = useState(false);
  const createScenarioDialogLabels = getCreateScenarioDialogLabels(t, editMode);
  const setEditingMode = useSetEditingMode();

  useEffect(() => {
    getAllOperations();
  }, []);

  useEffect(() => {
    setEditingMode(editMode);
  }, [editMode]);

  // Add accordion expand status in state
  const [accordionSummaryExpanded, setAccordionSummaryExpanded] = useState(
    localStorage.getItem('scenarioParametersAccordionExpanded') === 'true'
  );

  useEffect(() => {
    localStorage.setItem('scenarioParametersAccordionExpanded', accordionSummaryExpanded);
  }, [accordionSummaryExpanded]);

  const expandParametersAndCreateScenario = (workspaceID, scenarioData) => {
    createScenario(workspaceID, scenarioData);
    setAccordionSummaryExpanded(true);
  };

  const currentScenarioRenderInputTooltip = editMode
    ? t(
        'views.scenario.dropdown.scenario.tooltip.disabled',
        'Please save or discard current modifications before selecting another scenario'
      )
    : '';

  const handleScenarioChange = (event, scenario) => {
    findScenarioById(workspaceId, scenario.id);
  };

  useEffect(() => {
    appInsights.setScenarioData(currentScenario.data);
  }, [currentScenario]);

  useEffect(() => {
    getPipelineLastestRun();
  }, [currentScenario.data?.name]);

  const sortedScenarioList = scenarioList.data.length ? sortScenarioList(scenarioList.data.slice()) : [];
  const noScenario = currentScenario.data === null;
  const scenarioListDisabled = editMode || editDescriptionMode || scenarioList === null || noScenario;
  const scenarioListLabel = noScenario ? null : t('views.scenario.dropdown.scenario.label', Scenario);
  const showBackdrop = currentScenario.status === STATUSES.LOADING;

  let filteredRunTemplates = solution?.data?.runTemplates || [];
  const solutionRunTemplates = workspace.data?.solution?.runTemplateFilter;
  if (solutionRunTemplates) {
    filteredRunTemplates = filteredRunTemplates.filter(
      (runTemplate) => solutionRunTemplates.indexOf(runTemplate.id) !== -1
    );
  }
  return (
    <>
      <Backdrop className={classes.backdrop} open={showBackdrop}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Grid className={classes.mainGrid} container={true} data-cy='scenario-view' direction='column'>
        <Grid item={true} xs={12}>
          <div className={classes.topScenarioRow}>
            <div className={classes.hierarchicalComboBox}>
              <HierarchicalComboBox
                disabled={scenarioListDisabled}
                handleChange={handleScenarioChange}
                label={scenarioListLabel}
                renderInputToolType={currentScenarioRenderInputTooltip}
                style={{
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  opacity: `${sortedScenarioList?.length ? 1 : 0}`
                }}
                value={currentScenario.data}
                values={sortedScenarioList}
              />
            </div>
            {currentScenario.data && (
              <ScenarioDescription
                changeEditMode={setEditDescriptionMode}
                currentScenario={currentScenario}
                editMode={editDescriptionMode}
                style={{ opacity: `${sortedScenarioList?.length ? 1 : 0}` }}
                updateScenarioDescription={updateScenarioDescription}
                workspaceId={workspaceId}
              />
            )}
            <div className={classes.buttonCreateScenario}>
              <CreateScenarioButton
                createScenario={expandParametersAndCreateScenario}
                currentScenario={currentScenario}
                datasets={datasetList.data}
                disabled={editMode}
                labels={createScenarioDialogLabels}
                nameValidator={NAME_VALIDATOR}
                runTemplates={[filteredRunTemplates[1]]}
                scenarios={scenarioList.data}
                solution={solution}
                user={user}
                workspaceId={workspaceId}
              />
            </div>
          </div>
        </Grid>

        <Grid item={true} xs={12}>
          {currentScenario?.data && Object.keys(currentScenario?.data).length > 0 && (
            <ScenarioParameters
              accordionSummaryExpanded={accordionSummaryExpanded}
              addBreadCrumbsItems={addBreadCrumbsItems}
              addDatasetToStore={addDatasetToStore}
              budgetList={budgetList.data}
              changeEditMode={setEditMode}
              currentScenario={currentScenario}
              datasets={datasetList.data}
              editMode={editMode}
              getAllBudgetList={getAllBudgetList}
              getAllFamilyList={getAllFamilyList}
              getAllHumanResourceList={getAllHumanResourceList}
              humanResourceList={humanResourceList.data}
              key={currentScenario.data?.name}
              launchScenario={launchScenario}
              operationsList={operationsList}
              scenarioId={currentScenario.data.id}
              scenarioList={scenarioList.data}
              solution={solution.data}
              stopScenario={stopScenario}
              tabFromRouter={tabFromRouter}
              updateAndLaunchScenario={updateAndLaunchScenario}
              userRoles={user.roles}
              workspaceId={workspaceId}
              onChangeAccordionSummaryExpanded={setAccordionSummaryExpanded}
            />
          )}
        </Grid>
        <Grid item={true} xs={12}>
          <Card component={Paper} elevation={2}>
            <CardContent>
              <ScenarioPowerBiReport />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

Scenario.propTypes = {
  addBreadCrumbsItems: PropTypes.func.isRequired,
  addDatasetToStore: PropTypes.func.isRequired,
  budgetList: PropTypes.object.isRequired,
  createScenario: PropTypes.func.isRequired,
  currentScenario: PropTypes.object.isRequired,
  datasetList: PropTypes.object.isRequired,
  findScenarioById: PropTypes.func.isRequired,
  getAllBudgetList: PropTypes.func.isRequired,
  getAllFamilyList: PropTypes.func.isRequired,
  getAllHumanResourceList: PropTypes.func.isRequired,
  getAllOperations: PropTypes.func.isRequired,
  getPipelineLastestRun: PropTypes.func.isRequired,
  humanResourceList: PropTypes.object.isRequired,
  launchScenario: PropTypes.func.isRequired,
  operationsList: PropTypes.array.isRequired,
  scenarioList: PropTypes.object.isRequired,
  solution: PropTypes.object.isRequired,
  stopScenario: PropTypes.func.isRequired,
  updateAndLaunchScenario: PropTypes.func.isRequired,
  updateScenarioDescription: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  workspace: PropTypes.object.isRequired
};

export default Scenario;
