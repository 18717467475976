// Available TECHNICAL POLICY actions
export const PREDETERMINED_PROJECT_ACTIONS_KEY = {
  DELETE_PREDETERMINED_PROJECT: 'DELETE_PREDETERMINED_PROJECT',
  DELETE_ALL_PREDETERMINED_PROJECTS: 'DELETE_ALL_PREDETERMINED_PROJECTS',
  DELETE_ALL_PREDETERMINED_PROJECTS_LOADING: 'DELETE_ALL_PREDETERMINED_PROJECTS_LOADING',
  FIND_PREDETERMINED_PROJECT_BY_ID: 'FIND_PREDETERMINED_PROJECT_BY_ID',
  GET_ALL_PREDETERMINED_PROJECTS_ENUMS: 'GET_ALL_PREDETERMINED_PROJECTS_ENUMS',
  GET_ALL_PREDETERMINED_PROJECTS: 'GET_ALL_PREDETERMINED_PROJECTS',
  GET_ASSET_GROUPS_BY_ASSET_CLASS: 'GET_ASSET_GROUPS_BY_ASSET_CLASS',
  GET_PREDETERMINED_PROJECT: 'GET_PREDETERMINED_PROJECT',
  PREDETERMINED_PROJECT_IS_NAME_EXIST: 'PREDETERMINED_PROJECT_IS_NAME_EXIST',
  RESET_PREDETERMINED_PROJECT: 'RESET_PREDETERMINED_PROJECT',
  RESET_PREDETERMINED_PROJECTS_PAGE: 'RESET_PREDETERMINED_PROJECTS_PAGE',
  SET_ALL_PREDETERMINED_PROJECTS_ENUMS: 'SET_ALL_PREDETERMINED_PROJECTS_ENUMS',
  SET_ALL_PREDETERMINED_PROJECTS: 'SET_ALL_PREDETERMINED_PROJECTS',
  SET_ASSET_GROUPS_BY_ASSET_CLASS: 'SET_ASSET_GROUPS_BY_ASSET_CLASS',
  SET_PREDETERMINED_PROJECT: 'SET_PREDETERMINED_PROJECT',
  SET_PREDETERMINED_PROJECTS_ELEMENT_PER_PAGE: 'SET_PREDETERMINED_PROJECTS_ELEMENT_PER_PAGE',
  SET_PREDETERMINED_PROJECTS_IMPORT_ERRORS: 'SET_PREDETERMINED_PROJECTS_IMPORT_ERRORS',
  SET_PREDETERMINED_PROJECTS_IMPORT_UPLOADING: 'SET_PREDETERMINED_PROJECTS_IMPORT_UPLOADING',
  SET_PREDETERMINED_PROJECTS_LOADING: 'SET_PREDETERMINED_PROJECTS_LOADING',
  SET_PREDETERMINED_PROJECTS_PAGE_COUNT: 'SET_PREDETERMINED_PROJECTS_PAGE_COUNT',
  SET_PREDETERMINED_PROJECTS_PAGE: 'SET_PREDETERMINED_PROJECTS_PAGE',
  SET_PREDETERMINED_PROJECTS_SEARCH: 'SET_PREDETERMINED_PROJECTS_SEARCH',
  SET_SELECTED_PREDETERMINED_PROJECT: 'SET_SELECTED_PREDETERMINED_PROJECT',
  UPDATE_ALL_PREDETERMINED_PROJECTS: 'UPDATE_ALL_PREDETERMINED_PROJECTS',
  UPDATE_CURRENT_PREDETERMINED_PROJECT: 'UPDATE_CURRENT_PREDETERMINED_PROJECT',
  UPDATE_PREDETERMINED_PROJECT_IS_NAME_AVAILABLE_STATUS: 'UPDATE_PREDETERMINED_PROJECT_IS_NAME_AVAILABLE_STATUS',
  UPDATE_PREDETERMINED_PROJECT_IS_NAME_AVAILABLE: 'UPDATE_PREDETERMINED_PROJECT_IS_NAME_AVAILABLE',
  UPLOAD_PREDETERMINED_PROJECTS: 'UPLOAD_PREDETERMINED_PROJECTS'
};
