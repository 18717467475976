import { ApiSubset } from 'api/ApiSubset/ApiSubset';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DIALOG_ACTIONS_KEY, SUBSET_ACTIONS_KEY } from 'state/commons';
import { selectOrganizationId } from '../sagaUtils';

export function* createSubsetSaga(action) {
  try {
    if (action.subset) {
      const organizationId = yield selectOrganizationId();
      yield call(ApiSubset.createSubset, organizationId, action.subset);
      yield put({
        type: SUBSET_ACTIONS_KEY.SET_CURRENT_SUBSET,
        currentSubset: action.subset
      });
      yield put({
        type: SUBSET_ACTIONS_KEY.FETCH_ALL_SUBSET
      });
    }
  } catch (error) {
    yield put({
      type: DIALOG_ACTIONS_KEY.OPEN_DIALOG,
      payload: { isVisible: false }
    });
    yield put({
      type: SUBSET_ACTIONS_KEY.SET_CURRENT_SUBSET,
      currentSubset: null
    });
  }
}

function* createSubset() {
  yield takeLatest(SUBSET_ACTIONS_KEY.CREATE_SUBSET, createSubsetSaga);
}

export default createSubset;
