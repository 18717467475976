import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiHumanResource } from '../../../../api/ApiHumanResource';
import { HUMAN_RESOURCE_ACTIONS_KEY } from '../../../commons/HumanResourceConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllHumanResourcesData() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiHumanResource.fetchAllHumanResources, organizationId);
    if (data.length > 0) {
      yield put({
        type: HUMAN_RESOURCE_ACTIONS_KEY.SET_ALL_HUMAN_RESOURCES,
        humanResources: data
      });
    } else {
      yield put({
        type: HUMAN_RESOURCE_ACTIONS_KEY.SET_ALL_HUMAN_RESOURCES,
        humanResources: []
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllHumanResourcesData() {
  yield takeEvery(HUMAN_RESOURCE_ACTIONS_KEY.GET_ALL_HUMAN_RESOURCES, getAllHumanResourcesData);
}

export default findAllHumanResourcesData;
