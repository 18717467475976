const findElementById = (list, id) => list.find((el) => el.$dtId === id);

const findValueWithAvailableQuantity = (list) =>
  list.find(
    (el) => el.AvailableQuantityByYear && Object.values(el.AvailableQuantityByYear).some((value) => value >= 0)
  );

const getYearWithAvailableQuantity = (availableQuantityByYear) =>
  Object.values(availableQuantityByYear).find((year) => year !== undefined);

export const getValueFromListOrFallback = (iYear, selectedElement, list) => {
  if (selectedElement?.AvailableQuantityByYear?.[iYear]) {
    return selectedElement.AvailableQuantityByYear[iYear];
  }

  const foundId = findElementById(list, selectedElement.$dtId);

  if (foundId?.AvailableQuantityByYear?.[iYear]) {
    return foundId.AvailableQuantityByYear[iYear];
  }

  const foundValue = findValueWithAvailableQuantity(list);

  if (foundValue?.AvailableQuantityByYear) {
    return getYearWithAvailableQuantity(foundValue.AvailableQuantityByYear);
  }

  return 0;
};
