import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

interface IParams extends CircularProgressProps {
  customClassName?: string;
}

export const Loader = (props: IParams) => {
  const classes = useStyles();
  const { customClassName, ...otherProps } = props;

  return (
    <div className={`${classes.loader} ${customClassName}`}>
      <CircularProgress color='primary' {...otherProps} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
