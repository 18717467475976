import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiIntegration } from '../../../../api/ApiIntegration';
import { DATAINTEGRATION_ACTIONS_KEY } from '../../../commons/DataIntegrationConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* fetchPipelineHistory(action) {
  const { pipelineType } = action;
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiIntegration.fetchPipelineHistory, organizationId, pipelineType);
    if (data && data.length > 0) {
      yield put({
        type: DATAINTEGRATION_ACTIONS_KEY.SET_PIPELINE_HISTORY,
        historyData: { history: data, type: pipelineType }
      });
    } else {
      yield put({
        type: DATAINTEGRATION_ACTIONS_KEY.SET_PIPELINE_HISTORY,
        historyData: { history: [], type: pipelineType }
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* getPipelineHistoryData() {
  yield takeEvery(DATAINTEGRATION_ACTIONS_KEY.GET_PIPELINE_HISTORY, fetchPipelineHistory);
}

export default getPipelineHistoryData;
