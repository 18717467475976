import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Pagination, Skeleton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { DataGrid, frFR, gridClasses } from '@mui/x-data-grid';
import { SimpleTwoActionsDialogContent } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import { DateUtils } from 'utils/helpers';
import InputComponentFactory from '../InputComponentFactory';
import EditTableToolbar from './EditTableToolbar.component';

function EditTable(props) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { loading, tableSettings, data, onChange, traductions, toolbar, assetRegistry, 'data-testid': testId } = props;

  const [editData, setEditData] = useState([]);
  const [page, setPage] = useState(tableSettings.pages ? tableSettings.pages.currentPage : 0);
  const [showDeleteData, setShowDeleteData] = useState(false);
  const [payloadDeleteData, setPayloadDeleteData] = useState(null);

  const [showTable, setShowTable] = useState(false);
  const [toolbarData, setToolbarData] = useState(toolbar);

  useEffect(() => {
    if (tableSettings.isAlwaysEdit) {
      setEditData(data);
    }
    setShowTable(true);
  }, [data, tableSettings.isAlwaysEdit]);

  useEffect(() => {
    setToolbarData(toolbar);
  }, [toolbar]);

  const startEditData = (dataParam) => {
    if (tableSettings.editMethod) {
      tableSettings.editMethod(dataParam);
    } else {
      const copy = [...editData];
      copy.push(dataParam);
      setEditData(copy);
    }
  };

  const updateData = (newValue, index, property) => {
    if (
      tableSettings.isAlwaysEdit ||
      (tableSettings.actions && tableSettings.actions.findIndex((ele) => ele === 'edit') === -1)
    ) {
      onChange(newValue, index, property);
    }
  };

  const saveData = (newData) => {
    let copy = [...editData];
    copy = copy.filter((ele) => ele.id !== newData.id);
    setEditData(copy);
    onChange(data);
  };
  const preDeleteData = (row) => {
    setShowDeleteData(true);
    setPayloadDeleteData(row);
  };

  const deleteData = (e) => {
    onChange(e);
  };

  const changePage = (_event, value) => {
    if (value !== page) {
      setShowTable(false);
      setPage(value);
      tableSettings.pages.onChangePage(value);
    }
    return true;
  };

  const editTemplate = (row) => {
    if (tableSettings.actions && tableSettings.actions.findIndex((ele) => ele === 'edit') !== -1) {
      return editData.findIndex((ele) => ele.id === row.id) === -1 ? (
        <Button variant='text' onClick={() => startEditData(row)}>
          <EditIcon />
        </Button>
      ) : (
        <Button variant='text' onClick={() => saveData(row)}>
          <SaveIcon />
        </Button>
      );
    }
    return null;
  };

  const deleteTemplate = (row) =>
    tableSettings.actions && tableSettings.actions.findIndex((ele) => ele === 'delete') !== -1 ? (
      <Button variant='text' onClick={() => preDeleteData(row)}>
        <DeleteIcon />
      </Button>
    ) : null;

  function getDataWithDataGridId() {
    return data.map((row, rowIndex) => ({ ...row, dataGridId: rowIndex, actions: !!tableSettings.actions }));
  }

  function getTranslation(column, columnIndex, emptyHeaderName) {
    const columnName =
      tableSettings.colsTranslationKeys?.length && tableSettings.colsTranslationKeys.length > columnIndex
        ? tableSettings.colsTranslationKeys[columnIndex]
        : column;
    return t(`genericcomponent.edittable.colsnames.${columnName}`, columnName)
      ? t(`genericcomponent.edittable.colsnames.${columnName}`, columnName)
      : emptyHeaderName;
  }

  // Change flex value of datagrid
  function changeFlexValueInDatagridFromColumnName(columnName) {
    switch (columnName) {
      case '$dtId':
      case 'assetName':
      case 'EquipmentComponentAttribute':
      case 'Name':
      case 'TargetedAgingFamilies':
        return 3;

      case 'assetAgingFamily':
      case 'EquipmentGroup':
      case 'Operation':
      case 'Proportion':
      case 'Value':
      case 'ValueAsString':
        return 2;
      default:
        return 1;
    }
  }

  function changeWidthValueInDatagridFromColumnName(columnName) {
    switch (columnName) {
      case '$dtId':
      case 'assetName':
      case 'Name':
        return 300;

      case 'assetAgingFamily':
      case 'Proportion':
        return 150;

      case 'EquipmentComponentAttribute':
      case 'Operation':
      case 'TargetedAgingFamilies':
      case 'Threshold':
        return 100;

      case 'assetQuantity':
        return 50;

      default:
        return 175;
    }
  }

  function getDataGridColumnsDefinition() {
    const emptyHeaderName = ' ';
    const mainColumnsDefinition = tableSettings.cols.map((column, columnIndex) => ({
      field: column,
      headerName: getTranslation(column, columnIndex, emptyHeaderName),
      sortable: true,
      valueGetter: (params) => params.row[params.field],
      editable: false,
      disableColumnSelector: true,
      flex: !assetRegistry ? changeFlexValueInDatagridFromColumnName(column) : null,
      minWidth: assetRegistry ? changeWidthValueInDatagridFromColumnName(column) : null,
      align: 'left',
      renderCell: (cellValues) => {
        if (!tableSettings.isAlwaysEdit) {
          if (tableSettings.colProps?.length && tableSettings.colProps[columnIndex]?.type === 'link') {
            return (
              <Button className={classes.link} onClick={() => tableSettings.colProps[columnIndex].onClick(cellValues)}>
                {cellValues.value}
              </Button>
            );
          }
          if (tableSettings.colProps?.length && tableSettings.colProps[columnIndex]?.type === 'date') {
            return <span>{DateUtils.formatDateYYYYMMDDtoDDMMYYY(cellValues.value)}</span>;
          }
          return (
            <span>
              {typeof cellValues.value !== 'number' && cellValues.value
                ? t(`genericcomponent.edittable.${cellValues.value}`, cellValues.value)
                : cellValues.value}
            </span>
          );
        }
        const rowIndex = getDataWithDataGridId().findIndex((row) => getDataGridRowId(row) === cellValues.id);

        const errorMessage = tableSettings?.errorMessages?.at(rowIndex) ?? null;

        return (
          <InputComponentFactory
            data={cellValues.row}
            editProps={{ ...tableSettings.colProps[columnIndex], errorMessage: errorMessage }}
            field={cellValues.field}
            index={`row-${rowIndex}-input-${cellValues.field}-${columnIndex}`}
            value={cellValues.value}
            onChange={(newValue) => updateData(newValue, rowIndex, cellValues.field)}
          />
        );
      }
    }));

    if (tableSettings.actions) {
      mainColumnsDefinition.push({
        field: 'actions',
        headerName: emptyHeaderName,
        flex: 1,
        sortable: false,
        align: 'center',
        renderCell: (cellValues) => {
          const row = getDataWithDataGridId().find((dataRow) => getDataGridRowId(dataRow) === cellValues.id);
          return (
            <>
              {editTemplate(row)}
              {deleteTemplate(row)}
            </>
          );
        }
      });
    }

    return mainColumnsDefinition;
  }

  function getDataGridRowId(row) {
    return row.dataGridId;
  }

  function getCustomToolbar() {
    return <EditTableToolbar handleClickOnExport={toolbarData?.handleClickOnExport} />;
  }

  return (
    <>
      {showTable ? (
        <StripedDataGrid
          autoHeight={true}
          className={classes.datagrid}
          columns={getDataGridColumnsDefinition()}
          disableColumnSelector={true}
          disableRowSelectionOnClick={true}
          disableSelectionOnClick={true}
          filterMode={tableSettings.useNativePagination ? 'client' : 'server'}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25
              }
            }
          }}
          loading={loading}
          localeText={i18n.language === 'fr' ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
          maxColumns={tableSettings.cols.length + (tableSettings.actions ? 1 : 0)}
          paginationMode={tableSettings.useNativePagination ? 'client' : 'server'}
          rowCount={tableSettings.pages?.elementPerPage}
          rowLength={20}
          rows={getDataWithDataGridId()}
          slots={{
            toolbar: toolbar?.visible ? getCustomToolbar : undefined
          }}
          getRowId={getDataGridRowId}
          // getRowClassName={(params) => (params.rowIndex % 2 === 0 ? 'MuiDataGrid-row--even' : '')}
          hideFooter={!tableSettings.useNativePagination}
        />
      ) : (
        <Skeleton animation='wave' className={classes.skeleton} variant='rectangular' />
      )}
      {payloadDeleteData && (
        <SimpleTwoActionsDialogContent
          handleClickOnButton1={() => setShowDeleteData(false)}
          handleClickOnButton2={() => {
            deleteData(payloadDeleteData);
            setShowDeleteData(false);
          }}
          id='delete-confirmation'
          labels={{
            title: t(traductions ? traductions.deleteDialog.title : '', 'Confirm data suppression ?'),
            body: t(traductions ? traductions.deleteDialog.body : '', 'Would you like to delete this data ?'),
            button1: t(traductions ? traductions.deleteDialog.cancel : '', 'Cancel'),
            button2: t(traductions ? traductions.deleteDialog.delete : '', 'Delete'),
            ariaLabelledby: 'confirm-delete-dialog',
            button2BGColor: '#062F4F'
          }}
          open={showDeleteData}
        />
      )}
      {tableSettings.useNativePagination !== true && tableSettings.pages && tableSettings.pages.pageCount > 0 && (
        <Pagination
          className={classes.pagination}
          count={Math.ceil(tableSettings.pages.pageCount)}
          page={page}
          showFirstButton={true}
          showLastButton={true}
          onChange={changePage}
        />
      )}
    </>
  );
}

EditTable.defaultProps = {
  onChange: Function.prototype,
  traductions: {},
  loading: false,
  toolbar: {
    visible: false,
    handleClickOnExport: null
  },
  assetRegistry: false,
  deleteMany: null
};

EditTable.propTypes = {
  tableSettings: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  traductions: PropTypes.object,
  toolbar: PropTypes.object,
  assetRegistry: PropTypes.bool,
  deleteMany: PropTypes.func
};

export default EditTable;

const useStyles = makeStyles(() => ({
  actions: {
    justifyContent: 'center',
    display: 'flex'
  },
  skeleton: {
    width: '100%',
    height: '50vh',
    backgroundColor: 'rgb(0 0 0 / 11%)'
  },
  pagination: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: '24px',
    marginBottom: '24px',
    paddingBottom: '24px',
    '& button': {
      color: theme.palette.primary.white,
      '&.Mui-selected': {
        color: theme.palette.primary.primary
      }
    }
  },
  link: {
    cursor: 'pointer',
    color: 'black',
    fontSize: '0.71rem'
  },
  'Mui-even': {
    backgroundColor: '#C4C4C4'
  }
}));

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity)
        }
      }
    }
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#062F4F',
    color: '#fff',
    '& .MuiButtonBase-root': {
      color: 'white'
    },
    '& .MuiDataGrid-iconButtonContainer': { display: 'none' }
  },
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none'
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold !important'
  }
}));
