import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Autocomplete,
  Avatar,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DIALOG_TYPE } from 'state/commons';
import { useShowDialog } from 'state/hooks/Dialog.hooks';

import theme from 'theme';

export default function SubsetCardList({ subsetList }) {
  const { t } = useTranslation();
  const [selectedSubsets, setSelectedSubsets] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [displayedSubsets, setDisplayedSubsets] = useState(subsetList);

  const showDialog = useShowDialog();

  const filteredSelectedSubset = useMemo(
    () => subsetList.filter((subset) => selectedSubsets.some((sb) => sb.id === subset.id)),
    [selectedSubsets, subsetList]
  );

  useEffect(() => {
    setDisplayedSubsets(filteredSelectedSubset?.length ? filteredSelectedSubset : subsetList);
  }, [subsetList, selectedSubsets, filteredSelectedSubset]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue === '') {
      setDisplayedSubsets(filteredSelectedSubset.length ? filteredSelectedSubset : subsetList);
    } else {
      setDisplayedSubsets(
        subsetList.filter((option) => option.name.toLowerCase().includes(newInputValue.toLowerCase()))
      );
    }
  };

  const handleChange = (_, newSelectedSubsets) => {
    setSelectedSubsets(newSelectedSubsets);
    setDisplayedSubsets(newSelectedSubsets.length ? newSelectedSubsets : subsetList);
  };

  return (
    <Grid data-testid='subset-card-container' item md={12} sx={{ display: 'contents' }}>
      <Stack
        direction='column'
        key={'subset-components-container'}
        spacing={2}
        sx={{ maxHeight: '80vh', overflowY: 'scroll' }}
      >
        <Autocomplete
          getOptionLabel={(option) => option.name ?? ''}
          id='subset-card-search'
          inputValue={inputValue}
          limitTags={2}
          multiple
          options={subsetList}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'subset-search-input'
              }}
              label={t('layouts.tabs.assetregistry.subset.subsetSearch')}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option.id}
                label={option.name}
                variant='outlined'
                {...getTagProps({ index })}
                data-testid={`badge-${option.name}`}
              />
            ))
          }
          sx={{ backgroundColor: theme.palette.nexans.white, width: '30rem' }}
          value={filteredSelectedSubset}
          onChange={handleChange}
          onInputChange={handleInputChange}
        />
        <Stack direction='row' flexWrap='wrap' key={'subset-list-card'} spacing={{ xs: 1, sm: 2 }} useFlexGap>
          {displayedSubsets.map((subset, index) => (
            <Stack direction='row' key={subset.name + '-' + index} spacing={2}>
              <Card data-testid={`subset-details-card-${subset.name}`}>
                <CardContent>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    key={subset.id + '-' + index}
                    spacing={2}
                    sx={{ marginBottom: '1rem' }}
                  >
                    <Stack direction='row' spacing={2}>
                      <Avatar sx={{ bgcolor: theme.palette.primary.main, color: 'white' }}>
                        {subset.name.charAt(0)}
                      </Avatar>
                      <Typography color='text.secondary' gutterBottom sx={{ alignSelf: 'center' }} variant='h5'>
                        {subset.name}
                      </Typography>
                    </Stack>

                    <Tooltip
                      disableHoverListener={subset.canEditOrDelete}
                      placement='top'
                      title={t('layouts.tabs.assetregistry.subset.cannotEditOrDeleteSubset')}
                    >
                      <Stack direction='row' spacing={1}>
                        <IconButton
                          aria-label='edit'
                          disabled={!subset.canEditOrDelete}
                          edge='end'
                          onClick={() => {
                            showDialog(DIALOG_TYPE.SUBSET_CREATION_OR_EDIT_DIALOG_TYPE, { subset, mode: 'edit' });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label='delete'
                          disabled={!subset.canEditOrDelete}
                          edge='end'
                          onClick={() => {
                            showDialog(DIALOG_TYPE.SUBSET_DELETION_DIALOG_TYPE, { subset });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </Tooltip>
                  </Stack>

                  <Stack direction='column' spacing={2}>
                    {subset.agingFamilies.slice(0, 1).map((agingFamily, index) => (
                      <Chip
                        key={agingFamily + '-' + index}
                        label={agingFamily}
                        sx={{ maxWidth: '10rem' }}
                        title={agingFamily}
                      />
                    ))}
                    {subset.agingFamilies.length > 1 && (
                      <Chip
                        label={t('layouts.tabs.assetregistry.subset.more', { count: subset.agingFamilies.length - 1 })}
                      />
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Grid>
  );
}

SubsetCardList.propTypes = {
  subsetList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      agingFamilies: PropTypes.arrayOf(PropTypes.string).isRequired,
      organisationId: PropTypes.string.isRequired
    })
  )
};
