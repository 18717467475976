import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ApiIntegration } from 'api/ApiIntegration/ApiIntegration';
import clsx from 'clsx';
import { SimpleTwoActionsDialogContent } from 'components';
import { DOCUMENTATION_URL } from 'config/AppConfiguration';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useOpenNotificationWithMessage } from 'state/hooks/Notification.hook';
import { useOrganizationId } from 'state/hooks/Workspace.hooks';
import theme from 'theme';
import DownloadBlob from 'utils/hooks/DownloadBlob.hook';
import { GetMenuActionsByPipeline, icons } from './dataIntegration.assets';
import DataIntegrationCardImport from './DataIntegrationCardImport.component';
import DataIntegrationHistory from './DataIntegrationHistory.component';
import DataIntegrationKPILong from './DataIntegrationKPILong.component';
import DataIntegrationKPIShort from './DataIntegrationKPIShort.component';

function DynamicIcon({ icon, color }) {
  const Icon = icons[icon];
  return <Icon sx={{ color }} />;
}

export default function DataIntegrationCard(props) {
  const {
    cardData,
    deleteAllPipelineData,
    deletePipelineHistoryRunData,
    getPipelineHistory,
    removePipelineHistoryEntry,
    setIsFilePending,
    updateAgingModel,
    uploadPipelineFile
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openNotificationWithMessage = useOpenNotificationWithMessage();

  const [expanded, setExpanded] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteAllConfirm, setShowDeleteAllConfirm] = useState(false);
  const [financialLifecycle, setFinancialLifecycle] = useState('financeLifeCycle');
  const shouldDisplayImportCard =
    selectedAction &&
    selectedAction.name !== 'history' &&
    selectedAction.name !== 'export' &&
    selectedAction.name !== 'deleteAll' &&
    selectedAction.name !== 'info';
  const handleExpandClick = () => setExpanded(!expanded);
  const handleCardClick = () => (cardData.type === 'operation' ? navigate('/library') : setExpanded(!expanded));
  const handleMenuExpandClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const [isExporting, setIsExporting] = useState(false);
  const organizationId = useOrganizationId();

  const selectActionFromMenu = (action) => {
    if (action.name !== 'info') {
      if (!expanded) {
        handleExpandClick();
      }
      setSelectedAction(action);
    }
    switch (action.name) {
      case 'create':
      case 'history': {
        getPipelineHistory(cardData.type);
        break;
      }
      case 'export': {
        if (isExporting) {
          openNotificationWithMessage(t('commoncomponents.notification.dataIntegration.export.pending'));
        } else {
          openNotificationWithMessage(t('commoncomponents.notification.dataIntegration.export.starting'));
          exportPipeline();
        }
        break;
      }
      case 'deleteAll': {
        setShowDeleteAllConfirm(true);
        break;
      }
      case 'info': {
        openOutsideDocumentationUrl();
        break;
      }
      default:
        break;
    }

    handleMenuClose();
  };

  async function openOutsideDocumentationUrl() {
    window.open(DOCUMENTATION_URL, '_blank');
  }

  const test = new Date();

  async function exportPipeline() {
    setIsExporting(true);
    const blob = await ApiIntegration.exportPipeline(organizationId, cardData.type);
    await DownloadBlob(blob, `export_${cardData.type} +`);
    setIsExporting(false);
    openNotificationWithMessage(t('commoncomponents.notification.dataIntegration.export.success'));
  }

  return (
    <>
      <Card className={classes.container}>
        <div className={classes.subHeaderContainer}>
          <CardHeader
            avatar={
              <Avatar aria-label='recipe' className={classes.avatar}>
                <DynamicIcon color={theme.palette.nexans.white} icon={cardData.icon} />
              </Avatar>
            }
            classes={{
              title: classes.cardTitle,
              subheader: classes.subHeader,
              root: classes.cardHeader,
              content: classes.cardHeaderContent
            }}
            subheader={<DataIntegrationKPIShort cardData={cardData} updateAgingModel={updateAgingModel} />}
            title={t(`views.dataIntegration.${cardData.type}`)}
            onClick={handleCardClick}
          />
          <CardActions disableSpacing={true}>
            <div className={classes.column}>
              <IconButton
                aria-label='settings'
                disabled={cardData.type === 'operation'}
                size='large'
                style={{ opacity: cardData.type === 'operation' ? 0 : 1 }}
                onClick={handleMenuExpandClick}
              >
                <MoreHorizIcon style={{ color: theme.palette.nexans.primary }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                classes={{ paper: classes.menuPaper }}
                id='simple-menu'
                keepMounted={true}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {GetMenuActionsByPipeline(cardData.type).map((action, index) => (
                  <MenuItem
                    className={classes.menuItem}
                    disabled={action.disabled}
                    key={`data-integration-card-menu-${index + action.name}`}
                    onClick={() => selectActionFromMenu(action)}
                  >
                    <div className={classes.row}>
                      <IconButton aria-label={action.name} size='large' style={{ color: 'white' }}>
                        {action.icon}
                      </IconButton>
                      <Typography className={classes.menuItem}>
                        {action.name === 'export' && isExporting ? (
                          <CircularProgress size={20} sx={{ color: 'white' }} />
                        ) : (
                          t(`views.dataIntegration.actions.${action.name}`)
                        )}
                      </Typography>
                    </div>
                  </MenuItem>
                ))}
              </Menu>

              <IconButton
                aria-expanded={expanded}
                aria-label='show more'
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded
                })}
                size='large'
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon style={{ color: theme.palette.nexans.primary }} />
              </IconButton>
            </div>
          </CardActions>
        </div>

        <Collapse in={expanded} timeout='auto' unmountOnExit={true}>
          <CardContent>
            <div className={classes.cardContentTitle} style={{ paddingLeft: '5.2rem' }}>
              {t('views.dataIntegration.kpi.modelStatus')}
            </div>
            <DataIntegrationKPILong cardData={cardData} />

            {shouldDisplayImportCard && (
              <DataIntegrationCardImport
                additionalParameters={{ type: financialLifecycle }}
                cardData={cardData}
                removePipelineHistoryEntry={removePipelineHistoryEntry}
                selectedAction={selectedAction}
                setFinancialLifecycle={setFinancialLifecycle}
                setIsFilePending={setIsFilePending}
                uploadPipelineFile={uploadPipelineFile}
              />
            )}

            {selectedAction && selectedAction.name === 'history' && (
              <DataIntegrationHistory
                cardData={cardData}
                deletePipelineHistoryRunData={deletePipelineHistoryRunData}
                removePipelineHistoryEntry={removePipelineHistoryEntry}
              />
            )}
          </CardContent>
        </Collapse>
      </Card>
      <SimpleTwoActionsDialogContent
        handleClickOnButton1={() => setShowDeleteAllConfirm(false)}
        handleClickOnButton2={() => {
          setShowDeleteAllConfirm(false);
          deleteAllPipelineData(cardData.type);
        }}
        id='delete-all-confirmation'
        labels={{
          title: t('views.dataIntegration.actions.deleteAllConfirm.title'),
          body: t(`views.dataIntegration.actions.deleteAllConfirm.body.${cardData.type}`),
          button1: t('views.dataIntegration.actions.deleteAllConfirm.cancel'),
          button2: t('views.dataIntegration.actions.deleteAllConfirm.delete'),
          ariaLabelledby: 'confirm-delete-dialog',
          button2BGColor: '#062F4F'
        }}
        open={showDeleteAllConfirm}
      />
    </>
  );
}

DataIntegrationCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  deleteAllPipelineData: PropTypes.func.isRequired,
  deletePipelineHistoryRunData: PropTypes.func.isRequired,
  getPipelineHistory: PropTypes.func.isRequired,
  removePipelineHistoryEntry: PropTypes.func.isRequired,
  setIsFilePending: PropTypes.func.isRequired,
  updateAgingModel: PropTypes.func.isRequired,
  uploadPipelineFile: PropTypes.func.isRequired
};

DynamicIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.3rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.4rem',

    '& > div': {
      fontSize: '1rem',
      fontWeight: 700
    },
    '& > span': {
      fontSize: '1rem',
      fontWeight: 400
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: theme.palette.nexans.primary,
    color: 'white'
  },
  menu: {
    backgroundColor: theme.palette.nexans.primary,
    borderRadius: '10px',
    color: 'white',
    fontSize: '18px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  },
  menuItem: {
    color: 'white !important',
    fontSize: '1rem',
    alignSelf: 'center',
    padding: '0 0.5rem 0 0'
  },
  menuPaper: {
    backgroundColor: theme.palette.nexans.primary
  },
  cardTitle: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    color: 'black'
  },
  subHeader: {
    width: '100%'
  },
  cardHeader: {
    width: '100%'
  },
  cardHeaderContent: {
    paddingLeft: '1.5rem'
  },
  subHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 700,
      color: 'black'
    },
    '& > span': {
      fontSize: '1rem',
      fontWeight: 400
    }
  },
  cardContentTitle: {
    fontSize: '1.4rem',
    fontWeight: 700,
    color: 'black'
  }
}));
