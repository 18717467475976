import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import {
  PredeterminedProjectForm,
  PredeterminedProjectGantt,
  SimpleTwoActionsDialogContent,
  TechnicalPolicyForm
} from 'components';
import { SearchButton } from 'components/SearchButton';
import { useCallback, useMemo, useState } from 'react';
import Chart from 'react-google-charts';
import {
  useGetAllScenarioProjectList,
  useResetProject,
  useScenarioProjectList,
  useSetSelectedProject
} from 'state/hooks/Project.hooks';
import {
  useGetAllScenarioTechnicalPolicyList,
  useResetTechnicalPolicy,
  useScenarioTechnicalPolicyList,
  useSetSelectedTechnicalPolicy
} from 'state/hooks/TechnicalPolicy.hooks';
import { ColorsUtils } from 'utils/helpers/ColorsUtils';
import { v4 as uuidv4 } from 'uuid';

const create = (
  t,
  customClasses,
  parameterData,
  parametersState,
  setParametersState,
  editMode,
  parametersGroupDataId,
  extraConfig
) => {
  const {
    // List of import elements
    importPredeterminedProjectList,
    importTechnicalPolicyList,
    setImportPredeterminedProjectList,
    setImportTechnicalPolicyList,
    // Displays
    displayForm,
    displayTable,
    setDisplayForm,
    setDisplayTable,
    // Sub-menu
    subMenuAnchorEl,
    subMenuOpen,
    setSubMenuAnchorEl,
    setSubMenuOpen,
    // Pop-up notification
    showDeleteConfirmationPopUp,
    showWarningExitWithoutSave,
    setShowDeleteConfirmationPopUp,
    setShowWarningExitWithoutSave,
    // BreadCrumbs
    addBreadCrumbsItems
  } = extraConfig;

  const resetTechnicalPolicy = useResetTechnicalPolicy();
  const resetPredeterminedProject = useResetProject();
  const setSelectedTechnicalPolicy = useSetSelectedTechnicalPolicy();
  const setSelectedPredeterminedProject = useSetSelectedProject();
  const getScenarioTechnicalPolicyList = useGetAllScenarioTechnicalPolicyList();
  const getScenarioProjectList = useGetAllScenarioProjectList();

  const predeterminedProjectList = useScenarioProjectList();
  const technicalPolicyList = useScenarioTechnicalPolicyList();

  const [predeterminedProjectSearch, setPredeterminedProjectSearch] = useState('');
  const [page, setPage] = useState(0);
  const ROWS_PER_PAGE = 20;

  const emptyRows = useCallback(
    (rows) => {
      return page > 0 ? Math.max(0, (1 + page) * ROWS_PER_PAGE - rows.length) : 0;
    },
    [page]
  );

  const filteredImportPredeterminedProjectList = useMemo(() => {
    return importPredeterminedProjectList.filter((p) =>
      p.$dtId.toLowerCase().includes(predeterminedProjectSearch.toLowerCase())
    );
  }, [importPredeterminedProjectList, predeterminedProjectSearch]);

  const currentScenarioStartDate = parametersState['parameter_scenario_global_date_de_debut_scenario']
    ? parametersState['parameter_scenario_global_date_de_debut_scenario']
    : new Date().getFullYear();
  const currentScenarioEndDate = parametersState['parameter_scenario_global_date_de_debut_scenario']
    ? parametersState['parameter_scenario_global_date_de_fin_scenario']
    : new Date().getFullYear();

  // Memoize and modify event date to correspond with current scenario date
  const technicalPolicyListFromParametersStateMEMO = useMemo(
    () => parametersState.parameter_scenario_technical_policy_list,
    [parametersState.parameter_scenario_technical_policy_list, currentScenarioStartDate, currentScenarioEndDate]
  );

  const [deleteList, setDeleteList] = useState([]);
  const handleCheckboxChange = (event) => {
    if (!editMode) {
      return;
    }
    setDeleteList((list) => {
      const isAlreadyInList = !!list.find((element) => element.$dtId === event.$dtId);
      if (isAlreadyInList) {
        return list.filter((element) => element.$dtId !== event.$dtId);
      }
      return [...list, event];
    });
  };
  const handleDeleteClick = () => {
    if (editMode) {
      setShowDeleteConfirmationPopUp(deleteList);
    }
  };

  function initializeImportTechnicalPolicyList() {
    getScenarioTechnicalPolicyList();
    setImportTechnicalPolicyList(
      technicalPolicyList
        .map((technicalPolicy) => ({ ...technicalPolicy, Selected: false }))
        .sort((previous, current) => previous.$dtId.localeCompare(current.$dtId))
    );
  }

  function initializeImportPredeterminedProjectList() {
    getScenarioProjectList();
    setImportPredeterminedProjectList(
      predeterminedProjectList.data
        .map((predeterminedProject) => ({
          ...predeterminedProject,
          Selected: false,
          predeterminedProject: true
        }))
        .sort((previous, current) => previous.$dtId.localeCompare(current.$dtId))
    );
  }

  function getGanttColumns() {
    return [
      { type: 'string', label: 'Task ID' },
      { type: 'string', label: 'Task Name' },
      { type: 'string', label: 'Resource' },
      { type: 'date', label: 'Start Date' },
      { type: 'date', label: 'End Date' },
      { type: 'number', label: 'Duration' },
      { type: 'number', label: 'Percent Complete' },
      { type: 'string', label: 'Dependencies' }
    ];
  }

  function getScenarioEventsList() {
    return technicalPolicyListFromParametersStateMEMO?.length > 0
      ? technicalPolicyListFromParametersStateMEMO
      : parametersState.parameter_scenario_technical_policy_list || [];
  }

  function getValidTechnicalPolicyEvents() {
    return getScenarioEventsList().filter((event) => !event.predeterminedProject);
  }

  function getValidPredeterminedProjectEvents() {
    return getScenarioEventsList().filter((event) => event.predeterminedProject);
  }

  function getTechnicalPolicyGanttEvents() {
    return getValidTechnicalPolicyEvents().map((technicalPolicy) => [
      technicalPolicy.id,
      `${technicalPolicy.$dtId} - ${technicalPolicy.AssetClass}`,
      technicalPolicy.id,
      new Date(
        technicalPolicy.YearStart >= currentScenarioStartDate ? technicalPolicy.YearStart : currentScenarioStartDate,
        0,
        1
      ),
      new Date(
        technicalPolicy.YearEnd <= currentScenarioEndDate
          ? `${technicalPolicy.YearEnd}-12-31`
          : `${currentScenarioEndDate}-12-31`
      ),
      null,
      null,
      null
    ]);
  }

  function getGanttEvents() {
    const ganttEvents = getTechnicalPolicyGanttEvents();
    return ganttEvents;
  }

  function getGanttPalette() {
    const distinctAssetClass = technicalPolicyList
      .map((event) => event.AssetClass)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort();

    const paletteTechnicalPolicy = getValidTechnicalPolicyEvents().map((technicalPolicy) => {
      const colorToPick = ColorsUtils[distinctAssetClass.indexOf(technicalPolicy.AssetClass)];
      return {
        color: colorToPick,
        dark: colorToPick,
        light: colorToPick
      };
    });

    return paletteTechnicalPolicy;
  }

  function checkAnyImportTechnicalPolicySelected() {
    let anyChecked = false;
    if (importTechnicalPolicyList.some((importTechnicalPolicy) => importTechnicalPolicy.Selected)) anyChecked = true;
    return anyChecked;
  }

  function checkAnyImportPredeterminedProjectSelected() {
    let anyChecked = false;
    if (importPredeterminedProjectList.some((importPredeterminedProject) => importPredeterminedProject.Selected))
      anyChecked = true;
    return anyChecked;
  }

  function checkAllImportTechnicalPolicySelected() {
    let allChecked = true;
    if (importTechnicalPolicyList.some((importTechnicalPolicy) => !importTechnicalPolicy.Selected)) allChecked = false;
    return allChecked;
  }

  function checkAllImportPredeterminedProjectSelected() {
    let allChecked = true;
    if (
      filteredImportPredeterminedProjectList.some((importPredeterminedProject) => !importPredeterminedProject.Selected)
    )
      allChecked = false;
    return allChecked;
  }

  function handleSelectedAllImportTechnicalPolicyClick() {
    const allChecked = checkAllImportTechnicalPolicySelected();
    setImportTechnicalPolicyList([
      ...importTechnicalPolicyList.map((importTechnicalPolicy) => ({
        ...importTechnicalPolicy,
        Selected: !allChecked
      }))
    ]);
  }

  function handleSelectedAllImportPredeterminedProjectClick() {
    const allChecked = checkAllImportPredeterminedProjectSelected();

    // Optimization when no search filter
    if (importPredeterminedProjectList.length === filteredImportPredeterminedProjectList.length) {
      setImportPredeterminedProjectList([
        ...importPredeterminedProjectList.map((importPredeterminedProject) => ({
          ...importPredeterminedProject,
          Selected: !allChecked
        }))
      ]);
    } else {
      setImportPredeterminedProjectList([
        ...importPredeterminedProjectList.map((importPredeterminedProject) =>
          filteredImportPredeterminedProjectList.find((p) => p.$dtId === importPredeterminedProject.$dtId) !== undefined
            ? {
                ...importPredeterminedProject,
                Selected: !allChecked
              }
            : importPredeterminedProject
        )
      ]);
    }
  }

  function handleSelectedImportTechnicalPolicyClick(technicalPolicyIndex) {
    setImportTechnicalPolicyList([
      ...importTechnicalPolicyList.map((importTechnicalPolicy, importTechnicalPolicyIndex) =>
        technicalPolicyIndex === importTechnicalPolicyIndex
          ? {
              ...importTechnicalPolicy,
              Selected: !importTechnicalPolicy.Selected
            }
          : importTechnicalPolicy
      )
    ]);
  }

  function handleSelectedImportPredeterminedProjectClick(predeterminedProject) {
    setImportPredeterminedProjectList([
      ...importPredeterminedProjectList.map((importPredeterminedProject) =>
        predeterminedProject.$dtId === importPredeterminedProject.$dtId
          ? {
              ...importPredeterminedProject,
              Selected: !importPredeterminedProject.Selected
            }
          : importPredeterminedProject
      )
    ]);
  }

  function handleOpenMenu(event, menu) {
    if (editMode) {
      setSubMenuAnchorEl(event.currentTarget);
      setSubMenuOpen(menu);
    }
  }

  function handleCloseMenu() {
    setSubMenuAnchorEl(null);
    setSubMenuOpen(null);
  }

  function handleSubMenuTechnicalPolicyImportLibraryClick() {
    initializeImportTechnicalPolicyList();
    setDisplayForm(null);
    setDisplayTable('technical-policy');
    handleCloseMenu();
  }

  function handleSubMenuTechnicalPolicyCreateNewClick() {
    resetTechnicalPolicy();
    setDisplayTable(null);
    setDisplayForm('technical-policy');
    handleCloseMenu();
  }

  function handleSubMenuPredeterminedProjectImportLibraryClick() {
    initializeImportPredeterminedProjectList();
    setDisplayForm(null);
    setDisplayTable('predetermined-project');
    setPredeterminedProjectSearch('');
    setPage(0);
    handleCloseMenu();
  }

  function handleSubMenuPredeterminedProjectCreateNewClick() {
    resetPredeterminedProject();
    setDisplayTable(null);
    setDisplayForm('predetermined-project');
    handleCloseMenu();
  }

  function handleButtonCancelImportToScenarioClick() {
    setDisplayTable(null);
  }

  function generateUnique$dtId($dtId) {
    if (getScenarioEventsList().some((event) => event.$dtId === $dtId)) {
      let increment = 0;
      const generateUnique = ($dtId) => `${$dtId} (${increment})`;
      let unique;
      do {
        increment++;
        unique = generateUnique($dtId);
      } while (getScenarioEventsList().some((event) => event.$dtId === unique));
      return unique;
    } else {
      return $dtId;
    }
  }

  function handleButtonImportTechnicalPolicyToScenarioClick() {
    if (checkAnyImportTechnicalPolicySelected()) {
      setParametersState({
        ...parametersState,
        [parameterData.id]: [
          ...parametersState[parameterData.id],
          ...importTechnicalPolicyList
            .filter((importTechnicalPolicy) => importTechnicalPolicy.Selected)
            .map((importTechnicalPolicy) => {
              importTechnicalPolicy.Selected = undefined;
              if (parametersState[parameterData.id].some((event) => event.id === importTechnicalPolicy.id)) {
                importTechnicalPolicy.id = uuidv4();
              }
              importTechnicalPolicy.$dtId = generateUnique$dtId(importTechnicalPolicy.$dtId);
              return importTechnicalPolicy;
            })
        ]
      });
      setDisplayTable(null);
    }
  }

  function handleButtonImportPredeterminedProjectToScenarioClick() {
    if (checkAnyImportPredeterminedProjectSelected() === true) {
      setParametersState({
        ...parametersState,
        [parameterData.id]: [
          ...parametersState[parameterData.id],
          ...importPredeterminedProjectList
            .filter((importPredeterminedProject) => importPredeterminedProject.Selected)
            .map((importPredeterminedProject) => {
              importPredeterminedProject.Selected = undefined;
              if (parametersState[parameterData.id].some((event) => event.id === importPredeterminedProject.id)) {
                importPredeterminedProject.id = uuidv4();
              }
              importPredeterminedProject.$dtId = generateUnique$dtId(importPredeterminedProject.$dtId);
              return importPredeterminedProject;
            })
        ]
      });
      setDisplayTable(null);
    }
  }

  function handleGanttEditAction(eventToEdit) {
    if (editMode) {
      if (eventToEdit.predeterminedProject) {
        setSelectedPredeterminedProject(eventToEdit);
        setDisplayForm('predetermined-project');
      } else {
        setSelectedTechnicalPolicy(eventToEdit);
        setDisplayForm('technical-policy');
      }
      setDisplayTable(null);
    }
  }

  function handleDeleteEventsFromScenario(idList) {
    setParametersState({
      ...parametersState,
      [parameterData.id]: [
        ...parametersState[parameterData.id].filter((technicalPolicy) => !idList.includes(technicalPolicy.id))
      ]
    });
    setDeleteList([]);
    setDisplayForm(null);
    setShowDeleteConfirmationPopUp(null);
  }

  function applyUpdate(updatedEvent, isPredeterminedProject) {
    if (updatedEvent.id) {
      setParametersState({
        ...parametersState,
        [parameterData.id]: [
          ...parametersState[parameterData.id].map((technicalPolicy) =>
            technicalPolicy.id === updatedEvent.id ? updatedEvent : technicalPolicy
          )
        ]
      });
      initializeImportTechnicalPolicyList();
    } else {
      const newEvent = { ...updatedEvent, id: uuidv4() };
      if (isPredeterminedProject) {
        newEvent.predeterminedProject = true;
      }
      setParametersState({
        ...parametersState,
        [parameterData.id]: [...parametersState[parameterData.id], newEvent]
      });
    }
    initializeImportPredeterminedProjectList();
    setDisplayForm(null);
  }

  function handleExitWithoutSave() {
    setShowWarningExitWithoutSave(null);
    setDisplayForm(null);
  }

  const areGanttEventsInCurrentScenarioDatesIntervall = getGanttEvents()?.some(
    (event) => currentScenarioEndDate >= event[3].getFullYear()
  );
  const areGanttProjectsInCurrentScenarioDatesIntervall = getValidPredeterminedProjectEvents()?.some(
    (event) => currentScenarioEndDate >= new Date(event.PlannedDate).getFullYear()
  );

  const displayGantt = getScenarioEventsList().length > 0 && areGanttEventsInCurrentScenarioDatesIntervall;

  return (
    <Grid container={true} direction='row' key={`grid-container-${parametersGroupDataId}`}>
      <Grid item={true} key={`grid-row-0-${parametersGroupDataId}`} md={12}>
        <Grid
          container={true}
          direction='row'
          style={{
            paddingBottom: '0.5rem',
            gap: '1rem',
            cursor: editMode ? 'pointer' : 'not-allowed'
          }}
        >
          <Tooltip
            placement='top-end'
            title={editMode ? t('genericcomponent.button.editMode.true') : t('genericcomponent.button.editMode.false')}
          >
            <Card
              style={{
                display: 'flex',
                height: '4rem',
                alignItems: 'center'
              }}
            >
              <CardHeader
                action={
                  <IconButton
                    disabled={!editMode}
                    size='large'
                    onClick={(event) => handleOpenMenu(event, 'technical-policy')}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                avatar={
                  <Avatar className={editMode ? customClasses.activeIcon : customClasses.inactiveIcon}>
                    <AssignmentIcon />
                  </Avatar>
                }
                title={t('commoncomponents.scenario-form.technical-policy-tab.menu-technical-policy')}
              />
            </Card>
          </Tooltip>
          <Menu
            anchorEl={subMenuAnchorEl}
            id='technical-policy-menu'
            open={subMenuOpen === 'technical-policy'}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleSubMenuTechnicalPolicyImportLibraryClick}>
              {t('commoncomponents.scenario-form.technical-policy-tab.sub-menu-import-from-library')}
            </MenuItem>
            <MenuItem onClick={handleSubMenuTechnicalPolicyCreateNewClick}>
              {t('commoncomponents.scenario-form.technical-policy-tab.sub-menu-create-new')}
            </MenuItem>
          </Menu>

          <Tooltip
            placement='top-end'
            title={editMode ? t('genericcomponent.button.editMode.true') : t('genericcomponent.button.editMode.false')}
          >
            <Card style={{ display: 'flex', height: '4rem', alignItems: 'center' }}>
              <CardHeader
                action={
                  <IconButton
                    disabled={!editMode}
                    size='large'
                    onClick={(event) => handleOpenMenu(event, 'predetermined-project')}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                avatar={
                  <Avatar className={editMode ? customClasses.activeIcon : customClasses.inactiveIcon}>
                    <EventIcon />
                  </Avatar>
                }
                title={t('commoncomponents.scenario-form.technical-policy-tab.menu-predetermined-project')}
              />
            </Card>
          </Tooltip>

          <Menu
            anchorEl={subMenuAnchorEl}
            id='predetermined-project-menu'
            open={subMenuOpen === 'predetermined-project'}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleSubMenuPredeterminedProjectImportLibraryClick}>
              {t('commoncomponents.scenario-form.technical-policy-tab.sub-menu-import-from-library')}
            </MenuItem>
            <MenuItem onClick={handleSubMenuPredeterminedProjectCreateNewClick}>
              {t('commoncomponents.scenario-form.technical-policy-tab.sub-menu-create-new')}
            </MenuItem>
          </Menu>
          <Button disabled={!editMode} onClick={() => handleDeleteClick()}>
            <DeleteIcon />
          </Button>
        </Grid>
      </Grid>
      {displayForm === 'technical-policy' && (
        <TechnicalPolicyForm
          addBreadCrumbsItems={addBreadCrumbsItems}
          applyUpdate={(technicalPolicy) => applyUpdate(technicalPolicy)}
          cancelUpdate={() => setShowWarningExitWithoutSave(true)}
        />
      )}
      {displayForm === 'predetermined-project' && (
        <PredeterminedProjectForm
          applyUpdate={(updatedEvent) => applyUpdate(updatedEvent, true)}
          cancelUpdate={() => setShowWarningExitWithoutSave('predetermined-project')}
        />
      )}
      <SimpleTwoActionsDialogContent
        handleClickOnButton1={() => setShowWarningExitWithoutSave(null)}
        handleClickOnButton2={handleExitWithoutSave}
        id='exit-technicalpolicy-confirmation'
        key='exit-technicalpolicy-confirmation'
        labels={{
          title: t('genericcomponent.dialog.scenario.technicalpolicy.cancelEdit.title'),
          body: t('genericcomponent.dialog.scenario.technicalpolicy.cancelEdit.body'),
          button1: t('genericcomponent.dialog.scenario.technicalpolicy.cancelEdit.cancel'),
          button2: t('genericcomponent.dialog.scenario.technicalpolicy.cancelEdit.ok'),
          ariaLabelledby: 'confirm-exit-dialog',
          button2BGColor: '#062F4F'
        }}
        open={showWarningExitWithoutSave === 'technical-policy'}
      />
      <SimpleTwoActionsDialogContent
        handleClickOnButton1={() => setShowWarningExitWithoutSave(null)}
        handleClickOnButton2={handleExitWithoutSave}
        id='exit-predeterminedproject-confirmation'
        key='exit-predeterminedproject-confirmation'
        labels={{
          title: t('genericcomponent.dialog.scenario.predeterminedproject.cancelEdit.title'),
          body: t('genericcomponent.dialog.scenario.predeterminedproject.cancelEdit.body'),
          button1: t('genericcomponent.dialog.scenario.predeterminedproject.cancelEdit.cancel'),
          button2: t('genericcomponent.dialog.scenario.predeterminedproject.cancelEdit.ok'),
          ariaLabelledby: 'confirm-exit-dialog'
        }}
        open={showWarningExitWithoutSave === 'predetermined-project'}
      />
      {areGanttProjectsInCurrentScenarioDatesIntervall &&
        parametersState.parameter_scenario_technical_policy_list.find((element) => element.predeterminedProject) && (
          <PredeterminedProjectGantt
            currentScenarioEndDate={currentScenarioEndDate}
            currentScenarioStartDate={currentScenarioStartDate}
            getValidPredeterminedProjectEvents={getValidPredeterminedProjectEvents}
            handleGanttEditAction={handleGanttEditAction}
            parameterData={parameterData}
            parametersState={parametersState}
            predeterminedProjectList={importPredeterminedProjectList}
            setDisplayForm={setDisplayForm}
            setParametersState={setParametersState}
            setSelectedPredeterminedProject={setSelectedPredeterminedProject}
          />
        )}
      {!areGanttProjectsInCurrentScenarioDatesIntervall &&
        parametersState.parameter_scenario_technical_policy_list.find((element) => element.predeterminedProject) && (
          <Grid
            item={true}
            key={`gantt-projects-${parametersGroupDataId}-empty`}
            md={12}
            style={{ paddingBottom: '1rem' }}
          >
            <Paper className={customClasses.unselectItemBox} elevation={0}>
              <Typography>{t('commoncomponents.scenario-form.technical-policy-tab.notInDateRange')}</Typography>
            </Paper>
          </Grid>
        )}
      {displayTable === 'technical-policy' && [
        <Grid item={true} key={`grid-row-1${parametersGroupDataId}`} md={10}>
          <TableContainer className={customClasses.tableContainer} component={Paper}>
            <Table>
              <TableHead className={customClasses.tableHeader}>
                <TableRow>
                  <TableCell align='left' className={customClasses.tableCellTechnicalPolicies} key='table-cell-0'>
                    <Checkbox
                      checked={checkAllImportTechnicalPolicySelected()}
                      onChange={handleSelectedAllImportTechnicalPolicyClick}
                    />
                  </TableCell>
                  <TableCell align='left' key='table-cell-1'>
                    {t('commoncomponents.scenario-form.technical-policy-tab.table-column-name')}
                  </TableCell>
                  <TableCell align='left' key='table-cell-2'>
                    {t('commoncomponents.scenario-form.technical-policy-tab.table-column-assetClass')}
                  </TableCell>
                  <TableCell align='left' key='table-cell-3'>
                    {t('commoncomponents.scenario-form.technical-policy-tab.table-column-type')}
                  </TableCell>
                  <TableCell align='left' key='table-cell-4'>
                    {t('commoncomponents.scenario-form.technical-policy-tab.table-column-period')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {importTechnicalPolicyList.map((technicalPolicy, indexTechnicalPolicy) => (
                  <TableRow
                    className={
                      indexTechnicalPolicy % 2 === 0
                        ? customClasses.tableRowBackgroundEnabled
                        : customClasses.tableRowBackgroundEnabled2
                    }
                    key={`table-row-${indexTechnicalPolicy}`}
                  >
                    <TableCell align='left' className={customClasses.tableCellTechnicalPolicies} key='table-cell-0'>
                      <Checkbox
                        checked={technicalPolicy.Selected}
                        onChange={() => handleSelectedImportTechnicalPolicyClick(indexTechnicalPolicy)}
                      />
                    </TableCell>
                    <TableCell align='left' className={customClasses.tableCellTechnicalPolicies} key='table-cell-1'>
                      {technicalPolicy.$dtId}
                    </TableCell>
                    <TableCell align='left' className={customClasses.tableCellTechnicalPolicies} key='table-cell-2'>
                      {technicalPolicy.AssetClass}
                    </TableCell>
                    <TableCell align='left' className={customClasses.tableCellTechnicalPolicies} key='table-cell-3'>
                      {technicalPolicy.Type}
                    </TableCell>
                    <TableCell align='left' className={customClasses.tableCellTechnicalPolicies} key='table-cell-4'>
                      {`${technicalPolicy.YearStart}-${technicalPolicy.YearEnd}`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>,
        <Grid item={true} key={`grid-row-2-${parametersGroupDataId}`} md={2}>
          <Grid
            alignContent='center'
            alignItems='center'
            container={true}
            direction='column'
            justifyContent='flex-start'
          >
            <Grid item={true} key='import-technical-policy-action'>
              <Button
                color={checkAnyImportTechnicalPolicySelected() ? 'primary' : 'secondary'}
                variant='contained'
                onClick={handleButtonImportTechnicalPolicyToScenarioClick}
              >
                {t('commoncomponents.scenario-form.technical-policy-tab.button-import-to-scenario')}
              </Button>
            </Grid>
            <Grid item={true} key='cancel-import-technical-policy-action'>
              <Button
                color='primary'
                style={{
                  marginTop: '10px'
                }}
                variant='contained'
                onClick={handleButtonCancelImportToScenarioClick}
              >
                {t('commoncomponents.scenario-form.technical-policy-tab.button-cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ]}
      {displayTable === 'predetermined-project' && [
        <Grid item={true} key={`grid-row-1${parametersGroupDataId}`} md={10}>
          <TableContainer className={customClasses.tableContainer} component={Paper}>
            <Table>
              <TableHead className={customClasses.tableHeader}>
                <TableRow>
                  <TableCell align='left' className={customClasses.tableCellTechnicalPolicies} key='table-cell-0'>
                    {filteredImportPredeterminedProjectList.length > 0 && (
                      <Checkbox
                        checked={checkAllImportPredeterminedProjectSelected()}
                        onChange={handleSelectedAllImportPredeterminedProjectClick}
                      />
                    )}
                  </TableCell>
                  <TableCell align='left' key='table-cell-1'>
                    {t('commoncomponents.scenario-form.technical-policy-tab.table-column-name')}
                  </TableCell>
                  <TableCell align='left' key='table-cell-2'>
                    {t('commoncomponents.scenario-form.technical-policy-tab.table-column-equipmentgroup')}
                  </TableCell>
                  <TableCell align='center' key='table-cell-4'>
                    {t('commoncomponents.scenario-form.technical-policy-tab.table-column-date')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredImportPredeterminedProjectList
                  .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
                  .map((predeterminedProject, indexPredeterminedProject) => (
                    <TableRow
                      className={
                        indexPredeterminedProject % 2 === 0
                          ? customClasses.tableRowBackgroundEnabled
                          : customClasses.tableRowBackgroundEnabled2
                      }
                      key={`table-row-${predeterminedProject.$dtId}`}
                    >
                      <TableCell align='left' className={customClasses.tableCellTechnicalPolicies} key='table-cell-0'>
                        <Checkbox
                          checked={predeterminedProject.Selected}
                          onChange={() => handleSelectedImportPredeterminedProjectClick(predeterminedProject)}
                        />
                      </TableCell>
                      <TableCell
                        align='left'
                        className={customClasses.tableCellTechnicalPolicies}
                        key='table-cell-1'
                        style={{ minWidth: 100, maxWidth: 100 }}
                      >
                        {predeterminedProject.$dtId}
                      </TableCell>
                      <TableCell
                        align='left'
                        className={customClasses.tableCellTechnicalPolicies}
                        key='table-cell-2'
                        style={{ minWidth: 100, maxWidth: 100 }}
                      >
                        {predeterminedProject.OnEquipmentGroup}
                      </TableCell>
                      <TableCell
                        align='center'
                        className={customClasses.tableCellTechnicalPolicies}
                        key='table-cell-3'
                        style={{ minWidth: 50, maxWidth: 50 }}
                      >
                        {predeterminedProject.PlannedDate}
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows(filteredImportPredeterminedProjectList) > 0 && (
                  <TableRow style={{ height: 43 * emptyRows(filteredImportPredeterminedProjectList) }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                {filteredImportPredeterminedProjectList.length > 0 ? (
                  <TableRow>
                    <TablePagination
                      colSpan={3}
                      count={filteredImportPredeterminedProjectList.length}
                      labelDisplayedRows={({ page }) =>
                        `${page + 1} / ${Math.ceil(filteredImportPredeterminedProjectList.length / ROWS_PER_PAGE)}`
                      }
                      page={page}
                      rowsPerPage={ROWS_PER_PAGE}
                      rowsPerPageOptions={[]}
                      onPageChange={(_, newPage) => setPage(newPage)}
                    />
                  </TableRow>
                ) : (
                  <Typography variant='h5'>{t('views.library.predeterminedproject.noResults')}</Typography>
                )}
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>,
        <Grid item={true} key={`grid-row-2-${parametersGroupDataId}`} md={2}>
          <Grid alignContent='center' alignItems='center' container={true} direction='column' justifyContent='center'>
            <Grid item={true} key='search-predetermined-project-action'>
              <div style={{ marginLeft: '20px', marginTop: '2px' }}>
                <SearchButton
                  placeholder={t('commoncomponents.scenario-form.technical-policy-tab.button-search')}
                  value={predeterminedProjectSearch}
                  onChange={(evt) => {
                    setPredeterminedProjectSearch(evt.target.value);
                    setPage(0);
                  }}
                  onClear={() => {
                    setPredeterminedProjectSearch('');
                    setPage(0);
                  }}
                ></SearchButton>
              </div>
            </Grid>
            <Grid item={true} key='import-predetermined-project-action'>
              <Button
                color={checkAnyImportPredeterminedProjectSelected() ? 'primary' : 'secondary'}
                style={{ marginTop: '20px' }}
                variant='contained'
                onClick={handleButtonImportPredeterminedProjectToScenarioClick}
              >
                {t('commoncomponents.scenario-form.technical-policy-tab.button-import-to-scenario')}
              </Button>
            </Grid>
            <Grid item={true} key='cancel-predetermined-project-action'>
              <Button
                color='primary'
                style={{ marginTop: '10px' }}
                variant='contained'
                onClick={handleButtonCancelImportToScenarioClick}
              >
                {t('commoncomponents.scenario-form.technical-policy-tab.button-cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ]}
      {displayGantt && (
        <Grid container={true} direction='row' justifyContent='flex-start' spacing={0}>
          <Grid
            item={true}
            key={`grid-row3-${parametersGroupDataId}-gantt-action`}
            md={1}
            style={{ maxWidth: '3.5rem' }}
          >
            <Grid container={true} direction='row' justifyContent='flex-start' spacing={0}>
              <table
                cellSpacing='0'
                className={customClasses.ganttActionTable}
                style={{
                  height: `${getGanttEvents().length * 2.38}rem`,
                  marginBottom: '3rem'
                }}
              >
                <tbody className={customClasses.ganttActionBody}>
                  {[...getValidTechnicalPolicyEvents()].map((event, eventIndex) => (
                    <tr
                      className={eventIndex % 2 !== 0 ? customClasses.ganttActionRowOdd : ''}
                      key={`ganttActionRow-${eventIndex}`}
                    >
                      <td className={customClasses.ganttActionColumn}>
                        <Tooltip
                          title={
                            editMode
                              ? t('genericcomponent.button.editMode.true')
                              : t('genericcomponent.button.editMode.false')
                          }
                        >
                          <EditIcon
                            className={customClasses.ganttActionIcon}
                            disabled={!editMode}
                            style={{
                              cursor: editMode ? 'pointer' : 'not-allowed',
                              opacity: editMode ? '1' : '0.5'
                            }}
                            onClick={() => handleGanttEditAction(event)}
                          />
                        </Tooltip>
                        <Tooltip
                          title={
                            editMode
                              ? t('genericcomponent.button.editMode.true')
                              : t('genericcomponent.button.editMode.false')
                          }
                        >
                          <Checkbox
                            checked={deleteList.find((element) => element.id === event.id) !== undefined}
                            className={customClasses.ganttActionIcon}
                            disabled={!editMode}
                            style={{
                              cursor: editMode ? 'pointer' : 'not-allowed',
                              opacity: editMode ? '1' : '0.5'
                            }}
                            onClick={() => handleCheckboxChange(event)}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                  <SimpleTwoActionsDialogContent
                    handleClickOnButton1={() => setShowDeleteConfirmationPopUp(null)}
                    handleClickOnButton2={() => handleDeleteEventsFromScenario(deleteList.map((element) => element.id))}
                    id={'delete-confirmation'}
                    key={'delete-confirmation'}
                    labels={{
                      title: t(
                        `genericcomponent.dialog.scenario.technicalpolicy.${
                          deleteList.length > 1 ? 'deleteMany' : 'delete'
                        }.title`
                      ),
                      body: t(
                        `genericcomponent.dialog.scenario.technicalpolicy.${
                          deleteList.length > 1 ? 'deleteMany' : 'delete'
                        }.body`
                      ),
                      button1: t('genericcomponent.dialog.scenario.technicalpolicy.delete.cancel'),
                      button2: t('genericcomponent.dialog.scenario.technicalpolicy.delete.delete'),
                      ariaLabelledby: 'confirm-delete-dialog',
                      button2BGColor: '#062F4F'
                    }}
                    open={showDeleteConfirmationPopUp && deleteList?.length > 0}
                  />
                </tbody>
              </table>
            </Grid>
          </Grid>
          <Grid
            item={true}
            key={`grid-row3-${parametersGroupDataId}-gantt`}
            md={11}
            // Necessary to disable the uncustomizable tooltip
            style={{ pointerEvents: 'none' }}
          >
            <Chart
              chartType='Gantt'
              data={[getGanttColumns(), ...getGanttEvents()]}
              enableInteractivity={false}
              height='100%'
              legendToggle={true}
              options={{
                height: '100%',
                gantt: {
                  barCornerRadius: 10,
                  barHeight: 22,
                  labelStyle: {
                    fontName: 'Inter, sans-serif',
                    fontSize: 12,
                    color: '#000000'
                  },
                  percentEnabled: false,
                  sortTasks: false,
                  palette: getGanttPalette()
                }
              }}
              tooltip={{
                trigger: 'none'
              }}
              width='100%'
            />
          </Grid>
        </Grid>
      )}
      {!displayGantt && getGanttEvents().length === 0 && (
        <Grid item={true} key={`grid-row3-${parametersGroupDataId}-empty`} md={12}>
          <Paper className={customClasses.unselectItemBox} elevation={0}>
            <Typography className={customClasses.unselectItemText}>
              {t('commoncomponents.scenario-form.technical-policy-tab.empty')}
            </Typography>
          </Paper>
        </Grid>
      )}

      {!displayGantt && getGanttEvents().length > 0 && !areGanttEventsInCurrentScenarioDatesIntervall && (
        <Grid item={true} key={`grid-row3-${parametersGroupDataId}-empty`} md={12}>
          <Paper className={customClasses.unselectItemBox} elevation={0}>
            <Typography>{t('commoncomponents.scenario-form.technical-policy-tab.notInDateRange')}</Typography>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export const TechnicalPolicyListFactory = {
  create
};
