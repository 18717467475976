import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { Dialog } from 'components';
import { ISubset } from 'models';
import { useTranslation } from 'react-i18next';
import { DIALOG_TYPE } from 'state/commons';
import { useDialogParams, useHideDialog } from 'state/hooks/Dialog.hooks';
import { useDeleteSubset } from 'state/hooks/Subset.hooks';

interface IDialogParams {
  subset: ISubset;
}

export const SubsetDeletionConfirmationDialog = () => {
  const { t } = useTranslation();

  const dialogType = DIALOG_TYPE.SUBSET_DELETION_DIALOG_TYPE;

  const hideDialog = useHideDialog();
  const dialogParams = useDialogParams<IDialogParams>();
  const deleteSubsetById = useDeleteSubset();

  const contentText = t('layouts.tabs.assetregistry.subset.deleteSubsetMessage', {
    name: dialogParams?.subset?.name ?? ''
  });

  return (
    <Dialog
      actions={
        <>
          <Button onClick={hideDialog}>{t<string>('commoncomponents.dialog.confirm.delete.button.cancel')}</Button>
          <Button
            onClick={() => {
              if (dialogParams) {
                deleteSubsetById(dialogParams.subset.id);
                hideDialog();
              }
            }}
          >
            {t<string>('commoncomponents.dialog.confirm.delete.button.confirm')}
          </Button>
        </>
      }
      dialogType={dialogType}
      title={t('layouts.tabs.assetregistry.subset.deleteSubset')}
    >
      <DialogContentText>{contentText}</DialogContentText>
    </Dialog>
  );
};
