import { ApiOperation } from 'api/ApiOperation';
import { call, put, takeEvery } from 'redux-saga/effects';
import { OPERATION_ACTIONS_KEY } from 'state/commons/OperationConstants';
import { selectOrganizationId } from '../../sagaUtils';

// generators function
export function* importOperationsFile(action) {
  const file = action.file;
  try {
    yield put({
      type: OPERATION_ACTIONS_KEY.SET_OPERATIONS_IMPORT_ERRORS,
      errors: null
    });
    yield put({
      type: OPERATION_ACTIONS_KEY.SET_OPERATIONS_IMPORT_UPLOADING,
      uploading: true
    });
    const organizationId = yield selectOrganizationId();
    yield call(ApiOperation.importOperationsFile, organizationId, file);
    yield put({
      type: OPERATION_ACTIONS_KEY.GET_ALL_OPERATIONS,
      params: { keyword: null, perPage: 5, page: 1 }
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: OPERATION_ACTIONS_KEY.SET_OPERATIONS_IMPORT_ERRORS,
      errors: e.response?.data
    });
  }

  yield put({
    type: OPERATION_ACTIONS_KEY.SET_OPERATIONS_IMPORT_UPLOADING,
    uploading: false
  });
}

function* UploadOperationsFile() {
  yield takeEvery(OPERATION_ACTIONS_KEY.UPLOAD_OPERATIONS, importOperationsFile);
}

export default UploadOperationsFile;
