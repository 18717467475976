import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchSetSelectedAssetClass } from 'state/dispatchers/assetClasses/assetClassesDispatcher';
import { dispatchGetAllFamiliesByAssetClass } from 'state/dispatchers/technicalPolicy/technicalPolicyDispatcher';

export const useGetAllFamiliesByAssetClass = () => {
  const dispatch = useDispatch();
  return useCallback((assetClass) => dispatch(dispatchGetAllFamiliesByAssetClass(assetClass)), [dispatch]);
};

export const useSetSelectedAssetClass = () => {
  const dispatch = useDispatch();
  return useCallback((assetClass) => dispatch(dispatchSetSelectedAssetClass(assetClass)), [dispatch]);
};

export const useSelectedAssetClass = () => {
  return useSelector((state) => state.assetClasses.selected.data);
};

export const useOperationsByAssetClass = () => {
  return useSelector((state) => state.operation.operationsByAssetClass.data);
};
export const useAssetClasses = () => {
  return useSelector((state) => state.assetClasses.list.data);
};
export const useFamiliesByAssetClasses = () => {
  return useSelector((state) => state.familiesByAssetClass.list.data);
};
