import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Card, CardContent, FormControlLabel, Grid, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OperationSelector, SimpleTwoActionsDialogContent } from 'components';
import InputComponentFactory from 'components/InputComponentFactory';
import TechnicalPolicyCriterion from 'components/TechnicalPolicy/TechnicalPolicyCriterion';
import TechnicalPolicyCriterionTree from 'components/TechnicalPolicy/TechnicalPolicyCriterionTree';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAssetClasses, useOperationsByAssetClass, useSetSelectedAssetClass } from 'state/hooks/AssetClass.hooks';
import { useBudgetCategoriesEnum } from 'state/hooks/Budget.hooks';

import {
  useCheckIfTechnicalPolicyNameExists,
  useIsTechnicalPolicyNameAvailable,
  useSelectedTechnicalPolicy,
  useTechnicalPolicyEnums
} from 'state/hooks/TechnicalPolicy.hooks';
import theme from 'theme';

import { useSetOperationsByAssetClassSearch } from 'state/hooks/Operation.hooks';

function TechnicalPolicyForm({ addBreadCrumbsItems, applyUpdate, cancelUpdate, shouldReset }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showMissingFields, setShowMissingfields] = useState(false);
  const [areCriterionsNameValid, setAreCriterionsNameValid] = useState(true);
  const triggerNameIterator = useRef(1);

  const checkIsNameExist = useCheckIfTechnicalPolicyNameExists();
  const techPolNameAvailable = useIsTechnicalPolicyNameAvailable();
  const isNameAvailable = techPolNameAvailable.data;
  const isNameAvailableStatus = techPolNameAvailable.status;
  const selectedTechnicalPolicy = useSelectedTechnicalPolicy();
  const budgetCategoriesEnum = useBudgetCategoriesEnum();

  const operationsByAssetClass = useOperationsByAssetClass();
  const assetClassesList = useAssetClasses();
  const setSelectedAssetClass = useSetSelectedAssetClass();
  const technicalPolicyEnumsParameter = useTechnicalPolicyEnums();

  const setOperationByAssetClassSearch = useSetOperationsByAssetClassSearch();

  const replaceOperatorValueByOperatorKey = (data) => ({
    ...data,
    conditionValues: {
      ...data.conditionValues,
      operatorSettings: {
        ...data.conditionValues?.operatorSettings,
        enumLists: {
          ...data.conditionValues?.operatorSetting?.enumLists,
          NeedOperator: data.conditionValues?.operatorSettings?.enumLists?.NeedOperator?.map((operator) => ({
            key: operator.key,
            value: operator.key
          }))
        }
      }
    }
  });

  const technicalPolicyEnums = replaceOperatorValueByOperatorKey(technicalPolicyEnumsParameter);
  const technicalPolicyFormMinStartDate = 1900;
  const technicalPolicyFormMaxEndDate = 2200;

  const [technicalPolicyForm, setTechnicalPolicyForm] = useState({
    $dtId: selectedTechnicalPolicy.$dtId,
    $etag: selectedTechnicalPolicy.$etag,
    ActionToTrigger: selectedTechnicalPolicy.ActionToTrigger,
    AssetClass: selectedTechnicalPolicy.AssetClass,
    BudgetCategories: selectedTechnicalPolicy.BudgetCategories,
    CriteriaFormula: selectedTechnicalPolicy.CriteriaFormula,
    Criterions: selectedTechnicalPolicy.Criterions,
    Id: selectedTechnicalPolicy.id,
    EnablePendingActionRemoval: selectedTechnicalPolicy.EnablePendingActionRemoval,
    IsInactive: selectedTechnicalPolicy.IsInactive,
    NbRepAction1: selectedTechnicalPolicy.NbRepAction1,
    RepeatedAction: selectedTechnicalPolicy.RepeatedAction,
    Type: selectedTechnicalPolicy.Type,
    YearEnd: selectedTechnicalPolicy.YearEnd,
    YearStart: selectedTechnicalPolicy.YearStart
  });

  const [withIteration, setWithIteration] = useState(false);

  useEffect(() => {
    setSelectedAssetClass(technicalPolicyForm.AssetClass);
  }, [technicalPolicyForm.AssetClass]);

  useEffect(() => {
    initializeTechnicalPolicyForm();
    // On tech pol selection, scroll to top of the screen
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [selectedTechnicalPolicy.id, selectedTechnicalPolicy.$dtId, shouldReset]);

  function initializeTechnicalPolicyForm() {
    const item = selectedTechnicalPolicy?.$dtId
      ? selectedTechnicalPolicy.$dtId
      : t('layouts.tabs.library.technical.policies.newPolicy.breadcrumb.title');
    addBreadCrumbsItems([item]);
    setTechnicalPolicyForm({
      ...selectedTechnicalPolicy,
      YearEnd: selectedTechnicalPolicy.YearEnd ? selectedTechnicalPolicy.YearEnd : 2100,
      YearStart: selectedTechnicalPolicy.YearStart ? selectedTechnicalPolicy.YearStart : new Date().getFullYear()
    });
    // reset check name if init
    checkIsNameExist(selectedTechnicalPolicy.$dtId, selectedTechnicalPolicy, selectedTechnicalPolicy);
    setWithIteration(selectedTechnicalPolicy.NbRepAction1 > 0);
  }

  function getTechnicalPolicyTypes() {
    let technicalPolicyTypes = [];
    if (technicalPolicyEnums?.types) {
      technicalPolicyTypes = [
        ...Object.keys(technicalPolicyEnums.types).map((key) => ({
          key: technicalPolicyEnums.types[key],
          value: technicalPolicyEnums.types[key]
        }))
      ];
    }
    return technicalPolicyTypes;
  }

  function getTechnicalPolicyAssetClasses() {
    let technicalPolicyAssetClasses = [];
    if (assetClassesList) {
      technicalPolicyAssetClasses = [
        ...assetClassesList.map((assetClass) => ({
          key: assetClass,
          value: assetClass
        }))
      ];
    }
    return technicalPolicyAssetClasses;
  }

  const getTechnicalPolicyActionsAssetClasses = useCallback(
    () => operationsByAssetClass.map((operation) => operation.$dtId),
    [operationsByAssetClass]
  );

  function getTechnicalPolicyBudgetCategories() {
    const budgetCategoriesFormated = budgetCategoriesEnum.map((category) => ({
      key: category,
      value: category
    }));

    return budgetCategoriesFormated;
  }

  function updateTechnicalPolicyForm(field, value) {
    const newValue = value;
    switch (field) {
      case '$dtId': {
        const newTechPol = {
          ...technicalPolicyForm,
          [field]: newValue,
          // Update criterions $dtId with parent's $dtId
          Criterions: technicalPolicyForm.Criterions.map((criteria) => ({
            ...criteria,
            $dtId: `${value}-${criteria.Name}`
          }))
        };
        // If edit existing form, spare checkName
        if (selectedTechnicalPolicy.$dtId !== newTechPol.$dtId) {
          checkIsNameExist(value, selectedTechnicalPolicy, newTechPol);
        }
        setTechnicalPolicyForm(newTechPol);
        break;
      }
      case 'AssetClass':
        setTechnicalPolicyForm({
          ...technicalPolicyForm,
          [field]: newValue,
          ActionToTrigger: ''
        });
        break;
      default:
        setTechnicalPolicyForm({
          ...technicalPolicyForm,
          [field]: newValue
        });
        break;
    }
  }

  function switchWithIteration() {
    setTechnicalPolicyForm({
      ...technicalPolicyForm,
      NbRepAction1: withIteration ? -1 : 1,
      RepeatedAction: ''
    });
    setWithIteration(!withIteration);
  }

  function isCriterionEquipmentComponentAttributeEmpty(criterions) {
    return criterions?.some((criterion) => {
      switch (criterion.EquipmentComponentAttribute) {
        case 'Failure':
          return false;
        case 'Age':
          return criterion.value >= 0;
        case 'Downtime':
        case 'InspectionDowntime':
        case 'State':
        case 'EnvironmentAgingRate':
        case 'FinancialConsequencesFactor':
        case 'EnvironmentalConsequencesFactor':
        case 'NetworkConsequencesFactor':
        case 'SAIDI15min':
        case 'Quantity':
          return !criterion.Value || criterion.Value === -1 || criterion.value === '';
        case 'Area':
        case 'RateLevel':
        case 'AreaLevel2':
          return !criterion.ValueAsString || criterion.ValueAsString === '' || !criterion.ValueAsString?.length > 0;
        default:
          return true;
      }
    });
  }

  function isFormValid() {
    if (withIteration) {
      return (
        technicalPolicyForm.$dtId &&
        technicalPolicyForm.Type &&
        technicalPolicyForm.YearStart &&
        technicalPolicyForm.YearStart >= technicalPolicyFormMinStartDate &&
        technicalPolicyForm.AssetClass &&
        technicalPolicyForm.YearEnd &&
        technicalPolicyForm.YearEnd <= technicalPolicyFormMaxEndDate &&
        technicalPolicyForm.ActionToTrigger &&
        technicalPolicyForm.Criterions?.length > 0 &&
        !isCriterionEquipmentComponentAttributeEmpty(technicalPolicyForm.Criterions) &&
        technicalPolicyForm.NbRepAction1 &&
        technicalPolicyForm.RepeatedAction &&
        isNameAvailable &&
        isNameAvailableStatus &&
        areCriterionsNameValid
      );
    }
    return (
      technicalPolicyForm.$dtId &&
      technicalPolicyForm.Type &&
      technicalPolicyForm.YearStart &&
      technicalPolicyForm.YearStart >= technicalPolicyFormMinStartDate &&
      technicalPolicyForm.YearEnd &&
      technicalPolicyForm.YearEnd <= 2200 &&
      technicalPolicyForm.AssetClass &&
      technicalPolicyForm.ActionToTrigger &&
      technicalPolicyForm.Criterions?.length > 0 &&
      !isCriterionEquipmentComponentAttributeEmpty(technicalPolicyForm.Criterions) &&
      isNameAvailable &&
      isNameAvailableStatus &&
      areCriterionsNameValid
    );
  }
  function addIfMissing(condition, message, missingFields) {
    if (condition) {
      missingFields.push(t(message));
    }
  }

  function getMissingFields() {
    let missingFields = [];

    addIfMissing(
      !technicalPolicyForm.$dtId,
      'genericcomponent.dialog.missingFields.technicalPolicy.$dtId',
      missingFields
    );
    addIfMissing(
      !technicalPolicyForm.Type,
      'genericcomponent.dialog.missingFields.technicalPolicy.type',
      missingFields
    );
    addIfMissing(
      !technicalPolicyForm.YearStart,
      'genericcomponent.dialog.missingFields.technicalPolicy.yearStart',
      missingFields
    );
    addIfMissing(
      !technicalPolicyForm.AssetClass,
      'genericcomponent.dialog.missingFields.technicalPolicy.assetClass',
      missingFields
    );
    addIfMissing(
      !technicalPolicyForm.YearEnd,
      'genericcomponent.dialog.missingFields.technicalPolicy.yearEnd',
      missingFields
    );
    addIfMissing(
      !technicalPolicyForm.ActionToTrigger,
      'genericcomponent.dialog.missingFields.technicalPolicy.actionToTrigger',
      missingFields
    );

    addIfMissing(
      technicalPolicyForm.Criterions?.length <= 0 ||
        isCriterionEquipmentComponentAttributeEmpty(technicalPolicyForm.Criterions),
      'genericcomponent.dialog.missingFields.technicalPolicy.criterions',
      missingFields
    );

    addIfMissing(getAlreadyExistsErrorMessage(), 'views.library.technicalpolicy.alreadyExistLower', missingFields);

    if (withIteration) {
      addIfMissing(
        !technicalPolicyForm.NbRepAction1,
        'genericcomponent.dialog.missingFields.technicalPolicy.nbRepAction1',
        missingFields
      );
      addIfMissing(
        !technicalPolicyForm.RepeatedAction,
        'genericcomponent.dialog.missingFields.technicalPolicy.repeatedAction',
        missingFields
      );
    }

    if (missingFields.length > 1) {
      const regexp = new RegExp(/, (?![\s\S]*, )/);
      missingFields =
        t('genericcomponent.dialog.missingFields.introduction') +
        missingFields.join(', ').replace(regexp, t('genericcomponent.dialog.missingFields.and'));
    } else if (missingFields.length === 1) {
      missingFields = t('genericcomponent.dialog.missingFields.introduction') + missingFields[0];
    } else if (!areCriterionsNameValid) {
      missingFields = t('genericcomponent.dialog.missingFields.technicalPolicy.criterionNameTaken');
    } else {
      missingFields = '';
    }

    return missingFields;
  }

  function validateForm() {
    if (isFormValid()) {
      applyUpdate({
        ...technicalPolicyForm,
        Criterions: technicalPolicyForm.Criterions.map((criterion) => {
          return typeof criterion.ValueAsString === 'string'
            ? { ...criterion, ValueAsString: { 0: criterion.ValueAsString } }
            : criterion;
        })
      });
    } else {
      setShowMissingfields(true);
    }
  }

  function getTechnicalPolicyCriterionTableSettings() {
    // overwrite targetedAgingFamilyList && ValueAsStringSettings by current tech pol asset class
    const {
      dtIdSettings,
      equipmentComponentAttributeSettings,
      operatorSettings,
      proportionSettings,
      referenceAttributeSettings,
      targetedAgingFamiliesSettings,
      thresholdSettings,
      valueAsStringSettings,
      valueSettings
    } = technicalPolicyEnums.conditionValues ?? {};

    const CriterionName = dtIdSettings;
    const EquipmentComponentAttributeSettings = equipmentComponentAttributeSettings;
    const ProportionSettings = proportionSettings;
    const ThresholdSettings = thresholdSettings;
    const OperatorSettings = operatorSettings;
    const ReferenceAttributeSettings = referenceAttributeSettings;
    const TargetedAgingFamiliesSettings = {
      ...targetedAgingFamiliesSettings,
      enumList: targetedAgingFamiliesSettings?.enumLists?.[technicalPolicyForm.AssetClass]
    };
    const ValueSettings = valueSettings;
    const ValueAsStringSettings = {
      ...valueAsStringSettings,
      enumLists: Object.fromEntries(
        Object.entries(valueAsStringSettings?.enumLists ?? {}).map(([key, value]) => [
          key.endsWith(`___${technicalPolicyForm.AssetClass}`)
            ? key.slice(0, key.indexOf(`___${technicalPolicyForm.AssetClass}`))
            : key,
          (value ?? []).filter((v) => v !== null)
        ])
      )
    };

    const criterionsTableSettings = {
      cols: [
        'Name',
        'TargetedAgingFamilies',
        'Proportion',
        'Threshold',
        'EquipmentComponentAttribute',
        'Operator',
        'ReferenceAttribute',
        'Value',
        'ValueAsString'
      ],
      actions: ['delete'],
      isAlwaysEdit: true,
      colProps: [
        CriterionName,
        TargetedAgingFamiliesSettings,
        ProportionSettings,
        ThresholdSettings,
        EquipmentComponentAttributeSettings,
        OperatorSettings,
        ReferenceAttributeSettings,
        ValueSettings,
        ValueAsStringSettings
      ]
    };

    return criterionsTableSettings;
  }

  function checkJsonParse(jsonString) {
    try {
      JSON.parse(jsonString);
    } catch (e) {
      return false;
    }
    return true;
  }

  function getCriteriaFormulaAsObject() {
    const defaultCriteriaFormula = { AND: [] };
    if (technicalPolicyForm.CriteriaFormula && checkJsonParse(technicalPolicyForm.CriteriaFormula)) {
      const criteriaFormulaObject = JSON.parse(technicalPolicyForm.CriteriaFormula);
      if (
        typeof criteriaFormulaObject === 'object' &&
        Object.keys(criteriaFormulaObject).length > 0 &&
        (Object.keys(criteriaFormulaObject)[0] === 'AND' || Object.keys(criteriaFormulaObject)[0] === 'OR')
      ) {
        return criteriaFormulaObject;
      }
      return defaultCriteriaFormula;
    }
    return defaultCriteriaFormula;
  }

  function updateCriterions(criterions) {
    // Force criterion $dtId update to reflect criterion Name change
    setTechnicalPolicyForm({
      ...technicalPolicyForm,
      Criterions: [
        ...criterions.map((criterion) => ({
          ...criterion,
          $dtId: `${technicalPolicyForm.$dtId}-${criterion.Name}`,
          Value: criterion.EquipmentComponentAttribute === 'Failure' ? null : criterion.Value
        }))
      ]
    });
  }
  function updateCriteriaFormula(criteriaFormula) {
    // Define recursive function to add $dtId to each criteria name in criteriaFormula
    const updateCriteriaNames = (criteria) => {
      const group = Object.keys(criteria)[0];

      return {
        [group]: criteria[group].map((condition) => {
          if (typeof condition === 'string') {
            const correspondingCriterion = technicalPolicyForm.Criterions.find(
              (criterion) => criterion.Name === condition
            );
            return correspondingCriterion ? correspondingCriterion.$dtId : condition;
          } else {
            return updateCriteriaNames(condition);
          }
        })
      };
    };

    const updatedCriteriaFormula = JSON.stringify(updateCriteriaNames(criteriaFormula));

    setTechnicalPolicyForm({
      ...technicalPolicyForm,
      CriteriaFormula: updatedCriteriaFormula
    });
  }

  // Function to add a criteria to criterions
  function addCriteria() {
    const newCriteria = {
      Name: generationNewCriteriaId()
    };
    updateCriterions([...technicalPolicyForm.Criterions, newCriteria]);
  }

  function generationNewCriteriaId(iterationId) {
    let newCriteriaId = t('views.library.technicalpolicy.newCondition') + `${triggerNameIterator.current++}`;
    if (iterationId) {
      newCriteriaId = `${newCriteriaId} - ${iterationId}`;
    }
    const idUnavailable = technicalPolicyForm.Criterions.findIndex((criteria) => criteria.$dtId === newCriteriaId);
    if (idUnavailable >= 0) {
      const nextIteration = iterationId ? iterationId + 1 : 1;
      return generationNewCriteriaId(nextIteration);
    }
    return newCriteriaId;
  }

  function getAlreadyExistsErrorMessage() {
    return isNameAvailable ? null : t('views.library.technicalpolicy.alreadyExist');
  }

  return (
    <Card className={classes.cardForm}>
      {selectedTechnicalPolicy && (
        <CardContent>
          <h2 className='sub-title' data-testid='tp-form-title'>
            {t('views.library.technicalpolicy.generalParameters')}
          </h2>

          <Grid className={classes.formContainer} container={true} key='technical-policy-form-row-0'>
            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-0-col-0' xs={2}>
              <span className={classes.inputLabel}>{t('views.library.technicalpolicy.name')}</span>
            </Grid>

            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-0-col-1' xs={2}>
              <InputComponentFactory
                data={technicalPolicyForm}
                editProps={{ type: 'string', errorMessage: getAlreadyExistsErrorMessage() }}
                index='technical-policy-form-row-0-input-0'
                testId='tp-form-input-name'
                value={technicalPolicyForm.$dtId || ''}
                onChange={(event) => updateTechnicalPolicyForm('$dtId', event)}
              />
            </Grid>

            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-0-col-2' xs={2}>
              <span className={classes.inputLabel}>{t('views.library.technicalpolicy.type')}</span>
            </Grid>

            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-0-col-3' xs={2}>
              <InputComponentFactory
                data={technicalPolicyForm}
                editProps={{
                  type: 'select',
                  enumList: getTechnicalPolicyTypes()
                }}
                index='technical-policy-form-row-0-input-1'
                testId='tp-form-menu-type'
                value={technicalPolicyForm.Type}
                onChange={(newValue) => updateTechnicalPolicyForm('Type', newValue)}
              />
            </Grid>

            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-0-col-4' xs={2}>
              <span className={classes.inputLabel}>{t('views.library.technicalpolicy.startYear')}</span>
            </Grid>

            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-0-col-5' xs={2}>
              <InputComponentFactory
                editProps={{
                  type: 'dateYear',
                  placeholder: t('views.library.technicalpolicy.startYear'),
                  minDate: technicalPolicyFormMinStartDate,
                  maxDate: technicalPolicyFormMaxEndDate
                }}
                index='technical-policy-form-row-0-input-2'
                testId='tp-form-menu-start-year'
                value={technicalPolicyForm.YearStart}
                onChange={(newValue) => updateTechnicalPolicyForm('YearStart', newValue)}
              />
            </Grid>
          </Grid>

          <Grid className={classes.formContainer} container={true} key='technical-policy-form-row-1'>
            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-1-col-0' xs={2}>
              <span className={classes.inputLabel}>{t('views.library.technicalpolicy.assetClass')}</span>
            </Grid>
            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-1-col-1' xs={2}>
              <InputComponentFactory
                data={technicalPolicyForm}
                editProps={{
                  type: technicalPolicyForm.Id ? 'info' : 'select',
                  enumList: getTechnicalPolicyAssetClasses()
                }}
                index='technical-policy-form-row-1-input-0'
                testId='tp-form-menu-asset-class'
                value={technicalPolicyForm.AssetClass}
                onChange={(newValue) => updateTechnicalPolicyForm('AssetClass', newValue)}
              />
            </Grid>

            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-1-col-2' xs={2}>
              <span className={classes.inputLabel}>{t('views.library.technicalpolicy.budgetCategories')}</span>
            </Grid>

            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-1-col-3' xs={2}>
              <InputComponentFactory
                data={technicalPolicyForm}
                editProps={{
                  type: 'multi-select',
                  enumList: getTechnicalPolicyBudgetCategories(),
                  isValueFromDictionary: true
                }}
                index='technical-policy-form-row-1-input-1'
                testId='tp-form-menu-budget-categories'
                value={technicalPolicyForm.BudgetCategories}
                onChange={(newValue) => updateTechnicalPolicyForm('BudgetCategories', newValue)}
              />
            </Grid>

            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-1-col-4' xs={2}>
              <span className={classes.inputLabel}>{t('views.library.technicalpolicy.endYear')}</span>
            </Grid>

            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-1-col-5' xs={2}>
              <InputComponentFactory
                data={technicalPolicyForm}
                editProps={{
                  type: 'dateYear',
                  placeholder: t('views.library.technicalpolicy.endYear'),
                  minDate: technicalPolicyForm.YearStart,
                  maxDate: technicalPolicyFormMaxEndDate
                }}
                index='technical-policy-form-row-1-input-2'
                testId='tp-form-menu-end-year'
                value={technicalPolicyForm.YearEnd}
                onChange={(newValue) => updateTechnicalPolicyForm('YearEnd', newValue)}
              />
            </Grid>
          </Grid>

          <h2 className='sub-title'>{t('views.library.technicalpolicy.operation')}</h2>

          <Grid className={classes.formContainer} container={true} key='technical-policy-form-row-2'>
            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-2-col-0' xs={3}>
              <FormControlLabel
                color='secondary'
                control={<Switch checked={withIteration} color='primary' onChange={switchWithIteration} />}
                label={t('views.library.technicalpolicy.conditionedOperation')}
              />
            </Grid>
          </Grid>

          <Grid className={classes.formContainer} container={true} key='technical-policy-form-row-3'>
            <Grid className={classes.gridForm} item={true} key='technical-policy-form-row-3-col-0' xs={3}>
              <OperationSelector
                disabled={!technicalPolicyForm.AssetClass || operationsByAssetClass.length === 0}
                id='technical-policy-form-row-3-input-0'
                options={getTechnicalPolicyActionsAssetClasses()}
                testId='tp-form-menu-action-trigger'
                value={technicalPolicyForm.ActionToTrigger}
                onChange={(newValue) => {
                  updateTechnicalPolicyForm('ActionToTrigger', newValue);
                }}
                onInputChange={(newInputValue) => {
                  setOperationByAssetClassSearch(newInputValue);
                }}
              />
            </Grid>
            {withIteration
              ? [
                  <Grid className={classes.gridForm} item={true} key='technical-policy-form-grid-nbRepAction1' xs={2}>
                    <InputComponentFactory
                      data={technicalPolicyForm}
                      editProps={{
                        type: 'number',
                        disabled: !technicalPolicyForm.ActionToTrigger
                      }}
                      index='technical-policy-form-row-3-input-1'
                      label={t('genericcomponent.dialog.missingFields.technicalPolicy.nbRepAction')}
                      value={technicalPolicyForm.NbRepAction1}
                      onChange={(newValue) => updateTechnicalPolicyForm('NbRepAction1', newValue)}
                    />
                  </Grid>,
                  <Grid
                    className={classes.gridForm}
                    item={true}
                    key='technical-policy-form-grid-withIteration'
                    style={{ justifyContent: 'center' }}
                    xs={1}
                  >
                    <span className={classes.inputLabel}>{t('views.library.technicalpolicy.then')}</span>
                  </Grid>,
                  <Grid className={classes.gridForm} item={true} key='technical-policy-form-grid-repeatedAction' xs={3}>
                    <OperationSelector
                      disabled={!technicalPolicyForm.ActionToTrigger}
                      id='technical-policy-form-row-3-input-2'
                      options={getTechnicalPolicyActionsAssetClasses()}
                      value={technicalPolicyForm.RepeatedAction}
                      onChange={(newValue) => {
                        updateTechnicalPolicyForm('RepeatedAction', newValue);
                      }}
                      onInputChange={(newInputValue) => {
                        setOperationByAssetClassSearch(newInputValue);
                      }}
                    />
                  </Grid>
                ]
              : null}
          </Grid>

          <Grid container={true} justifyContent='space-between'>
            <Grid item={true} xs={10}>
              <h2 className='sub-title'>{t('views.library.technicalpolicy.condition')}</h2>
            </Grid>
            <Grid item={true} style={{ textAlign: 'right' }} xs={2}>
              <Button className={classes.addBtn} color='primary' type='button' variant='outlined' onClick={addCriteria}>
                {t('views.library.technicalpolicy.newCondition')} <ControlPointIcon />
              </Button>
            </Grid>
          </Grid>

          <br />

          <TechnicalPolicyCriterion
            criterionTableSettings={getTechnicalPolicyCriterionTableSettings()}
            criterions={technicalPolicyForm.Criterions}
            setAreCriterionsNameValid={setAreCriterionsNameValid}
            updateCriterions={updateCriterions}
          />

          <TechnicalPolicyCriterionTree
            criteriaFormula={getCriteriaFormulaAsObject()}
            criterions={technicalPolicyForm.Criterions}
            updateCriteriaFormula={updateCriteriaFormula}
          />

          <div className={classes.btnEndForm}>
            <Button color='primary' variant='outlined' onClick={cancelUpdate}>
              {t('views.library.technicalpolicy.cancel')} <AddCircleOutlineIcon className={classes.cancel} />
            </Button>
            <Button
              color={isFormValid() ? 'primary' : 'secondary'}
              type='button'
              variant='contained'
              onClick={validateForm}
            >
              {t('views.library.technicalpolicy.save')} <SaveIcon />
            </Button>
          </div>
        </CardContent>
      )}

      <SimpleTwoActionsDialogContent
        handleClickOnButton1={() => setShowMissingfields(false)}
        handleClickOnButton2={() => setShowMissingfields(false)}
        id='missing-fields'
        key='missing-fields'
        labels={{
          title: t('genericcomponent.dialog.missingFields.title'),
          body: getMissingFields(),
          button1: t('genericcomponent.dialog.missingFields.cancel'),
          button2: t('genericcomponent.dialog.missingFields.ok'),
          ariaLabelledby: 'missing-field-dialog',
          button2BGColor: '#062F4F'
        }}
        open={showMissingFields}
      />
    </Card>
  );
}

TechnicalPolicyForm.propTypes = {
  addBreadCrumbsItems: PropTypes.func.isRequired,
  applyUpdate: PropTypes.func.isRequired,
  cancelUpdate: PropTypes.func.isRequired,
  shouldReset: PropTypes.bool.isRequired
};

export default TechnicalPolicyForm;

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    width: '100%',
    height: '100%'
  },
  libraryChoice: {
    margin: 'auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  libraryCard: {
    width: '30vw',
    height: 200,
    display: 'flex',
    margin: '0 24px',
    cursor: 'pointer'
  },
  cardLibraryContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.gradient,
    width: 44,
    height: 44,
    borderRadius: 44,
    marginRight: 24,
    '& svg': {
      color: theme.palette.nexans.white
    }
  },
  tableContainer: {
    width: '90%',
    margin: '20px auto 0'
  },
  topBar: {
    display: 'flex',
    width: '90%',
    margin: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 0 0'
  },
  flexSearch: {
    display: 'flex'
  },
  searchInput: {
    backgroundColor: theme.palette.nexans.white,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    marginRight: 24
  },
  searchInputField: {
    padding: 8,
    flex: 1,
    color: 'black'
  },
  searchIcon: {
    background: theme.palette.background.gradient,
    color: theme.palette.nexans.white,
    borderRadius: '0 10px 10px 0'
  },
  cardForm: {
    width: '90%',
    margin: '24px auto',
    borderRadius: '10px'
  },
  formContainer: {
    margin: '24px 0'
  },
  inputLabel: {
    color: 'black'
  },
  gridForm: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px'
  },
  radio: {
    margin: 'auto'
  },
  btnEndForm: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 25,
    '& button': {
      borderRadius: 100,
      margin: '0 8px'
    }
  },
  cancel: {
    marginLeft: 8,
    transform: 'rotate(45deg)'
  },
  operationContainer: {
    width: '80%',
    display: 'flex',
    margin: '24px auto',
    justifyContent: 'space-between'
  },
  assetFamilyList: {
    width: '35%',
    '& button': {
      minWidth: '100%',
      padding: 12,
      color: theme.palette.text.black,
      '& span': {
        fontSize: 16,
        textTransform: 'initial',
        alignItems: 'flex-start'
      },
      '&.Mui-selected': {
        background: theme.palette.background.gradient,
        color: theme.palette.text.white
      }
    }
  },
  operationsList: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    '&[hidden]': {
      display: 'none'
    }
  },
  scrollableOperationList: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 280px)',
    overflowY: 'scroll',
    marginTop: 12,
    padding: '0 8px'
  },
  operationItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '12px 0',
    backgroundColor: theme.palette.nexans.white,
    padding: '24px 12px',
    border: '1px solid #DFDFDF',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    fontSize: 14
  },
  tableOperationFormContainer: {
    margin: '24px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  addBtn: {
    borderRadius: 50,
    border: '1.5px solid #062F4F',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    '& svg': {
      marginLeft: 8
    }
  }
}));
