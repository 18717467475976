import { createReducer } from '@reduxjs/toolkit';
import { DRAWER_ACTIONS_KEY } from '../../commons/DrawerConstants';

export const drawerInitialState = {
  position: 'right',
  isVisible: false
};

export const drawerReducer = createReducer(drawerInitialState, (builder) => {
  builder.addCase(DRAWER_ACTIONS_KEY.SHOW_DRAWER, (state, action) => {
    state.isVisible = action.isVisible;
  });
});
