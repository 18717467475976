import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberUtils } from 'utils/helpers';
import {
  BooleanInputComponent,
  DateInputComponent,
  NumberInputComponent,
  SelectInputComponent,
  StringInputComponent
} from './InputComponents';

function InputComponentFactory(props) {
  const { editProps, data, value, field, index, onChange, testId } = props;

  const { t } = useTranslation();
  const [error] = useState(null);
  let switcher = editProps?.type ? editProps.type : null;
  let enumlist = editProps?.enumList ? editProps.enumList : null;
  let infoText = editProps?.infoText ? editProps.infoText : null;
  let exception = editProps?.exception ? editProps.exception : null;

  const valueFromString = editProps?.isValueFromString ? editProps.isValueFromString : false;
  const valueFromDictionary = editProps?.isValueFromDictionary ? editProps.isValueFromDictionary : false;
  const disabled = editProps?.disabled ? editProps.disabled : false;
  const placeholder = editProps?.placeholder ? editProps.placeholder : null;
  const label = editProps?.label ? editProps.label : null;
  const max = editProps?.max ? editProps.max : null;
  const min = editProps?.min ?? null;

  const intValue = editProps?.intValue ? editProps.intValue : null;
  const prefix = editProps?.prefix ? editProps.prefix : null;
  const isBoolean = editProps?.isBoolean ? editProps.isBoolean : false;
  const errorMessage = editProps?.errorMessage ? editProps.errorMessage : null;
  const shouldHide =
    (data?.EquipmentComponentAttribute === 'Failure' && field === 'Value') ||
    (data?.EquipmentComponentAttribute === 'Area' && field === 'ReferenceAttribute') ||
    (data?.EquipmentComponentAttribute === 'Area' && field === 'Operator') ||
    (data?.EquipmentComponentAttribute === 'AreaLevel2' && field === 'ReferenceAttribute') ||
    (data?.EquipmentComponentAttribute === 'AreaLevel2' && field === 'Operator') ||
    (data?.EquipmentComponentAttribute === 'Quantity' && field === 'ReferenceAttribute') ||
    (data?.EquipmentComponentAttribute === 'EnvironmentAgingRate' && field === 'ReferenceAttribute') ||
    (data?.EquipmentComponentAttribute === 'EnvironmentAgingRate' && field === 'Operator') ||
    (data?.EquipmentComponentAttribute === 'EnvironmentalConsequencesFactor' && field === 'ReferenceAttribute') ||
    (data?.EquipmentComponentAttribute === 'FinancialConsequencesFactor' && field === 'ReferenceAttribute') ||
    (data?.EquipmentComponentAttribute === 'NetworkConsequencesFactor' && field === 'ReferenceAttribute') ||
    (data?.EquipmentComponentAttribute === 'RateLevel' && field === 'ReferenceAttribute') ||
    (data?.EquipmentComponentAttribute === 'RateLevel' && field === 'Operator') ||
    (data?.EquipmentComponentAttribute === 'SAIDI15min' && field === 'ReferenceAttribute');

  function updateNumber(value) {
    const newValue = NumberUtils.getNormalAnnotation(value);
    onChange(newValue);
  }

  function updateUnsignedNumber(value) {
    let newValue = NumberUtils.getNormalAnnotation(value);
    if (newValue < 0) {
      newValue = 0;
    }
    onChange(newValue);
  }

  function updatePercentageNumber(value) {
    let newValue = NumberUtils.getNormalAnnotation(value);
    if (newValue < 0) {
      newValue = 1;
    } else if (newValue > 100) {
      newValue = 100;
    }
    onChange(newValue);
  }

  function updateNullablePercentageNumber(value) {
    let newValue = NumberUtils.getNormalAnnotation(value);
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 100) {
      newValue = 100;
    }
    onChange(newValue);
  }

  const errorMessageToDisplay = useMemo(() => {
    switch (error) {
      case 'maxDate':
        return editProps.maxDateMessage
          ? editProps.maxDateMessage
          : t('genericcomponent.dateInput.error.maxDateMessage');
      case 'minDate': {
        return editProps.minDateMessage
          ? editProps.minDateMessage
          : t('genericcomponent.dateInput.error.minDateMessage');
      }

      case 'invalidDate': {
        return t('genericcomponent.dateInput.error.invalidDateMessage');
      }

      default: {
        return errorMessage;
      }
    }
  }, [error]);

  // Procedural code to determine the type of input returned in case of needValueOf specification.
  // (Note: For a specified column, the type of input can change according to another column value)
  if (editProps?.needValueOf && typeof editProps.needValueOf === 'object') {
    // Determine the input type accordings specified definition
    Object.keys(editProps.needValueOf).forEach((condition) => {
      let conditionValid = true;
      const conditionDefinition = editProps.needValueOf[condition];
      Object.keys(conditionDefinition).forEach((column) => {
        if (data[column] && conditionDefinition[column].findIndex((validation) => validation === data[column]) === -1) {
          conditionValid = false;
        }
      });
      if (conditionValid) {
        switcher = editProps.types[condition];
        switch (switcher) {
          case 'select': {
            enumlist = editProps.enumLists[condition];
            if (editProps.exceptions?.[condition] && data[editProps.exceptions[condition]]) {
              exception = data[editProps.exceptions[condition]];
            }
            break;
          }
          case 'none': {
            if (editProps.infoTexts?.[condition]) {
              infoText = editProps.infoTexts[condition];
            }
            break;
          }
          default:
            break;
        }
      }
    });
  }

  // Procedural code to determine the type of input returned in case of default specification
  if ((!editProps?.needValueOf || (editProps.needValueOf && typeof editProps.needValueOf === 'object')) && switcher) {
    // Generate input component key
    const inputComponentKey = `${editProps.type}-${index}`;
    let inputLabel = null;
    if (label) {
      inputLabel = label;
    }

    switch (switcher) {
      case 'boolean': {
        return (
          <BooleanInputComponent
            disabled={disabled}
            hidden={shouldHide}
            inputKey={inputComponentKey}
            isBoolean={isBoolean}
            label={inputLabel}
            setValue={onChange}
            testId={testId}
            value={shouldHide ? 1 : value}
          />
        );
      }
      case 'number': {
        return (
          <NumberInputComponent
            disabled={disabled}
            inputKey={inputComponentKey}
            intValue={intValue}
            label={placeholder}
            max={max}
            min={min}
            placeholder={placeholder}
            prefix={prefix}
            setValue={updateNumber}
            testId={testId}
            value={value}
          />
        );
      }
      case 'dateSimple': {
        return (
          <TextField
            InputLabelProps={{
              shrink: true
            }}
            id='date'
            label={inputLabel}
            placeholder={editProps.placeholder}
            type='date'
            value={value}
            variant='standard'
            onChange={(event) => onChange(event.target.value)}
          />
        );
      }

      case 'dateYear': {
        return (
          <DateInputComponent
            changeSelectedDate={onChange}
            dateProps={{
              minDate: editProps.minDate,
              maxDate: editProps.maxDate,
              disabled: disabled,
              minDateMessage: t('genericcomponent.dateInput.error.minDateMessage'),
              maxDateMessage: t('genericcomponent.dateInput.error.maxDateMessage'),
              invalidDateMessage: t('genericcomponent.dateInput.error.invalidDateMessage'),
              testId: editProps.testId ?? testId
            }}
            error={error}
            format='yyyy'
            id={inputComponentKey}
            label={placeholder}
            value={value}
          />
        );
      }
      case 'year': {
        return (
          <NumberInputComponent
            disabled={disabled}
            inputKey={inputComponentKey}
            label={inputLabel}
            max={editProps.maxDate || 2300}
            placeholder={placeholder}
            prefix='year'
            setValue={updateUnsignedNumber}
            value={value}
          />
        );
      }
      case 'percentage': {
        return (
          <NumberInputComponent
            disabled={disabled}
            inputKey={inputComponentKey}
            intValue
            label={inputLabel}
            max={100}
            min={1}
            placeholder={placeholder}
            prefix='%'
            setValue={updatePercentageNumber}
            value={value}
          />
        );
      }
      case 'nullablePercentage': {
        return (
          <NumberInputComponent
            disabled={disabled}
            inputKey={inputComponentKey}
            intValue
            label={inputLabel}
            max={100}
            min={0}
            placeholder={placeholder}
            prefix='%'
            setValue={updateNullablePercentageNumber}
            value={value}
          />
        );
      }
      case 'unumber': {
        return (
          <NumberInputComponent
            disabled={disabled}
            inputKey={inputComponentKey}
            intValue={intValue}
            label={inputLabel}
            max={max}
            min={0}
            placeholder={placeholder}
            prefix={prefix}
            setValue={updateUnsignedNumber}
            value={value}
          />
        );
      }
      case 'multi-number': {
        return (
          <NumberInputComponent
            disabled={disabled}
            inputKey={inputComponentKey}
            isMultiple={true}
            isValueFromDictionary={valueFromDictionary}
            label={inputLabel}
            placeholder={placeholder}
            setValue={onChange}
            value={value}
          />
        );
      }
      case 'string': {
        return (
          <StringInputComponent
            disabled={disabled}
            errorMessage={errorMessage}
            inputKey={inputComponentKey}
            isMultiple={false}
            isValueFromDictionary={valueFromDictionary}
            isValueFromString={valueFromString}
            label={inputLabel}
            setValue={onChange}
            testId={testId}
            value={value}
          />
        );
      }
      case 'multi-string': {
        return (
          <StringInputComponent
            disabled={disabled}
            inputKey={inputComponentKey}
            isMultiple={true}
            isValueFromDictionary={valueFromDictionary}
            isValueFromString={valueFromString}
            label={inputLabel}
            setValue={onChange}
            testId={testId}
            value={value}
          />
        );
      }
      case 'select': {
        return (
          <SelectInputComponent
            disabled={shouldHide || disabled}
            exception={exception}
            hidden={shouldHide}
            inputKey={inputComponentKey}
            isValueFromDictionary={valueFromDictionary}
            isValueFromString={valueFromString}
            label={inputLabel}
            setValue={onChange}
            testId={testId}
            value={value}
            valueList={enumlist}
          />
        );
      }
      case 'multi-select': {
        return (
          <SelectInputComponent
            disabled={disabled}
            inputKey={inputComponentKey}
            isMultiple={true}
            isValueFromDictionary={valueFromDictionary}
            isValueFromString={valueFromString}
            label={inputLabel}
            setValue={onChange}
            testId={testId}
            value={value}
            valueList={enumlist}
          />
        );
      }
      case 'info': {
        return <span style={{ color: '#000' }}>{value}</span>;
      }
      default: {
        return <span style={{ color: '#000' }}>{infoText || ''}</span>;
      }
    }
  } else {
    return <></>;
  }
}

InputComponentFactory.propTypes = {
  editProps: PropTypes.shape({
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    enumList: PropTypes.array,
    errorMessage: PropTypes.string,
    exception: PropTypes.any,
    infoText: PropTypes.string,
    intValue: PropTypes.bool,
    isBoolean: PropTypes.bool,
    isValueFromDictionary: PropTypes.bool,
    isValueFromString: PropTypes.bool,
    label: PropTypes.string,
    max: PropTypes.number,
    maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    min: PropTypes.number,
    minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    needValueOf: PropTypes.any,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    type: PropTypes.string,
    valueFromDictionary: PropTypes.bool,
    valueFromString: PropTypes.bool,
    testId: PropTypes.string,
    minDateMessage: PropTypes.string,
    maxDateMessage: PropTypes.string
  }),
  data: PropTypes.object,
  field: PropTypes.string,
  index: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  testId: PropTypes.string,
  value: PropTypes.any
};

InputComponentFactory.defaultProps = {
  editProps: {
    disabled: undefined,
    testId: undefined
  }
};

export default InputComponentFactory;
