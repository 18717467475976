import { DIALOG_ACTIONS_KEY } from 'state/commons/DialogConstants';

export const dispatchShowDialog = (isVisible: boolean, params?: unknown, dialogType?: string) => ({
  type: DIALOG_ACTIONS_KEY.OPEN_DIALOG,
  payload: {
    isVisible,
    params,
    dialogType
  }
});
