import { all, fork } from 'redux-saga/effects';
import { downloadCnaimFileData } from './DownloadCnaimFile';
import { getAbacusTableData } from './GetAbacusTable';
import { getCnaimFilesMetadataData } from './GetCnaimFilesMetadata';
import { uploadCnaimFileData } from './UploadCnaimFile';

export default function* cnaimSaga() {
  yield all([
    fork(getCnaimFilesMetadataData),
    fork(uploadCnaimFileData),
    fork(downloadCnaimFileData),
    fork(getAbacusTableData)
  ]);
}
