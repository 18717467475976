import { ApiFinance } from 'api/ApiFinance';
import { ApiScenario } from 'api/ApiScenario';
import { WORKSPACE_ID } from 'config/AppInstance';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { FINANCE_ACTIONS_KEY } from '../../../commons/FinanceConstants';
import { selectOrganizationId } from '../../sagaUtils';

// Utility function to remove duplicates when merging arrays
function mergeArraysUnique(arr1, arr2) {
  return Array.from(new Set([...arr1, ...arr2]));
}

// Utility function used when using mui input with thousand separator
function convertToNumber(value) {
  if (typeof value === 'string') {
    return Number(value.replace(/ /g, ''));
  }
  return value;
}

// Generator function
export function* updateFinanceTab() {
  const waccScenarioList = yield select((state) => state.finance.waccScenario.list);
  const operatingForecastScenarioList = yield select((state) => state.finance.operatingForecastScenario.list);
  const waccList = yield select((state) => state.finance.wacc.list);
  const operatingForecastList = yield select((state) => state.finance.operatingForecast.list);
  const { id } = yield select((state) => state.scenario.current.data);

  yield delay(10000);
  const organizationId = yield selectOrganizationId();
  const response = yield call(ApiScenario.findScenarioById, organizationId, WORKSPACE_ID, id);

  // if no simulationRun is found, we use parent scenario's simulationRun
  const simulationRun = response.data.lastRun?.csmSimulationRun
    ? response.data.lastRun?.csmSimulationRun
    : response.data.lastRun?.parentLastRun?.csmSimulationRun;

  const concatenedWaccList = mergeArraysUnique(waccList, waccScenarioList);

  const waccPayload = concatenedWaccList.map((el) => {
    return {
      ...el,
      scenarioId: id,
      simulationRun
    };
  });

  const concatenedList = mergeArraysUnique(operatingForecastList, operatingForecastScenarioList);

  const operatingForecastPayload = concatenedList.map((item) => ({
    ...item,
    extraCharges: convertToNumber(item.extraCharges),
    suppliedEnergy: convertToNumber(item.suppliedEnergy),
    scenarioId: id,
    simulationRun
  }));

  try {
    if (waccPayload) {
      yield call(ApiFinance.updateWaccs, organizationId, waccPayload);
      yield put({
        type: FINANCE_ACTIONS_KEY.SET_ALL_WACC,
        waccList: waccPayload
      });
      yield put({
        type: FINANCE_ACTIONS_KEY.SET_CURRENT_SCENARIO_WACC,
        waccList: waccPayload
      });
    }
    if (operatingForecastPayload) {
      yield call(ApiFinance.updateOperatingForecasts, organizationId, operatingForecastPayload);

      yield put({
        type: FINANCE_ACTIONS_KEY.SET_ALL_OPERATING_FORECAST,
        operatingForecastList: operatingForecastPayload
      });
      yield put({
        type: FINANCE_ACTIONS_KEY.SET_CURRENT_SCENARIO_OPERATING_FORECAST,
        operatingForecastList: operatingForecastPayload
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* updateFinanceTabData() {
  yield takeEvery(FINANCE_ACTIONS_KEY.UPDATE_FINANCE_TAB, updateFinanceTab);
}

export default updateFinanceTabData;
