import { all, fork } from 'redux-saga/effects';
import { findAllNetworkLevelData } from './FindAllNetworkLevel';
import { findAllOperatingForecastData } from './FindAllOperatingForecast';
import { findAllWaccData } from './FindAllWacc';
import { updateFinanceTabData } from './UpdateFinanceTab';

export default function* financeSaga() {
  yield all([
    fork(findAllNetworkLevelData),
    fork(findAllOperatingForecastData),
    fork(findAllWaccData),
    fork(updateFinanceTabData)
  ]);
}
