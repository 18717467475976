import { TableBody, TableCell, TableRow, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import theme from 'theme';

export default function FinanceTableOperatingForecastBody({
  headersYears,
  handleChangeValueFromYear,
  list,
  selectedNetwokLevel
}) {
  const { t } = useTranslation();
  const minTableValueWidth = (year, row) => {
    const valueToDisplay = list.find((element) => element.year === year)?.[row];
    return valueToDisplay ? valueToDisplay.toString().length * 1 : 0.5;
  };
  const displayFormatedValue = (row, year) => {
    return list.find((element) => element.year === year && element.networkLevel === selectedNetwokLevel)?.[row];
  };

  return (
    <TableBody>
      {['extraCharges', 'suppliedEnergy'].map((row, rowIndex) => (
        <TableRow key={`table-row-finance-${row}`} sx={{ backgroundColor: theme.palette.background.default }}>
          <TableCell align='center' style={{ width: '20px' }}>
            {t(`commoncomponents.scenario-form.finance-tab.${row}`)}
          </TableCell>
          {headersYears.map((year, index) => (
            <TableCell
              align='center'
              style={{
                minWidth: `${minTableValueWidth(year, row)}rem`
              }}
              variant='string'
              key={`table-body-cell-finance-operating-forecast-${year}-${index}`}
              data-testid={`finance-operating-forecast-table-body-cell-year-${year}-${rowIndex}`}
            >
              <NumericFormat
                variant='standard'
                value={displayFormatedValue(row, year)}
                onChange={(event) => handleChangeValueFromYear(row, year, event)}
                customInput={TextField}
                thousandSeparator=' '
                decimalScale={2}
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}
FinanceTableOperatingForecastBody.propTypes = {
  handleChangeValueFromYear: PropTypes.func.isRequired,
  headersYears: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired
};
