import { Autocomplete, Button, Checkbox, Chip, Stack, TextField } from '@mui/material';
import { Dialog } from 'components';
import InputComponentFactory from 'components/InputComponentFactory';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DIALOG_TYPE } from 'state/commons/DialogConstants';
import { useDialogParams, useDialogStatus, useHideDialog } from 'state/hooks/Dialog.hooks';
import { useAgingFamilyList } from 'state/hooks/Family.hooks';
import { useCreateSubset, useEditSubset, useSubsetList } from 'state/hooks/Subset.hooks';
import { CustomPaper } from './CustomPaper.component';

export default function SubsetCreationOrEditionDialog() {
  const agingFamilyList = useAgingFamilyList();
  const subsetList = useSubsetList();

  const autoCompleteSearchText = useRef('');
  const autoCompleteFilteredOptions = useRef(agingFamilyList);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dialogType = DIALOG_TYPE.SUBSET_CREATION_OR_EDIT_DIALOG_TYPE;

  const dialogParams = useDialogParams();

  const { t } = useTranslation();

  const isEditMode = useMemo(() => dialogParams?.mode === 'edit', [dialogParams?.mode]);

  const initialPayloadState = useMemo(
    () =>
      isEditMode
        ? {
            id: dialogParams.subset.id,
            name: dialogParams.subset.name,
            agingFamilies: dialogParams.subset.agingFamilies
          }
        : {
            name: '',
            agingFamilies: []
          },
    [dialogParams?.subset?.agingFamilies, dialogParams?.subset?.name, dialogParams?.subset?.id, isEditMode]
  );

  const [payload, setPayload] = useState(initialPayloadState);

  const createSubset = useCreateSubset();
  const editSubset = useEditSubset();

  const hideDialog = useHideDialog();
  const isOpen = useDialogStatus(dialogType);

  const resetPayloadToInitState = useCallback(() => {
    setPayload(initialPayloadState);
  }, [initialPayloadState]);

  useEffect(() => {
    if (isOpen) {
      resetPayloadToInitState();
    }
  }, [isOpen, resetPayloadToInitState]);

  function handleAutocompleteChange(event, newValue) {
    setPayload({ ...payload, agingFamilies: newValue });
  }

  const handleSelectAll = useCallback(
    (isChecked) => {
      setPayload({ ...payload, agingFamilies: isChecked ? agingFamilyList : [] });
      setIsDropdownOpen(false);
    },
    [agingFamilyList, payload]
  );

  const handleOnSearchSelect = useCallback(() => {
    const filteredOptions = autoCompleteFilteredOptions.current;
    setPayload({ ...payload, agingFamilies: [...new Set(payload.agingFamilies.concat(filteredOptions))] });
    setIsDropdownOpen(false);
  }, [payload]);

  function submit() {
    hideDialog();
    isEditMode ? editSubset(payload) : createSubset(payload);
  }

  const formatString = (str) =>
    str
      .trim()
      .replace(/^_+|_+$/g, '')
      .toLowerCase();

  const isNameInvalid = payload.name?.trim() === '' || payload.name === null;

  const isNameAvailable = useMemo(
    () =>
      !subsetList.some((subset) => {
        const name = formatString(subset.name || '');
        const payloadSubsetName = formatString(payload.name || '');

        if (isEditMode && dialogParams?.subset?.name && formatString(dialogParams.subset.name) === name) {
          return false;
        }

        return name === payloadSubsetName;
      }),
    [dialogParams?.subset?.name, isEditMode, payload.name, subsetList]
  );

  const canCreateSubset = !isNameInvalid && isNameAvailable && payload.agingFamilies.length > 0;

  const displayNameInputErrorMessage = () => {
    if (isNameInvalid) {
      return t('layouts.tabs.assetregistry.subset.invalidName');
    } else if (!isNameAvailable) {
      return t('layouts.tabs.assetregistry.subset.unavailableName');
    }
    return null;
  };

  return (
    <Dialog
      dialogType={dialogType}
      title={
        isEditMode
          ? t('layouts.tabs.assetregistry.subset.editSubset')
          : t('layouts.tabs.assetregistry.subset.addSubset')
      }
      onClose={() => {
        if (isDropdownOpen) {
          setIsDropdownOpen(false);
        }
      }}
    >
      <Stack direction='column' spacing={2}>
        <div style={{ display: 'flex', width: '30%' }}>
          <InputComponentFactory
            data={payload}
            editProps={{
              type: 'string',
              errorMessage: displayNameInputErrorMessage()
            }}
            style={{ width: '50%' }}
            testId='dialog-input-name'
            value={payload.name}
            onChange={(newName) => setPayload({ ...payload, name: newName })}
          />
        </div>

        <Autocomplete
          ListboxProps={{
            style: {
              maxHeight: '30vh'
            }
          }}
          PaperComponent={CustomPaper}
          disableCloseOnSelect
          disablePortal={false}
          filterOptions={(options, state) => {
            autoCompleteSearchText.current = state.inputValue;

            const filteredOptions = options.filter((o) => o.toLowerCase().includes(state.inputValue.toLowerCase()));
            autoCompleteFilteredOptions.current = filteredOptions;

            return filteredOptions;
          }}
          forcePopupIcon={true}
          fullWidth
          multiple
          noOptionsText={t('layouts.tabs.assetregistry.subset.noAgingFamiliesOptions')}
          open={isDropdownOpen}
          options={agingFamilyList}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'dialog-input-autocomplete'
              }}
              label={t('layouts.tabs.assetregistry.subset.agingFamilySearch')}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              {option}
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.length <= 3
              ? value.map((option, index) => (
                  <Chip key={option + index} label={option} variant='outlined' {...getTagProps({ index })} />
                ))
              : value
                  .slice(0, 3)
                  .map((option, index) => (
                    <Chip key={option + index} label={option} variant='outlined' {...getTagProps({ index })} />
                  ))
                  .concat(
                    <Chip
                      key={'more'}
                      label={t('layouts.tabs.assetregistry.subset.more', { count: value.length - 3 })}
                      variant='outlined'
                    />
                  )
          }
          slotProps={{
            paper: {
              autoCompleteFilteredOptions,
              autoCompleteSearchText,
              isAllSelected: payload.agingFamilies.length === agingFamilyList.length,
              onSearchSelect: handleOnSearchSelect,
              onToggleSelectAll: handleSelectAll
            }
          }}
          value={payload.agingFamilies}
          onChange={handleAutocompleteChange}
          onClose={() => setIsDropdownOpen(false)}
          onOpen={() => setIsDropdownOpen(true)}
        />

        <Stack alignItems='center' direction='row' justifyContent='flex-end' spacing={2}>
          <Button
            aria-controls='dialog-cancel-button'
            aria-haspopup='true'
            color='primary'
            data-testid='dialog-cancel-button'
            variant='contained'
            onClick={() => hideDialog()}
          >
            {t('genericcomponent.dialog.warningExitWithoutSave.cancel')}
          </Button>
          <Button
            aria-controls='dialog-create-button'
            aria-haspopup='true'
            color='primary'
            data-testid='dialog-create-button'
            disabled={!canCreateSubset}
            sx={{ opacity: canCreateSubset ? 1 : 0.5 }}
            variant='contained'
            onClick={submit}
          >
            {isEditMode
              ? t('commoncomponents.dialog.create.scenario.button.edit')
              : t('commoncomponents.dialog.create.scenario.button.create')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
