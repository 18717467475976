import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SimpleTwoActionsDialogContent } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

function TechnicalPolicyCriterionTree(props) {
  const { criterions, criteriaFormula, updateCriteriaFormula } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [showDeleteConfirmationGroupPopUp, setShowDeleteConfirmationGroupPopUp] = useState(false);
  const [showDeleteConfirmationTriggerPopUp, setShowDeleteConfirmationTriggerPopUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);
  const [criteriaAutoMode, setCriteriaAutoMode] = useState(false);

  useEffect(() => {
    if (criteriaAutoMode) {
      initializeCriteriaFormulaAuto();
    } else if (criterions.length === 1 || criterions.length === 0) {
      setCriteriaAutoMode(true);
      initializeCriteriaFormulaAuto();
    }
  }, [criterions]);

  useEffect(() => {
    if (criteriaAutoMode) {
      initializeCriteriaFormulaAuto();
    } else {
      initializeCriteriaFormulaManual();
    }
  }, [criteriaAutoMode]);

  // Function to initialize criteriaFormula in AUTO mod
  function initializeCriteriaFormulaAuto() {
    if (criterions && criterions.length > 0) {
      const validCriterions = criterions.filter((criteria) => criteria.Name).map((criteria) => criteria.Name);
      const criteriaFormulaAuto = {
        AND: validCriterions
      };
      updateCriteriaFormula(criteriaFormulaAuto);
    }
  }

  // Function to initialize criteriaFormula in MANUAL mod
  function initializeCriteriaFormulaManual() {
    const validCriterions = criterions.filter((criteria) => criteria.Name).map((criteria) => criteria.Name);
    const criteriaFormulaManual = {
      AND: [validCriterions.length > 0 ? validCriterions[0] : '']
    };
    updateCriteriaFormula(criteriaFormulaManual);
  }

  // Function recursive to iterate criteria to display criteria formula
  function generatePreview(criteria) {
    if (!criteria) {
      return '';
    }

    const group = Object.keys(criteria)[0];
    const previewType =
      group === 'AND' ? ` ${t('views.library.technicalpolicy.and')} ` : ` ${t('views.library.technicalpolicy.or')} `;

    let criteriaFormulaPreview = '';
    if (typeof criteria[group] === 'object') {
      const conditions = criteria[group].map((condition) => {
        if (typeof condition === 'string') {
          const correspondingCriterion = criterions.find((criterion) => criterion.$dtId === condition);
          return correspondingCriterion ? correspondingCriterion.Name : condition;
        } else {
          return `(${generatePreview(condition)})`;
        }
      });
      criteriaFormulaPreview = `(${conditions.join(previewType)})`;
    }

    return criteriaFormulaPreview;
  }

  // Method to get criterions options list
  function getCriterionsOptionsList() {
    return criterions
      .filter((criterion) => criterion.Name)
      .sort((a, b) => (a.Name > b.Name ? 1 : -1))
      .map((criterion) => ({ key: criterion.Name, value: criterion.Name }));
  }

  // Function to open add criteria menu
  const handleButtonAddMenuClick = (event, indexArrayToString) => {
    setAnchorEl(event.currentTarget);
    setOpen(indexArrayToString);
  };

  // Function to close add criteria menu
  const handleAddMenuClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  // Function to get target criteria
  function getTargetCriteria(criteria, indexArray) {
    let targetCriteria = criteria;
    const targetParentCriteria = getTargetParentCriteria(criteria, indexArray);
    if (indexArray) {
      const targetParentCriteriaGroup = Object.keys(targetParentCriteria)[0];
      targetCriteria = targetParentCriteria[targetParentCriteriaGroup][indexArray[indexArray.length - 1]];
    }
    return targetCriteria;
  }

  // Function to get target parent criteria
  function getTargetParentCriteria(criteria, indexArray) {
    let targetParentCriteria = criteria;
    if (indexArray) {
      indexArray.forEach((iArray, i) => {
        if (i < indexArray.length - 1) {
          const targetParentCriteriaGroup = Object.keys(targetParentCriteria)[0];
          targetParentCriteria = targetParentCriteria[targetParentCriteriaGroup][iArray];
        }
      });
    }
    return targetParentCriteria;
  }

  // Function to add Trigger
  function addTreeTrigger(indexArray) {
    const newTrigger = '';
    const updatedCriteriaFormula = criteriaFormula;
    const targetCriteria = getTargetCriteria(updatedCriteriaFormula, indexArray);
    const targetCriteriaGroup = Object.keys(targetCriteria)[0];
    targetCriteria[targetCriteriaGroup].push(newTrigger);
    updateCriteriaFormula(updatedCriteriaFormula);
  }

  // Function to add Group
  function addTreeGroup(indexArray) {
    const newGroup = { AND: [] };
    const updatedCriteriaFormula = criteriaFormula;
    const targetCriteria = getTargetCriteria(updatedCriteriaFormula, indexArray);
    const targetCriteriaGroup = Object.keys(targetCriteria)[0];
    targetCriteria[targetCriteriaGroup].push(newGroup);
    updateCriteriaFormula(updatedCriteriaFormula);
  }

  // Function to handlechange of Trigger
  function handleChangeTreeTrigger(newTrigger, indexArray) {
    const updatedCriteriaFormula = criteriaFormula;
    const targetParentCriteria = getTargetParentCriteria(updatedCriteriaFormula, indexArray);
    const targetParentCriteriaGroup = Object.keys(targetParentCriteria)[0];
    const targetCriteriaIndex = indexArray[indexArray.length - 1];
    targetParentCriteria[targetParentCriteriaGroup].splice(targetCriteriaIndex, 1, newTrigger);
    updateCriteriaFormula(updatedCriteriaFormula);
  }

  // Function to handlechange of Group
  function handleChangeTreeGroup(newGroup, indexArray) {
    let updatedCriteriaFormula = criteriaFormula;
    if (indexArray) {
      const targetCriteria = getTargetCriteria(updatedCriteriaFormula, indexArray);
      const targetCriteriaGroup = Object.keys(targetCriteria)[0];
      const targetParentCriteria = getTargetParentCriteria(updatedCriteriaFormula, indexArray);
      const targetParentCriteriaGroup = Object.keys(targetParentCriteria)[0];
      const targetCriteriaIndex = indexArray[indexArray.length - 1];
      const newCriteriaGroup = {
        [newGroup]: targetCriteria[targetCriteriaGroup]
      };
      targetParentCriteria[targetParentCriteriaGroup].splice(targetCriteriaIndex, 1, newCriteriaGroup);
    } else {
      updatedCriteriaFormula = {
        [newGroup]: [...criteriaFormula[Object.keys(criteriaFormula)[0]]]
      };
    }
    updateCriteriaFormula(updatedCriteriaFormula);
  }

  // Function to delete Trigger
  function handleDeleteTreeCriteria(indexArray) {
    const updatedCriteriaFormula = criteriaFormula;
    const targetParentCriteria = getTargetParentCriteria(updatedCriteriaFormula, indexArray);
    const targetParentCriteriaGroup = Object.keys(targetParentCriteria)[0];
    const targetCriteriaIndex = indexArray[indexArray.length - 1];
    targetParentCriteria[targetParentCriteriaGroup].splice(targetCriteriaIndex, 1);
    updateCriteriaFormula(updatedCriteriaFormula);
  }

  // Function recursive to iterate criteria and display Tree
  function generateTree(criteria, indexArray) {
    const indexArrayToString = indexArray ? indexArray.join('-') : 'main';
    let criteriaIndexArray = null;
    if (indexArray) {
      criteriaIndexArray = [];
      indexArray.forEach((iArray) => criteriaIndexArray.push(iArray));
    }
    if (typeof criteria === 'object') {
      const treeClass = !criteriaIndexArray ? 'tree-limbs tree-limbs-first' : 'tree-limbs';
      const groupValue = Object.keys(criteria)[0];

      return (
        <div key={`group${indexArrayToString}`}>
          {/* <> Generate Group </> */}
          <div
            className={classes.flexBox}
            style={{
              marginLeft: indexArray
                ? indexArray.length * (indexArray.length > 1 ? 10 : 20) -
                  (indexArray.length > 2 ? indexArray.length * 3.5 : 0)
                : 40
            }}
          >
            <FormControl className={treeClass} variant='standard'>
              <InputLabel id='andor-select-label'>{t('views.library.technicalpolicy.type')}</InputLabel>
              <Select
                disableUnderline={true}
                id='andor-select'
                label='andor'
                labelId='andor-select-label'
                sx={{ minWidth: '4rem', marginRight: '0.5rem' }}
                value={groupValue}
                variant='standard'
                onChange={(event) => handleChangeTreeGroup(event.target.value, criteriaIndexArray)}
              >
                <MenuItem key='AND' value='AND'>
                  {t('views.library.technicalpolicy.and')}
                </MenuItem>
                <MenuItem key='OR' value='OR'>
                  {t('views.library.technicalpolicy.or')}
                </MenuItem>
              </Select>
            </FormControl>
            <div className={classes.flexBox}>
              <Button
                aria-controls={`menu-${indexArrayToString}`}
                aria-expanded={open === indexArrayToString}
                aria-haspopup='true'
                id={`add-button-menu-${indexArrayToString}`}
                onClick={(event) => handleButtonAddMenuClick(event, indexArrayToString)}
              >
                <ControlPointIcon />
              </Button>
              <Menu
                anchorEl={anchorEl}
                aria-labelledby={`add-button-menu-${indexArrayToString}`}
                id={`menu-${indexArrayToString}`}
                open={open === indexArrayToString}
                onClose={handleAddMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    addTreeTrigger(criteriaIndexArray);
                    handleAddMenuClose();
                  }}
                >
                  {t('views.library.technicalpolicy.addTrigger')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    addTreeGroup(criteriaIndexArray);
                    handleAddMenuClose();
                  }}
                >
                  {t('views.library.technicalpolicy.addGroup')}
                </MenuItem>
              </Menu>
            </div>
            {criteriaIndexArray ? (
              <div className={classes.flexBox}>
                <Button
                  id={`del-button${indexArrayToString}`}
                  onClick={() => setShowDeleteConfirmationGroupPopUp(indexArrayToString)}
                >
                  <DeleteIcon />
                </Button>
                <SimpleTwoActionsDialogContent
                  handleClickOnButton1={() => setShowDeleteConfirmationGroupPopUp(null)}
                  handleClickOnButton2={() => {
                    handleDeleteTreeCriteria(criteriaIndexArray);
                    setShowDeleteConfirmationGroupPopUp(null);
                  }}
                  id='delete-confirmation'
                  labels={{
                    title: t('genericcomponent.dialog.treeconditions.group.title', 'Confirm data suppression ?'),
                    body: t(
                      'genericcomponent.dialog.treeconditions.group.body',
                      'Would you like to delete this data ?'
                    ),
                    button1: t('genericcomponent.dialog.treeconditions.group.cancel', 'Cancel'),
                    button2: t('genericcomponent.dialog.treeconditions.group.delete', 'Delete'),
                    ariaLabelledby: 'confirm-delete-dialog',
                    button2BGColor: '#062F4F'
                  }}
                  open={showDeleteConfirmationGroupPopUp === indexArrayToString}
                />
              </div>
            ) : null}
          </div>
          {/* <> Generate Group's Criterions </> */}
          <div style={{ marginLeft: 60 }}>
            {criteria[groupValue].map((subCriteria, subCriteriaIndex) =>
              generateTree(
                subCriteria,
                criteriaIndexArray ? [...criteriaIndexArray, subCriteriaIndex] : [subCriteriaIndex]
              )
            )}
          </div>
        </div>
      );
    }

    const firstCriterionOptionsListValue = () => {
      getCriterionsOptionsList().at(0)?.key;
    };

    const emptyCriterionOptionsList = getCriterionsOptionsList().length === 0;

    // Generate Trigger
    return (
      <div key={`trigger${indexArrayToString}`}>
        <div className={classes.flexBox}>
          <FormControl className='tree-limbs-child' variant='standard'>
            <InputLabel id='condition-select-label'>{t('views.library.technicalpolicy.condition')}</InputLabel>
            <Select
              autoWidth={true}
              defaultValue={firstCriterionOptionsListValue}
              disableUnderline={true}
              disabled={emptyCriterionOptionsList}
              id='condition-select'
              label='condition'
              labelId='condition-select-label'
              sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'pre', minWidth: '4rem' }}
              value={criteria.Name}
              variant='standard'
              onChange={(event) => handleChangeTreeTrigger(event.target.value, indexArray)}
            >
              {getCriterionsOptionsList().map((criteriaOption) => (
                <MenuItem key={criteriaOption.key} value={criteriaOption.key}>
                  {criteriaOption.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            id={`del-button${indexArrayToString}`}
            onClick={() => setShowDeleteConfirmationTriggerPopUp(indexArrayToString)}
          >
            <DeleteIcon />
          </Button>
          <SimpleTwoActionsDialogContent
            handleClickOnButton1={() => setShowDeleteConfirmationTriggerPopUp(null)}
            handleClickOnButton2={() => {
              handleDeleteTreeCriteria(indexArray);
              setShowDeleteConfirmationTriggerPopUp(null);
            }}
            id='delete-confirmation'
            labels={{
              title: t('genericcomponent.dialog.treeconditions.condition.title', 'Confirm data suppression ?'),
              body: t('genericcomponent.dialog.treeconditions.condition.body', 'Would you like to delete this data ?'),
              button1: t('genericcomponent.dialog.treeconditions.condition.cancel', 'Cancel'),
              button2: t('genericcomponent.dialog.treeconditions.condition.delete', 'Delete'),
              ariaLabelledby: 'confirm-delete-dialog',
              button2BGColor: '#062F4F'
            }}
            open={showDeleteConfirmationTriggerPopUp === indexArrayToString}
          />
        </div>
      </div>
    );
  }

  function handleClickCriteriaMode(_element, value) {
    if (value && criteriaAutoMode !== true) {
      setCriteriaAutoMode(true);
    } else if (!value && criteriaAutoMode !== false) {
      setCriteriaAutoMode(false);
    }
  }

  return (
    <Grid className={classes.conditionTreeContainer} container={true}>
      <Grid className={classes.treeBlock} item={true} xs={6}>
        <ToggleButtonGroup
          className={classes.btnGroup}
          color='primary'
          exclusive={true}
          value={criteriaAutoMode}
          onChange={handleClickCriteriaMode}
        >
          <ToggleButton
            className={criteriaAutoMode === false ? classes.selectedMod : classes.unselectedMod}
            value={false}
          >
            {t('views.library.technicalpolicy.manual')}
          </ToggleButton>
          <ToggleButton
            className={criteriaAutoMode === true ? classes.selectedMod : classes.unselectedMod}
            value={true}
          >
            {t('views.library.technicalpolicy.auto')}
          </ToggleButton>
        </ToggleButtonGroup>
        {!criteriaAutoMode ? generateTree(criteriaFormula) : null}
      </Grid>
      <Grid className={classes.previewBlock} item={true} xs={6}>
        <p className={classes.textBlack}>{t('views.library.technicalpolicy.preview')}</p>
        <p className={classes.preview} data-testid='preview-paragraph'>
          {generatePreview(criteriaFormula)}
        </p>
      </Grid>
    </Grid>
  );
}

TechnicalPolicyCriterionTree.propTypes = {
  criterions: PropTypes.array,
  criteriaFormula: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  technicalPolicyForm: PropTypes.object,
  updateCriteriaFormula: PropTypes.func
};

export default TechnicalPolicyCriterionTree;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '24px 0'
  },
  addBtn: {
    // width: 240,
    alignSelf: 'end',
    marginTop: '-24px',
    marginBottom: 24,
    borderRadius: 50,
    border: '1.5px solid #062F4F',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    '& svg': {
      marginLeft: 8
    }
  },
  btnAndOr: {
    '& > button:first-child': {
      background: 'linear-gradient(90deg, #3A3A3A, #C4C4C4) !important',
      borderColor: 'transparent',
      borderRight: 'none'
    }
  },
  flexBox: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > button': {
      padding: '12px 0'
    }
  },
  conditionTreeContainer: {
    margin: '24px 0',
    '& .MuiGrid-item': {
      padding: '0 24px'
    }
  },
  treeBlock: {
    '& .MuiInput-formControl': {
      width: 215
    }
  },
  previewBlock: {
    borderLeft: `1px dashed ${theme.palette.nexans.black.main}`
  },
  textBlack: {
    color: theme.palette.text.black
  },
  preview: {
    color: theme.palette.text.black,
    padding: 8,
    borderRadius: 10,
    border: '1px solid gray',
    marginTop: 8
  },
  btnGroup: {
    marginBottom: 24
  },
  unselectedMode: {
    background: theme.palette.background.secondary
  },
  selectedMod: {
    background: theme.palette.background.gradient,
    color: `${theme.palette.text.white}!important`
  }
}));
