import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiFamily } from '../../../../api/ApiFamily';
import { FINANCE_ACTIONS_KEY } from '../../../commons/FinanceConstants';
import { selectOrganizationId } from '../../sagaUtils';

export function* getAllNetworkLevel() {
  try {
    const organizationId = yield selectOrganizationId();
    const data = yield call(ApiFamily.fetchAllNetworkLevel, organizationId);
    if (data?.length > 0) {
      yield put({
        type: FINANCE_ACTIONS_KEY.SET_ALL_NETWORK_LEVEL,
        networkLevelList: data
      });
    } else {
      yield put({
        type: FINANCE_ACTIONS_KEY.SET_ALL_NETWORK_LEVEL,
        networkLevelList: []
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function* findAllNetworkLevelData() {
  yield takeEvery(FINANCE_ACTIONS_KEY.GET_ALL_NETWORK_LEVEL, getAllNetworkLevel);
}

export default findAllNetworkLevelData;
